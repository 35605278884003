import { MDBBtn } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../utils/Url';

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false); 
  const navigate = useNavigate();




  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/admin');
    }

    const rememberMeChecked = localStorage.getItem('rememberMe') === 'true';
    if (rememberMeChecked) {
      const storedEmail = localStorage.getItem('email');
      const storedPassword = localStorage.getItem('password');
      if (storedEmail && storedPassword) {
        setEmail(storedEmail);
        setPassword(storedPassword);
      }
    }
  }, [navigate]);

  

  const handleLogin = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/clogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMe', true);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }
      alert('Login successful');
      navigate('/admin');
    } catch (error) {
      console.error('There was an error!', error);
      alert('Login failed. Please check your credentials.');
    }
  };

  return (
    <div id='LoginMainBox'>
      <div id='LoginBoxCol'>
        <img src='loginSideImg.png' alt='loginSideImg' className='w-100 h-100' />
      </div>
      <div id='LoginBoxCol'>
        <div className='text-center'>
          <img src='logo_skylab.png' alt='logo_skylab.png' id='loginLogoImg' loading="lazy" />
          <p className='fs-5 my-4 signupText'>Login to Admin access control </p>
          <input placeholder='Enter your email' className='loginInputField' type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <input placeholder='Password' className='loginInputField' type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />

          <div>
            <label htmlFor="rememberMe">Remember Me</label>
            <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
          </div>

          <MDBBtn type='button' onClick={handleLogin} className='rounded-pill py-1 my-2 px-5' id='loginButton'>Login</MDBBtn>
          <p>Don't have an account? </p>
        </div>
      </div>
    </div>
  );
}
