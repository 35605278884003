import React, { useState } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import UserExportData from "./components/UserExportData";
import UserNavbar from "../UserSidebar/UserNavbar";
import UserSidebar from "../UserSidebar/UserSidebar";


export default function UserExport() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div id="admin-body">
      <UserNavbar/>
      <div style={{ display: "flex" }}>
        <UserSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginTop: "30px",
            marginLeft: isSidebarOpen ? "13%" : "4.5%",
          }}
        >
          <MDBRow >
            <MDBCol>
              <h1>Export  Data</h1>
              <UserExportData />
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </div>
  );
}
