import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
    MDBIcon,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBCarousel,
    MDBCarouselItem,
} from "mdb-react-ui-kit";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { Settings } from "@mui/icons-material";
import FilterIcon from "@mui/icons-material/Filter";
import SuperAdminTrackOrder from "../../../Super_Admin/CreateOrder/components/SuperAdminTrackOrder";
import SuperAdminChat from "../../../Super_Admin/CreateOrder/components/SuperAdminChat";
import { Loader } from "../../../Super_Admin/SVG/Svg";
import { baseUrl } from "../../../utils/Url";

export default function AdminCancleTable() {
    const [remittanceData, setRemittanceData] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedJewelryType, setSelectedJewelryType] = useState("all");
    const [selectedJewelrySubType, setSelectedJewelrySubType] = useState("all");
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedJewelrySubTypeFilter, setSelectedJewelrySubTypeFilter] = useState("all");
    const [selectedShapeTypeFilter, setSelectedShapeTypeFilter] = useState("all");
    const [selectedMetalTypeFilter, setSelectedMetalTypeFilter] = useState("all");
    const [refLotIdFilter, setRefLotIdFilter] = useState("");
    const [jewelrySizeFilter, setJewelrySizeFilter] = useState("");
    const [diamondSizeFilter, setDiamondSizeFilter] = useState("");
    const [filterQty, setFilterQty] = useState("");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [showStatusFilter, setShowStatusFilter] = useState(false);
    const [showTypeFilter, setShowTypeFilter] = useState(false);
    const [showSubTypeFilter, setShowSubTypeFilter] = useState(false);
    const [showLotIDFilter, setShowLotIDFilter] = useState(false);
    const [showShape_TypeFilter, setshowShape_TypeFilter] = useState(false);
    const [showMetal_TypeFilter, setshowMetal_TypeFilter] = useState(false);
    const [showJewelry_SizeFilter, setshowJewelry_SizeFilter] = useState(false);
    const [showDiamondSizeFilter, setshowDiamondSizeFilter] = useState(false);
    const [showQtyFilter, setshowQtyFilter] = useState(false);
    const [clientIdFilter, setClientIdFilter] = useState("");
    const [showClientIdFilter, setShowClientIdFilter] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [centredModal, setCentredModal] = useState(false);
    const [selectedRequestStatus, setSelectedRequestStatus] = useState("");
    const [selectedItemId, setSelectedItemId] = useState("");
    const [basicModal, setBasicModal] = useState(false);
    const [centredModal1, setCentredModal1] = useState(false);
    const [selectedRemittance, setSelectedRemittance] = useState(null);
    const [isEditing, setIsEditing] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [imageSource, setImageSource] = useState("");
    const [trackingData, setTrackingData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [chatData, setChatData] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showE_DateFilter, setShowE_DateFilter] = useState(false);
    const [startE_Date, setStartE_Date] = useState(null);
    const [endE_Date, setEndE_Date] = useState(null);
    const [showDe_DateFilter, setShowDe_DateFilter] = useState(false);
    const [startDe_Date, setStartDe_Date] = useState(null);
    const [endDe_Date, setEndDe_Date] = useState(null);
    const [showOrderNoFilter, setShowOrderNoFilter] = useState(false);
    const [orderNo, setOrderNoFilter] = useState("");
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [userScrolledUp, setUserScrolledUp] = useState(false);
    const [showClientNameFilter, setShowClientNameFilter] = useState(false);
    const [clientNameFilter, setClientNameFilter] = useState("");
    const [unreadMessages, setUnreadMessages] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    // const [idToDelete, setIdToDelete] = useState(null);
    const dropdownRef = useRef(null);
    const chatBoxRef = useRef(null);
    const [showBag_Filter, setShowBag_Filter] = useState(false);
    const [bagFilter, setBagFilter] = useState("");
    const [showPoNo_Filter, setShowPoNo_Filter] = useState(false);
    const [poNoFilter, setPoNoFilter] = useState("");
    const [showDesignNeed_Filter, setShowDesignNeed_Filter] = useState(false);
    const [designNeedFilter, setDesignNeedFilter] = useState("");
    const [selectedSetting, setSelectedSetting] = useState("all");
    const [showSettingFilter, setShowSettingFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [showRef_byFilter, setShowRef_byFilter] = useState(false);
    const [selectedRef_byType, setSelectedRef_byType] = useState("all");
    const [showCreate_byFilter, setShowCreate_byFilter] = useState(false);
    const [selectedCreate_byType, setSelectedCreate_byType] = useState("all");
    const [showFullValue, setShowFullValue] = useState(false);
    const [showLotNameFilter, setShowLotNameFilter] = useState(false);
    const [refLotNameFilter, setRefLotNameFilter] = useState("");
    const [isLot_NameEditing, setIsLot_NameEditing] = useState(
        new Array(remittanceData.length).fill(false)
    );
    const [valuesLotName, setValuesLotName] = useState(remittanceData.map(() => ""));

    const handleDoubleLotNameClick = (index) => {
        const newEditLotNameModes = [...isLot_NameEditing];
        newEditLotNameModes[index] = true;
        setIsLot_NameEditing(newEditLotNameModes);
    };

    const handleLotnameChange = (e, index) => {
        const newLotnameValues = [...valuesLotName];
        newLotnameValues[index] = e.target.value;
        setValuesLotName(newLotnameValues);
    };

    const handleLotNameBlur = (index, lotNameId) => {
        const newEditLotNameModes = [...isLot_NameEditing];
        newEditLotNameModes[index] = false;
        sendLotNameToBackend(valuesLotName[index], lotNameId);

        setIsLot_NameEditing(newEditLotNameModes);
    };


    const sendLotNameToBackend = (lotName, lotNameId) => {
        const token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({ Lotname: lotName }),
            redirect: "follow",
        };

        fetch(
            `${baseUrl}/api/update_custom_order/${lotNameId}`,
            requestOptions
        )
            .then((response) => {
                if (response.ok) {
                    fetchRemittanceData();
                } else {
                    throw new Error(
                        `Failed to post status: ${response.status} ${response.statusText}`
                    );
                }
            })
            .catch((error) => {
                console.error("Error posting status:", error);
            });
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenDropdownIndex(null);
            setIsEditing(null);
            setShowInput(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mouseover", handleClickOutside);
        };
    }, []);

    const clearOrderNoFilter = () => {
        setOrderNoFilter("");
        setShowOrderNoFilter(false);
    };

    const handleOrderNoClose = () => {
        clearOrderNoFilter();
    };

    const handleOrderNoFilterChange = (e) => {
        const value = e.target.value;
        setOrderNoFilter(value.startsWith('J') ? value.slice(1) : value);
        setCurrentPage(1);
    };

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleUpload = async (id) => {
        if (selectedFiles) {
            setUploading(true);
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("finalImg[]", selectedFiles[i]);
            }
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(
                    `${baseUrl}/api/upload-final-media/${id}`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to upload files");
                }

                setSelectedFiles(null);
                setUploading(false);
            } catch (error) {
                console.error("Upload failed:", error);
                setUploading(false);
            }
        }
    };

    const handleChat = async (chatId) => {
        try {
            const token = localStorage.getItem("token");
            const userCode = localStorage.getItem("userCode");

            const [chatResponse, markReadResponse] = await Promise.all([
                axios.get(
                    `${baseUrl}/api/order-chat/show/${chatId}`,
                    {
                        params: { user_id: userCode },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                ),
                // axios.get(
                //   `${baseUrl}/api/order-chat/message-read/${chatId}`,
                //   {
                //     params: { user_id: userCode },
                //     headers: {
                //       Authorization: `Bearer ${token}`,
                //       "Content-Type": "application/json",
                //     },
                //   }
                // ),
            ]);

            if (chatResponse.status === 200) {
                setChatData(chatResponse.data);
            } else {
                throw new Error(
                    `Failed to fetch status: ${chatResponse.status} ${chatResponse.statusText}`
                );
            }

            if (markReadResponse.status !== 200) {
                throw new Error(
                    `Failed to mark messages as read: ${markReadResponse.status} ${markReadResponse.statusText}`
                );
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDataChat = useCallback(async () => {
        if (chatId) {
            await handleChat(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        fetchDataChat();

        const interval = setInterval(() => {
            fetchDataChat();
        }, 3000);

        return () => clearInterval(interval);
    }, [fetchDataChat]);

    const handleScroll = () => {
        setUserScrolledUp(true);
    };

    useEffect(() => {
        if (!userScrolledUp && chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [userScrolledUp]);

    const handleTrackOrderClick = async (remittanceId) => {
        setLoading1(true);
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${baseUrl}/api/track-corder/${remittanceId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setTrackingData(response.data);
            } else {
                throw new Error(
                    `Failed to fetch status: ${response.status} ${response.statusText}`
                );
            }
        } catch (error) {
            console.error("Error fetching status:", error);
        } finally {
            setLoading1(false);
        }
    };

    const isBeforeDays = (estDate) => {
        if (!estDate) {
            return {};
        }

        const [day, month, year] = estDate.split("-");
        const estimatedDate = new Date(year, month - 1, day);
        const deadlineDate = new Date(estimatedDate);
        deadlineDate.setDate(deadlineDate.getDate() - 5);

        if (deadlineDate >= new Date()) {
            return {};
        } else if (
            deadlineDate >= new Date(new Date().setDate(new Date().getDate() - 5))
        ) {
            return { color: "orange" };
        } else {
            return { color: "red" };
        }
    };

    const handleOpenImageModal = (src) => {
        setImageSource(src);
        setShowImageModal(true);
    };

    const handleOpenModal = (remittanceData) => {
        setSelectedRemittance(remittanceData);
        toggleOpen();
    };

    const toggleOpen1 = () => setCentredModal1(!centredModal1);

    const toggleOpen = () => {
        setBasicModal(!basicModal);
    };

    const formatDate = (dateString, dateType) => {
        if (!dateString) return "N/A";

        if (dateType === "estDate") {
            const [day, month, year] = dateString.split("-");
            return `${month}-${day}-${year}`;
        } else {
            const [year, month, day] = dateString.split("-");
            return `${month}-${day}-${year}`;
        }
    };

    const fetchChatData = async () => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            const userRoleId = localStorage.getItem("userRoleId");
            const role_type = localStorage.getItem("userCode");

            const queryParams = new URLSearchParams({
                user_id: role_type,
                role_type: userRoleId,
            }).toString();

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const url = `${baseUrl}/api/order-chat/message-unread/count?${queryParams}`;

            const response = await fetch(url, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                const unreadMessagesData = {};
                responseData.data.forEach((item) => {
                    unreadMessagesData[item.order_id] = item.unread_message;
                });
                setUnreadMessages(unreadMessagesData);
            } else {
                console.error("Error fetching remittance data:", response.error);
            }
        } catch (error) {
            console.error("Error fetching remittance data:", error);
        }
    };

    useEffect(() => {
        fetchChatData();
        const intervalId = setInterval(fetchChatData, 10000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const getTotalUnreadMessages = () => {
        const unreadCounts = Object.values(unreadMessages);
        return unreadCounts.reduce((total, count) => total + count, 0);
    };

    // const renderUnreadMessageCount = () => {
    //   const totalUnread = getTotalUnreadMessages();
    //   if (totalUnread > 0) {
    //     return (
    //       <span className="text-danger text-capitalize fw-bold">{`You have ${totalUnread} unread message(s)`}</span>
    //     );
    //   }
    //   return null;
    // };

    const renderRedDot = (orderId) => {
        const unreadCount = unreadMessages[orderId] || 0;
        if (unreadCount > 0) {
            return <span className="red-dot position-absolute"></span>;
        }
        return null;
    };

    const fetchRemittanceData = async () => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            const response = await fetch(
                `${baseUrl}/api/get_customize_order_list`,
                requestOptions
            );

            if (response.ok) {
                const responseData = await response.json();
                setRemittanceData(responseData);
            } else {
                console.error("Error fetching remittance data:", response.error);
            }
        } catch (error) {
            console.error("Error fetching remittance data:", error);
        }
    };

    const fetchData = async () => {
        await Promise.all([fetchRemittanceData(), handleTrackOrderClick()]);
    };

    useEffect(() => {
        setLoading(true);
        fetchRemittanceData().then(() => setLoading(false));

        fetchData();
        fetchRemittanceData();

        const intervalId = setInterval(fetchRemittanceData, 10000);
        return () => clearInterval(intervalId);
    }, []);

    // const handleDelete = async () => {
    //   try {
    //     const token = localStorage.getItem("token");
    //     const myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Bearer ${token}`);

    //     const requestOptions = {
    //       method: "DELETE",
    //       headers: myHeaders,
    //       redirect: "follow",
    //     };

    //     const response = await fetch(
    //       `${baseUrl}/api/corders/${idToDelete}`,
    //       requestOptions
    //     );
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok.");
    //     }

    //     // Assuming remittanceData is also a state variable
    //     const newDataArray = remittanceData.filter(
    //       (data) => data.id !== idToDelete
    //     );
    //     // Update state with the new data
    //     setRemittanceData(newDataArray);

    //     // Close the popup
    //     setShowPopup(false);
    //   } catch (error) {
    //     console.error("Error deleting user:", error);
    //   }
    // };

    const handleYes = () => {
        setShowPopup(false);
        // handleDelete();
    };

    const handleNo = () => {
        setShowPopup(false);
    };

    const saveChanges = () => {
        const token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({ requestStatus: selectedRequestStatus }),
            redirect: "follow",
        };

        fetch(
            `${baseUrl}/api/update_custom_order/${selectedItemId}`,
            requestOptions
        )
            .then((response) => {
                if (response.ok) {
                    closeModal();
                    setOpenDropdownIndex(null);
                    setSelectedRequestStatus("");
                } else {
                    throw new Error(
                        `Failed to post status: ${response.status} ${response.statusText}`
                    );
                }
            })
            .catch((error) => {
                console.error("Error posting status:", error);
            });
    };

    const handleSelectChange = (e) => {
        const { value } = e.target;
        setSelectedRequestStatus(value);
        setCentredModal(true);
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
        setCurrentPage(1);
    };

    const handleRefLotNameFilterChange = (e) => {
        setRefLotNameFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setCurrentPage(1);
    };

    const handleStartE_DateChange = (e) => {
        setStartE_Date(e.target.value);
        setCurrentPage(1);
    };
    const handleStartDe_DateChange = (e) => {
        setStartDe_Date(e.target.value);
        setCurrentPage(1);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setCurrentPage(1);
    };

    const handleEndE_DateChange = (e) => {
        setEndE_Date(e.target.value);
        setCurrentPage(1);
    };
    const handleEndDe_DateChange = (e) => {
        setEndDe_Date(e.target.value);
        setCurrentPage(1);
    };

    const handleJewelryTypeChange = (e) => {
        setSelectedJewelryType(e.target.value);
        setCurrentPage(1);
    };

    const handleRef_byTypeChange = (e) => {
        setSelectedRef_byType(e.target.value);
        setCurrentPage(1);
    };

    const handleCreate_byTypeChange = (e) => {
        setSelectedCreate_byType(e.target.value);
        setCurrentPage(1);
    };

    const handleJewelrySubTypeFilterChange = (e) => {
        setSelectedJewelrySubTypeFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleSettingFilterChange = (e) => {
        setSelectedSetting(e.target.value);
        setCurrentPage(1);
    };

    const handleShapeTypeFilterChange = (e) => {
        setSelectedShapeTypeFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleMetalTypeFilterChange = (e) => {
        setSelectedMetalTypeFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleRefLotIdFilterChange = (e) => {
        setRefLotIdFilter(e.target.value);
        setCurrentPage(1);
    };
    const handleJewelry_SizeFilterChange = (e) => {
        setJewelrySizeFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleDiamondSizeChange = (e) => {
        setDiamondSizeFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleQtyChange = (e) => {
        setFilterQty(e.target.value);
        setCurrentPage(1);
    };

    const handleClientIdFilterChange = (e) => {
        setClientIdFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleClientNameFilterChange = (event) => {
        const values = event.target.value;
        setClientNameFilter(values);
        setCurrentPage(1);
    };
    const handleBagFilterChange = (e) => {
        setBagFilter(e.target.value);
        setCurrentPage(1);
    };
    const handlePoNoFilterChange = (e) => {
        setPoNoFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleDesignNeedFilterChange = (event) => {
        const values = event.target.value;
        setDesignNeedFilter(values);
        setCurrentPage(1);
    };

    const handleDropdownClick = (e, index, itemId) => {
        e.stopPropagation();
        setOpenDropdownIndex(index);
        setSelectedItemId(itemId);
    };

    const closeModal = () => {
        setCentredModal(false);
    };

    const filteredRemittanceData = remittanceData.filter((item) => {

        const toLowerCase = (str) =>
            typeof str === "string" ? str.toLowerCase() : str;

        if (
            clientNameFilter &&
            !item.company.toLowerCase().includes(clientNameFilter.toLowerCase())
        ) {
            return false;
        }

        if (
            designNeedFilter !== undefined &&
            designNeedFilter !== null &&
            typeof designNeedFilter === "string" &&
            item.DesignNeed !== undefined &&
            item.DesignNeed !== null &&
            typeof item.DesignNeed === "string" &&
            !item.DesignNeed.toLowerCase().includes(designNeedFilter.toLowerCase()) &&
            item.DesignNeed.toLowerCase() !== "n/a" // Exclude 'N/A' values
        ) {
            return false;
        }

        if (showBag_Filter && bagFilter && item.bagNo != bagFilter) {
            return false;
        }

        if (showPoNo_Filter && poNoFilter && item.PoNo != poNoFilter) {
            return false; // Exclude items with bagNo not matching the filter
        }

        if (
            selectedCreate_byType !== "all" &&
            item.created_by !== selectedCreate_byType
        ) {
            return false;
        }

        if (startE_Date && endE_Date) {
            const requestE_Date = item?.estDate;
            if (!requestE_Date) return false;

            const [day, month, year] = requestE_Date.split("-");
            const requestE_DateObj = new Date(`${year}-${month}-${day}`);
            const startE_DateObj = new Date(startE_Date);
            const endE_DateObj = new Date(endE_Date);

            return (
                requestE_DateObj >= startE_DateObj && requestE_DateObj <= endE_DateObj
            );
        }

        const requestDe_Date = new Date(item?.deliveryDate);
        const startDe_DateObj = new Date(startDe_Date);
        const endDe_DateObj = new Date(endDe_Date);

        if (orderNo && item.id != orderNo) {
            return false;
        }

        if (startDe_Date && endDe_Date) {
            return requestDe_Date >= startDe_DateObj && requestDe_Date <= endDe_DateObj;
        } else if (!startDe_Date && endDe_Date) {
            return requestDe_Date <= endDe_DateObj;
        } else if (startDe_Date && !endDe_Date) {
            return requestDe_Date >= startDe_DateObj;
        }

        if (
            selectedJewelryType !== "all" &&
            item.jewelryType !== selectedJewelryType
        ) {
            return false;
        }

        if (selectedRef_byType !== "all" && item.ref_by !== selectedRef_byType) {
            return false;
        }

        if (
            selectedJewelrySubType !== "all" &&
            item.jewelrySubType !== selectedJewelrySubType
        ) {
            return false;
        }
        // if (
        //   selectedJewelryTypeFilter !== "all" &&
        //   item.jewelryType !== selectedJewelryTypeFilter
        // ) {
        //   return false;
        // }
        if (
            selectedJewelrySubTypeFilter !== "all" &&
            item.jewelrySubType !== selectedJewelrySubTypeFilter
        ) {
            return false;
        }
        if (
            selectedShapeTypeFilter !== "all" &&
            item.shapeType !== selectedShapeTypeFilter
        ) {
            return false;
        }
        if (
            selectedMetalTypeFilter !== "all" &&
            item.metalType !== selectedMetalTypeFilter
        ) {
            return false;
        }

        if (selectedSetting !== "all" && item.setting !== selectedSetting) {
            return false;
        }

        if (
            refLotNameFilter !== "" &&
            toLowerCase(item.Lotname) !== toLowerCase(refLotNameFilter)
        ) {
            return false;
        }

        if (
            refLotIdFilter !== "" &&
            toLowerCase(item.refLotId) !== toLowerCase(refLotIdFilter)
        ) {
            return false;
        }
        // Convert jewelry size to lower case for case-insensitive comparison
        const itemJewelrySize = item.jewelrySize
            ? item.jewelrySize.toLowerCase()
            : null;

        // Check if the jewelry size matches the filter value
        if (jewelrySizeFilter) {
            // If the filter value is numeric, compare it directly with the jewelry size
            if (!isNaN(jewelrySizeFilter)) {
                if (!itemJewelrySize) return false; // Skip items without a jewelry size
                // Convert the jewelry size to a numeric value for comparison
                const numericFilter = parseFloat(jewelrySizeFilter);
                // Extract the numeric value from the jewelry size (assuming it always ends with 'mm' or 'Inch')
                const numericJewelrySize = parseFloat(itemJewelrySize.split(" ")[0]);
                // Compare the numeric filter value with the numeric jewelry size
                if (numericJewelrySize !== numericFilter) {
                    return false;
                }
            } else {
                // If the filter value is not numeric, compare it with the jewelry size as a string
                if (itemJewelrySize !== jewelrySizeFilter.toLowerCase()) {
                    return false;
                }
            }
        }

        if (
            diamondSizeFilter &&
            toLowerCase(item.diamondApoSize) !== toLowerCase(diamondSizeFilter)
        ) {
            return false;
        }

        if (filterQty !== "" && parseFloat(item.qty) !== parseFloat(filterQty)) {
            return false;
        }
        if (
            clientIdFilter &&
            parseFloat(item.code) !== parseFloat(clientIdFilter)
        ) {
            return false;
        }

        if (selectedStatus !== "all") {
            if (selectedStatus === "Req_Pending" || selectedStatus === null) {
                if (!item.id || !item.requestStatus) {
                    return true;
                } else if (item.requestStatus !== "Req_Pending") {
                    return false;
                }
            } else if (item.requestStatus !== selectedStatus) {
                return false;
            }
        }

        if (startDate && endDate) {
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const requestDate = new Date(item.requestDate);

            if (requestDate >= startDateObj && requestDate <= endDateObj) {
                return true;
            } else {
                return false;
            }
        } else if (!startDate && !endDate) {
            return true;
        } else if (!startDate && endDate) {
            const endDateObj = new Date(endDate);
            const requestDate = new Date(item.requestDate);
            return requestDate <= endDateObj;
        } else if (startDate && !endDate) {
            const startDateObj = new Date(startDate);
            const requestDate = new Date(item.requestDate);
            return requestDate >= startDateObj;
        }

        return true;
    });



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentRemittanceData = filteredRemittanceData
        .filter(remittance => remittance.requestStatus === "Canceled Order")
        .reverse()
        .slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(
        remittanceData.filter(remittance => remittance.requestStatus === "Canceled Order").length / itemsPerPage
    );

    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const jewelryTypes = [
        ...new Set(remittanceData.map((item) => item.jewelryType)),
    ];
    const ref_by = [...new Set(remittanceData.map((item) => item.ref_by))];
    const create_by = [...new Set(remittanceData.map((item) => item.created_by))];
    const jewelrySubTypes = [
        ...new Set(remittanceData.map((item) => item.jewelrySubType)),
    ];

    const setting = [...new Set(remittanceData.map((item) => item.setting))];
    const shapeTypes = [...new Set(remittanceData.map((item) => item.shapeType))];
    const metalTypes = [...new Set(remittanceData.map((item) => item.metalType))];

    const handleCheckboxChange = (e, id) => {
        const checked = e.target.checked;

        if (selectAllChecked && !checked) {
            setSelectAllChecked(false);
        }

        setSelectedRows((prevSelectedRows) => {
            if (checked && !prevSelectedRows.includes(id)) {
                return [...prevSelectedRows, id];
            } else {
                return prevSelectedRows.filter((item) => item !== id);
            }
        });
    };

    const handleSelectAll = () => {
        if (!selectAllChecked || selectedRows.length !== remittanceData.length) {
            const allOrderNumbers = remittanceData.map((item) => item.id);
            setSelectedRows(allOrderNumbers);
            setSelectAllChecked(true);
        } else {
            setSelectedRows([]);
            setSelectAllChecked(false);
        }
    };

    const downloadSelectedCSV = () => {
        let selectedData = [];

        if (selectAllChecked) {

            selectedData = remittanceData.filter((item) => {
                const passesClientIdFilter = !clientIdFilter || item.code === clientIdFilter;
                const passesClientNameFilter = !clientNameFilter || item.company.toLowerCase().includes(clientNameFilter.toLowerCase());
                const passesOrderNoFilter = !orderNo || item.id == orderNo;

                const passesRequestDateFilter = !startDate || !endDate || (
                    new Date(item.requestDate) >= new Date(startDate) && new Date(item.requestDate) <= new Date(endDate)
                );

                const passesEstDateFilter = !startE_Date || !endE_Date || (
                    item.estDate &&
                    new Date(item.estDate.split("-").reverse().join("-")) >= new Date(startE_Date) &&
                    new Date(item.estDate.split("-").reverse().join("-")) <= new Date(endE_Date)
                );

                const passesDeliveryDateFilter = !startDe_Date || !endDe_Date || (
                    item.deliveryDate &&
                    new Date(item.deliveryDate) >= new Date(startDe_Date) && new Date(item.deliveryDate) <= new Date(endDe_Date)
                );

                const passesStatusFilter = selectedStatus === "all" || (
                    selectedStatus === "Req_Pending" && (!item.requestStatus || item.requestStatus === "Req_Pending")
                ) || item.requestStatus === selectedStatus;

                const passesJewelryTypeFilter = selectedJewelryType === "all" || item.jewelryType === selectedJewelryType;
                const passesRefByTypeFilter = selectedRef_byType === "all" || item.ref_by === selectedRef_byType;
                const passesCreateByTypeFilter = selectedCreate_byType === "all" || item.created_by === selectedCreate_byType;
                const passesSettingFilter = selectedSetting === "all" || item.setting === selectedSetting;
                const passesJewelrySubTypeFilter = selectedJewelrySubTypeFilter === "all" || item.jewelrySubType === selectedJewelrySubTypeFilter;
                const passesRefLotNameFilter =
                    !refLotNameFilter || (item.Lotname && item.Lotname.toLowerCase() === refLotNameFilter.toLowerCase());
                const passesRefLotIdFilter =
                    !refLotIdFilter || (item.refLotId && item.refLotId.toLowerCase() === refLotIdFilter.toLowerCase());
                const passesShapeTypeFilter = selectedShapeTypeFilter === "all" || item.shapeType === selectedShapeTypeFilter;
                const passesMetalTypeFilter = selectedMetalTypeFilter === "all" || item.metalType === selectedMetalTypeFilter;
                const passesJewelrySizeFilter =
                    !jewelrySizeFilter || (item.jewelrySize && item.jewelrySize.toLowerCase() === jewelrySizeFilter.toLowerCase());
                const passesDiamondSizeFilter =
                    !diamondSizeFilter || (item.diamondApoSize && item.diamondApoSize.toLowerCase() === diamondSizeFilter.toLowerCase());
                const passesQtyFilter = !filterQty || item.qty === parseInt(filterQty);
                const passesBagFilter = !bagFilter || item.bagNo === bagFilter;
                const passesPoNoFilter = !poNoFilter || item.PoNo === poNoFilter;
                const passesDesignNeedFilter = !designNeedFilter || (
                    item.DesignNeed &&
                    item.DesignNeed.toLowerCase().includes(designNeedFilter.toLowerCase()) && item.DesignNeed.toLowerCase() !== "n/a"
                );

                const passesFilter =
                    passesClientIdFilter &&
                    passesClientNameFilter &&
                    passesOrderNoFilter &&
                    passesRequestDateFilter &&
                    passesEstDateFilter &&
                    passesDeliveryDateFilter &&
                    passesStatusFilter &&
                    passesJewelryTypeFilter &&
                    passesRefByTypeFilter &&
                    passesCreateByTypeFilter &&
                    passesSettingFilter &&
                    passesJewelrySubTypeFilter &&
                    passesRefLotNameFilter &&
                    passesRefLotIdFilter &&
                    passesShapeTypeFilter &&
                    passesMetalTypeFilter &&
                    passesJewelrySizeFilter &&
                    passesDiamondSizeFilter &&
                    passesQtyFilter &&
                    passesBagFilter &&
                    passesPoNoFilter &&
                    passesDesignNeedFilter;

                if (!passesFilter) {
                    console.log({
                        passesClientIdFilter,
                        passesClientNameFilter,
                        passesOrderNoFilter,
                        passesRequestDateFilter,
                        passesEstDateFilter,
                        passesDeliveryDateFilter,
                        passesStatusFilter,
                        passesJewelryTypeFilter,
                        passesRefByTypeFilter,
                        passesCreateByTypeFilter,
                        passesSettingFilter,
                        passesJewelrySubTypeFilter,
                        passesRefLotNameFilter,
                        passesRefLotIdFilter,
                        passesShapeTypeFilter,
                        passesMetalTypeFilter,
                        passesJewelrySizeFilter,
                        passesDiamondSizeFilter,
                        passesQtyFilter,
                        passesBagFilter,
                        passesPoNoFilter,
                        passesDesignNeedFilter
                    });
                }

                return passesFilter;
            });
        } else {

            selectedData = remittanceData.filter((item) => {
                const passesFilter =
                    selectedRows.includes(item.id) &&
                    (selectedStatus === "all" ||
                        (selectedStatus === "Req_Pending" &&
                            (!item.requestStatus || item.requestStatus === "Req_Pending")) ||
                        item.requestStatus === selectedStatus) &&
                    (!startDe_Date || !endDe_Date || (item.deliveryDate >= new Date(startDe_Date) && item.deliveryDate <= new Date(endDe_Date)));

                if (!passesFilter) {
                    console.log("Item did not pass filter:", item);
                }
                return passesFilter;
            });
        }

        // Filter data with requestStatus as "Export"
        selectedData = selectedData.filter((item) => {
            const isCanceledOrder = item.requestStatus === "Canceled Order";
            if (isCanceledOrder) {
                console.log("Item has requestStatus 'Export':", item);
            }
            return isCanceledOrder;
        });


        // Check if any data is selected
        if (selectedData.length === 0) {
            console.error("No data found for the selected criteria.");
            return;
        }

        // Convert data to CSV format
        const headings = Object.keys(selectedData[0]).join(",");
        const data = selectedData
            .map((item) => Object.values(item).join(","))
            .join("\n");

        // Create CSV content and download link
        const csvContent = "data:text/csv;charset=utf-8," + headings + "\n" + data;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "selected_remittance_data.csv");
        document.body.appendChild(link);
        link.click();
    };

    const clearDateFilters = () => {
        setStartDate("");
        setEndDate("");
        setShowDateFilter(false);
    };

    const clearE_DateFilters = () => {
        setStartE_Date("");
        setEndE_Date("");
        setShowE_DateFilter(false);
    };
    const clearDe_DateFilters = () => {
        setStartDe_Date("");
        setEndDe_Date("");
        setShowDe_DateFilter(false);
    };

    // Function to clear status filter
    const clearStatusFilter = () => {
        setSelectedStatus("all");
        setShowStatusFilter(false);
    };

    const clearRefLotNameFilter = () => {
        setRefLotNameFilter("");
        setShowLotNameFilter(false);
    };


    // Function to clear jewelry type filter
    const clearJewelryTypeFilter = () => {
        setSelectedJewelryType("all");
        setShowTypeFilter(false);
    };

    const clearRef_byTypeFilter = () => {
        setSelectedRef_byType("all");
        setShowRef_byFilter(false);
    };

    const clearCreate_byTypeFilter = () => {
        setSelectedCreate_byType("all");
        setShowCreate_byFilter(false);
    };

    const clearSettingFilter = () => {
        setSelectedSetting("all");
        setShowSettingFilter(false);
    };

    // Function to clear jewelry sub type filter
    const clearJewelrySubTypeFilter = () => {
        setSelectedJewelrySubType("all");
        setShowSubTypeFilter(false);
        setSelectedJewelrySubTypeFilter("all");
    };

    // Function to clear ref lot ID filter
    const clearRefLotIdFilter = () => {
        setRefLotIdFilter("");
        setShowLotIDFilter(false);
    };

    // Function to clear shape type filter
    const clearShapeTypeFilter = () => {
        setSelectedShapeTypeFilter("all");
        setshowShape_TypeFilter(false);
    };

    // Function to clear metal type filter
    const clearMetalTypeFilter = () => {
        setSelectedMetalTypeFilter("all");
        setshowMetal_TypeFilter(false);
    };

    // Function to clear jewelry size filter
    const clearJewelrySizeFilter = () => {
        setJewelrySizeFilter("");
        setshowJewelry_SizeFilter(false);
    };

    // Function to clear diamond size filter
    const clearDiamondSizeFilter = () => {
        setDiamondSizeFilter("");
        setshowDiamondSizeFilter(false);
    };

    // Function to clear quantity filter
    const clearQtyFilter = () => {
        setFilterQty("");
        setshowQtyFilter(false);
    };

    const handleStartDateClose = () => {
        clearDateFilters();
    };

    const handleStartE_DateClose = () => {
        clearE_DateFilters();
    };
    const handleStartDe_DateClose = () => {
        clearDe_DateFilters();
    };

    const handleStatusClose = () => {
        clearStatusFilter();
    };
    const handleTypeClose = () => {
        clearJewelryTypeFilter();
    };

    const handleRef_byTypeClose = () => {
        clearRef_byTypeFilter();
    };

    const handleCreate_byTypeClose = () => {
        clearCreate_byTypeFilter();
    };

    const handleRefLotNameClose = () => {
        clearRefLotNameFilter();
    };

    const handleJewelrySubTypeClose = () => {
        clearJewelrySubTypeFilter();
    };

    const handleSettingClose = () => {
        clearSettingFilter();
    };
    const handleRefLotIdClose = () => {
        clearRefLotIdFilter();
    };
    const handleShapeTypeClose = () => {
        clearShapeTypeFilter();
    };
    const handleMetalTypeClose = () => {
        clearMetalTypeFilter();
    };
    const handleJewelrySizeClose = () => {
        clearJewelrySizeFilter();
    };
    const handleDiamondSizeClose = () => {
        clearDiamondSizeFilter();
    };
    const handleQtyClose = () => {
        clearQtyFilter();
    };

    const handleClientIdFilterClose = () => {
        setClientIdFilter("");
        setShowClientIdFilter(false);
    };

    const handleClientNameFilterClose = () => {
        setClientNameFilter("");
        setShowClientNameFilter(false);
    };

    const handleBagFilterClose = () => {
        setBagFilter("");
        setShowBag_Filter(false);
    };
    const handlePoNoFilterClose = () => {
        setPoNoFilter("");
        setShowPoNo_Filter(false);
    };

    const handleDesignNeedFilterClose = () => {
        setDesignNeedFilter("");
        setShowDesignNeed_Filter(false);
    };

    // const handleValueClick = () => {
    //   setShowFullValue(!showFullValue);
    // };

    const handleValueClick = (index) => {
        setShowFullValue((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const allKeys = new Set();

    currentRemittanceData.forEach(item => {
        Object.keys(item).forEach(key => allKeys.add(key));
    });



    const jewelryType1 = 'jewelryType';
    const jewelrySubType1 = 'jewelrySubType';
    const refLotId1 = 'refLotId';
    const metalType1 = 'metalType';
    const jewelrySize1 = 'jewelrySize';
    const diamondApoSize1 = 'diamondApoSize';
    const refLink1 = 'refLink';
    const comment1 = 'comment';
    const qty1 = 'qty';
    const estDate1 = 'estDate';
    const requestDate1 = 'requestDate';
    const setting1 = 'setting';
    const bagNo1 = 'bagNo';
    const DesignNeed1 = 'DesignNeed';
    const PoNo1 = 'PoNo';
    const finalImg1 = 'finalImg';
    const refImge1 = 'refImge';
    const deliveryDate1 = 'deliveryDate';
    const shapeType1 = 'shapeType';
    const ref_by1 = 'ref_by';
    const requestStatus1 = 'requestStatus';
    const code1 = 'code';
    const id1 = 'id';
    // const estRate1 = 'estRate';
    // const orderNumber1 = 'orderNumber';
    // const due_date1 = 'due_date';
    const created_by1 = 'created_by';
    const client_id1 = 'client_id';
    const company1 = 'company';

    const totalOrder = filteredRemittanceData
        .filter((remittance) => remittance.requestStatus === "Canceled Order")
        .reverse().length;

    return (
        <>
            <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-3 w-100">
                    {/* <div>{renderUnreadMessageCount()}</div> */}
                    <div>
                        Total Order <span className="fw-bold fs-5">({totalOrder})*</span>
                    </div>

                    <div>
                        <Link to="/adminOrder">
                            <button className="btn btn-primary me-2">Back To Order</button>
                        </Link>
                        <button onClick={handleSelectAll} className="btn btn-primary me-2">
                            {selectAllChecked ? "Deselect All" : "Select All"}
                        </button>
                        <MDBBtn onClick={downloadSelectedCSV}>
                            <MDBIcon fas icon="download" className="me-2" />
                            Download Data
                        </MDBBtn>
                    </div>
                </div>
                <div
                    className="d-flex  align-items-center gap-2 px-2 rounded-5  bg_report w-100"
                    style={{ top: "164px", zIndex: 999, position: "sticky" }}
                >
                    {/* <div className=" d-flex gap-3">
              <div className="d-flex flex-column w-100">
                <FilterAltRoundedIcon onClick={toggleFilters} />
              </div>
            </div> */}

                    <>
                        {showRef_byFilter && (
                            <div className="d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="text-center pb-1">
                                        Ref_by
                                    </label>
                                    <select
                                        value={selectedRef_byType}
                                        onChange={handleRef_byTypeChange}
                                        className="form-select w-100"
                                    >
                                        <option value="all">All</option>
                                        {ref_by
                                            .filter((type) => type !== null && type.trim() !== "")
                                            .map((type, index) => (
                                                <option key={index} value={type.trim()}>
                                                    {type.trim()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleRef_byTypeClose} />
                            </div>
                        )}

                        {/* {showCreate_byFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="text-center pb-1">
                    Create_by
                  </label>
                  <select
                    value={selectedCreate_byType}
                    onChange={handleCreate_byTypeChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {create_by &&
                      create_by
                        .filter(
                          (type) =>
                            typeof type === "string" && type.trim() !== ""
                        )
                        .map((type, index) => (
                          <option key={index} value={type.trim()}>
                            {type.trim()}
                          </option>
                        ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleCreate_byTypeClose} />
              </div>
            )} */}

                        {showClientIdFilter && (
                            <div className="d-flex gap-3">
                                <div className="d-flex flex-column  w-50">
                                    <label htmlFor="" className="fs-6  pb-1">
                                        Client ID
                                        <CloseRoundedIcon
                                            className="ms-2"
                                            onClick={handleClientIdFilterClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={clientIdFilter}
                                        onChange={handleClientIdFilterChange}
                                        className="form-control w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {/* {showClientNameFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Client Name
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleClientNameFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={clientNameFilter}
                    onChange={handleClientNameFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )} */}

                        {showOrderNoFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column  w-100">
                                    <label htmlFor="" className="fs-6 pb-1">
                                        Order No
                                        <CloseRoundedIcon
                                            onClick={handleOrderNoClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={`J${orderNo}`}
                                        onChange={handleOrderNoFilterChange}
                                        className="form-control  w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showDateFilter && (
                            <div className=" d-flex gap-1">
                                <div className="d-flex flex-column ">
                                    <label className="me-2  text-center pb-1 ">Start Date</label>
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control "
                                    />
                                </div>
                                <div className="d-flex flex-column ">
                                    <label className="me-2  text-center pb-1 ">End Date</label>
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control"
                                    />
                                </div>
                                <CloseRoundedIcon onClick={handleStartDateClose} />
                            </div>
                        )}
                        {showStatusFilter && (
                            <div className=" d-flex gap-1">
                                <div className="d-flex flex-column  ">
                                    <label className=" fs-6 text-center pb-1" htmlFor="">
                                        Status
                                    </label>
                                    <select
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        className="form-select  w-100"
                                    >
                                        <option value="all">All</option>
                                        <option value="Export">Export</option>
                                        <option value="Canceled Order">Canceled Order</option>
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleStatusClose} />
                            </div>
                        )}
                        {showTypeFilter && (
                            <div className="d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="text-center pb-1">
                                        JewelryType
                                    </label>
                                    <select
                                        value={selectedJewelryType}
                                        onChange={handleJewelryTypeChange}
                                        className="form-select w-100"
                                    >
                                        <option value="all">All</option>
                                        {jewelryTypes
                                            .filter((type) => type !== null && type.trim() !== "")
                                            .map((type, index) => (
                                                <option key={index} value={type.trim()}>
                                                    {type.trim()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleTypeClose} />
                            </div>
                        )}
                        {showSubTypeFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 text-center pb-1">
                                        JewelrySubType
                                    </label>
                                    <select
                                        value={selectedJewelrySubTypeFilter}
                                        onChange={handleJewelrySubTypeFilterChange}
                                        className="form-select  w-100"
                                    >
                                        <option value="all">All</option>
                                        {jewelrySubTypes
                                            .filter((type) => type !== null && type.trim() !== "")
                                            .map((type, index) => (
                                                <option key={index} value={type.trim()}>
                                                    {type.trim()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleJewelrySubTypeClose} />
                            </div>
                        )}
                        {showSettingFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 text-center pb-1">
                                        Setting
                                    </label>
                                    <select
                                        value={selectedSetting}
                                        onChange={handleSettingFilterChange}
                                        className="form-select  w-100"
                                    >
                                        <option value="all">All</option>
                                        {setting
                                            .filter((type) => type !== null && type.trim() !== "")
                                            .map((type, index) => (
                                                <option key={index} value={type.trim()}>
                                                    {type.trim()}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleSettingClose} />
                            </div>
                        )}
                        {showLotIDFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 pb-1">
                                        Ref. Piece
                                        <CloseRoundedIcon
                                            onClick={handleRefLotIdClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={refLotIdFilter}
                                        onChange={handleRefLotIdFilterChange}
                                        className="form-control  w-50"
                                    />
                                </div>
                            </div>
                        )}

                        {showLotNameFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 pb-1">
                                        Lot_Name
                                        <CloseRoundedIcon
                                            className="ms-2"
                                            onClick={handleRefLotNameClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={refLotNameFilter}
                                        onChange={handleRefLotNameFilterChange}
                                        className="form-control  w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showShape_TypeFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 text-center pb-1">
                                        ShapType
                                    </label>
                                    <select
                                        value={selectedShapeTypeFilter}
                                        onChange={handleShapeTypeFilterChange}
                                        className="form-select  w-100"
                                    >
                                        <option value="all">All</option>
                                        {shapeTypes &&
                                            shapeTypes
                                                .filter((type) => type && type.trim() !== "")
                                                .map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleShapeTypeClose} />
                            </div>
                        )}
                        {showMetal_TypeFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 text-center pb-1">
                                        MetalType
                                    </label>
                                    <select
                                        value={selectedMetalTypeFilter}
                                        onChange={handleMetalTypeFilterChange}
                                        className="form-select  w-100"
                                    >
                                        <option value="all">All</option>
                                        {metalTypes &&
                                            metalTypes
                                                .filter((type) => type && type.trim() !== "") // Exclude null and empty values
                                                .map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                    </select>
                                </div>
                                <CloseRoundedIcon onClick={handleMetalTypeClose} />
                            </div>
                        )}
                        {showJewelry_SizeFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 pb-1">
                                        Jewelry_Size
                                        <CloseRoundedIcon
                                            onClick={handleJewelrySizeClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={jewelrySizeFilter}
                                        onChange={handleJewelry_SizeFilterChange}
                                        className="form-control  w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showDiamondSizeFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 pb-1">
                                        T_Dia_Size
                                        <CloseRoundedIcon
                                            onClick={handleDiamondSizeClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={diamondSizeFilter}
                                        onChange={handleDiamondSizeChange}
                                        className="form-control  w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showQtyFilter && (
                            <div className=" d-flex gap-3">
                                <div className="d-flex flex-column w-100">
                                    <label htmlFor="" className="fs-6 pb-1">
                                        Filter Quantity
                                        <CloseRoundedIcon
                                            onClick={handleQtyClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={filterQty}
                                        onChange={handleQtyChange}
                                        className="form-control  w-50"
                                        required
                                    />
                                </div>
                            </div>
                        )}
                        {showBag_Filter && (
                            <div className="d-flex gap-3">
                                <div className="d-flex flex-column  w-100">
                                    <label htmlFor="" className="fs-6  pb-1">
                                        Bag-No
                                        <CloseRoundedIcon
                                            className="ms-2"
                                            onClick={handleBagFilterClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={bagFilter}
                                        onChange={handleBagFilterChange}
                                        className="form-control w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showPoNo_Filter && (
                            <div className="d-flex gap-3">
                                <div className="d-flex flex-column  w-100">
                                    <label htmlFor="" className="fs-6  pb-1">
                                        Po-No
                                        <CloseRoundedIcon
                                            className="ms-2"
                                            onClick={handlePoNoFilterClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={poNoFilter}
                                        onChange={handlePoNoFilterChange}
                                        className="form-control w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showDesignNeed_Filter && (
                            <div className="d-flex gap-3">
                                <div className="d-flex flex-column  w-100">
                                    <label htmlFor="" className="fs-6  pb-1">
                                        Design-Need?
                                        <CloseRoundedIcon
                                            className="ms-2"
                                            onClick={handleDesignNeedFilterClose}
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        value={designNeedFilter}
                                        onChange={handleDesignNeedFilterChange}
                                        className="form-control w-50"
                                    />
                                </div>
                            </div>
                        )}
                        {showE_DateFilter && (
                            <div className=" d-flex gap-1">
                                <div className="d-flex flex-column ">
                                    <label className="me-2  text-center pb-1 ">
                                        Start E_Date
                                    </label>
                                    <input
                                        type="date"
                                        value={startE_Date}
                                        onChange={handleStartE_DateChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="d-flex flex-column ">
                                    <label className="me-2  text-center pb-1 ">End E_Date</label>
                                    <input
                                        type="date"
                                        value={endE_Date}
                                        onChange={handleEndE_DateChange}
                                        className="form-control"
                                    />
                                </div>
                                <CloseRoundedIcon onClick={handleStartE_DateClose} />
                            </div>
                        )}
                        {showDe_DateFilter && (
                            <div className=" d-flex gap-1">
                                <div className="d-flex flex-column ">
                                    <label className="me-2  text-center pb-1 ">
                                        Start De_Date
                                    </label>
                                    <input
                                        type="date"
                                        value={startDe_Date}
                                        onChange={handleStartDe_DateChange}
                                        className="form-control "
                                    />
                                </div>
                                <div className="d-flex flex-column ">
                                    <label className="me-2  text-center pb-1 ">End De_Date</label>
                                    <input
                                        type="date"
                                        value={endDe_Date}
                                        onChange={handleEndDe_DateChange}
                                        className="form-control"
                                    />
                                </div>
                                <CloseRoundedIcon onClick={handleStartDe_DateClose} />
                            </div>
                        )}
                    </>
                </div>
                <div>
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <>
                            <table className="table table-bordered table-responsive w-100">
                                <thead
                                    id="addProductTableHead"
                                    style={{ top: "79px", position: "sticky", zIndex: 999 }}
                                >
                                    <tr>
                                        <th className="p-2 fw-bold">Select</th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(ref_by1) ? 'table-cell' : 'none' }}>
                                            Ref_by
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowRef_byFilter(!showRef_byFilter)}
                                            />
                                        </th>
                                        {/* <th className="p-2 fw-bold" style={{ display: allKeys?.has(created_by1) ? 'table-cell' : 'none' }}>
                      Create_by
                      <ArrowDropDownRoundedIcon
                        onClick={() =>
                          setShowCreate_byFilter(!showCreate_byFilter)
                        }
                      />
                    </th> */}
                                        <th className="p-2 fw-bold position-relative" style={{ display: allKeys?.has(client_id1) ? 'table-cell' : 'none' }}>
                                            C_ID
                                            <ArrowDropDownRoundedIcon
                                                onClick={() =>
                                                    setShowClientIdFilter(!showClientIdFilter)
                                                }
                                            />
                                        </th>
                                        {/* <th className="p-2 fw-bold position-relative" style={{ display: allKeys?.has(company1) ? 'table-cell' : 'none' }}>
                      C_Name
                      <ArrowDropDownRoundedIcon
                        onClick={() =>
                          setShowClientNameFilter(!showClientNameFilter)
                        }
                      />
                    </th> */}
                                        <th className="p-2 fw-bold"
                                        // style={{ display: allKeys?.has(id1) ? 'table-cell' : 'none' }}
                                        >
                                            Order_No
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowOrderNoFilter(!showOrderNoFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(requestDate1) ? 'table-cell' : 'none' }}>
                                            Order Date{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowDateFilter(!showDateFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(requestStatus1) ? 'table-cell' : 'none' }}>
                                            Status{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowStatusFilter(!showStatusFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(jewelryType1) ? 'table-cell' : 'none' }}>
                                            Type
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowTypeFilter(!showTypeFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(jewelrySubType1) ? 'table-cell' : 'none' }}>
                                            Sub_Type
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowSubTypeFilter(!showSubTypeFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(setting1) ? 'table-cell' : 'none' }}>
                                            Setting
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowSettingFilter(!showSettingFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(refLotId1) ? 'table-cell' : 'none' }}>
                                            Ref. Piece
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowLotIDFilter(!showLotIDFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold">
                                            Lot Name
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowLotNameFilter(!showLotNameFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(shapeType1) ? 'table-cell' : 'none' }}>
                                            Shape
                                            <ArrowDropDownRoundedIcon
                                                onClick={() =>
                                                    setshowShape_TypeFilter(!showShape_TypeFilter)
                                                }
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(metalType1) ? 'table-cell' : 'none' }}>
                                            Metal
                                            <ArrowDropDownRoundedIcon
                                                onClick={() =>
                                                    setshowMetal_TypeFilter(!showMetal_TypeFilter)
                                                }
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(jewelrySize1) ? 'table-cell' : 'none' }}>
                                            Size
                                            <ArrowDropDownRoundedIcon
                                                onClick={() =>
                                                    setshowJewelry_SizeFilter(!showJewelry_SizeFilter)
                                                }
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(diamondApoSize1) ? 'table-cell' : 'none' }}>
                                            Diamond Size
                                            <ArrowDropDownRoundedIcon
                                                onClick={() =>
                                                    setshowDiamondSizeFilter(!showDiamondSizeFilter)
                                                }
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(qty1) ? 'table-cell' : 'none' }}>
                                            Qty
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setshowQtyFilter(!showQtyFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(comment1) ? 'table-cell' : 'none' }}>Comment</th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(refLink1) ? 'table-cell' : 'none' }}>Ref.link</th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(refImge1) ? 'table-cell' : 'none' }}>ref_Img</th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(PoNo1) ? 'table-cell' : 'none' }}>
                                            Po.No{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowPoNo_Filter(!showPoNo_Filter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(DesignNeed1) ? 'table-cell' : 'none' }}>
                                            Design Need?{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() =>
                                                    setShowDesignNeed_Filter(!showDesignNeed_Filter)
                                                }
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(bagNo1) ? 'table-cell' : 'none' }}>
                                            Bag-No{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowBag_Filter(!showBag_Filter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(estDate1) ? 'table-cell' : 'none' }}>
                                            E_Date{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowE_DateFilter(!showE_DateFilter)}
                                            />
                                        </th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(finalImg1) ? 'table-cell' : 'none' }}>
                                            D_Date{" "}
                                            <ArrowDropDownRoundedIcon
                                                onClick={() => setShowDe_DateFilter(!showDe_DateFilter)}
                                            />
                                        </th>
                                        {/* <th className="p-2 fw-bold">Due Date</th> */}
                                        <th className="p-2 fw-bold">Chatbox</th>
                                        <th className="p-2 fw-bold" style={{ display: allKeys?.has(finalImg1) ? 'table-cell' : 'none' }}>F_Img</th>
                                        <th className="p-2 fw-bold">Tracking</th>
                                        {/* <th className="p-2 fw-bold">Delete</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRemittanceData
                                        .map((remittance, index) => (
                                            <tr
                                                key={index}
                                                className={`hover-row ${selectedRows.includes(remittance.id)
                                                    ? "bg_select"
                                                    : ""
                                                    }`}
                                            >
                                                <td
                                                    id="showUserTablebody"
                                                    className="d-flex align-items-center justify-content-center"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                            handleCheckboxChange(e, remittance.id)
                                                        }
                                                        checked={
                                                            selectAllChecked ||
                                                            selectedRows.includes(remittance.id)
                                                        }
                                                    />
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(ref_by1) ? 'table-cell' : 'none' }}>
                                                    {remittance.ref_by || "N/A"}
                                                </td>
                                                {/* <td id="showUserTablebody">
                          {remittance.created_by || "N/A"}
                        </td> */}
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(client_id1) ? 'table-cell' : 'none' }}>{remittance.code}</td>
                                                {/* <td id="showUserTablebody" style={{ display: allKeys?.has(company1) ? 'table-cell' : 'none' }}>{remittance.company}</td> */}
                                                <td id="showUserTablebody"
                                                // style={{ display: allKeys?.has(id1) ? 'table-cell' : 'none' }}
                                                >
                                                    J{remittance.id}
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(requestDate1) ? 'table-cell' : 'none' }}>
                                                    {formatDate(remittance.requestDate)}
                                                </td>
                                                <span style={{ display: allKeys?.has(requestStatus1) ? 'table-cell' : 'none' }}>
                                                    <td
                                                        id="showUserTablebody"
                                                        className="fw-bold"
                                                        onClick={(e) =>
                                                            handleDropdownClick(e, index, remittance.id)
                                                        }
                                                    >
                                                        {remittance.requestStatus}
                                                    </td>
                                                </span>

                                                <td id="showUserTablebody" style={{ display: allKeys?.has(jewelryType1) ? 'table-cell' : 'none' }}>{remittance.jewelryType}</td>
                                                <td id="showUserTablebody" tyle={{ display: allKeys?.has(jewelrySubType1) ? 'table-cell' : 'none' }}>
                                                    {remittance.jewelrySubType}
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(setting1) ? 'table-cell' : 'none' }}>
                                                    <span
                                                        onClick={() => handleValueClick(index)}
                                                        style={{ cursor: "pointer" }}
                                                        title={remittance.setting}
                                                    >
                                                        {showFullValue[index] ||
                                                            !remittance.setting ||
                                                            remittance.setting.length <= 10
                                                            ? remittance.setting || "N/A"
                                                            : remittance.setting.substring(0, 10) + "..."}
                                                    </span>
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(refLotId1) ? 'table-cell' : 'none' }}>{remittance.refLotId}</td>
                                                <td id="showUserTablebody">
                                                    {isLot_NameEditing[index] ? (
                                                        <input
                                                            type="text"
                                                            value={valuesLotName[index]}
                                                            onChange={(e) => handleLotnameChange(e, index)}
                                                            onBlur={() => handleLotNameBlur(index, remittance.id)}
                                                            className="w-75"
                                                        />
                                                    ) : (
                                                        <span onClick={() => handleDoubleLotNameClick(index)}>
                                                            {remittance.Lotname || "N/A"}
                                                        </span>
                                                    )}
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(shapeType1) ? 'table-cell' : 'none' }}>{remittance.shapeType}</td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(metalType1) ? 'table-cell' : 'none' }}>{remittance.metalType}</td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(jewelrySize1) ? 'table-cell' : 'none' }}>{remittance.jewelrySize}</td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(diamondApoSize1) ? 'table-cell' : 'none' }}>
                                                    {remittance.diamondApoSize}
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(qty1) ? 'table-cell' : 'none' }}>{remittance.qty}</td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(comment1) ? 'table-cell' : 'none' }}>
                                                    {remittance.comment ? (
                                                        <CommentIcon
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#modalfade-${index}`}
                                                            onClick={() => handleOpenModal(remittance)}
                                                            style={{ color: "#008000" }} // Green color if comment exists
                                                        />
                                                    ) : (
                                                        <CommentIcon
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#modalfade-${index}`}
                                                            onClick={() => handleOpenModal(remittance)}
                                                            style={{ color: "#EBEBEB" }} // Light gray color if no comment
                                                        />
                                                    )}
                                                    <div
                                                        className="modal fade"
                                                        id={`modalfade-${index}`}
                                                        tabIndex="-1"
                                                        aria-labelledby={`popupLabel-${index}`}
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <div>
                                                                        <h5
                                                                            className="fw-bold text-start"
                                                                            id={`popupLabel-${index}`}
                                                                        >
                                                                            Comment
                                                                        </h5>
                                                                        <span>
                                                                            Order No- J
                                                                            {selectedRemittance &&
                                                                                selectedRemittance.id}
                                                                        </span>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>
                                                                <div className="modal-body text-center">
                                                                    <span>{remittance.comment || "N/A"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                {/*<td id="showUserTablebody">{remittance.comment || "N/A"}</td>*/}
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(refLink1) ? 'table-cell' : 'none' }}>
                                                    {remittance.refLink ? (
                                                        <Link
                                                            target="_blank"
                                                            to={remittance.refLink}
                                                            style={{ color: "#008000" }}
                                                        >
                                                            <MDBIcon fas icon="eye" />
                                                        </Link>
                                                    ) : (
                                                        <MDBIcon
                                                            fas
                                                            icon="eye"
                                                            style={{ color: "#EBEBEB" }}
                                                        />
                                                    )}
                                                </td>
                                                <td id="showUserTablebody">
                                                    <FilterIcon
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#modal-${index}`}
                                                        onClick={() => handleOpenModal(remittance)}
                                                        style={{
                                                            color: remittance.refImge ? "#008000" : "#EBEBEB",
                                                        }} // Green color if comment exists, else light gray
                                                    />
                                                    <div
                                                        className="modal fade"
                                                        id={`modal-${index}`}
                                                        tabIndex="-1"
                                                        aria-labelledby={`popupLabel-${index}`}
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="fw-bold text-start"
                                                                        id={`popupLabel-${index}`}
                                                                    >
                                                                        Ref Images - {remittance.id}
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {remittance.refImge ? (
                                                                        (() => {
                                                                            try {
                                                                                const sanitizedRefImge =
                                                                                    remittance.refImge.replace(
                                                                                        /\\\//g,
                                                                                        "/"
                                                                                    );
                                                                                let images = [];

                                                                                // Check if it's a JSON array or a single image path
                                                                                if (
                                                                                    sanitizedRefImge.startsWith('["') &&
                                                                                    sanitizedRefImge.endsWith('"]')
                                                                                ) {
                                                                                    images = JSON.parse(sanitizedRefImge);
                                                                                } else {
                                                                                    images = [sanitizedRefImge];
                                                                                }

                                                                                return (
                                                                                    <MDBCarousel
                                                                                        showControls
                                                                                        style={{ color: "red" }}
                                                                                    >
                                                                                        {images.map((media, mediaIndex) => (
                                                                                            <MDBCarouselItem
                                                                                                key={mediaIndex}
                                                                                                itemId={mediaIndex + 1}
                                                                                            >
                                                                                                <img
                                                                                                    src={`${baseUrl}${media}`}
                                                                                                    className="d-block w-100"
                                                                                                    alt="Final"
                                                                                                    height={450}
                                                                                                    loading="lazy"
                                                                                                    onClick={() =>
                                                                                                        window.open(
                                                                                                            `${baseUrl}${media}`
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </MDBCarouselItem>
                                                                                        ))}
                                                                                    </MDBCarousel>
                                                                                );
                                                                            } catch (error) {
                                                                                console.error(
                                                                                    "Error parsing JSON:",
                                                                                    error
                                                                                );
                                                                                return (
                                                                                    <img
                                                                                        src="./No Image Fund.jpg"
                                                                                        className="d-block w-100"
                                                                                        alt="Final"
                                                                                        height={450}
                                                                                        loading="lazy"
                                                                                    />
                                                                                );
                                                                            }
                                                                        })()
                                                                    ) : (
                                                                        <img
                                                                            src="./No Image Fund.jpg"
                                                                            className="d-block w-100"
                                                                            alt="Final"
                                                                            height={450}
                                                                            loading="lazy"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(PoNo1) ? 'table-cell' : 'none' }}>{remittance.PoNo}</td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(DesignNeed1) ? 'table-cell' : 'none' }}>{remittance.DesignNeed}</td>
                                                <td id="showUserTablebody" style={{ display: allKeys?.has(bagNo1) ? 'table-cell' : 'none' }}>
                                                    {remittance.bagNo || "N/A"}
                                                </td>

                                                <span style={{ display: allKeys?.has(estDate1) ? 'table-cell' : 'none' }}>
                                                    <td
                                                        id="showUserTablebody"
                                                        style={isBeforeDays(remittance.estDate)}
                                                    >
                                                        <td id="showUserTablebody">
                                                            {formatDate(remittance.estDate, "estDate")}
                                                        </td>
                                                    </td>
                                                </span>

                                                <td id="showUserTablebody">
                                                    <td id="showUserTablebody">
                                                        {formatDate(remittance.deliveryDate)}
                                                    </td>
                                                </td>

                                                <td id="showUserTablebody">
                                                    <MDBBtn
                                                        className="userChat"
                                                        id="chatBox"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#popup-${index}`}
                                                        onClick={() => setChatId(remittance.id)}
                                                    >
                                                        <MDBIcon far icon="comments" />
                                                        {renderRedDot(remittance.id)}
                                                    </MDBBtn>
                                                    <div
                                                        className="modal fade"
                                                        id={`popup-${index}`}
                                                        tabIndex="-1"
                                                        aria-labelledby={`popupLabel-${index}`}
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <div className="text-start">
                                                                        <span>Order No-J{remittance.id}</span>
                                                                        <br />
                                                                        <span className="text-danger">
                                                                            *Content can be deleted within 3 minutes
                                                                        </span>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>
                                                                <div
                                                                    className="modal-body"
                                                                    ref={chatBoxRef}
                                                                    onScroll={handleScroll}
                                                                >
                                                                    <div>
                                                                        <SuperAdminChat
                                                                            chatPostId={remittance.id}
                                                                            chatData={chatData}
                                                                            isLoading={isLoading}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td id="showUserTablebody" style={{ display: allKeys?.has(finalImg1) ? 'table-cell' : 'none' }}>
                                                    <div key={remittance.id}>
                                                        <MDBBtn
                                                            id={`chatBox-${remittance.id}`}
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#viewImage-${remittance.id}`}
                                                            className="userChat"
                                                        >
                                                            <MDBIcon fas icon="photo-video" />
                                                        </MDBBtn>
                                                        <div
                                                            className="modal fade"
                                                            id={`viewImage-${remittance.id}`}
                                                            tabIndex="-1"
                                                            aria-labelledby={`popupLabel-${remittance.id}`}
                                                            aria-hidden="true"
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5
                                                                            className="modal-title"
                                                                            id={`popupLabel-${remittance.id}`}
                                                                        >
                                                                            Final Image - J{remittance.id}
                                                                        </h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                        ></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        {uploading ? (
                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                <Loader />
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {remittance.finalImg ? (
                                                                                    <MDBCarousel
                                                                                        showControls
                                                                                        style={{ color: "red" }}
                                                                                    >
                                                                                        <div>
                                                                                            {JSON.parse(
                                                                                                remittance.finalImg
                                                                                            ).map((media, mediaIndex) => (
                                                                                                <MDBCarouselItem
                                                                                                    key={mediaIndex}
                                                                                                    itemId={mediaIndex + 1}
                                                                                                >
                                                                                                    <div key={mediaIndex}>
                                                                                                        {media.endsWith(".mp4") ? (
                                                                                                            <video
                                                                                                                controls
                                                                                                                className="w-100"
                                                                                                            >
                                                                                                                <source
                                                                                                                    src={`${baseUrl}/${media}`}
                                                                                                                    type="video/mp4"
                                                                                                                />
                                                                                                                Your browser does not
                                                                                                                support the video
                                                                                                            </video>
                                                                                                        ) : (
                                                                                                            <img
                                                                                                                src={`${baseUrl}/${media}`}
                                                                                                                alt={`Media ${mediaIndex}`}
                                                                                                                className="w-100"
                                                                                                                loading="lazy"
                                                                                                            />
                                                                                                        )}
                                                                                                    </div>
                                                                                                </MDBCarouselItem>
                                                                                            ))}
                                                                                        </div>
                                                                                    </MDBCarousel>
                                                                                ) : (
                                                                                    <img
                                                                                        src="No Image Fund.jpg"
                                                                                        alt="No Images"
                                                                                        className=" w-100 h-100 mw-50 mh-50"
                                                                                        loading="lazy"
                                                                                    />
                                                                                )}
                                                                                <hr />
                                                                                <div>
                                                                                    <h5>Upload Images or Videos</h5>
                                                                                    <input
                                                                                        type="file"
                                                                                        id={`imageUpload-${remittance.id}`}
                                                                                        accept="image/*, video/*"
                                                                                        name="finalImg[]"
                                                                                        onChange={(e) =>
                                                                                            handleFileChange(e, remittance.id)
                                                                                        }
                                                                                        multiple
                                                                                    />

                                                                                    <button
                                                                                        onClick={() =>
                                                                                            handleUpload(remittance.id)
                                                                                        }
                                                                                        disabled={!selectedFiles}
                                                                                    >
                                                                                        Upload
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td id="showUserTablebody">
                                                    <MDBBtn
                                                        id="chatBox"
                                                        className="userChat"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#trackProduct-${index}`}
                                                        onClick={() => handleTrackOrderClick(remittance.id)}
                                                    >
                                                        <MDBIcon fas icon="search-location" />
                                                    </MDBBtn>
                                                    <div
                                                        className="modal fade"
                                                        id={`trackProduct-${index}`}
                                                        tabIndex="-1"
                                                        aria-labelledby={`popupLabel-${index}`}
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered modal-xl">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title"
                                                                        id={`popupLabel-${index}`}
                                                                    >
                                                                        Order - J{remittance.id}
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {loading1 ? (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div className="loader"></div>
                                                                        </div>
                                                                    ) : (
                                                                        <SuperAdminTrackOrder
                                                                            trackingData={trackingData}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                {/* <td id="showUserTablebody">
                          <DeleteForeverIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setIdToDelete(remittance.id);
                              setShowPopup(true);
                            }}
                          />
                        </td> */}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => paginate(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        <MDBIcon
                                            fas
                                            icon="angle-double-left"
                                            className={`  p-2 rounded-9 text-white ${currentPage === 1 ? "disabled" : ""
                                                }`}
                                            style={{ backgroundColor: "#83193E" }}
                                        />
                                    </button>
                                </li>
                                <li className="page-item">
                                    <span className="page-link px-2">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                </li>
                                <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => paginate(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        <MDBIcon
                                            fas
                                            icon="angle-double-right"
                                            className={` p-2 rounded-9 text-white ${currentPage ===
                                                Math.ceil(remittanceData.length / itemsPerPage)
                                                ? "disabled"
                                                : ""
                                                }`}
                                            style={{ backgroundColor: "#83193E" }}
                                        />
                                    </button>
                                </li>
                            </ul>
                            {/* <ul className="pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <MDBIcon
                      fas
                      icon="angle-double-left"
                      className={`  p-2 rounded-9 text-white ${currentPage === 1 ? "disabled" : ""
                        }`}
                      style={{ backgroundColor: "#386bc0" }}
                    />
                  </button>  
                </li>
                <li className="page-item">
                  <span className="page-link px-2">
                    Page {currentPage} of{" "}
                    {Math.ceil(remittanceData.length / itemsPerPage)}
                  </span>
                </li>
                <li
                  className={`page-item ${currentPage ===
                      Math.ceil(remittanceData.length / itemsPerPage)
                      ? "disabled"
                      : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(remittanceData.length / itemsPerPage)
                    }
                  >
                    <MDBIcon
                      fas
                      icon="angle-double-right"
                      className={` p-2 rounded-9 text-white ${currentPage ===
                          Math.ceil(remittanceData.length / itemsPerPage)
                          ? "disabled"
                          : ""
                        }`}
                      style={{ backgroundColor: "#386bc0" }}
                    />
                  </button>
                </li>
              </ul> */}
                        </>
                    )}
                </div>

                <MDBModal open={centredModal} setOpen={setCentredModal}>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={closeModal}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <p className="text-danger fs-4 text-center fw-semibold">
                                    ARE YOU SURE?
                                </p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="danger" onClick={closeModal}>
                                    Close
                                </MDBBtn>
                                <MDBBtn color="success" onClick={saveChanges}>
                                    Save changes
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

                <MDBModal tabIndex="-1" open={centredModal1} setOpen={setCentredModal1}>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Modal title</MDBModalTitle>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={toggleOpen1}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>ARE YOU SURE</MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={toggleOpen1}>
                                    Close
                                </MDBBtn>
                                <MDBBtn>Save changes</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                {showPopup && (
                    <div className="popup text-capitalize ">
                        <p>Are you sure you want to delete?</p>
                        <div className="d-flex gap-2">
                            <span onClick={handleYes}>Yes</span>
                            <span onClick={handleNo}>No</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
