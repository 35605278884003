import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Papa from "papaparse";
import {
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCarousel,
  MDBCarouselItem,
} from "mdb-react-ui-kit";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TrackProduct from "../../../Users/components/TrackProduct";
import CommentIcon from "@mui/icons-material/Comment";
import AdminChatApp from "./AdminChatApp";
import axios from "axios";
import FilterIcon from "@mui/icons-material/Filter";
import { baseUrl } from "../../../utils/Url";

export default function UserReportProduct({
  remittanceData,
  fetchRemittanceData,
  loading,
}) {
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedJewelryType, setSelectedJewelryType] = useState("all");
  const [selectedJewelrySubType, setSelectedJewelrySubType] = useState("all");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedJewelryTypeFilter, setSelectedJewelryTypeFilter] =
    useState("all");
  const [selectedJewelrySubTypeFilter, setSelectedJewelrySubTypeFilter] =
    useState("all");
  const [selectedShapeTypeFilter, setSelectedShapeTypeFilter] = useState("all");
  const [selectedMetalTypeFilter, setSelectedMetalTypeFilter] = useState("all");
  const [refLotIdFilter, setRefLotIdFilter] = useState("");
  const [jewelrySizeFilter, setJewelrySizeFilter] = useState("");
  const [diamondSizeFilter, setDiamondSizeFilter] = useState("");
  const [filterQty, setFilterQty] = useState("");
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showSubTypeFilter, setShowSubTypeFilter] = useState(false);
  const [showLotIDFilter, setShowLotIDFilter] = useState(false);
  const [showShape_TypeFilter, setshowShape_TypeFilter] = useState(false);
  const [showMetal_TypeFilter, setshowMetal_TypeFilter] = useState(false);
  const [showJewelry_SizeFilter, setshowJewelry_SizeFilter] = useState(false);
  const [showDiamondSizeFilter, setshowDiamondSizeFilter] = useState(false);
  const [showQtyFilter, setshowQtyFilter] = useState(false);
  const [clientIdFilter, setClientIdFilter] = useState("");
  const [showClientIdFilter, setShowClientIdFilter] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [centredModal, setCentredModal] = useState(false);
  const [selectedRequestStatus, setSelectedRequestStatus] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [basicModal, setBasicModal] = useState(false);
  const [orderNo, setOrderNoFilter] = useState("");
  const [showOrderNoFilter, setShowOrderNoFilter] = useState(false);
  const [selectedRemittance, setSelectedRemittance] = useState(null);
  const [dates, setDates] = useState({});
  const [isEditing, setIsEditing] = useState(null);
  const [dateChanged, setDateChanged] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [id, setId] = useState("");
  const [chatId, setChatId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [chatData, setChatData] = useState(null);
  const [trackingData, setTrackingData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [showE_DateFilter, setShowE_DateFilter] = useState(false);
  const [startE_Date, setStartE_Date] = useState(null);
  const [endE_Date, setEndE_Date] = useState(null);
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [showDe_DateFilter, setShowDe_DateFilter] = useState(false);
  const [startDe_Date, setStartDe_Date] = useState(null);
  const [endDe_Date, setEndDe_Date] = useState(null);
  const [clientNameFilter, setClientNameFilter] = useState("");
  const [isBagNoEditing, setisBagNoEditing] = useState(
    new Array(remittanceData.length).fill(false)
  );
  const [values, setValues] = useState(remittanceData.map(() => ""));
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [showBag_Filter, setShowBag_Filter] = useState(false);
  const [bagFilter, setBagFilter] = useState("");
  const [showPoNo_Filter, setShowPoNo_Filter] = useState(false);
  const [poNoFilter, setPoNoFilter] = useState("");
  const [showDesignNeed_Filter, setShowDesignNeed_Filter] = useState(false);
  const [designNeedFilter, setDesignNeedFilter] = useState("");
  const [selectedSetting, setSelectedSetting] = useState("all");
  const [showSettingFilter, setShowSettingFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showRef_byFilter, setShowRef_byFilter] = useState(false);
  const [selectedRef_byType, setSelectedRef_byType] = useState("all");
  const [showFullValue, setShowFullValue] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [editedRowIndex, setEditedRowIndex] = useState(null);
  const [renderedData, setRenderedData] = useState([]);
  const [showRenderedData, setShowRenderedData] = useState(false);
  const [showClientNameFilter, setShowClientNameFilter] = useState(false);
  const [showCreate_byFilter, setShowCreate_byFilter] = useState(false);
  const [selectedCreate_byType, setSelectedCreate_byType] = useState("all");
  const [buttonText, setButtonText] = useState("View unread messages");
  const [showUnreadMessages, setShowUnreadMessages] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [centredModal2, setCentredModal2] = useState(false);
  const [showDue_DateFilter, setShowDue_DateFilter] = useState(false);
  const [startDue_Date, setStartDue_Date] = useState(null);
  const [endDue_Date, setEndDue_Date] = useState(null);
  const [showPriorityFilter, setShowPriorityFilter] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [openPriorityDropdownIndex, setOpenPriorityDropdownIndex] = useState(null);

  const statusOptions = [
    { label: "Req_Pending", value: "Req_Pending" },
    { label: "Req_Approved", value: "Req_Approved" },
    { label: "Designing", value: "Designing" },
    { label: "Design Approval Pending", value: "Design Approval Pending" },
    { label: "Design Approved", value: "Design Approved" },
    { label: "Wax & Cast", value: "Wax & Cast" },
    { label: "Filing", value: "Filing" },
    { label: "Pre Polishing", value: "Pre Polishing" },
    { label: "Diamond", value: "Diamond" },
    { label: "Metal Setting", value: "Metal Setting" },
    { label: "Final Polishing", value: "Final Polishing" },
    { label: "Finished Good", value: "Finished Good" },
    { label: "Hold", value: "Hold" },
    { label: "Assembly Filling", value: "Assembly Filling" },
  ];

  const priorityOptions = [
    { label: "Urgent", value: "Urgent" },
    // { label: "High", value: "High" },
    { label: "Normal", value: "Normal" },
    { label: "Low", value: "Low" },
  ];



  const handleDoubleClick = (index) => {
    const newEditModes = [...isBagNoEditing];
    newEditModes[index] = true;
    setisBagNoEditing(newEditModes);
  };

  const handleBagNoChange = (e, index) => {
    const newValues = [...values];
    newValues[index] = e.target.value;
    setValues(newValues);
  };

  const handleBagNoBlur = (index, bagId) => {
    const newEditModes = [...isBagNoEditing];
    newEditModes[index] = false;
    sendBagNoToBackend(values[index], bagId);

    setisBagNoEditing(newEditModes);
  };

  const sendBagNoToBackend = (bagNo, bagId) => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ bagNo: bagNo }),
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/api/update_custom_order/${bagId}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          fetchRemittanceData();
        } else {
          throw new Error(
            `Failed to post status: ${response.status} ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        console.error("Error posting status:", error);
      });
  };

  const dropdownRef = useRef(null);
  const chatBoxRef = useRef(null);

  const handleStartDe_DateChange = (e) => {
    setStartDe_Date(e.target.value);
    setCurrentPage(1);
  };
  const handleStartDue_DateChange = (e) => {
    setStartDue_Date(e.target.value);
    setCurrentPage(1);
  };

  const handleEndDe_DateChange = (e) => {
    setEndDe_Date(e.target.value);
    setCurrentPage(1);
  };

  const handleEndDue_DateChange = (e) => {
    setEndDue_Date(e.target.value);
    setCurrentPage(1);
  };

  const clearDe_DateFilters = () => {
    setStartDe_Date("");
    setEndDe_Date("");
    setShowDe_DateFilter(false);
    setCurrentPage(1);
  };

  const clearDue_DateFilters = () => {
    setStartDue_Date("");
    setEndDue_Date("");
    setShowDue_DateFilter(false);
    setCurrentPage(1);
  };



  const handleStartDe_DateClose = () => {
    clearDe_DateFilters();
    setCurrentPage(1);
  };

  const handleStartDue_DateClose = () => {
    clearDue_DateFilters();
    setCurrentPage(1);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mouseover", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpenDropdownIndex(null);
      setIsEditing(null);
      setShowInput(false);
    }
  };

  const clearE_DateFilters = () => {
    setStartE_Date("");
    setEndE_Date("");
    setShowE_DateFilter(false);
    setCurrentPage(1);
  };
  const handleStartE_DateChange = (e) => {
    setStartE_Date(e.target.value);
    setCurrentPage(1);
  };
  const handleStartE_DateClose = () => {
    clearE_DateFilters();
    setCurrentPage(1);
  };
  const handleEndE_DateChange = (e) => {
    setEndE_Date(e.target.value);
    setCurrentPage(1);
  };

  const handleTrackOrderClick = async (remittanceId) => {
    setLoading1(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/track-corder/${remittanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setTrackingData(response.data);
      } else {
        throw new Error(
          `Failed to fetch status: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching status:", error);
    } finally {
      setLoading1(false);
    }
  };

  const fetchData = async () => {
    // setLoading(true); // Set loading to true before fetching data
    await Promise.all([fetchRemittanceData(), handleTrackOrderClick()]);
  };

  const handleChat = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      const userCode = localStorage.getItem("userCode");

      const [chatResponse, markReadResponse] = await Promise.all([
        axios.get(
          `${baseUrl}/api/order-chat/show/${chatId}`,
          {
            params: { user_id: userCode },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        ),
        // axios.get(
        //   `${baseUrl}/api/order-chat/message-read/${chatId}`,
        //   {
        //     params: { user_id: userCode },
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //       "Content-Type": "application/json",
        //     },
        //   }
        // ),
      ]);

      if (chatResponse.status === 200) {
        setChatData(chatResponse.data);
      } else {
        throw new Error(
          `Failed to fetch status: ${chatResponse.status} ${chatResponse.statusText}`
        );
      }

      if (markReadResponse.status !== 200) {
        throw new Error(
          `Failed to mark messages as read: ${markReadResponse.status} ${markReadResponse.statusText}`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataChat = useCallback(async () => {
    if (chatId) {
      await handleChat(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    fetchDataChat();

    const interval = setInterval(() => {
      fetchDataChat();
    }, 3000);

    return () => clearInterval(interval);
  }, [fetchDataChat]);

  const handleScroll = () => {
    // Logic to determine if user has scrolled up
    setUserScrolledUp(true); // Example logic, you need to implement your own
  };

  const savePriorityChanges = () => {
    console.log("ndjfbd");
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ priority: selectedPriority }),
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/api/update_custom_order/${selectedItemId}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          closeModal2();
          setOpenPriorityDropdownIndex(null);
          setSelectedPriority("");
          fetchRemittanceData();
        } else {
          throw new Error(
            `Failed to post priority: ${response.status} ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        console.error("Error posting priority:", error);
      });
  };

  useEffect(() => {
    if (!userScrolledUp && chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [userScrolledUp]);

  const fetchChatData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const userRoleId = localStorage.getItem("userRoleId");
      const role_type = localStorage.getItem("userCode");

      const queryParams = new URLSearchParams({
        user_id: role_type,
        role_type: userRoleId,
      }).toString();

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const url = `${baseUrl}/api/order-chat/message-unread/count?${queryParams}`;

      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const responseData = await response.json();
        const unreadMessagesData = {};
        responseData.data.forEach((item) => {
          unreadMessagesData[item.order_id] = item.unread_message;
        });
        setUnreadMessages(unreadMessagesData);
      } else {
        console.error("Error fetching remittance data:", response.error);
        // alert("An error occurred while fetching data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };

  useEffect(() => {
    fetchChatData();
    const intervalId = setInterval(fetchChatData, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const renderRedDot = (orderId) => {
    const unreadCount = unreadMessages[orderId] || 0;
    if (unreadCount > 0) {
      return <span className="red-dot position-absolute"></span>;
    }
    return null;
  };

  const sortedRemittanceData = [...remittanceData].sort((a, b) => {
    const unreadCountA = unreadMessages[a.id] || 0;
    const unreadCountB = unreadMessages[b.id] || 0;
    // Sort in descending order of unread message count
    return unreadCountB - unreadCountA;
  });

  const handleSave = async (remittance) => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        refLotId: editedValues.refLotId,
        customer: editedValues.customer,
        vendor_style: editedValues.vendor_style,
        milgrain: editedValues.milgrain,
        quote: editedValues.quote,
        labor_gdc: editedValues.labor_gdc,
        jewelryType: editedValues.jewelryType,
        jewelrySubType: editedValues.jewelrySubType,
        shapeType: editedValues.shapeType,
        metalType: editedValues.metalType,
        jewelrySize: editedValues.jewelrySize,
        head: editedValues.head,
        stones: editedValues.stones,
        diamondApoSize: editedValues.diamondApoSize,
        qty: editedValues.qty,
        setting: editedValues.setting,
        PoNo: editedValues.PoNo,
        DesignNeed: editedValues.DesignNeed,
        ref_by: editedValues.ref_by,
        refLink: editedValues.refLink,
        due_date: editedValues.due_date,
      };

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        `${baseUrl}/api/update_custom_order/${remittance.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Error updating data. Please try again.");
      }

      const responseData = await response.json();
      setEditedValues(responseData);
      setEditedValues({});
      setEditMode(false);
      fetchRemittanceData();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const toggleEditMode = (rowIndex) => {
    setEditedValues({});
    setEditedRowIndex(rowIndex);
    setEditMode(!editMode);
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setShowInput(false);
    sendDateToBackend(newDate, id);
    setCurrentPage(1);
  };

  const sendDateToBackend = (date, id) => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ deliveryDate: date }),
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/api/update_custom_order/${id}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          fetchRemittanceData();
        } else {
          throw new Error(
            `Failed to post status: ${response.status} ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        console.error("Error posting status:", error);
      });
  };

  const handleTdClick = (remittanceId) => {
    if (isEditing !== remittanceId && !dateChanged[remittanceId]) {
      setIsEditing(remittanceId);
    }
  };

  const closeModal2 = () => {
    setCentredModal2(false);
    setOpenPriorityDropdownIndex(null);
  };

  const handleChange = (event, remittanceId) => {
    const { value } = event.target;
    const reformattedDate = value.split("-").reverse().join("-");
    setDates((prevDates) => ({
      ...prevDates,
      [remittanceId]: reformattedDate,
    }));
    // Assuming handleSubmit is a function defined somewhere in your component
    handleSubmit(remittanceId, reformattedDate);
    setDateChanged((prevDateChanged) => ({
      ...prevDateChanged,
      [remittanceId]: true,
    }));
  };

  const handleBlur = (remittanceId) => {
    // setIsEditing(null);
  };

  // const getCellStyle = (estDate) => {
  //   if (!estDate) {
  //     return {};
  //   }
  //   const [day, month, year] = estDate.split("-");
  //   const estimatedDate = new Date(year, month - 1, day);
  //   const currentDate = new Date();
  //   const differenceInDays = Math.floor(
  //     (estimatedDate - currentDate) / (1000 * 60 * 60 * 24)
  //   );
  //   if (differenceInDays >= 0 && differenceInDays <= 4) {
  //     return { color: "orange" };
  //   } else if (estimatedDate < currentDate) {
  //     return { color: "red" };
  //   } else {
  //     return {};
  //   }
  // };

  const isBeforeDays = (estDate) => {
    if (!estDate) {
      return {};
    }

    const [day, month, year] = estDate.split("-");
    const estimatedDate = new Date(year, month - 1, day); // month - 1 because months are zero-based in JavaScript
    const deadlineDate = new Date(estimatedDate);
    deadlineDate.setDate(deadlineDate.getDate() - 5); // Calculate deadline 5 days before estimatedDate

    if (deadlineDate >= new Date()) {
      return {};
    } else if (
      deadlineDate >= new Date(new Date().setDate(new Date().getDate() - 5))
    ) {
      return { color: "orange" };
    } else {
      return { color: "red" };
    }
  };

  const handleSubmit = (remittanceId, dates) => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ estDate: dates }),
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/api/update_custom_order/${remittanceId}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          fetchRemittanceData();
        } else {
          throw new Error(
            `Failed to post status: ${response.status} ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        console.error("Error posting status:", error);
      });
  };

  const handleOpenImageModal = (src) => {
    setImageSource(src);
    setShowImageModal(true);
  };

  const handleOpenModal = (remittanceData) => {
    setSelectedRemittance(remittanceData);
    toggleOpen();
  };

  const preloadImage = () => {
    const img = new Image();
  };

  const toggleOpen = () => {
    setBasicModal(!basicModal);
  };

  const formatDate = (dateString, dateType) => {
    if (!dateString) return "N/A";

    if (dateType === "estDate") {
      const [day, month, year] = dateString.split("-");
      return `${month}-${day}-${year}`;
    } else {
      const [year, month, day] = dateString.split("-");
      return `${month}-${day}-${year}`;
    }
  };

  const handleOrderNoFilterChange = (e) => {
    const value = e.target.value;
    setOrderNoFilter(value.startsWith("J") ? value.slice(1) : value);
    setCurrentPage(1);
  };

  const saveChanges = () => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ requestStatus: selectedRequestStatus }),
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/api/update_custom_order/${selectedItemId}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          closeModal();
          setOpenDropdownIndex(null);
          setSelectedRequestStatus("");
          fetchRemittanceData();
        } else {
          throw new Error(
            `Failed to post status: ${response.status} ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        console.error("Error posting status:", error);
      });
  };

  const handleDiamondSizeChange = (e) => {
    setDiamondSizeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleQtyChange = (e) => {
    setFilterQty(e.target.value);
    setCurrentPage(1);
  };

  // const handleStatusChange = (e) => {
  //   setSelectedStatus(e.target.value);
  //   setCurrentPage(1);
  // };

  const handleStatusChange = (value) => {
    const newSelectedStatus = selectedStatus.includes(value)
      ? selectedStatus.filter((status) => status !== value)
      : [...selectedStatus, value];
    setSelectedStatus(newSelectedStatus);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setCurrentPage(1);
  };

  const handleCreate_byTypeClose = () => {
    clearCreate_byTypeFilter();
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setCurrentPage(1);
  };

  const handleJewelryTypeChange = (e) => {
    setSelectedJewelryType(e.target.value);
    setCurrentPage(1);
  };
  const handleRef_byTypeChange = (e) => {
    setSelectedRef_byType(e.target.value);
    setCurrentPage(1);
  };

  const handleSettingFilterChange = (e) => {
    setSelectedSetting(e.target.value);
    setCurrentPage(1);
  };

  const handleCreate_byTypeChange = (e) => {
    setSelectedCreate_byType(e.target.value);
    setCurrentPage(1);
  };

  const handleJewelrySubTypeChange = (e) => {
    setSelectedJewelrySubType(e.target.value);
    setCurrentPage(1);
  };

  const handleJewelrySubTypeFilterChange = (e) => {
    setSelectedJewelrySubTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleShapeTypeFilterChange = (e) => {
    setSelectedShapeTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleMetalTypeFilterChange = (e) => {
    setSelectedMetalTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleRefLotIdFilterChange = (e) => {
    setRefLotIdFilter(e.target.value);
    setCurrentPage(1);
  };
  const handleJewelry_SizeFilterChange = (e) => {
    setJewelrySizeFilter(e.target.value);
    setCurrentPage(1);
  };
  const handleFilterQtyChange = (e) => {
    setFilterQty(e.target.value);
    setCurrentPage(1);
  };

  const handleClientIdFilterChange = (e) => {
    setClientIdFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleBagFilterChange = (e) => {
    setBagFilter(e.target.value);
    setCurrentPage(1);
  };
  const handlePoNoFilterChange = (e) => {
    setPoNoFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleDesignNeedFilterChange = (event) => {
    const values = event.target.value;
    setDesignNeedFilter(values);
    setCurrentPage(1);
  };

  const handleDropdownClick = (e, index, itemId) => {
    e.stopPropagation();
    setOpenDropdownIndex(index);
    setSelectedItemId(itemId);
  };

  const handlePriorityDropdownClick = (e, index, itemId) => {
    e.stopPropagation();
    setOpenPriorityDropdownIndex(index);
    setSelectedItemId(itemId);
  };


  const handlePriorityChange = (e, index) => {
    const { value } = e.target;
    setSelectedPriority(value);
    setOpenPriorityDropdownIndex(null)
    setCentredModal2(true);
  };

  const handleClientNameFilterChange = (event) => {
    const values = event.target.value;
    setClientNameFilter(values);
    setCurrentPage(1);
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedRequestStatus(value);
    setCentredModal(true);
    // setCurrentPage(1);
  };

  const closeModal = () => {
    setCentredModal(false);
    // setCurrentPage(1);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    setCurrentPage(1);
  };

  const clearCreate_byTypeFilter = () => {
    setSelectedCreate_byType("all");
    setShowCreate_byFilter(false);
  };

  const handleInputChange = (event, fieldName) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: event.target.value,
    }));
  };

  const handleUpload = async (id) => {
    if (selectedFiles) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("finalImg[]", selectedFiles[i]);
      }
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${baseUrl}/api/upload-final-media/${id}`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to upload files");
        }

        const data = await response.json();
        fetchRemittanceData();
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const handleUploadOrder = async (id) => {
    if (selectedFiles) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("refImge[]", selectedFiles[i]);
      }
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${baseUrl}/api/update_custom_order/${id}`,
          {
            method: "PUT",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to upload files");
        }

        const data = await response.json();
        fetchRemittanceData();
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const filteredRemittanceData = remittanceData.filter((item) => {
    const toLowerCase = (str) =>
      typeof str === "string" ? str.toLowerCase() : str;

    if (
      clientNameFilter &&
      !toLowerCase(item.company)?.includes(toLowerCase(clientNameFilter))
    ) {
      return false;
    }

    if (
      designNeedFilter !== undefined &&
      designNeedFilter !== null &&
      typeof designNeedFilter === "string" &&
      item.DesignNeed !== undefined &&
      item.DesignNeed !== null &&
      typeof item.DesignNeed === "string" &&
      !toLowerCase(item.DesignNeed)?.includes(toLowerCase(designNeedFilter)) &&
      toLowerCase(item.DesignNeed) !== "n/a"
    ) {
      return false;
    }

    if (
      showBag_Filter &&
      bagFilter &&
      toLowerCase(item.bagNo) !== toLowerCase(bagFilter)
    ) {
      return false;
    }

    if (
      showPoNo_Filter &&
      poNoFilter &&
      toLowerCase(item.PoNo) !== toLowerCase(poNoFilter)
    ) {
      return false;
    }

    if (
      selectedCreate_byType !== "all" &&
      toLowerCase(item.created_by) !== toLowerCase(selectedCreate_byType)
    ) {
      return false;
    }


    const requestDue_Date = new Date(item?.due_date);
    if (!requestDue_Date || isNaN(requestDue_Date.getTime())) return false;
    const startDue_DateObj = new Date(startDue_Date);
    const endDue_DateObj = new Date(endDue_Date);

    if (startDue_Date && endDue_Date) {
      if (
        !(requestDue_Date >= startDue_DateObj && requestDue_Date <= endDue_DateObj)
      ) {
        return false;
      }
    } else if (!startDue_Date && endDue_Date) {
      if (!(requestDue_Date <= endDue_DateObj)) {
        return false;
      }
    } else if (startDue_Date && !endDue_Date) {
      if (!(requestDue_Date >= startDue_DateObj)) {
        return false;
      }
    }

    if (showPriorityFilter && selectedPriority.length > 0) {
      return selectedPriority.includes(item.priority) || selectedPriority.includes("all");
    }


    if (startE_Date && endE_Date) {
      const requestE_Date = item?.estDate;
      if (!requestE_Date) return false;

      const [day, month, year] = requestE_Date.split("-");
      const requestE_DateObj = new Date(`${year}-${month}-${day}`);
      const startE_DateObj = new Date(startE_Date);
      const endE_DateObj = new Date(endE_Date);

      return (
        requestE_DateObj >= startE_DateObj && requestE_DateObj <= endE_DateObj
      );
    }

    const requestDe_Date = new Date(item?.deliveryDate);
    const startDe_DateObj = new Date(startDe_Date);
    const endDe_DateObj = new Date(endDe_Date);

    if (orderNo && item.id != orderNo) {
      return false;
    }

    if (startDe_Date && endDe_Date) {
      if (
        !(requestDe_Date >= startDe_DateObj && requestDe_Date <= endDe_DateObj)
      ) {
        return false;
      }
    } else if (!startDe_Date && endDe_Date) {
      if (!(requestDe_Date <= endDe_DateObj)) {
        return false;
      }
    } else if (startDe_Date && !endDe_Date) {
      if (!(requestDe_Date >= startDe_DateObj)) {
        return false;
      }
    }

    if (
      selectedJewelryType !== "all" &&
      toLowerCase(item.jewelryType) !== toLowerCase(selectedJewelryType)
    ) {
      return false;
    }

    if (showRef_byFilter) {
      const selectedRefByTypes = Object.keys(checkboxValues).filter(
        (key) => checkboxValues[key]
      );

      if (
        selectedRefByTypes.length > 0 &&
        !selectedRefByTypes.includes("all") &&
        !(
          selectedRefByTypes.includes("N/A") &&
          (item.ref_by === null || item.ref_by.trim() === "")
        ) &&
        !selectedRefByTypes.includes(item.ref_by)
      ) {
        return false;
      }
    }

    // if (
    //   selectedRef_byType !== "all" &&
    //   toLowerCase(item.ref_by) !== toLowerCase(selectedRef_byType)
    // ) {
    //   return false;
    // }
    if (
      selectedJewelrySubType !== "all" &&
      toLowerCase(item.jewelrySubType) !== toLowerCase(selectedJewelrySubType)
    ) {
      return false;
    }
    // if (
    //   selectedJewelryTypeFilter !== "all" &&
    //   item.jewelryType !== selectedJewelryTypeFilter
    // ) {
    //   return false;
    // }

    if (
      selectedJewelryTypeFilter !== "all" &&
      toLowerCase(item.jewelryType) !== toLowerCase(selectedJewelryTypeFilter)
    ) {
      return false;
    }

    if (
      selectedJewelrySubTypeFilter !== "all" &&
      toLowerCase(item.jewelrySubType) !==
      toLowerCase(selectedJewelrySubTypeFilter)
    ) {
      return false;
    }
    if (
      selectedShapeTypeFilter !== "all" &&
      toLowerCase(item.shapeType) !== toLowerCase(selectedShapeTypeFilter)
    ) {
      return false;
    }
    if (
      selectedMetalTypeFilter !== "all" &&
      toLowerCase(item.metalType) !== toLowerCase(selectedMetalTypeFilter)
    ) {
      return false;
    }

    if (
      selectedSetting !== "all" &&
      toLowerCase(item.setting) !== toLowerCase(selectedSetting)
    ) {
      return false;
    }

    if (
      refLotIdFilter !== "" &&
      toLowerCase(item.refLotId) !== toLowerCase(refLotIdFilter)
    ) {
      return false;
    }

    const itemJewelrySize = item.jewelrySize
      ? toLowerCase(item.jewelrySize)
      : null;

    if (jewelrySizeFilter) {
      if (!isNaN(jewelrySizeFilter)) {
        if (!itemJewelrySize) return false;
        const numericFilter = parseFloat(jewelrySizeFilter);
        const numericJewelrySize = parseFloat(itemJewelrySize.split(" ")[0]);
        if (numericJewelrySize !== numericFilter) {
          return false;
        }
      } else {
        if (itemJewelrySize !== toLowerCase(jewelrySizeFilter)) {
          return false;
        }
      }
    }
    if (
      diamondSizeFilter &&
      toLowerCase(item.diamondApoSize) !== toLowerCase(diamondSizeFilter)
    ) {
      return false;
    }
    if (filterQty !== "" && parseFloat(item.qty) !== parseFloat(filterQty)) {
      return false;
    }
    if (
      clientIdFilter &&
      toLowerCase(item.code) !== toLowerCase(clientIdFilter)
    ) {
      return false;
    }

    // Status filter
    if (selectedStatus.length > 0 && !selectedStatus.includes("all")) {
      const lowerCaseStatus = toLowerCase(item.requestStatus || "");

      const isPending =
        selectedStatus.includes("Req_Pending") &&
        (item.requestStatus === null ||
          item.requestStatus === undefined ||
          lowerCaseStatus === "req_pending");

      const matchesOtherStatuses = selectedStatus.some(
        (status) => toLowerCase(status) === lowerCaseStatus
      );

      if (!isPending && !matchesOtherStatuses) {
        return false;
      }
    }

    // if (selectedStatus !== "all") {
    //   if (selectedStatus === "Req_Pending" || selectedStatus === null) {
    //     if (!item.id || !item.requestStatus) {
    //       return true;
    //     } else if (toLowerCase(item.requestStatus) !== "req_pending") {
    //       return false;
    //     }
    //   } else if (
    //     toLowerCase(item.requestStatus) !== toLowerCase(selectedStatus)
    //   ) {
    //     return false;
    //   }
    // }

    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const requestDate = new Date(item.requestDate);

      if (requestDate >= startDateObj && requestDate <= endDateObj) {
        return true;
      } else {
        return false;
      }
    } else if (!startDate && !endDate) {
      return true;
    } else if (!startDate && endDate) {
      const endDateObj = new Date(endDate);
      const requestDate = new Date(item.requestDate);
      return requestDate <= endDateObj;
    } else if (startDate && !endDate) {
      const startDateObj = new Date(startDate);
      const requestDate = new Date(item.requestDate);
      return requestDate >= startDateObj;
    }

    return true;
  });

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // const totalPages = Math.ceil(
  //   filteredRemittanceData.filter((remittance) => remittance.requestStatus !== "Export" && remittance.requestStatus !== "Canceled Order")
  //     .length / itemsPerPage
  // );
  // const totalPages = Math.ceil(
  //   filteredRemittanceData.filter(
  //     (remittance) =>
  //       remittance.requestStatus !== "Export" &&
  //       remittance.requestStatus !== "Canceled Order"
  //   ).length / itemsPerPage
  // );

  // const paginate = (pageNumber) => {
  //   if (pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  const jewelryTypes = [
    ...new Set(remittanceData.map((item) => item.jewelryType)),
  ];
  const create_by = [...new Set(remittanceData.map((item) => item.created_by))];

  const ref_by = [...new Set(remittanceData.map((item) => item.ref_by))];
  const jewelrySubTypes = [
    ...new Set(remittanceData.map((item) => item.jewelrySubType)),
  ];

  const setting = [...new Set(remittanceData.map((item) => item.setting))];
  const shapeTypes = [...new Set(remittanceData.map((item) => item.shapeType))];
  const metalTypes = [...new Set(remittanceData.map((item) => item.metalType))];

  // const handleCheckboxChange = (e, id) => {
  //   const checked = e.target.checked;

  //   if (selectAllChecked && !checked) {
  //     setSelectAllChecked(false);
  //   }

  //   setSelectedRows((prevSelectedRows) => {
  //     if (checked && !prevSelectedRows.includes(id)) {
  //       return [...prevSelectedRows, id];
  //     } else {
  //       return prevSelectedRows.filter((item) => item !== id);
  //     }
  //   });
  // };

  const handleCheckboxChangeCheckBox = (type) => {
    const newCheckboxValues = {
      ...checkboxValues,
      [type]: !checkboxValues[type],
    };
    setCheckboxValues(newCheckboxValues);

    const selectedTypes = Object.keys(newCheckboxValues).filter(
      (key) => newCheckboxValues[key]
    );
    setSelectedRef_byType(selectedTypes.join(", "));
    setCurrentPage(1);
  };
  const handleCheckboxChange = (e, id, type) => {
    const checked = e.target.checked;

    if (selectAllChecked && !checked) {
      setSelectAllChecked(false);
    }

    setSelectedRows((prevSelectedRows) => {
      if (checked && !prevSelectedRows.includes(id)) {
        return [...prevSelectedRows, id];
      } else {
        return prevSelectedRows.filter((item) => item !== id);
      }
    });
  };

  const handleSelectAll = () => {
    if (!selectAllChecked || selectedRows.length !== remittanceData.length) {
      const allOrderNumbers = remittanceData.map((item) => item.id);
      setSelectedRows(allOrderNumbers);
      setSelectAllChecked(true);
    } else {
      setSelectedRows([]);
      setSelectAllChecked(false);
    }
  };

  const downloadSelectedCSV = () => {
    // Helper function to convert a string to lowercase
    const toLowerCase = (str) =>
      typeof str === "string" ? str.toLowerCase() : str;

    // Get selected ref_by types from checkboxValues
    const selectedRefByTypes = Object.keys(checkboxValues)
      .filter((key) => checkboxValues[key])
      .map((key) => key.toLowerCase());

    const statusArray = Array.isArray(selectedStatus) ? selectedStatus : [];

    // Apply initial filters based on selected rows and requestStatus
    const initialFilter = remittanceData
      .filter((item) => selectedRows.includes(item.id))
      .filter(
        (item) =>
          item.requestStatus?.toLowerCase() !== "export" &&
          item.requestStatus?.toLowerCase() !== "canceled order"
      );

    // Apply additional filters, including ref_by filtering
    const selectedData = initialFilter.filter((item) => {
      const itemRefBy = item.ref_by ? item.ref_by.toLowerCase() : "";

      const lowerCaseStatus = toLowerCase(item.requestStatus || "");
      const isPending =
        statusArray.includes("Req_Pending") &&
        (item.requestStatus === null ||
          item.requestStatus === undefined ||
          lowerCaseStatus === "req_pending");
      const matchesOtherStatuses = statusArray.some(
        (status) => toLowerCase(status) === lowerCaseStatus
      );

      return (
        (!clientIdFilter ||
          toLowerCase(item.code)?.includes(toLowerCase(clientIdFilter))) &&
        (!clientNameFilter ||
          toLowerCase(item.company)?.includes(toLowerCase(clientNameFilter))) &&
        (!orderNo || item.id == orderNo) &&
        (!startDate ||
          !endDate ||
          (new Date(item.requestDate) >= new Date(startDate) &&
            new Date(item.requestDate) <= new Date(endDate))) &&
        (!startE_Date ||
          !endE_Date ||
          (new Date(item.estDate) >= new Date(startE_Date) &&
            new Date(item.estDate) < new Date(endE_Date))) &&
        (!startDue_Date ||
          !endDue_Date ||
          (new Date(item.due_date) >= new Date(startDue_Date) &&
            new Date(item.due_date) < new Date(endDue_Date))) &&
        (!startDe_Date ||
          !endDe_Date ||
          (new Date(item.deliveryDate) >= new Date(startDe_Date) &&
            new Date(item.deliveryDate) <= new Date(endDe_Date))) &&
        (selectedStatus.length === 0 ||
          selectedStatus.includes("all") ||
          isPending ||
          matchesOtherStatuses) &&
        // (selectedStatus === "all" ||
        //   (selectedStatus === "Req_Pending" &&
        //     (!item.requestStatus ||
        //       item.requestStatus?.toLowerCase() === "req_pending")) ||
        //   item.requestStatus?.toLowerCase() === toLowerCase(selectedStatus)) &&
        (selectedJewelryType === "all" ||
          toLowerCase(item?.jewelryType) ===
          toLowerCase(selectedJewelryType)) &&
        (selectedCreate_byType === "all" ||
          toLowerCase(item.created_by) ===
          toLowerCase(selectedCreate_byType)) &&
        (selectedSetting === "all" ||
          toLowerCase(item?.setting) === toLowerCase(selectedSetting)) &&
        (selectedJewelrySubTypeFilter === "all" ||
          toLowerCase(item.jewelrySubType) ===
          toLowerCase(selectedJewelrySubTypeFilter)) &&
        (!refLotIdFilter ||
          toLowerCase(item.refLotId) === toLowerCase(refLotIdFilter)) &&
        // (!refLotNameFilter ||
        //   toLowerCase(item.refLotId) === toLowerCase(refLotNameFilter)) &&
        (selectedShapeTypeFilter === "all" ||
          toLowerCase(item.shapeType) ===
          toLowerCase(selectedShapeTypeFilter)) &&
        (selectedMetalTypeFilter === "all" ||
          toLowerCase(item.metalType) ===
          toLowerCase(selectedMetalTypeFilter)) &&
        (!jewelrySizeFilter ||
          toLowerCase(item.jewelrySize) === toLowerCase(jewelrySizeFilter)) &&
        (!diamondSizeFilter ||
          toLowerCase(item.diamondApoSize) ===
          toLowerCase(diamondSizeFilter)) &&
        (!filterQty || item.qty === parseInt(filterQty)) &&
        (!bagFilter || toLowerCase(item.bagNo) === toLowerCase(bagFilter)) &&
        (!poNoFilter || toLowerCase(item.PoNo) === toLowerCase(poNoFilter)) &&
        (!designNeedFilter ||
          toLowerCase(item.DesignNeed) === toLowerCase(designNeedFilter)) &&
        (selectedRefByTypes.length === 0 ||
          selectedRefByTypes.includes("all") ||
          (selectedRefByTypes.includes("n/a") &&
            (!item.ref_by || item.ref_by.trim() === "")) ||
          selectedRefByTypes.includes(itemRefBy)) &&
        (!showPriorityFilter ||
          selectedPriority.includes("all") ||
          selectedPriority.includes(item.priority))

        // (selectedRefByTypes.length === 0 ||
        //   selectedRefByTypes.includes("all") ||
        //   (selectedRefByTypes.includes("N/A") &&
        //     (!item.ref_by || item.ref_by.trim() === "")) ||
        //   selectedRefByTypes.includes(itemRefBy))

        // (selectedRef_byType === "all" ||
        //   (selectedRef_byType === "N/A" &&
        //     (!item.ref_by || toLowerCase(item.ref_by) === "n/a")) ||
        //   selectedRefByTypes.includes(itemRefBy))
      );
    });

    // Convert selectedData to CSV format
    const csv = Papa.unparse(selectedData);

    // Create a Blob object containing the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "selected_data.csv");

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body after download
    document.body.removeChild(link);
  };

  // const downloadSelectedCSV = () => {
  //   // Helper function to convert a string to lowercase
  //   const toLowerCase = (str) =>
  //     typeof str === "string" ? str.toLowerCase() : str;

  //   // Apply the initial filter based on selected rows and requestStatus
  //   const initialFilter = remittanceData
  //     .filter((item) => selectedRows.includes(item.id))
  //     .filter(
  //       (item) =>
  //         item.requestStatus?.toLowerCase() !== "export" &&
  //         item.requestStatus?.toLowerCase() !== "canceled order"
  //     );

  //   // Apply the additional filters with partial matching for strings
  //   const selectedData = initialFilter.filter(
  //     (item) =>
  //       (!clientIdFilter ||
  //         toLowerCase(item.code)?.includes(toLowerCase(clientIdFilter))) &&
  //       (!clientNameFilter ||
  //         toLowerCase(item.company)?.includes(toLowerCase(clientNameFilter))) &&
  //       (!orderNo || item.id == orderNo) &&
  //       (!startDate ||
  //         !endDate ||
  //         (new Date(item.requestDate) >= new Date(startDate) &&
  //           new Date(item.requestDate) <= new Date(endDate))) &&
  //       (!startE_Date ||
  //         !endE_Date ||
  //         (new Date(item.estDate) >= new Date(startE_Date) &&
  //           new Date(item.estDate) < new Date(endE_Date))) &&
  //       (!startDe_Date ||
  //         !endDe_Date ||
  //         (new Date(item.deliveryDate) >= new Date(startDe_Date) &&
  //           new Date(item.deliveryDate) <= new Date(endDe_Date))) &&
  //       (selectedStatus === "all" ||
  //         (selectedStatus === "Req_Pending" &&
  //           (!item.requestStatus ||
  //             item.requestStatus?.toLowerCase() === "req_pending")) ||
  //         item.requestStatus?.toLowerCase() === toLowerCase(selectedStatus)) &&
  //       (selectedJewelryType === "all" ||
  //         toLowerCase(item?.jewelryType) ===
  //         toLowerCase(selectedJewelryType)) &&
  //       (selectedRef_byType === "all" ||
  //         toLowerCase(item.ref_by) === toLowerCase(selectedRef_byType)) &&
  //       (selectedCreate_byType === "all" ||
  //         toLowerCase(item.created_by) ===
  //         toLowerCase(selectedCreate_byType)) &&
  //       (selectedSetting === "all" ||
  //         toLowerCase(item?.setting) === toLowerCase(selectedSetting)) &&
  //       (selectedJewelrySubTypeFilter === "all" ||
  //         toLowerCase(item.jewelrySubType) ===
  //         toLowerCase(selectedJewelrySubTypeFilter)) &&
  //       (!refLotIdFilter ||
  //         toLowerCase(item.refLotId) === toLowerCase(refLotIdFilter)) &&
  //       // (!refLotNameFilter || toLowerCase(item.refLotId) === toLowerCase(refLotNameFilter)) &&
  //       (selectedShapeTypeFilter === "all" ||
  //         toLowerCase(item.shapeType) ===
  //         toLowerCase(selectedShapeTypeFilter)) &&
  //       (selectedMetalTypeFilter === "all" ||
  //         toLowerCase(item.metalType) ===
  //         toLowerCase(selectedMetalTypeFilter)) &&
  //       (!jewelrySizeFilter ||
  //         toLowerCase(item.jewelrySize) === toLowerCase(jewelrySizeFilter)) &&
  //       (!diamondSizeFilter ||
  //         toLowerCase(item.diamondApoSize) ===
  //         toLowerCase(diamondSizeFilter)) &&
  //       (!filterQty || item.qty === parseInt(filterQty)) &&
  //       (!bagFilter || toLowerCase(item.bagNo) === toLowerCase(bagFilter)) &&
  //       (!poNoFilter || toLowerCase(item.PoNo) === toLowerCase(poNoFilter)) &&
  //       (!designNeedFilter ||
  //         toLowerCase(item.DesignNeed) === toLowerCase(designNeedFilter))
  //   );

  //   // Convert selectedData to CSV format
  //   const csv = Papa.unparse(selectedData);

  //   // Create a Blob object containing the CSV data
  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  //   // Create a temporary link element
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.setAttribute("download", "selected_data.csv");

  //   // Append the link to the document body
  //   document.body.appendChild(link);

  //   // Programmatically click the link to trigger the download
  //   link.click();

  //   // Remove the link from the document body after download
  //   document.body.removeChild(link);
  // };

  // const downloadSelectedCSV = () => {
  //   const selectedData = remittanceData
  //     .filter((item) => selectedRows.includes(item.id))
  //     .filter((item) => item.requestStatus !== 'Export');

  //   const csv = Papa.unparse(selectedData);

  //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.setAttribute('download', 'selected_data.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const clearPriorityFilter = () => {
    setSelectedPriority("all");
    setShowPriorityFilter(false);
  };

  const handlePriorityClose = () => {
    clearPriorityFilter();
  };

  const handleClientNameFilterClose = () => {
    setClientNameFilter("");
    setShowClientNameFilter(false);
  };

  const clearDateFilters = () => {
    setStartDate("");
    setEndDate("");
    setShowDateFilter(false);
  };

  // Function to clear status filter
  const clearStatusFilter = () => {
    setSelectedStatus("all");
    setShowStatusFilter(false);
  };

  // Function to clear jewelry type filter
  const clearJewelryTypeFilter = () => {
    setSelectedJewelryType("all");
    setShowTypeFilter(false);
  };

  const clearRef_byTypeFilter = () => {
    setSelectedRef_byType("all");
    setShowRef_byFilter(false);
  };

  // Function to clear jewelry sub type filter
  const clearJewelrySubTypeFilter = () => {
    setSelectedJewelrySubType("all");
    setShowSubTypeFilter(false);
    setSelectedJewelrySubTypeFilter("all");
  };

  const clearSettingFilter = () => {
    setSelectedSetting("all");
    setShowSettingFilter(false);
  };

  // Function to clear ref lot ID filter
  const clearRefLotIdFilter = () => {
    setRefLotIdFilter("");
    setShowLotIDFilter(false);
  };

  // Function to clear shape type filter
  const clearShapeTypeFilter = () => {
    setSelectedShapeTypeFilter("all");
    setshowShape_TypeFilter(false);
  };

  // Function to clear metal type filter
  const clearMetalTypeFilter = () => {
    setSelectedMetalTypeFilter("all");
    setshowMetal_TypeFilter(false);
  };

  // Function to clear jewelry size filter
  const clearJewelrySizeFilter = () => {
    setJewelrySizeFilter("");
    setshowJewelry_SizeFilter(false);
  };

  // Function to clear diamond size filter
  const clearDiamondSizeFilter = () => {
    setDiamondSizeFilter("");
    setshowDiamondSizeFilter(false);
  };

  // Function to clear quantity filter
  const clearQtyFilter = () => {
    setFilterQty("");
    setshowQtyFilter(false);
  };
  const clearOrderNoFilter = () => {
    setOrderNoFilter("");
    setShowOrderNoFilter(false);
  };

  const handleStartDateClose = () => {
    clearDateFilters();
  };

  const handleStatusClose = () => {
    clearStatusFilter();
  };
  const handleTypeClose = () => {
    clearJewelryTypeFilter();
  };
  const handleRef_byTypeClose = () => {
    clearRef_byTypeFilter();
    setCheckboxValues({});
    setSelectedRef_byType("");
  };
  const handleJewelrySubTypeClose = () => {
    clearJewelrySubTypeFilter();
  };
  const handleRefLotIdClose = () => {
    clearRefLotIdFilter();
  };
  const handleShapeTypeClose = () => {
    clearShapeTypeFilter();
  };

  const handleSettingClose = () => {
    clearSettingFilter();
  };

  const handleMetalTypeClose = () => {
    clearMetalTypeFilter();
  };
  const handleJewelrySizeClose = () => {
    clearJewelrySizeFilter();
  };
  const handleDiamondSizeClose = () => {
    clearDiamondSizeFilter();
  };
  const handleQtyClose = () => {
    clearQtyFilter();
  };

  const handleClientIdFilterClose = () => {
    setClientIdFilter("");
    setShowClientIdFilter(false);
  };

  const handleOrderNoClose = () => {
    clearOrderNoFilter();
  };

  const handleBagFilterClose = () => {
    setBagFilter("");
    setShowBag_Filter(false);
  };
  const handlePoNoFilterClose = () => {
    setPoNoFilter("");
    setShowPoNo_Filter(false);
  };

  const handleDesignNeedFilterClose = () => {
    setDesignNeedFilter("");
    setShowDesignNeed_Filter(false);
  };

  const handleValueClick = (index) => {
    setShowFullValue((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getTotalUnreadMessages = () => {
    const unreadCounts = Object.values(unreadMessages);
    return unreadCounts.reduce((total, count) => total + count, 0);
  };

  const toggleButtonText = () => {
    setButtonText((prevText) =>
      prevText === "View unread messages"
        ? "Show all orders"
        : "View unread messages"
    );
  };

  const renderUnreadMessageCount = () => {
    const totalUnread = getTotalUnreadMessages();
    if (totalUnread > 0) {
      return (
        <button
          className="text-white text-uppercase btn btn-primary"
          onClick={toggleButtonText}
        >
          {buttonText}
        </button>
      );
    }
    return null;
  };

  const dataToRender = showUnreadMessages
    ? filteredRemittanceData.filter(
      (remittance) => unreadMessages[remittance.id] > 0
    )
    : filteredRemittanceData;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = dataToRender.filter(
    (remittance) =>
      remittance.requestStatus !== "Export" &&
      remittance.requestStatus !== "Canceled Order"
  );

  const currentRemittanceData = filteredData
    .reverse()
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // const currentRemittanceData = filteredRemittanceData
  //   .filter((remittance) => remittance.requestStatus !== "Export" && remittance.requestStatus !== "Canceled Order")
  //   .reverse()
  //   .slice(indexOfFirstItem, indexOfLastItem);

  const handleRedChatBox = () => {
    setShowUnreadMessages(!showUnreadMessages);
    toggleButtonText();
    // setShowRenderedData(!showRenderedData);

    // const remittancesWithUnreadMessages = sortedRemittanceData.filter(
    //   (remittance) => unreadMessages[remittance.id] > 0
    // );

    // console.log(
    //   "remittancesWithUnreadMessages:",
    //   remittancesWithUnreadMessages
    // );

    // setRenderedData(remittancesWithUnreadMessages);
  };

  const allKeys = new Set();
  currentRemittanceData.forEach((item) => {
    Object.keys(item).forEach((key) => allKeys.add(key));
  });

  // const dataToRender = showRenderedData ? renderedData : currentRemittanceData;

  const jewelryType1 = "jewelryType";
  const jewelrySubType1 = "jewelrySubType";
  const refLotId1 = "refLotId";
  const metalType1 = "metalType";
  const jewelrySize1 = "jewelrySize";
  const diamondApoSize1 = "diamondApoSize";
  const refLink1 = "refLink";
  const comment1 = "comment";
  const qty1 = "qty";
  const quote = "quote";
  const vendor_style = "vendor_style";
  const milgrain = "milgrain";
  const head = "head";
  const stones = "stones";
  const labor_gdc = "labor_gdc";
  const estDate1 = "estDate";
  const requestDate1 = "requestDate";
  const setting1 = "setting";
  const bagNo1 = "bagNo";
  const DesignNeed1 = "DesignNeed";
  const PoNo1 = "PoNo";
  const Priority1 = "priority";
  const finalImg1 = "finalImg";
  const refImge1 = "refImge";
  const deliveryDate1 = "deliveryDate";
  const shapeType1 = "shapeType";
  const ref_by1 = "ref_by";
  const requestStatus1 = "requestStatus";
  const code1 = "code";
  const id1 = "id";
  // const estRate1 = 'estRate';
  // const orderNumber1 = 'orderNumber';
  // const due_date1 = 'due_date';
  const created_by1 = "created_by";
  const client_id1 = "client_id";
  const company1 = "company";

  const totalOrder = filteredRemittanceData
    .filter(
      (remittance) =>
        remittance.requestStatus !== "Export" &&
        remittance.requestStatus !== "Canceled Order"
    )
    .reverse().length;

  const uniqueJewelryTypes = Array.from(
    new Set(
      jewelryTypes
        .filter((type) => type !== null && type?.trim() !== "")
        .map((type) => type?.trim().toLowerCase())
    )
  );

  const uniqueJewelrySubTypes = Array.from(
    new Set(
      jewelrySubTypes
        .filter((type) => type !== null && type?.trim() !== "")
        .map((type) => type?.trim().toLowerCase())
    )
  );

  const uniqueSettings = Array.from(
    new Set(
      setting
        .filter((type) => type !== null && type?.trim() !== "")
        .map((type) => type?.trim().toLowerCase())
    )
  );

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handlePriorityFilterChange = (value) => {
    const newSelectedPriority = selectedPriority.includes(value)
      ? selectedPriority.filter((priority) => priority !== value)
      : [...selectedPriority, value];
    setSelectedPriority(newSelectedPriority);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="">
        <div className="d-flex justify-content-between align-items-center mb-3 w-100">
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <div onClick={handleRedChatBox} className="btn btn-primary">
              {buttonText}
            </div>
            {/* <div onClick={handleRedChatBox}>{renderUnreadMessageCount()}</div> */}
            <div>
              Total Order <span className="fw-bold fs-5">({totalOrder})*</span>
            </div>
          </div>
          <div>
            <Link to="/adminCancelOrder">
              <button className="btn btn-primary">Canceled List</button>
            </Link>
            <Link to="/adminExport">
              <button className="btn btn-primary">Delivered List </button>
            </Link>
            <button onClick={handleSelectAll} className="btn btn-primary me-2">
              {selectAllChecked ? "Deselect All" : "Select All"}
            </button>
            <MDBBtn onClick={downloadSelectedCSV}>
              <MDBIcon fas icon="download" className="me-2" />
              Download Data
            </MDBBtn>
          </div>
        </div>
        <div
          className="d-flex  align-items-center gap-2 px-2 rounded-5 bg_report"
          style={{ top: "163px", zIndex: 999, position: "sticky" }}
        >
          {/* <div className=" d-flex gap-3">
            <div className="d-flex flex-column w-100">
              <FilterAltRoundedIcon onClick={toggleFilters} />
            </div>
          </div> */}

          <>
            {showRef_byFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="text-center pb-1">
                    Ref_by
                  </label>
                  <div className="dropdown">
                    <button
                      className="form-select w-100"
                      type="button"
                      onClick={handleDropdownToggle}
                    >
                      {selectedRef_byType || "Select"}
                    </button>
                    {dropdownOpen && (
                      <div className="dropdown-menu show w-100 scrollable-menu">
                        <div className="dropdown-item">
                          <div>
                            <input
                              type="checkbox"
                              // className="form-check-input"
                              id="checkbox-all"
                              checked={checkboxValues["all"]}
                              onChange={() =>
                                handleCheckboxChangeCheckBox("all")
                              }
                            />
                            <label
                              className="form-check-label checkBox"
                              htmlFor="checkbox-all"
                            >
                              All
                            </label>
                          </div>
                        </div>
                        <div className="scrollable-content">
                          {ref_by
                            .filter(
                              (type) => type !== null && type.trim() !== ""
                            )
                            .map((type, index) => (
                              <div key={index} className="dropdown-item">
                                <div>
                                  <input
                                    type="checkbox"
                                    // className="form-check-input"
                                    id={`checkbox-${type.trim()}`}
                                    checked={checkboxValues[type.trim()]}
                                    onChange={() =>
                                      handleCheckboxChangeCheckBox(type.trim())
                                    }
                                  />
                                  <label
                                    className="form-check-label checkBox"
                                    htmlFor={`checkbox-${type.trim()}`}
                                  >
                                    {type.trim()}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="dropdown-item">
                          <div>
                            <input
                              type="checkbox"
                              // className="form-check-input"
                              id="checkbox-N/A"
                              checked={checkboxValues["N/A"]}
                              onChange={() =>
                                handleCheckboxChangeCheckBox("N/A")
                              }
                            />
                            <label
                              className="form-check-label checkBox"
                              htmlFor="checkbox-N/A"
                            >
                              N/A
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <CloseRoundedIcon onClick={handleRef_byTypeClose} />
              </div>
            )}

            {/* {showRef_byFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="text-center pb-1">
                    Ref_by
                  </label>
                  <select
                    value={selectedRef_byType}
                    onChange={handleRef_byTypeChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {ref_by
                      .filter((type) => type !== null && type.trim() !== "")
                      .map((type, index) => (
                        <option key={index} value={type.trim()}>
                          {type.trim()}
                        </option>
                      ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleRef_byTypeClose} />
              </div>
            )} */}
            {showCreate_byFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="text-center pb-1">
                    Create_by
                  </label>
                  <select
                    value={selectedCreate_byType}
                    onChange={handleCreate_byTypeChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {create_by &&
                      create_by
                        .filter(
                          (type) =>
                            typeof type === "string" && type.trim() !== ""
                        )
                        .map((type, index) => (
                          <option key={index} value={type.trim()}>
                            {type.trim()}
                          </option>
                        ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleCreate_byTypeClose} />
              </div>
            )}
            {showOrderNoFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Order No
                    <CloseRoundedIcon onClick={handleOrderNoClose} />
                  </label>
                  <input
                    type="text"
                    value={`J${orderNo}`}
                    onChange={handleOrderNoFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}

            {showClientIdFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-50">
                  <label htmlFor="" className="fs-6  pb-1">
                    Client ID
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleClientIdFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={clientIdFilter}
                    onChange={handleClientIdFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}
            {showClientNameFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Client Name
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleClientNameFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={clientNameFilter}
                    onChange={handleClientNameFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}
            {showDateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">Start Date</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="form-control "
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End Date</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartDateClose} />
              </div>
            )}

            {showDue_DateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    Start Due_Date
                  </label>
                  <input
                    type="date"
                    value={startDue_Date}
                    onChange={handleStartDue_DateChange}
                    className="form-control "
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End Due_Date</label>
                  <input
                    type="date"
                    value={endDue_Date}
                    onChange={handleEndDue_DateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartDue_DateClose} />
              </div>
            )}


            {showStatusFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="text-center pb-1">
                    Status
                  </label>
                  <div className="dropdown">
                    <button
                      className="form-select w-100"
                      type="button"
                      onClick={handleDropdownToggle}
                    >
                      select
                    </button>
                    {dropdownOpen && (
                      <div className="dropdown-menu show scrollable-menu">
                        {statusOptions.map((option, index) => (
                          <div key={index} className="dropdown-item">
                            <div>
                              <input
                                type="checkbox"
                                id={`checkbox-${option.value}`}
                                checked={selectedStatus.includes(option.value)}
                                onChange={() =>
                                  handleStatusChange(option.value)
                                }
                              />
                              <label
                                className="checkBox"
                                htmlFor={`checkbox-${option.value}`}
                              >
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <CloseRoundedIcon onClick={handleStatusClose} />
              </div>
            )}

            {showPriorityFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="text-center pb-1">
                    Priority
                  </label>
                  <div className="dropdown">
                    <button
                      className="form-select w-100"
                      type="button"
                      onClick={handleDropdownToggle}
                    >
                      select
                    </button>
                    {dropdownOpen && (
                      <div className="dropdown-menu show scrollable-menu">
                        {priorityOptions.map((option, index) => (
                          <div key={index} className="dropdown-item">
                            <div>
                              <input
                                type="checkbox"
                                id={`checkbox-${option.value}`}
                                checked={selectedPriority.includes(option.value)}
                                onChange={() =>
                                  handlePriorityFilterChange(option.value)
                                }
                              />
                              <label
                                className="checkBox"
                                htmlFor={`checkbox-${option.value}`}
                              >
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <CloseRoundedIcon onClick={handlePriorityClose} />
              </div>
            )}


            {/* {showStatusFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column  ">
                  <label className=" fs-6 text-center pb-1" htmlFor="">
                    Status
                  </label>
                  <select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    <option value="Req_Pending">Req_Pending</option>
                    <option value="Req_Approved">Req_Approved</option>
                    <option value="Designing">Designing</option>
                    <option value="Design Approval Pending">
                      Design Approval Pending
                    </option>
                    <option value="Design Approved">Design Approved</option>
                    <option value="Wax & Cast">Wax & Cast</option>
                    <option value="Filing">Filing</option>
                    <option value="Pre Polishing">Pre Polishing</option>
                    <option value="Diamond">Diamond</option>
                    <option value="Metal Setting">Metal Setting</option>
                    <option value="Final Polishing">Final Polishing</option>
                    <option value="Finished Good">Finished Good</option>
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleStatusClose} />
              </div>
            )} */}
            {showTypeFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="text-center pb-1">
                    JewelryType
                  </label>
                  <select
                    value={selectedJewelryType}
                    onChange={handleJewelryTypeChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {uniqueJewelryTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleTypeClose} />
              </div>
            )}

            {showSubTypeFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    JewelrySubType
                  </label>
                  <select
                    value={selectedJewelrySubTypeFilter}
                    onChange={handleJewelrySubTypeFilterChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {uniqueJewelrySubTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleJewelrySubTypeClose} />
              </div>
            )}

            {showSettingFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    Setting
                  </label>
                  <select
                    value={selectedSetting}
                    onChange={handleSettingFilterChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {uniqueSettings.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleSettingClose} />
              </div>
            )}
            {showLotIDFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Ref. Piece
                    <CloseRoundedIcon onClick={handleRefLotIdClose} />
                  </label>
                  <input
                    type="text"
                    value={refLotIdFilter}
                    onChange={handleRefLotIdFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}
            {showShape_TypeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    ShapType
                  </label>
                  <select
                    value={selectedShapeTypeFilter}
                    onChange={handleShapeTypeFilterChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {shapeTypes &&
                      shapeTypes
                        .filter((type) => type && type.trim() !== "") // Exclude null and empty values
                        .map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleShapeTypeClose} />
              </div>
            )}

            {showMetal_TypeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    MetalType
                  </label>
                  <select
                    value={selectedMetalTypeFilter}
                    onChange={handleMetalTypeFilterChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {metalTypes &&
                      metalTypes
                        .filter((type) => type && type.trim() !== "") // Exclude null and empty values
                        .map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleMetalTypeClose} />
              </div>
            )}
            {showJewelry_SizeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Jewelry_Size
                    <CloseRoundedIcon onClick={handleJewelrySizeClose} />
                  </label>
                  <input
                    type="text"
                    value={jewelrySizeFilter}
                    onChange={handleJewelry_SizeFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}
            {showDiamondSizeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    T_Dia_Size
                    <CloseRoundedIcon onClick={handleDiamondSizeClose} />
                  </label>
                  <input
                    type="text"
                    value={diamondSizeFilter}
                    onChange={handleDiamondSizeChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}
            {showQtyFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Filter Quantity
                    <CloseRoundedIcon onClick={handleQtyClose} />
                  </label>
                  <input
                    type="text"
                    value={filterQty}
                    onChange={handleQtyChange}
                    className="form-control  w-50"
                    required
                  />
                </div>
              </div>
            )}

            {showBag_Filter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Bag-No
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleBagFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={bagFilter}
                    onChange={handleBagFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}

            {showPoNo_Filter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Po-No
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handlePoNoFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={poNoFilter}
                    onChange={handlePoNoFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}

            {showDesignNeed_Filter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Design-Need?
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleDesignNeedFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={designNeedFilter}
                    onChange={handleDesignNeedFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}

            {showE_DateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    Start E_Date
                  </label>
                  <input
                    type="date"
                    value={startE_Date}
                    onChange={handleStartE_DateChange}
                    className="form-control"
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End E_Date</label>
                  <input
                    type="date"
                    value={endE_Date}
                    onChange={handleEndE_DateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartE_DateClose} />
              </div>
            )}

            {showDe_DateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    Start De_Date
                  </label>
                  <input
                    type="date"
                    value={startDe_Date}
                    onChange={handleStartDe_DateChange}
                    className="form-control "
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End De_Date</label>
                  <input
                    type="date"
                    value={endDe_Date}
                    onChange={handleEndDe_DateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartDe_DateClose} />
              </div>
            )}

            {/*
         <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    JewelrySubType
                  </label>
                  <select
                    value={selectedJewelrySubTypeFilter}
                    onChange={handleJewelrySubTypeFilterChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {jewelrySubTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    Category
                  </label>
                  <select
                    value={selectedJewelryType}
                    onChange={handleJewelryTypeChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {jewelryTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
               
                
              </div> */}
          </>
          {/* )} */}
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <table className="table table-bordered ">
              <thead id="addProductTableHead" style={{ top: "79px" }}>
                <tr>
                  <th className="p-2 fw-bold">Select</th>
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(created_by1) ? "table-cell" : "none",
                    }}
                  >
                    Create_by
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setShowCreate_byFilter(!showCreate_byFilter)
                      }
                    />
                  </th> */}
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(ref_by1) ? "table-cell" : "none",
                    }}
                  >
                    Order #
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowRef_byFilter(!showRef_byFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold position-relative"
                    style={{
                      display: allKeys?.has(client_id1) ? "table-cell" : "none",
                    }}
                  >
                    C_ID
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowClientIdFilter(!showClientIdFilter)}
                    />
                    {/* {showClientIdFilter && (
                <div className="d-flex  p-2 align-items-center w-100 start-0  position-absolute bg-white">
                  <div className="d-flex flex-column ">
                   
                    <input
                      type="text"
                      value={clientIdFilter}
                      onChange={handleClientIdFilterChange}
                      className="form-control w-100"
                    />
                  </div>
                  <CloseRoundedIcon onClick={handleClientIdFilterClose} />
                </div>
              )} */}
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(company1) ? "table-cell" : "none",
                    }}
                  >
                    Outlet
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setShowClientNameFilter(!showClientNameFilter)
                      }
                    />
                  </th>

                  <th className="p-2 fw-bold position-relative">
                    Customer
                  </th>

                  <th
                    className="p-2 fw-bold"
                  // style={{
                  //   display: allKeys?.has(id1) ? "table-cell" : "none",
                  // }}
                  >
                    OrderID
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowOrderNoFilter(!showOrderNoFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(requestDate1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Date{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowDateFilter(!showDateFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(requestStatus1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Status{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowStatusFilter(!showStatusFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(jewelryType1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Type
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowTypeFilter(!showTypeFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(jewelrySubType1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Sub_Type
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowSubTypeFilter(!showSubTypeFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(setting1) ? "table-cell" : "none",
                    }}
                  >
                    Setting
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowSettingFilter(!showSettingFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(refLotId1) ? "table-cell" : "none",
                    }}
                  >
                    Gdc Style
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowLotIDFilter(!showLotIDFilter)}
                    />
                  </th>

                  <th className="p-2 fw-bold">
                    Vendor Style
                  </th>

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(shapeType1) ? "table-cell" : "none",
                    }}
                  >
                    Shape
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowShape_TypeFilter(!showShape_TypeFilter)
                      }
                    />
                  </th> */}
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(metalType1) ? "table-cell" : "none",
                    }}
                  >
                    Metal
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowMetal_TypeFilter(!showMetal_TypeFilter)
                      }
                    />
                  </th>

                  <th className="p-2 fw-bold">
                    Milgrain
                  </th>

                  <th className="p-2 fw-bold">
                    Head
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(jewelrySize1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Size
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowJewelry_SizeFilter(!showJewelry_SizeFilter)
                      }
                    />
                  </th>

                  <th className="p-2 fw-bold">
                    Stones
                  </th>

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(diamondApoSize1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Diamond Size
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowDiamondSizeFilter(!showDiamondSizeFilter)
                      }
                    />
                  </th> */}

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(qty1) ? "table-cell" : "none",
                    }}
                  >
                    Quantity
                    <ArrowDropDownRoundedIcon
                      onClick={() => setshowQtyFilter(!showQtyFilter)}
                    />
                  </th>

                  <th className="p-2 fw-bold">LABOR GDC</th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(comment1) ? "table-cell" : "none",
                    }}
                  >
                    Instructions
                  </th>

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(refLink1) ? "table-cell" : "none",
                    }}
                  >
                    Ref.link
                  </th> */}

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(refImge1) ? "table-cell" : "none",
                    }}
                  >
                    ref_Img
                  </th> */}

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(PoNo1) ? "table-cell" : "none",
                    }}
                  >
                    Po.No{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowPoNo_Filter(!showPoNo_Filter)}
                    />
                  </th> */}
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(DesignNeed1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Cad Cam{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setShowDesignNeed_Filter(!showDesignNeed_Filter)
                      }
                    />
                  </th>

                  <th className="p-2 fw-bold">Quote</th>

                  <th className="p-2 fw-bold">
                    Due Date{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowDue_DateFilter(!showDue_DateFilter)}
                    />
                  </th>

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(bagNo1) ? "table-cell" : "none",
                    }}
                  >
                    Bag-No{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowBag_Filter(!showBag_Filter)}
                    />
                  </th> */}
                  {/* <th className="p-2 fw-bold">
                    Due_Date{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowDue_DateFilter(!showDue_DateFilter)}
                    />
                  </th> */}
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(estDate1) ? "table-cell" : "none",
                    }}
                  >
                    E_Date{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowE_DateFilter(!showE_DateFilter)}
                    />
                  </th> */}

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(Priority1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Priority{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowPriorityFilter(!showPriorityFilter)}
                    />
                  </th> */}
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys.has(deliveryDate1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    D_Date
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowDe_DateFilter(!showDe_DateFilter)}
                    />
                  </th> */}

                  <th className="p-2 fw-bold">Chatbox</th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(finalImg1) ? "table-cell" : "none",
                    }}
                  >
                    F_Img
                  </th>
                  <th className="p-2 fw-bold">Tracking</th>
                  <th className="p-2 fw-bold">Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentRemittanceData.map((remittance, index) => {
                  return (
                    <tr
                      key={index}
                      className={`hover-row ${selectedRows.includes(remittance.id) ? "bg_select" : ""
                        }`}
                    >

                      <td
                        // id="showUserTablebody"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(e, remittance.id)
                          }
                          checked={
                            selectAllChecked ||
                            selectedRows.includes(remittance.id)
                          }
                        />
                      </td>

                      {/* <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(created_by1) ? "table-cell" : "none",
                        }}
                      >
                        {remittance.created_by || "N/A"}
                      </td> */}

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(ref_by1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "60px" }}
                            value={
                              editedValues.ref_by !== undefined
                                ? editedValues.ref_by
                                : remittance.ref_by
                            }
                            onChange={(e) => handleInputChange(e, "ref_by")}
                          />
                        ) : (
                          remittance.ref_by || "N/A"
                        )}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(client_id1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {remittance.code}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(company1)
                            ? "table-cell"
                            : "none",
                          backgroundColor:
                            remittance.company === "Test"
                              ? "Yellow"
                              : "inherit",
                        }}
                      >
                        {remittance.company}
                      </td>

                      <td id="showUserTablebody">{remittance.customer || "N/A"}</td>

                      <td id="showUserTablebody">J{remittance.id}</td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(requestDate1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {formatDate(remittance.requestDate)}
                      </td>

                      <span
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(requestStatus1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        <td
                          ref={dropdownRef}
                          id="showUserTablebody"
                          className="fw-bold"
                          onClick={(e) =>
                            handleDropdownClick(e, index, remittance.id)
                          }
                          style={{
                            color:
                              openDropdownIndex === index
                                ? "black"
                                : !remittance.id ||
                                  !remittance.requestStatus ||
                                  remittance.requestStatus === "Req_Pending"
                                  ? "orange"
                                  : remittance.requestStatus === "Req_Approved"
                                    ? "green"
                                    : remittance.requestStatus === "Designing"
                                      ? "blue"
                                      : remittance.requestStatus ===
                                        "Design Approval Pending"
                                        ? "#9A208C"
                                        : remittance.requestStatus === "Design Approved"
                                          ? "#4FA095"
                                          : remittance.requestStatus === "Wax & Cast"
                                            ? "#b7410e"
                                            : remittance.requestStatus === "Filing"
                                              ? "#ff7f50"
                                              : remittance.requestStatus === "Pre Polishing"
                                                ? "#50c878"
                                                : remittance.requestStatus === "Diamond"
                                                  ? "olive"
                                                  : remittance.requestStatus === "Metal Setting"
                                                    ? "#b7410e"
                                                    : remittance.requestStatus === "Final Polishing"
                                                      ? "#e0b0ff"
                                                      : remittance.requestStatus === "Finished Good"
                                                        ? "red"
                                                        : remittance.requestStatus === "Hold"
                                                          ? "#096C87"
                                                          : remittance.requestStatus === "Assembly Filling"
                                                            ? "#000187"
                                                            : remittance.requestStatus === "Export"
                                                              ? "#4BE8E1"
                                                              : "inherit",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {openDropdownIndex === index &&
                            remittance.requestStatus !== "Export" ? (
                            <select
                              ref={dropdownRef}
                              id="showUserTablebody"
                              value={selectedRequestStatus}
                              onChange={handleSelectChange}
                            >
                              {remittance.requestStatus === null ||
                                remittance.requestStatus === "Req_Pending" ? (
                                <>
                                  <option value="Req_Pending">
                                    Req_Pending
                                  </option>
                                  <option value="Req_Approved">
                                    Req_Approved
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Req_Approved" ? (
                                <>
                                  <option value="Req_Approved">
                                    Req_Approved
                                  </option>
                                  <option value="Designing">Designing</option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Designing" ? (
                                <>
                                  <option value="Designing">Designing</option>
                                  <option value="Design Approval Pending">
                                    Design Approval Pending
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus ===
                                "Design Approval Pending" ? (
                                <>
                                  <option value="Design Approval Pending">
                                    Design Approval Pending
                                  </option>
                                  <option value="Design Approved">
                                    Design Approved
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus ===
                                "Design Approved" ? (
                                <>
                                  <option value="Design Approved">
                                    Design Approved
                                  </option>
                                  <option value="Wax & Cast">Wax & Cast</option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Wax & Cast" ? (
                                <>
                                  <option value="Wax & Cast">Wax & Cast</option>
                                  <option value="Filing">Filing</option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Filing" ? (
                                <>
                                  <option value="Filing">Filing</option>
                                  <option value="Pre Polishing">
                                    Pre Polishing
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Pre Polishing" ? (
                                <>
                                  <option value="Pre Polishing">
                                    Pre Polishing
                                  </option>
                                  <option value="Assembly Filling">Assembly Filling</option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Assembly Filling" ? (
                                <>
                                  <option value="Assembly Filling">Assembly Filling</option>
                                  <option value="Diamond">
                                    Diamond
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Diamond" ? (
                                <>
                                  <option value="Diamond">
                                    Diamond
                                  </option>
                                  <option value="Metal Setting">
                                    Metal Setting
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus ===
                                "Metal Setting" ? (
                                <>
                                  <option value="Metal Setting">
                                    Metal Setting
                                  </option>
                                  <option value="Final Polishing">
                                    Final Polishing
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {remittance.requestStatus === "Final Polishing" ? (
                                <>
                                  <option value="Final Polishing">
                                    Final Polishing
                                  </option>
                                  <option value="Finished Good">Finished Good</option>
                                </>
                              ) : (
                                ""
                              )}

                              {remittance.requestStatus === "Finished Good" ? (
                                <>
                                  <option value="Finished Good">
                                    Finished Good
                                  </option>
                                  <option value="Hold">Hold</option>
                                </>
                              ) : (
                                ""
                              )}


                              {remittance.requestStatus === "Hold" ? (
                                <>
                                  <option value="Hold">
                                    Hold
                                  </option>
                                  <option value="Export">Export</option>
                                </>
                              ) : (
                                ""
                              )}

                              {/* {remittance.requestStatus === "Assembly Filling" ? (
                                <>
                                  <option value="Assembly Filling">
                                    Assembly Filling
                                  </option>
                                  <option value="Export">Export</option>
                                </>
                              ) : (
                                ""
                              )} */}

                            </select>
                          ) : !remittance.id || !remittance.requestStatus ? (
                            "Req_Pending"
                          ) : (
                            remittance.requestStatus
                          )}
                        </td>
                      </span>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(jewelryType1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "100px" }}
                            value={
                              editedValues.jewelryType !== undefined
                                ? editedValues.jewelryType
                                : remittance.jewelryType
                            }
                            onChange={(e) =>
                              handleInputChange(e, "jewelryType")
                            }
                          />
                        ) : (
                          remittance.jewelryType
                        )}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(jewelrySubType1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "80px" }}
                            value={
                              editedValues.jewelrySubType !== undefined
                                ? editedValues.jewelrySubType
                                : remittance.jewelrySubType
                            }
                            onChange={(e) =>
                              handleInputChange(e, "jewelrySubType")
                            }
                          />
                        ) : (
                          remittance.jewelrySubType
                        )}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(setting1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "80px" }}
                            value={
                              editedValues.setting !== undefined
                                ? editedValues.setting
                                : remittance.setting
                            }
                            onChange={(e) => handleInputChange(e, "setting")}
                          />
                        ) : (
                          <span
                            onClick={() => handleValueClick(index)}
                            style={{ cursor: "pointer" }}
                            title={remittance.setting}
                          >
                            {showFullValue[index] ||
                              !remittance.setting ||
                              remittance.setting.length <= 10
                              ? remittance.setting || "N/A"
                              : remittance.setting.substring(0, 10) + "..."}
                          </span>
                        )}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(refLotId1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "40px" }}
                            value={
                              editedValues.refLotId !== undefined
                                ? editedValues.refLotId
                                : remittance.refLotId
                            }
                            onChange={(e) => handleInputChange(e, "refLotId")}
                          />
                        ) : (
                          remittance.refLotId
                        )}
                      </td>

                      <td id="showUserTablebody"
                        style={{
                          display: allKeys?.has(vendor_style)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            placeholder="Vendor Style"
                            type="text"
                            className="input-field"
                            style={{ width: "60px" }}
                            value={
                              editedValues.vendor_style !== undefined
                                ? editedValues.vendor_style
                                : remittance.vendor_style
                            }
                            onChange={(e) => handleInputChange(e, "vendor_style")}
                          />
                        ) : (
                          remittance.vendor_style || "N/A"
                        )}
                      </td>

                      {/* <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(shapeType1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "70px" }}
                            value={
                              editedValues.shapeType !== undefined
                                ? editedValues.shapeType
                                : remittance.shapeType
                            }
                            onChange={(e) => handleInputChange(e, "shapeType")}
                          />
                        ) : (
                          remittance.shapeType
                        )}
                      </td> */}

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(metalType1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "50px" }}
                            value={
                              editedValues.metalType !== undefined
                                ? editedValues.metalType
                                : remittance.metalType
                            }
                            onChange={(e) => handleInputChange(e, "metalType")}
                          />
                        ) : (
                          remittance.metalType
                        )}
                      </td>

                      <td id="showUserTablebody"
                        style={{
                          display: allKeys?.has(milgrain)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            placeholder="Milgrain"
                            type="text"
                            className="input-field"
                            style={{ width: "60px" }}
                            value={
                              editedValues.milgrain !== undefined
                                ? editedValues.milgrain
                                : remittance.milgrain
                            }
                            onChange={(e) => handleInputChange(e, "milgrain")}
                          />
                        ) : (
                          remittance.milgrain || "N/A"
                        )}
                      </td>

                      <td id="showUserTablebody"
                        style={{
                          display: allKeys?.has(head)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "50px" }}
                            value={
                              editedValues.head !== undefined
                                ? editedValues.head
                                : remittance.head
                            }
                            onChange={(e) => handleInputChange(e, "head")}
                          />
                        ) : (
                          remittance.head || "N/A"
                        )}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(jewelrySize1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "70px" }}
                            value={
                              editedValues.jewelrySize !== undefined
                                ? editedValues.jewelrySize
                                : remittance.jewelrySize
                            }
                            onChange={(e) =>
                              handleInputChange(e, "jewelrySize")
                            }
                          />
                        ) : (
                          remittance.jewelrySize
                        )}
                      </td>

                      <td id="showUserTablebody">
                        <div>
                          <CommentIcon
                            data-bs-toggle="modal"
                            data-bs-target={`#modalStone-${index}`}
                            onClick={() => handleOpenModal(remittance)}
                            style={{ color: remittance.stones ? "#008000" : "#EBEBEB" }}
                          />
                          <div
                            className="modal fade"
                            id={`modalStone-${index}`}
                            tabIndex="-1"
                            aria-labelledby={`popupLabelStone-${index}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <div>
                                    <h5 className="fw-bold text-start" id={`popupLabelStone-${index}`}>
                                      Stones
                                    </h5>
                                    <span>
                                      Order No- J{selectedRemittance && selectedRemittance.id}
                                    </span>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body text-center">
                                  <span>{remittance.stones || "N/A"}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      {/* <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(diamondApoSize1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "30px" }}
                            value={
                              editedValues.diamondApoSize !== undefined
                                ? editedValues.diamondApoSize
                                : remittance.diamondApoSize
                            }
                            onChange={(e) =>
                              handleInputChange(e, "diamondApoSize")
                            }
                          />
                        ) : (
                          <span
                            onClick={() => handleValueClick(index)}
                            style={{ cursor: "pointer" }}
                            title={remittance.diamondApoSize}
                          >
                            {showFullValue[index] ||
                              !remittance.diamondApoSize ||
                              remittance.diamondApoSize.length <= 10
                              ? remittance.diamondApoSize || "N/A"
                              : remittance.diamondApoSize.substring(0, 10) +
                              "..."}
                          </span>
                        )}
                      </td> */}

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(qty1) ? "table-cell" : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            type="text"
                            className="input-field"
                            style={{ width: "30px" }}
                            value={
                              editedValues.qty !== undefined
                                ? editedValues.qty
                                : remittance.qty
                            }
                            onChange={(e) => handleInputChange(e, "qty")}
                          />
                        ) : (
                          remittance.qty
                        )}
                      </td>

                      <td id="showUserTablebody"
                        style={{
                          display: allKeys?.has(labor_gdc) ? "table-cell" : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            placeholder="Labor GDC"
                            type="text"
                            className="input-field"
                            style={{ width: "60px" }}
                            value={
                              editedValues.labor_gdc !== undefined
                                ? editedValues.labor_gdc
                                : remittance.labor_gdc
                            }
                            onChange={(e) => handleInputChange(e, "labor_gdc")}
                          />
                        ) : (
                          remittance.labor_gdc || "N/A"
                        )}
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(comment1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {/* <CommentIcon
                data-bs-toggle="modal"
                data-bs-target={`#modalfade-${index}`}
                onClick={() => handleOpenModal(remittance)}
              /> */}
                        {remittance.comment ? (
                          <CommentIcon
                            data-bs-toggle="modal"
                            data-bs-target={`#modalfade-${index}`}
                            onClick={() => handleOpenModal(remittance)}
                            style={{ color: "#008000" }} // Green color if comment exists
                          />
                        ) : (
                          <CommentIcon
                            data-bs-toggle="modal"
                            data-bs-target={`#modalfade-${index}`}
                            onClick={() => handleOpenModal(remittance)}
                            style={{ color: "#EBEBEB" }} // Light gray color if no comment
                          />
                        )}
                        <div
                          className="modal fade"
                          id={`modalfade-${index}`}
                          tabIndex="-1"
                          aria-labelledby={`popupLabel-${index}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <div>
                                  <h5
                                    className="fw-bold text-start"
                                    id={`popupLabel-${index}`}
                                  >
                                    Comment
                                  </h5>
                                  <span>
                                    Order No- J
                                    {selectedRemittance &&
                                      selectedRemittance.id}
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="text-center">
                                  <span>{remittance.comment || "N/A"}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      {/* <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(refLink1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {remittance.refLink ? (
                          <Link
                            target="_blank"
                            to={remittance.refLink}
                            style={{ color: "#008000" }}
                          >
                            <MDBIcon fas icon="eye" />
                          </Link>
                        ) : (
                          <MDBIcon
                            fas
                            icon="eye"
                            style={{ color: "#EBEBEB" }}
                          />
                        )}
                      </td> */}

                      {/* <td id="showUserTablebody">
                        <FilterIcon
                          data-bs-toggle="modal"
                          data-bs-target={`#modal-${remittance.id}`}
                          onClick={() => handleOpenModal(remittance)}
                          style={{
                            color: remittance.refImge ? "#008000" : "#EBEBEB",
                          }}
                        />
                        <div
                          className="modal fade"
                          id={`modal-${remittance.id}`}
                          tabIndex="-1"
                          aria-labelledby={`popupLabel-${remittance.id}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="fw-bold text-start"
                                  id={`popupLabel-${remittance.id}`}
                                >
                                  Ref Images - {remittance.id}
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                {remittance.refImge ? (
                                  (() => {
                                    try {
                                      const sanitizedRefImge =
                                        remittance.refImge.replace(
                                          /\\\//g,
                                          "/"
                                        );
                                      let images = [];

                                      if (
                                        sanitizedRefImge.startsWith('["') &&
                                        sanitizedRefImge.endsWith('"]')
                                      ) {
                                        images = JSON.parse(sanitizedRefImge);
                                      } else {
                                        images = [sanitizedRefImge];
                                      }

                                      return (
                                        <div>
                                          <MDBCarousel
                                            showControls
                                            style={{ color: "red" }}
                                          >
                                            {images.map((media, mediaIndex) => (
                                              <MDBCarouselItem
                                                key={mediaIndex}
                                                itemId={mediaIndex + 1}
                                              >
                                                <img
                                                  src={`${baseUrl}${media}`}
                                                  className="d-block w-100"
                                                  alt="Final"
                                                  height={450}
                                                  loading="lazy"
                                                  onClick={() =>
                                                    window.open(
                                                      `${baseUrl}${media}`
                                                    )
                                                  }
                                                />
                                              </MDBCarouselItem>
                                            ))}
                                          </MDBCarousel>
                                        </div>
                                      );
                                    } catch (error) {
                                      console.error(
                                        "Error parsing JSON:",
                                        error
                                      );
                                      return (
                                        <img
                                          src="./No Image Fund.jpg"
                                          className="d-block w-100"
                                          alt="Final"
                                          height={450}
                                          loading="lazy"
                                        />
                                      );
                                    }
                                  })()
                                ) : (
                                  <img
                                    src="./No Image Fund.jpg"
                                    className="d-block w-100"
                                    alt="Final"
                                    height={450}
                                    loading="lazy"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td> */}

                      {/* <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(PoNo1) ? "table-cell" : "none",
                        }}
                      >
                        {remittance.PoNo}
                      </td> */}

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(DesignNeed1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {remittance.DesignNeed}
                      </td>


                      <td id="showUserTablebody"
                        style={{
                          display: allKeys?.has(quote)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {editMode && editedRowIndex === index ? (
                          <input
                            placeholder="Quote"
                            type="text"
                            className="input-field"
                            style={{ width: "60px" }}
                            value={
                              editedValues.quote !== undefined
                                ? editedValues.quote
                                : remittance.quote
                            }
                            onChange={(e) => handleInputChange(e, "quote")}
                          />
                        ) : (
                          remittance.quote || "N/A"
                        )}
                      </td>

                      {/* <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(bagNo1) ? "table-cell" : "none",
                        }}
                      >
                        {isBagNoEditing[index] ? (
                          <input
                            type="text"
                            value={values[index]}
                            onChange={(e) => handleBagNoChange(e, index)}
                            onBlur={() => handleBagNoBlur(index, remittance.id)}
                            className="w-75"
                          />
                        ) : (
                          <span onClick={() => handleDoubleClick(index)}>
                            {remittance.bagNo || "N/A"}
                          </span>
                        )}
                      </td> */}

                      <td
                        id="showUserTablebody"
                        onClick={() => handleTdClick(remittance.id)}
                        style={isBeforeDays(remittance.due_date)}
                      >
                        {isEditing === remittance.id ? (
                          <td id="showUserTablebody">
                            <input
                              ref={dropdownRef}
                              type="date"
                              id={`dateInput-${remittance.id}`}
                              value={dates[remittance.id] || ""}
                              onChange={(event) =>
                                handleChange(event, remittance.id)
                              }
                              onBlur={handleBlur}
                              readOnly={isEditing !== remittance.id}
                            />
                          </td>
                        ) : (
                          <td id="showUserTablebody">
                            {formatDate(remittance.due_date, "due_date")}
                          </td>
                        )}
                      </td>

                      {/* <span
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(estDate1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        <td
                          id="showUserTablebody"
                          onClick={() => handleTdClick(remittance.id)}
                          style={isBeforeDays(remittance.estDate)}
                        >
                          {isEditing === remittance.id ? (
                            <td id="showUserTablebody">
                              <input
                                ref={dropdownRef}
                                type="date"
                                id={`dateInput-${remittance.id}`}
                                value={dates[remittance.id] || ""}
                                onChange={(event) =>
                                  handleChange(event, remittance.id)
                                }
                                onBlur={handleBlur}
                                readOnly={isEditing !== remittance.id}
                              />
                            </td>
                          ) : (
                            <td id="showUserTablebody">
                              {formatDate(remittance.estDate, "estDate")}
                            </td>
                          )}
                        </td>
                      </span> */}

                      {/* <td
                        id="showUserTablebody"
                        className="fw-bold"
                        onClick={(e) => handlePriorityDropdownClick(e, index, remittance.id)}
                        style={{
                          color:
                            openPriorityDropdownIndex === index
                              ? "black"
                              : !remittance.id || !remittance.priority
                                ? "orange"
                                : remittance.priority === "Urgent"
                                  ? "green"
                                  : remittance.priority === "Normal"
                                    ? "#9A208C"
                                    : remittance.priority === "Low"
                                      ? "#4FA095"
                                      : "inherit",
                          display: allKeys?.has(Priority1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        {openPriorityDropdownIndex === index ? (
                          <select
                            ref={dropdownRef}
                            id="showUserTablebody"
                            value={selectedPriority}
                            onChange={handlePriorityChange}
                          >
                            <option value="Select">Select</option>
                            <option value="Urgent">Urgent</option>
                            <option value="Normal">Normal</option>
                            <option value="Low">Low</option>
                          </select>
                        ) : !remittance.id || !remittance.priority ? (
                          "Select"
                        ) : (
                          remittance.priority
                        )}
                      </td> */}

                      {/* <td
                        style={{
                          display: allKeys.has(deliveryDate1)
                            ? "table-cell"
                            : "none",
                        }}
                        id="showUserTablebody"
                        onClick={() => {
                          setId(remittance.id);
                          setShowInput((prevState) => ({
                            ...prevState,
                            [remittance.id]: true,
                          }));
                        }}
                      >
                        {showInput[remittance.id] ? (
                          <input
                            type="date"
                            ref={dropdownRef}
                            onChange={handleDateChange}
                            defaultValue={remittance.deliveryDate}
                          />
                        ) : (
                          formatDate(remittance.deliveryDate)
                        )}
                      </td> */}

                      <td id="showUserTablebody">
                        <MDBBtn
                          className="userChat"
                          id="chatBox"
                          data-bs-toggle="modal"
                          data-bs-target={`#popup-${index}`}
                          onClick={() => setChatId(remittance.id)}
                        >
                          <MDBIcon far icon="comments" />
                          {renderRedDot(remittance.id)}
                        </MDBBtn>
                        <div
                          className="modal fade"
                          id={`popup-${index}`}
                          tabIndex="-1"
                          aria-labelledby={`popupLabel-${index}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <div className="text-start">
                                  <span>Order No-J{remittance.id}</span>
                                  <br />
                                  <span className="text-danger">
                                    *Content can be deleted within 3 minutes
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                className="modal-body"
                                ref={chatBoxRef}
                                onScroll={handleScroll}
                              >
                                <div>
                                  <AdminChatApp
                                    chatPostId={remittance.id}
                                    chatData={chatData}
                                    isLoading={isLoading}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td
                        id="showUserTablebody"
                        style={{
                          display: allKeys?.has(finalImg1)
                            ? "table-cell"
                            : "none",
                        }}
                      >
                        <div key={remittance.id}>
                          <MDBBtn
                            id={`chatBox-${remittance.id}`}
                            data-bs-toggle="modal"
                            data-bs-target={`#viewImage-${remittance.id}`}
                            className="userChat"
                            style={{
                              backgroundColor: remittance.finalImg
                                ? ""
                                : "#EBEBEB",
                            }}
                          >
                            <MDBIcon fas icon="photo-video" />
                          </MDBBtn>
                          <div
                            className="modal fade"
                            id={`viewImage-${remittance.id}`}
                            tabIndex="-1"
                            aria-labelledby={`popupLabel-${remittance.id}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id={`popupLabel-${remittance.id}`}
                                  >
                                    Final Image - J{remittance.id}
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  {remittance.finalImg ? (
                                    <MDBCarousel
                                      showControls
                                      style={{ color: "red" }}
                                    >
                                      <div>
                                        {JSON.parse(remittance.finalImg).map(
                                          (media, mediaIndex) => (
                                            <MDBCarouselItem
                                              key={mediaIndex}
                                              itemId={mediaIndex + 1}
                                            >
                                              <div key={mediaIndex}>
                                                {media.endsWith(".mp4") ? (
                                                  <video
                                                    controls
                                                    className="w-100"
                                                  >
                                                    <source
                                                      src={`${baseUrl}/${media}`}
                                                      type="video/mp4"
                                                    />
                                                    Your browser does not
                                                    support the video
                                                  </video>
                                                ) : (
                                                  <img
                                                    src={`${baseUrl}/${media}`}
                                                    alt={`Media ${mediaIndex}`}
                                                    className="w-100"
                                                    loading="lazy"
                                                  />
                                                )}
                                              </div>
                                            </MDBCarouselItem>
                                          )
                                        )}
                                      </div>
                                    </MDBCarousel>
                                  ) : (
                                    <img
                                      src="No Image Fund.jpg"
                                      alt="No Images"
                                      className=" w-100 h-100 mw-50 mh-50"
                                      loading="lazy"
                                    />
                                  )}
                                  <hr />
                                  <div>
                                    <h5>Upload Images or Videos</h5>
                                    <input
                                      type="file"
                                      id={`imageUpload-${remittance.id}`}
                                      accept="image/*, video/*"
                                      name="finalImg[]"
                                      onChange={(e) =>
                                        handleFileChange(e, remittance.id)
                                      }
                                      multiple
                                    />
                                    <button
                                      onClick={() =>
                                        handleUpload(remittance.id)
                                      }
                                    >
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td id="showUserTablebody">
                        <MDBBtn
                          id="chatBox"
                          className="userChat"
                          data-bs-toggle="modal"
                          data-bs-target={`#trackProduct-${index}`}
                          onClick={() => handleTrackOrderClick(remittance.id)}
                        >
                          <MDBIcon fas icon="search-location" />
                        </MDBBtn>
                        <div
                          className="modal fade"
                          id={`trackProduct-${index}`}
                          tabIndex="-1"
                          aria-labelledby={`popupLabel-${index}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id={`popupLabel-${index}`}
                                >
                                  Order - J{remittance.id}
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                {loading1 ? (
                                  <div className="d-flex justify-content-center">
                                    <div className="loader"></div>
                                  </div>
                                ) : (
                                  <TrackProduct trackingData={trackingData} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td id="showUserTablebody">
                        {editMode && editedRowIndex === index ? (
                          <>
                            <SaveIcon
                              onClick={() =>
                                handleSave(remittance, editedValues)
                              }
                            />
                          </>
                        ) : (
                          <EditIcon onClick={() => toggleEditMode(index)} />
                        )}
                      </td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <MDBIcon
                    fas
                    icon="angle-double-left"
                    className={`p-2 rounded-9 text-white ${currentPage === 1 ? "disabled" : ""
                      }`}
                    style={{ backgroundColor: "#83193E" }}
                  />
                </button>
              </li>
              <li className="page-item">
                <span className="page-link px-2">
                  Page {currentPage} of {totalPages}
                </span>
              </li>
              <li
                className={`page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <MDBIcon
                    fas
                    icon="angle-double-right"
                    className={`p-2 rounded-9 text-white ${currentPage === totalPages ? "disabled" : ""
                      }`}
                    style={{ backgroundColor: "#83193E" }}
                  />
                </button>
              </li>
            </ul>
          </>
        )}

        <MDBModal open={centredModal2} setOpen={setCentredModal2}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={closeModal2}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className="text-danger fs-4 text-center fw-semibold">
                  ARE YOU SURE?
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="danger" onClick={closeModal2}>
                  Close
                </MDBBtn>
                <MDBBtn color="success" onClick={savePriorityChanges}>
                  Save changes
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal open={centredModal} setOpen={setCentredModal}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={closeModal}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p className="text-danger fs-4 text-center fw-semibold">
                  ARE YOU SURE?
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="danger" onClick={closeModal}>
                  Close
                </MDBBtn>
                <MDBBtn color="success" onClick={saveChanges}>
                  Save changes
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
}
