import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IconButton, CircularProgress } from "@mui/material";
import {
  ArrowDownward,
  AttachFileRounded,
  Delete,
  Fullscreen,
  FullscreenExit,
} from "@mui/icons-material";
import { baseUrl } from "../../../utils/Url";

const BulkChatBox = ({ chatPostId, chatData, handleChat }) => {

  const [input, setInput] = useState("");
  const [file, setFile] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [showDeleteIcon, setShowDeleteIcon] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState([]);
  const chatBoxRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [disableSendButton, setDisableSendButton] = useState(true);
  const [userScrolled, setUserScrolled] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
    setShowScrollButton(scrollHeight - scrollTop !== clientHeight);
    setUserScrolled(scrollHeight - scrollTop !== clientHeight);
  };

  useEffect(() => {
    if (!userScrolled) {
      scrollToBottom();
    }
  }, [chatData, userScrolled]);

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    chatBox.addEventListener("scroll", handleScroll);

    return () => {
      chatBox.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const sendMessage = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const userCode = localStorage.getItem("userCode");
      const userRoleId = localStorage.getItem("userRoleId");
      const formData = new FormData();
      formData.append("user_id", userCode);
      formData.append("role_type", userRoleId);
      formData.append("messages", input || file);
      formData.append("type", file ? "1" : "0");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${baseUrl}/api/order-chat/store/${chatPostId}`,
        formData,
        { headers }
      );

      if (response.status === 200) {
        setInput("");
        setFile(null);
        handleChat(chatPostId);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      const fileId = Date.now();
      setLoadingMessages((prevMessages) => [...prevMessages, fileId]);
      sendMessage();
    }
  }, [file]);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileName = selectedFile.name;
      const validFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "text/csv",
        "video/mp4",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ".zip",
        "application/x-rar-compressed",
        "model/stl",
        "model/3dm"
      ];

      if (validFileTypes.includes(fileType) || validFileTypes.some((type) => fileName.endsWith(type))) {
        if (fileType === "application/pdf") {
          setShowPDF(true);
          setPdfURL(URL.createObjectURL(selectedFile));
        }
        setFile(selectedFile);
      }
    }
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        setFile(blob);
        break;
      }
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.delete(
        `${baseUrl}/api/order-chat/delete/${messageId}`,
        { headers }
      );
      if (response.status === 200) {
        handleChat(chatPostId);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        error.response?.data?.message ||
        "An error occurred while deleting the message. Please try again."
      );
    }
  };

  useEffect(() => {
    setDisableSendButton(input.trim() === "" && !file);
  }, [input, file]);

  const formatDate = (dateString) => {
    try {
      const parts = dateString.split("-");
      if (parts.length !== 3) {
        throw new Error("Invalid date format");
      }
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]);
      const year = parseInt(parts[2]);

      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        throw new Error("Invalid date");
      }

      const date = new Date(year, month - 1, day);

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      const formattedMonth = (month < 10 ? "0" : "") + month;
      const formattedDay = (day < 10 ? "0" : "") + day;
      return `${formattedMonth}-${formattedDay}-${year}`;
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid Date";
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      chatContainerRef.current
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
          );
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error(
            `Error attempting to exit fullscreen mode: ${err.message} (${err.name})`
          );
        });
    }
  };

  return (
    <div ref={chatContainerRef} className="chat-container">
      <div
        onClick={toggleFullscreen}
        className="position-relative d-flex justify-content-end me-2"
      >
        {isFullscreen ? (
          <FullscreenExit />
        ) : (
          <span className="fullchatbox">
            {" "}
            <Fullscreen />
          </span>
        )}
      </div>

      <div className="chat-box overflow-auto" ref={chatBoxRef}>
        {isLoading && <CircularProgress className="circularProgress" />}{" "}
        {chatData &&
          chatData.data &&
          chatData.data.map((message) => (
            <div
              key={message.id}
              className={`d-flex flex-column py-2 ${message.user_data?.client_code ===
                localStorage.getItem("userCode")
                ? "align-items-end"
                : "align-items-start"
                }`}
              onMouseEnter={() => setShowDeleteIcon(message.id)}
              onMouseLeave={() => setShowDeleteIcon(null)}
            >
              {message.user_id !== localStorage?.getItem("userCode") && (
                <div className="user-name text-capitalize">
                  {message?.user_name} - {message.user_id}
                </div>
              )}
              {!message.messages_url && (
                <div
                  className={`bg_msg position-relative ${message.role_type?.name === "superadmin"
                    ? "sent"
                    : "received"
                    }`}
                >
                  <div className="text-start ap fw-bold">
                    {message.messages}
                  </div>
                  <div className="position-absolute delete_icon">
                    {showDeleteIcon === message.id && (
                      <IconButton
                        onClick={() => handleDeleteMessage(message.id)}
                        aria-label="delete"
                        size="small"
                        className="delete-icon"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                  <div className="time_div">
                    {message.time} {formatDate(message.date)}
                  </div>
                </div>
              )}
              {message.messages_url && (
                <div
                  className={`bg_msg position-relative ${message.role_type?.name === "superadmin"
                    ? "sent"
                    : "received"
                    }`}
                >
                  <a
                    href={message.messages_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                  >
                    {loadingMessages.includes(message.id) && (
                      <CircularProgress />
                    )}
                    {!loadingMessages.includes(message.id) && (
                      <>
                        {message.messages_url.match(/\.(jpg|jpeg|png)$/i) ? (
                          <img
                            src={message.messages_url}
                            alt="image"
                            width={250}
                            height={150}
                            loading="lazy"
                          />
                        ) : message.messages_url.endsWith(".pdf") ? (
                          <img
                            src="/pdf.jpg"
                            alt="pdf"
                            width={100}
                            loading="lazy"
                          />
                        ) : message.messages_url.endsWith(".csv") ? (
                          <img
                            src="/csv.png"
                            alt="csv"
                            width={100}
                            loading="lazy"
                          />
                        ) : message.messages_url.endsWith(".xlsx") ? (
                          <img
                            src="/xlsx.png"
                            alt="xlsx"
                            width={150}
                            loading="lazy"
                          />
                        ) : message.messages_url.endsWith(".zip") ? (
                          <img
                            src="/zip.png"
                            alt="zip"
                            width={150}
                            loading="lazy"
                          />
                        ) : message.messages_url.match(/\.(mp4|ogg|webm)$/i) ? (
                          <video controls width={250}>
                            <source
                              src={message.messages_url}
                              type="video/mp4"
                            />
                          </video>
                        ) : message.messages_url.endsWith(".rar") ? (
                          <img
                            src="/rar.png"
                            alt="rar"
                            width={100}
                            loading="lazy"
                          />
                        ) : message.messages_url.endsWith(".3dm") ? (
                          <div className="3dm-file">
                            <img
                              src="/3dm.jpg"
                              alt="3dm"
                              width={100}
                              loading="lazy"
                            />
                            <p>
                              Unable to preview.{" "}
                              <a
                                href={message.messages_url}
                                download
                                target="_blank"
                                className="text-decoration-none"
                              >
                                Download
                              </a>{" "}
                              or{" "}
                              <a
                                href="https://www.rhino3d.com/viewer/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Open with Rhino 3D Viewer
                              </a>
                            </p>
                          </div>
                        ) : message.messages_url.endsWith(".stl") ? (
                          <img
                            src="/stl.png"
                            alt="stl"
                            width={100}
                            loading="lazy"
                          />
                        ) : null}
                      </>
                    )}
                  </a>
                  <div className="position-absolute delete_icon">
                    {showDeleteIcon === message.id && (
                      <IconButton
                        onClick={() => handleDeleteMessage(message.id)}
                        aria-label="delete"
                        size="small"
                        className="delete-icon"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                  <div className="time_div">
                    {message.time} {formatDate(message.date)}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      {showScrollButton && (
        <div className="scroll-down-button position-absolute scroll_down_arrow">
          <IconButton onClick={scrollToBottom}>
            <ArrowDownward />
          </IconButton>
        </div>
      )}
      <div className="input-container d-flex align-items-center gap-2 border rounded">
        <div>
          <IconButton component="label" className="z-3">
            <input type="file" onChange={handleFileChange} hidden />
            <AttachFileRounded />
          </IconButton>
        </div>
        <input
          type="text"
          placeholder="Type your message here..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="form-control rounded-lg"
          onKeyDown={(e) => {
            if (e.key === "Enter" && input.trim()) {
              e.preventDefault(); // Prevent any default action, such as opening modals
              sendMessage();
            }
          }}
        />
        <button
          onClick={() => {
            if (input.trim()) {
              sendMessage();
            }
          }}
          className="send-button"
          disabled={!input.trim()}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default BulkChatBox;

