import React, { useState } from "react";
import UserReportProduct from "../UserReportProduct";
import UserNavbar from "../../UserSidebar/UserNavbar";
import UserSidebar from "../../UserSidebar/UserSidebar";

export default function UserReport() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  return (
    <div id="admin-body">
      <UserNavbar />
      <div style={{ display: "flex" }}>
        <UserSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginTop: "30px",
            paddingLeft: "30px",
            marginLeft: isSidebarOpen ? "10%" : "4.5%",
          }}
        >
          <UserReportProduct />
        </div>
      </div>
    </div>
  );
}
