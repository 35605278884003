import React, { useEffect, useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/Url";

export default function CardFilter({
  csvData,
  setCsvData,
  selectedCards,
  setSelectedCards,
  filteredDataSearch,
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${baseUrl}/api/jstype-show-all`,
          requestOptions
        );

        if (response.ok) {
          const responseData = await response.json();
          setCsvData(responseData);
        } else {
          console.error("Error uploading CSV data:", response.error);
          alert("An error occurred while storing data. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const categoryMap = new Map();
  csvData?.forEach((ele) => {
    if (ele && ele.category && ele.subcategory) {
      if (!categoryMap.has(ele.category)) {
        categoryMap.set(ele.category, new Set());
      }
      categoryMap.get(ele.category).add(ele.subcategory);
    }
  });

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setSelectedSetting(null);
  };

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setSelectedSetting(null);
  };

  const handleSettingFilter = (setting) => {
    setSelectedSetting(setting);
  };

  const handleSelectAll = () => {
    if (selectedCards.length === filteredData.length) {
      setSelectedCards([]);
    } else {
      const allSelected = filteredData.map((item) => item.id);
      setSelectedCards(allSelected);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...csvData];
    updatedData[index][field] = value;
    setCsvData(updatedData);
  };

  const filteredData = csvData
    ?.filter((item) => {
      if (selectedSetting) {
        return (
          item.subcategory === selectedSubcategory &&
          item.category === selectedCategory &&
          item.setting === selectedSetting
        );
      } else if (selectedSubcategory) {
        return (
          item.subcategory === selectedSubcategory &&
          item.category === selectedCategory
        );
      } else if (selectedCategory) {
        return item.category === selectedCategory;
      }
      return true;
    })
    .filter((item) => {
      if (filteredDataSearch && filteredDataSearch.length > 0) {
        return filteredDataSearch.includes(item);
      }
      return true;
    });

  return (
    <div>
      <div className="submenu-container">
        <nav className="d-flex justify-content-between">
          <ul className="subcategory p-2 d-flex justify-content-between g-3 align-items-center">
            {[...categoryMap]?.map(([category, subcategories], index) => (
              <li className="col-lg-3" key={index}>
                <a href="#" onClick={() => handleCategoryClick(category)}>
                  {category}
                </a>
                {selectedCategory === category && (
                  <ul>
                    {[...subcategories]?.map((subcategory, subIndex) => (
                      <li key={subIndex}>
                        <a
                          href="#"
                          onClick={() => handleSubcategoryClick(subcategory)}
                        >
                          {subcategory}
                        </a>

                        {selectedSubcategory === subcategory && (
                          <ul className="setting_filter">
                            {[...new Set(
                              csvData
                                .filter(item => item.subcategory === selectedSubcategory)
                                .map(item => item.setting)
                            )].map((setting, index) => (
                              <li key={index}>
                                <a onClick={() => handleSettingFilter(setting)}>
                                  {setting}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}

                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div>
            <MDBBtn onClick={handleSelectAll} className="btn-primary m-2">
              Select All
            </MDBBtn>
          </div>
        </nav>
      </div>

      <div>
        <br />
        <span className="fs-5">Total Designs: {filteredData?.length}</span>
        <CardData
          filteredData={filteredData}
          setSelectedCards={setSelectedCards}
          selectedCards={selectedCards}
          handleInputChange={handleInputChange}
          setCsvData={setCsvData}
          loading={loading}
          filteredDataSearch={filteredDataSearch}
        />
      </div>
    </div>
  );
}

function CardData({
  filteredData,
  selectedCards,
  setSelectedCards,
  setCsvData,
  loading,
  filteredDataSearch,
}) {
  const [centredModal, setCentredModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [imageLoading, setImageLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(false);
      setImageLoading(false);
      setShowImage(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleImageLoad = () => {
    setImageLoading(false);
  };


  const mergedData = filteredDataSearch && filteredData;

  const toggleOpen = (item) => {
    setCurrentVideo(item);
    setCentredModal(!centredModal);
  };

  const handleCheckboxChange = (id) => {
    const isSelected = selectedCards.includes(id);
    if (!isSelected) {
      setSelectedCards([...selectedCards, id]);
    } else {
      setSelectedCards(selectedCards.filter((cardId) => cardId !== id));
    }
  };

  const fillEmptyFields = (data) => {
    const filledData = { ...data };
    Object.keys(filledData).forEach((key) => {
      if (!filledData[key]) {
        filledData[key] = "-";
      }
    });
    return filledData;
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(fillEmptyFields(editedData)),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/jstype-edit`,
        requestOptions
      );

      if (response.ok) {
        const updatedData = await response.json();
        const newData = [...filteredData];
        newData[editIndex] = updatedData;
        setCsvData(newData);
        setEditIndex(null);
        setErrorMessage("");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setErrorMessage("An error occurred while saving data. Please try again.");
    }
  };


  const copyToClipboard = () => {
    if (currentVideo) {
      const textArea = document.createElement("textarea");
      textArea.value = currentVideo.video_url;
      textArea.style.position = "fixed"; // To make sure it's out of view
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const message = successful ? "Link copied to clipboard!" : "Failed to copy the link.";
        const icon = successful ? "success" : "error";

        Swal.fire({
          title: "Copy to Clipboard",
          text: message,
          icon: icon,
        });
      } catch (err) {
        console.error("Error copying text to clipboard:", err);
      }

      document.body.removeChild(textArea);
    }
  };


  const copyToClipboardVideo = () => {
    if (currentVideo) {
      const textArea = document.createElement("textarea");
      textArea.value = currentVideo.video_url;
      textArea.style.position = "fixed"; // To make sure it's out of view
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const message = successful ? "Link copied to clipboard!" : "Failed to copy the link.";
        const icon = successful ? "success" : "error";

        Swal.fire({
          title: "Copy to Clipboard",
          text: message,
          icon: icon,
        });
      } catch (err) {
        console.error("Error copying text to clipboard:", err);
      }

      document.body.removeChild(textArea);
    }
  };

  const copyToClipboardImage = () => {
    if (currentVideo) {
      const textArea = document.createElement("textarea");
      textArea.value = currentVideo.image_url;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const message = successful ? "Link copied to clipboard!" : "Failed to copy the link.";
        const icon = successful ? "success" : "error";

        Swal.fire({
          title: "Copy to Clipboard",
          text: message,
          icon: icon,
        });
      } catch (err) {
        console.error("Error copying text to clipboard:", err);
      }

      document.body.removeChild(textArea);
    }
  };



  const shareOnWhatsAppVideo = (item) => {
    if (!item) {
      console.error("Item is undefined");
      return;
    }

    const messagePart = "*Check Out This Item:*";
    const videoLink = item?.video_url?.trim().replace(/\s/g, "%20");

    const additionalInfo = `
    *Video*: ${videoLink}
    `;

    const message = `${messagePart}\n${additionalInfo}`;

    const encodedMessage = encodeURIComponent(message);

    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  };


  const shareOnWhatsAppImage = (item) => {
    if (!item) {
      console.error("Item is undefined");
      return;
    }

    const messagePart = "*Check Out This Item:*";
    const imgLink = item?.image_url?.trim().replace(/\s/g, "%20");

    const additionalInfo = `
    *Image*: ${imgLink}
    `;

    const message = `${messagePart}\n${additionalInfo}`;

    const encodedMessage = encodeURIComponent(message);

    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  }

  // const shareOnWhatsApp = (item) => {
  //   if (!item) {
  //     console.error("Item is undefined");
  //     return;
  //   }

  //   const messagePart = "*Check Out This Item:*";
  //   const videoLink = item?.video_url?.trim().replace(/\s/g, "%20");
  //   const imgLink = item?.image_url?.trim().replace(/\s/g, "%20");

  //   const additionalInfo = `
  //   1. *Style Name*: ${item.name}
  //   2. *Category*: ${item.category}
  //   3. *Subcategory*: ${item.subcategory}
  //   4. *Metal*: ${item.metal}
  //   5. *Shape*: ${item.shape}
  //   6. *Color*: ${item.color}
  //   7. *Center Color Range*: ${item.center_color_range}
  //   6. *Avg Stone Size*: ${item.avg_stone_size}
  //   7. *Total Ct Weight*: ${item.ct_weight_total}
  //   8. *Total Diamond Range*: ${item.total_diamond_range}
  //   9. *Total Orientation*: ${item.orientation}
  //   10. *Total Setting*: ${item.setting}
  //   11. *Design No*: ${item.design_no}
  //   12. *Compnt_Total_Qty_Dia*: ${item.compnt_total_qty_dia}
  //   13. *Image*: ${imgLink}
  //   14. *Video*: ${videoLink}
  //   `;

  //   const message = `${messagePart}\n${additionalInfo}`;

  //   const encodedMessage = encodeURIComponent(message);

  //   window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  // };

  return (
    <>
      {errorMessage && (
        <p className="error-message text-danger fw-bold fs-3">{errorMessage}</p>
      )}
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-wrap">
            {mergedData && mergedData.length > 0 ? (
              mergedData?.map((item, index) => (
                <MDBCard key={index} className="custom-card">
                  <input
                    type="checkbox"
                    className="position-absolute chack_box"
                    checked={selectedCards.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  {/* <span
                    className="position-absolute cursor-pointer edit_button"
                    onClick={() => handleEditClick(index)}
                  >
                    Edit
                  </span>
                  {editIndex === index && (
                    <span
                      className="position-absolute cursor-pointer edit_button"
                      onClick={handleSaveClick}
                    >
                      Save
                    </span>
                  )} */}

                  <MDBCardHeader
                    className="p-0 d-flex justify-content-center align-items-center h-100 position-relative"
                    onClick={() => toggleOpen(item)}
                  >
                    {showSpinner && (
                      <div className="spinner-border position-absolute" role="status" style={{ zIndex: 1 }}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {showImage ? (
                      <>
                        <img
                          src={item.image_url}
                          className={`w-100 img-fluid ${imageLoading ? "d-none" : ""}`}
                          loading="lazy"
                          onLoad={handleImageLoad}
                          alt="Item"
                          style={{ zIndex: 0 }}
                        />
                        <PlayCircleIcon
                          className="card_desc cursor-pointer position-absolute"
                          style={{ fontSize: 70, color: "white", zIndex: 2, display: imageLoading ? 'none' : 'block' }}
                        />
                      </>
                    ) : (
                      // Placeholder for the image to maintain layout consistency
                      <div style={{ width: "100%", height: "100%" }}></div>
                    )}
                    {!item.image_url && (
                      <img src="./No Video Found.jpg" alt="No Video Found" className="w-100" />
                    )}
                  </MDBCardHeader>

                  <MDBCardBody className="row d-flex">
                    {editIndex === index ? (
                      <>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>STYLE NAME</label>
                          <input
                            type="text"
                            value={editedData.name}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                name: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            subcategory
                          </label>
                          <input
                            type="text"
                            value={editedData.subcategory}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                subcategory: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>CATEGORY</label>
                          <input
                            type="text"
                            value={editedData.category}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                category: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>SHAPE</label>
                          <input
                            type="text"
                            value={editedData.shape}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                shape: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>METAL</label>
                          <input
                            type="text"
                            value={editedData.metal}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                metal: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>AVG STONE</label>
                          <input
                            type="text"
                            value={editedData.avg_stone_size}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                avg_stone_size: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>SETTING</label>
                          <input
                            type="text"
                            value={editedData.setting}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                setting: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            ORIENTATION
                          </label>
                          <input
                            type="text"
                            value={editedData.orientation}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Ct_weight
                          </label>
                          <input
                            type="text"
                            value={editedData.ct_weight_total}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Color
                          </label>
                          <input
                            type="text"
                            value={editedData.color}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Compnt_Qty_Dia
                          </label>
                          <input
                            type="text"
                            value={editedData.compnt_total_qty_dia}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Center_Col_Range
                          </label>
                          <input
                            type="text"
                            value={editedData.center_color_range}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Design_No
                          </label>
                          <input
                            type="text"
                            value={editedData.design_no}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Total_Dia_Range
                          </label>
                          <input
                            type="text"
                            value={editedData.total_diamond_range}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>
                            Image_url
                          </label>
                          <input
                            type="text"
                            value={editedData.image_url}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                orientation: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          md="4"
                          className="text-center py-1 border-bottom d-flex flex-column"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          <label style={{ fontSize: "11px" }}>VIDEO</label>
                          <input
                            type="text"
                            value={editedData.video_url}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                video_url: e.target.value,
                              })
                            }
                          />
                        </MDBCol>
                      </>
                    ) : (
                      <>
                        <MDBCardBody className=" row d-flex ">
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>
                              {" "}
                              STYLE NAME{" "}
                            </label>{" "}
                            {item.name}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "10px" }}>
                              SUBCATEGORY
                            </label>
                            {item.subcategory}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>CATEGORY</label>
                            {item.category}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>SHAPE</label>
                            {item.shape}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>Ct_Weight_Total</label>
                            {item.ct_weight_total}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom  d-flex flex-column "
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>METAL</label>
                            {item.metal}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>Diamond_Range</label>
                            {item.total_diamond_range}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>Design_No</label>
                            {item.design_no}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>Center_Color_Range</label>
                            {item.center_color_range}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>Color</label>
                            {item.color}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>Compnt_Qty_Dia</label>
                            {item.compnt_total_qty_dia}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>
                              AVG STONE SIZE
                            </label>
                            {item.avg_stone_size}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>SETTING</label>
                            {item.setting}
                          </MDBCol>
                          <MDBCol
                            md="4"
                            className="text-center py-1 border-bottom d-flex flex-column"
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            <label style={{ fontSize: "11px" }}>
                              ORIENTATION
                            </label>
                            {item.orientation}
                          </MDBCol>
                        </MDBCardBody>
                      </>
                    )}
                  </MDBCardBody>
                </MDBCard>
              ))
            ) : (
              <p>No data available</p>
            )}
            <MDBModal
              tabIndex="-1"
              open={centredModal}
              setOpen={setCentredModal}
            >
              <MDBModalDialog centered>
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={() => toggleOpen(null)}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    {currentVideo?.video_url && (
                      <div>
                        <video
                          src={currentVideo?.video_url}
                          controls
                          className="w-100"
                        />
                        <div className="mt-3 d-flex">
                          <MDBDropdown>
                            <MDBDropdownToggle color="primary">
                              Share
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="dropdown_menu">
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={copyToClipboardVideo}
                              >
                                Copy Link Video
                              </MDBDropdownItem>

                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={copyToClipboardImage}
                              >
                                Copy Link Image
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={() => shareOnWhatsAppVideo(currentVideo)}
                              >
                                Share on WhatsApp Video
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={() => shareOnWhatsAppImage(currentVideo)}
                              >
                                Share on WhatsApp Image
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                          {/* <MDBBtn onClick={downloadVideo} className="ms-2">Download</MDBBtn> */}
                        </div>
                      </div>
                    )}
                  </MDBModalBody>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </div>
        </div>
      )}
    </>
  );
}
