import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { baseUrl } from "../../../utils/Url";
const CsvUpload = ({ setCsvData }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please select a CSV file to upload.");
      return;
    }
    const token = localStorage.getItem("token"); // Retrieve token from local storage

    if (!token) {
      alert("Please log in to upload CSV files.");
      return;
    }
    Papa.parse(file, {
      complete: (result) => {
        const parsedData = result.data;
        sendToApi(parsedData, token);
      },
      header: true,
    });
  };
  

  const sendToApi = async (parsedData, token) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formdata = new FormData();
      formdata.append("csv_file", file, "style_name.csv");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseUrl}/api/jstype-upload-csv`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    } catch (error) {
      console.error("Error uploading CSV data:", error);
      // alert("An error occurred while storing data. Please try again.");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseUrl}/api/jstype-show-all`, requestOptions)
      .then((response) => response.text())
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload} className="btn-bg-color">
        Upload CSV
      </button>
    </div>
  );
};
export default CsvUpload;
