import React, { Suspense, useState } from 'react'
import Navbar from '../SideBar/Navbar';
import Sidebar from '../SideBar/Sidebar';
import AdminCadMasterUploadPopup from './Component/AdminCadMasterUploadPopup';
import AdminCadMasterRequirement from './Component/AdminCadMasterRequirement';

export default function AdminCadMasterOrder() {
    const [csvData, setCsvData] = useState(null);
    const [selectedCards, setSelectedCards] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div id='admin-body'>
            <Navbar />
            <div style={{ display: 'flex' }}>
                <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div style={{ flex: 1, padding: '20px', marginLeft: isSidebarOpen ? '13%' : '4.5%' }}>

                    <div className='pb-3'>
                        <AdminCadMasterUploadPopup
                            setCsvData={setCsvData} setSelectedCards={setSelectedCards} selectedCards={selectedCards}
                        />
                    </div>
                    <div id="superAdrequimentForm">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AdminCadMasterRequirement
                                csvData={csvData} setCsvData={setCsvData} setSelectedCards={setSelectedCards} selectedCards={selectedCards}
                            />
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
}
