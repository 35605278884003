import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/Url";

export default function ShowPermissionTable({
  permissionUser,
  sendPermissionEditId,
  permissionUserEdit,
  fetchMPermission
}) {
  const [centredModal, setCentredModal] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});
  const [module, setModule] = useState("");
  const [userId, setUserId] = useState("");
  const [ordersData, setOrdersData] = useState(null);

  const toggleCheckbox = (value) => {
    if (value !== "null") {
      setCheckedValues((prevState) => ({
        ...prevState,
        [value]: !prevState[value],
      }));
    }
  };

  const toggleColumnCheckbox = (columnName, checkboxName) => {
    const key = `${columnName}-${checkboxName}`;
    setCheckedValues((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useEffect(() => {
    if (module === "orders") {
      fetchOrdersData();
    }
  }, [module]);

  const fetchOrdersData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/modules/${module}`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setOrdersData(responseData);
      } else {
        console.error("Error fetching module data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
  };

  useEffect(() => {
    if (permissionUserEdit) {
      setModule(permissionUserEdit.module);
      setUserId(permissionUserEdit.user_id.toString());

      const initialCheckedValues = {};
      permissionUserEdit.row_level.forEach((value) => {
        initialCheckedValues[value] = true;
      });

      const initialColumnLevelCheckedValues = {};
      Object.keys(permissionUserEdit.col_level).forEach((columnName) => {
        permissionUserEdit.col_level[columnName].forEach((value) => {
          initialColumnLevelCheckedValues[`${columnName}-${value}`] = true;
        });
      });

      setCheckedValues({
        ...initialCheckedValues,
        ...initialColumnLevelCheckedValues,
      });
    }
  }, [permissionUserEdit]);

  const toggleOpen = () => setCentredModal(!centredModal);

  const saveChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const filteredRowLevel = Object.keys(checkedValues).filter(
        (key) =>
          (key.startsWith("add") || key.startsWith("delete")) &&
          checkedValues[key]
      );

      const filteredColLevel = Object.keys(checkedValues).reduce((acc, key) => {
        const [columnName, checkboxName] = key.split("-");
        if (
          (columnName !== "add" || columnName !== "delete") &&
          checkboxName &&
          checkedValues[key]
        ) {
          if (!acc[columnName]) {
            acc[columnName] = [];
          }
          acc[columnName].push(checkboxName);
        }
        return acc;
      }, {});

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify({
          id: permissionUserEdit.id,
          module: module,
          user_id: parseInt(userId),
          row_level: filteredRowLevel,
          col_level: filteredColLevel,
          name: permissionUserEdit.name,
        }),
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/mpermission/${permissionUserEdit.id}`,
        requestOptions
      );

      if (response.ok) {
        fetchMPermission()
        fetchOrdersData()
        Swal.fire({
          title: "Good job!",
          text: "Permission data Edit successfully!",
          icon: "success",
        });
      } else {
        console.error("Error updating permission data:", response.error);
      }
    } catch (error) {
      console.error("Error updating permission data:", error);
    }
  };
  return (
    <div className=" bg-light" style={{ height: "100%", overflow: "auto" }}>
      <table className="table table-bordered">
        <thead id="addProductTableHead">
          <tr>
            <th className="p-2 fw-bold">Module</th>
            <th className="p-2 fw-bold">User ID</th>
            <th className="p-2 fw-bold">Name</th>
            <th className="p-2 fw-bold">Row Level</th>
            <th className="p-2 fw-bold">Column Level</th>
            <th className="p-2 fw-bold">Edit</th>
          </tr>
        </thead>
        <tbody>
          {permissionUser?.map((permission) => (
            <tr key={permission.id}>
              <td>{permission.module}</td>
              <td>{permission.user_id}</td>
              <td>{permission.name}</td>
              <td>
                <div style={{ height: "120px", overflow: "auto" }}>
                  {permission.row_level.length > 0
                    ? permission.row_level
                        .join(", ")
                        .split(",")
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            {item}
                            <br />
                          </React.Fragment>
                        ))
                    : "None"}
                </div>
              </td>
              <td>
                <div
                  className="chat-box bg-light"
                  style={{ height: "120px", overflow: "auto" }}
                >
                  {Object?.keys(permission.col_level).map((key) => (
                    <div key={key}>
                      <strong>{`${key}:`}</strong>
                      {permission.col_level[key].join(", ")}
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <button
                  className="btn border border-primary d-flex my-1 mx-auto py-2"
                  onClick={() => {
                    sendPermissionEditId(permission);
                    toggleOpen();
                  }}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        {permissionUserEdit && (
          <MDBModal
            tabIndex="-1"
            open={centredModal}
            onClose={() => setCentredModal(false)}
          >
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>{`Module: ${module} | UserID: ${userId}`}</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => setCentredModal(false)}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody
                  style={{
                    maxHeight: "calc(100vh - 400px)",
                    overflowY: "auto",
                  }}
                  className="chat-box bg-light"
                >
                  <div>
                    <label htmlFor="module">Module:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="module"
                      name="module"
                      value={module}
                      onChange={(e) => setModule(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="user_id">User ID:</label>
                    <input
                      type="text"
                      id="user_id"
                      className="form-control"
                      name="user_id"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </div>

                  <div className="d-flex gap-2 my-2 align-items-center ">
                    <label>Row Level: </label>
                    <input
                      type="checkbox"
                      id="add"
                      name="add"
                      checked={checkedValues.add}
                      onChange={() => toggleCheckbox("add")}
                    />
                    <label htmlFor="add">Add</label>
                    <input
                      type="checkbox"
                      id="delete"
                      name="delete"
                      checked={checkedValues.delete}
                      onChange={() => toggleCheckbox("delete")}
                    />
                    <label htmlFor="delete">Delete</label>
                  </div>
                  <table className="table border">
                    <thead>
                      <tr>
                        <th className="fw-bold fs-6">Column Name</th>
                        <th className="fw-bold fs-6">Column Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersData?.map((columnName) => (
                        <tr key={columnName}>
                          <td>{columnName}</td>
                          <td>
                            <div className="d-flex gap-2 align-items-center ">
                              <input
                                type="checkbox"
                                name={`edit-${columnName}`}
                                checked={checkedValues[`${columnName}-edit`]}
                                onChange={() =>
                                  toggleColumnCheckbox(columnName, "edit")
                                }
                              />
                              <label htmlFor={`edit-${columnName}`}>Edit</label>

                              <input
                                type="checkbox"
                                name={`show-${columnName}`}
                                checked={checkedValues[`${columnName}-show`]}
                                onChange={() =>
                                  toggleColumnCheckbox(columnName, "show")
                                }
                              />
                              <label htmlFor={`show-${columnName}`}>Show</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    color="secondary"
                    onClick={() => setCentredModal(false)}
                  >
                    Close
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      saveChanges();
                      setCentredModal(false);
                    }}
                  >
                    Save changes
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        )}
      </div>
    </div>
  );
}
