import {
  MDBTabsItem,
  MDBTabsPane,
  MDBTabs,
  MDBTabsContent,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import AdminRequirement from "../../AdminRequirement/AdminRequirement";

export default function OrderDetials() {
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <>
      <AdminRequirement />
    </>
  );
}
