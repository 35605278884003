import React, { useState } from "react";
import UserNavbar from "../../UserSidebar/UserNavbar";
import UserSidebar from "../../UserSidebar/UserSidebar";
import UserStyleProduct from "../UserStyleProduct";

export default function UserStyle() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div id="admin-body">
      <UserNavbar />
      <div style={{ display: "flex" }}>
        <UserSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginTop: "30px",
            paddingLeft: "35px",
            marginLeft: isSidebarOpen ? "10%" : "3.5%",
          }}
        >
          <UserStyleProduct />
        </div>
      </div>
    </div>
  );
}
