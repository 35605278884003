import React, { useRef, useState } from 'react'
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CommentIcon from "@mui/icons-material/Comment";
import AdminSkuOrder from './AdminSkuOrder';
import { baseUrl } from '../../../utils/Url';


export default function AdminBulkTable({
    loading,
    fetchBulkOrder,
    setGetBulk,
    getBulk
}) {
    const [chatId, setChatId] = useState(null);
    const chatBoxRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [selectedSubOrder, setSelectedSubOrder] = useState(null);
    const [showSubOrder, setShowSubOrder] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showRef_byFilter, setShowRef_byFilter] = useState(false);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [selectedRef_byType, setSelectedRef_byType] = useState("all");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [bulkID, setBulkID] = useState("");
    const [orderNo, setOrderNoFilter] = useState("");
    const [showOrderNoFilter, setShowOrderNoFilter] = useState(false);
    const [showProjectNameFilter, setShowProjectNameFilter] = useState(false);
    const [projectNameFilter, setProjectNameFilter] = useState("");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showT_QtyFilter, setshowT_QtyFilter] = useState(false);
    const [filterT_Qty, setFilterT_Qty] = useState("");
    const [showT_SkuFilter, setShowT_SkuFilter] = useState(false)
    const [filterT_Sku, setFilterT_Sku] = useState("");
    const [showMfgFilter, setShowMfgFilter] = useState(false)
    const [filterMfg, setFilterMfg] = useState("");
    const [showFgFilter, setShowFgFilter] = useState(false)
    const [filterFg, setFilterFg] = useState("");
    const [showExportFilter, setShowExportFilter] = useState(false)
    const [filterExport, setFilterExport] = useState("");
    const [showPendingFilter, setShowPendingFilter] = useState(false)
    const [filterPending, setFilterPending] = useState("");
    const [showT_Dia_QtyFilter, setShowT_Dia_QtyFilter] = useState(false)
    const [filterT_Dia_Qty, setFilterT_Dia_Qty] = useState("");
    const [showPolish_AvailFilter, setShowPolish_AvailFilter] = useState(false)
    const [filterPolish_Avail, setFilterPolish_Avail] = useState("");
    const [showRough_ShortFilter, setShowRough_ShortFilter] = useState(false)
    const [filterRough_Short, setFilterRough_Short] = useState("");
    const [showDue_DateFilter, setShowDue_DateFilter] = useState(false);
    const [startDue_Date, setStartDue_Date] = useState(null);
    const [endDue_Date, setEndDue_Date] = useState(null);

    const handleCheckboxChange = (Bulkid) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(Bulkid)
                ? prevSelectedRows.filter(row => row !== Bulkid)
                : [...prevSelectedRows, Bulkid]
        );
    };

    const handleSubOrderClick = (subOrder) => {
        setBulkID(subOrder);
        setShowSubOrder(prev => prev === subOrder ? !prev : true);
        setSelectedSubOrder(prev => prev === subOrder ? null : subOrder);
        // setSelectedOrderId(subOrder);
    };

    const handleCheckboxChangeCheckBox = (type) => {
        const newCheckboxValues = {
            ...checkboxValues,
            [type]: !checkboxValues[type],
        };
        setCheckboxValues(newCheckboxValues);

        const selectedTypes = Object.keys(newCheckboxValues).filter(
            (key) => newCheckboxValues[key]
        );
        setSelectedRef_byType(selectedTypes.join(", "));
    };

    const ref_by = [...new Set(getBulk.map((item) => item.ref_by))];

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const clearRef_byTypeFilter = () => {
        setSelectedRef_byType("all");
        setShowRef_byFilter(false);
    };

    const handleRef_byTypeClose = () => {
        clearRef_byTypeFilter();
        setCheckboxValues({});
        setSelectedRef_byType("");
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);

        if (isNaN(date.getTime())) return null;
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleStartDateClose = () => {
        clearDateFilters();
    };

    const clearDateFilters = () => {
        setStartDate("");
        setEndDate("");
        setShowDateFilter(false);
    };

    const handleProjectNameFilterClose = () => {
        setProjectNameFilter("");
        setShowProjectNameFilter(false);
    };

    const handleProjectNameFilterChange = (event) => {
        const values = event.target.value;
        setProjectNameFilter(values);
    };

    const clearT_QtyFilter = () => {
        setFilterT_Qty("");
        setshowT_QtyFilter(false);
    };

    const handleT_QtyClose = () => {
        clearT_QtyFilter();
    };

    const handleT_QtyChange = (e) => {
        setFilterT_Qty(e.target.value);
    };

    const clearT_SkuFilter = () => {
        setFilterT_Sku("");
        setShowT_SkuFilter(false);
    };

    const clearFgFilter = () => {
        setFilterFg("");
        setShowFgFilter(false);
    };

    const handleT_SkuChange = (e) => {
        setFilterT_Sku(e.target.value);
    };

    const clearExportFilter = () => {
        setFilterExport("");
        setShowExportFilter(false);
    };

    const handleFgChange = (e) => {
        setFilterFg(e.target.value);
    };

    const handleExportClose = () => {
        clearExportFilter();
    };

    const handleExportChange = (e) => {
        setFilterExport(e.target.value);
    };

    const handleT_SkuClose = () => {
        clearT_SkuFilter();
    };

    const clearMfgFilter = () => {
        setFilterMfg("");
        setShowMfgFilter(false);
    };

    const handlePendingChange = (e) => {
        setFilterPending(e.target.value);
    };

    const handleT_Dia_QtyClose = () => {
        clearT_Dia_QtyFilter();
    };

    const clearPolish_AvailFilter = () => {
        setFilterPolish_Avail("");
        setShowPolish_AvailFilter(false);
    };

    const clearT_Dia_QtyFilter = () => {
        setFilterT_Dia_Qty("");
        setShowT_Dia_QtyFilter(false);
    };

    const handleFgClose = () => {
        clearFgFilter();
    };

    const handlePolish_AvailChange = (e) => {
        setFilterPolish_Avail(e.target.value);
    };

    const clearRough_ShortFilter = () => {
        setFilterRough_Short("");
        setShowRough_ShortFilter(false);
    };

    const clearDue_DateFilters = () => {
        setStartDue_Date("");
        setEndDue_Date("");
        setShowDue_DateFilter(false);
    };

    const handleStartDue_DateClose = () => {
        clearDue_DateFilters();
    };

    const handleStartDue_DateChange = (e) => {
        setStartDue_Date(e.target.value);
    };

    const handleEndDue_DateChange = (e) => {
        setEndDue_Date(e.target.value);
    };

    const handleRough_ShortClose = () => {
        clearRough_ShortFilter();
    };

    const handleRough_ShortChange = (e) => {
        setFilterRough_Short(e.target.value);
    };

    const handlePolish_AvailClose = () => {
        clearPolish_AvailFilter();
    };

    const handleT_Dia_QtyChange = (e) => {
        setFilterT_Dia_Qty(e.target.value);
    };

    const clearPendingFilter = () => {
        setFilterPending("");
        setShowPendingFilter(false);
    };

    const handlePendingClose = () => {
        clearPendingFilter();
    };

    const handleMfgClose = () => {
        clearMfgFilter();
    };

    const handleMfgChange = (e) => {
        setFilterMfg(e.target.value);
    };

    const handleBulkOrderDelete = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({ isdeleted: 1 }),
            };

            const response = await fetch(`${baseUrl}/api/blk-order/${id}`, requestOptions);

            if (response.ok) {
                console.log('Order deleted successfully');
                const data = getBulk.filter((v) => v.id !== id);
                console.log("data:", data);
                setGetBulk(data);
                fetchBulkOrder();
            } else {
                console.error('Failed to delete the order');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const clearOrderNoFilter = () => {
        setOrderNoFilter("");
        setShowOrderNoFilter(false);
    };

    const handleOrderNoClose = () => {
        clearOrderNoFilter();
    };

    const handleOrderNoFilterChange = (e) => {
        const value = e.target.value;
        setOrderNoFilter(value.startsWith("B") ? value.slice(1) : value);
    };

    const bulkOrderFilter = getBulk.filter((item) => {
        const toLowerCase = (str) =>
            typeof str === "string" ? str.toLowerCase() : str;

        if (orderNo && item.id != orderNo) {
            return false;
        }

        if (
            projectNameFilter &&
            !toLowerCase(item.project_name)?.includes(toLowerCase(projectNameFilter))
        ) {
            return false;
        }

        if (showRef_byFilter) {
            const selectedRefByTypes = Object.keys(checkboxValues).filter(
                (key) => checkboxValues[key]
            );

            if (
                selectedRefByTypes.length > 0 &&
                !selectedRefByTypes.includes("all") &&
                !(
                    selectedRefByTypes.includes("N/A") &&
                    (item.ref_by === null || item.ref_by.trim() === "")
                ) &&
                !selectedRefByTypes.includes(item.ref_by)
            ) {
                return false;
            }
        }

        if (filterT_Qty !== "" && parseFloat(item.total_qty) !== parseFloat(filterT_Qty)) {
            return false;
        }

        if (filterT_Sku !== "" && parseFloat(item.total_sku) !== parseFloat(filterT_Sku)) {
            return false;
        }

        if (filterMfg !== "" && parseFloat(item.mfg) !== parseFloat(filterMfg)) {
            return false;
        }

        if (filterFg !== "" && parseFloat(item.fg) !== parseFloat(filterFg)) {
            return false;
        }

        if (filterExport !== "" && parseFloat(item.export) !== parseFloat(filterExport)) {
            return false;
        }

        if (filterPending !== "" && parseFloat(item.pending) !== parseFloat(filterPending)) {
            return false;
        }

        if (filterT_Dia_Qty !== "" && parseFloat(item.total_dia_qty) !== parseFloat(filterT_Dia_Qty)) {
            return false;
        }

        if (filterPolish_Avail !== "" && parseFloat(item.polish_avail) !== parseFloat(filterPolish_Avail)) {
            return false;
        }

        if (filterRough_Short !== "" && parseFloat(item.rough_short) !== parseFloat(filterRough_Short)) {
            return false;
        }

        if (startDate && endDate) {
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const requestDate = new Date(item.created_date);

            if (!(requestDate >= startDateObj && requestDate <= endDateObj)) {
                return false;
            }
        } else if (!startDate && !endDate) {
            return true;
        } else if (!startDate && endDate) {
            const endDateObj = new Date(endDate);
            const requestDate = new Date(item.created_date);
            if (!(requestDate <= endDateObj)) {
                return false;
            }
        } else if (startDate && !endDate) {
            const startDateObj = new Date(startDate);
            const requestDate = new Date(item.created_date);
            if (!(requestDate >= startDateObj)) {
                return false;
            }
        }

        if (startDue_Date && endDue_Date) {
            const startDue_DateObj = new Date(startDue_Date);
            const endDue_DateObj = new Date(endDue_Date);
            const requestDue_Date = new Date(item.due_date);

            if (!(requestDue_Date >= startDue_DateObj && requestDue_Date <= endDue_DateObj)) {
                return false;
            }
        } else if (!startDue_Date && !endDue_Date) {
            return true;
        } else if (!startDue_Date && endDue_Date) {
            const endDue_DateObj = new Date(endDue_Date);
            const requestDue_Date = new Date(item.due_date);
            if (!(requestDue_Date <= endDue_DateObj)) {
                return false;
            }
        } else if (startDue_Date && !endDue_Date) {
            const startDue_DateObj = new Date(startDue_Date);
            const requestDue_Date = new Date(item.due_date);
            if (!(requestDue_Date >= startDue_DateObj)) {
                return false;
            }
        }
        return true;
    })

    return (
        <>
            <div
                className="d-flex  align-items-center gap-2 px-2 rounded-5 bg_report w-100"
                style={{ top: "162px", zIndex: 999, position: "sticky" }}
            >
                {showOrderNoFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column  w-100">
                            <label htmlFor="" className="fs-6  pb-1">
                                Order No
                                <CloseRoundedIcon
                                    className="ms-2"
                                    onClick={handleOrderNoClose}
                                />
                            </label>
                            <input
                                type="text"
                                value={`B${orderNo}`}
                                onChange={handleOrderNoFilterChange}
                                className="form-control  w-50"
                            />
                        </div>
                    </div>
                )}

                {showRef_byFilter && (
                    <div className="d-flex gap-3">
                        <div className="d-flex flex-column">
                            <label htmlFor="" className="text-center pb-1">
                                Ref_by
                            </label>
                            <div className="dropdown">
                                <button
                                    className="form-select w-100"
                                    type="button"
                                    onClick={handleDropdownToggle}
                                >
                                    {selectedRef_byType || "Select"}
                                </button>
                                {dropdownOpen && (
                                    <div className="dropdown-menu show scrollable-menu">
                                        <div className="dropdown-item">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="checkbox-N/A"
                                                    checked={checkboxValues["N/A"]}
                                                    onChange={() =>
                                                        handleCheckboxChangeCheckBox("N/A")
                                                    }
                                                />
                                                <label className="checkBox" htmlFor="checkbox-N/A">
                                                    N/A
                                                </label>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    // className="form-check-input"
                                                    id="checkbox-all"
                                                    checked={checkboxValues["all"]}
                                                    onChange={() =>
                                                        handleCheckboxChangeCheckBox("all")
                                                    }
                                                />
                                                <label
                                                    className="form-check-label checkBox"
                                                    htmlFor="checkbox-all"
                                                >
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        <div className="scrollable-content">
                                            {ref_by
                                                .filter(
                                                    (type) => type !== null && type.trim() !== ""
                                                )
                                                .map((type, index) => (
                                                    <div key={index} className="dropdown-item">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                id={`checkbox-${type.trim()}`}
                                                                checked={checkboxValues[type.trim()]}
                                                                onChange={() =>
                                                                    handleCheckboxChangeCheckBox(type.trim())
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label checkBox"
                                                                htmlFor={`checkbox-${type.trim()}`}
                                                            >
                                                                {type.trim()}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <CloseRoundedIcon onClick={handleRef_byTypeClose} />
                    </div>
                )}

                {showDateFilter && (
                    <div className=" d-flex gap-1">
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">Start Order_Date</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                className="form-control "
                            />
                        </div>
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">End Order_Date</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className="form-control"
                            />
                        </div>
                        <CloseRoundedIcon onClick={handleStartDateClose} />
                    </div>
                )}

                {showProjectNameFilter && (
                    <div className="d-flex gap-3">
                        <div className="d-flex flex-column  w-100">
                            <label htmlFor="" className="fs-6  pb-1">
                                Project Name
                                <CloseRoundedIcon
                                    className="ms-2"
                                    onClick={handleProjectNameFilterClose}
                                />
                            </label>
                            <input
                                type="text"
                                value={projectNameFilter}
                                onChange={handleProjectNameFilterChange}
                                className="form-control w-50"
                            />
                        </div>
                    </div>
                )}

                {showT_QtyFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Total Quantity
                                <CloseRoundedIcon onClick={handleT_QtyClose} />
                            </label>
                            <input
                                type="text"
                                value={filterT_Qty}
                                onChange={handleT_QtyChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showT_SkuFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Total SKU
                                <CloseRoundedIcon onClick={handleT_SkuClose} />
                            </label>
                            <input
                                type="text"
                                value={filterT_Sku}
                                onChange={handleT_SkuChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showMfgFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                MFG
                                <CloseRoundedIcon onClick={handleMfgClose} />
                            </label>
                            <input
                                type="text"
                                value={filterMfg}
                                onChange={handleMfgChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showFgFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                FG
                                <CloseRoundedIcon onClick={handleFgClose} />
                            </label>
                            <input
                                type="text"
                                value={filterFg}
                                onChange={handleFgChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showExportFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Export
                                <CloseRoundedIcon onClick={handleExportClose} />
                            </label>
                            <input
                                type="text"
                                value={filterExport}
                                onChange={handleExportChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showPendingFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Pending
                                <CloseRoundedIcon onClick={handlePendingClose} />
                            </label>
                            <input
                                type="text"
                                value={filterPending}
                                onChange={handlePendingChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showT_Dia_QtyFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                T_Dia_Qty
                                <CloseRoundedIcon onClick={handleT_Dia_QtyClose} />
                            </label>
                            <input
                                type="text"
                                value={filterT_Dia_Qty}
                                onChange={handleT_Dia_QtyChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showPolish_AvailFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Polish_Avail
                                <CloseRoundedIcon onClick={handlePolish_AvailClose} />
                            </label>
                            <input
                                type="text"
                                value={filterPolish_Avail}
                                onChange={handlePolish_AvailChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showRough_ShortFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Rough_Short
                                <CloseRoundedIcon onClick={handleRough_ShortClose} />
                            </label>
                            <input
                                type="text"
                                value={filterRough_Short}
                                onChange={handleRough_ShortChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showDue_DateFilter && (
                    <div className=" d-flex gap-1">
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">
                                Start Due_Date
                            </label>
                            <input
                                type="date"
                                value={startDue_Date}
                                onChange={handleStartDue_DateChange}
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">End Due_Date</label>
                            <input
                                type="date"
                                value={endDue_Date}
                                onChange={handleEndDue_DateChange}
                                className="form-control"
                            />
                        </div>
                        <CloseRoundedIcon onClick={handleStartDue_DateClose} />
                    </div>
                )}
            </div>

            <div>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        <table className="table table-bordered table-responsive w-100">
                            <thead id="addProductTableHead">
                                <tr>
                                    <th className="p-0 py-2 fw-bold">Select</th>
                                    <th className="p-0 py-2 fw-bold">
                                        Order_No
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowOrderNoFilter(!showOrderNoFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Ref_by
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowRef_byFilter(!showRef_byFilter)}
                                        />
                                    </th>

                                    <th className="p-0 py-2 fw-bold">
                                        Project_Name
                                        <ArrowDropDownRoundedIcon
                                            onClick={() =>
                                                setShowProjectNameFilter(!showProjectNameFilter)
                                            }
                                        />
                                    </th>

                                    <th className="p-0 py-2 fw-bold">
                                        Order Date
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowDateFilter(!showDateFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Total SKU
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowT_SkuFilter(!showT_SkuFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">
                                        T.Qty
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setshowT_QtyFilter(!showT_QtyFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">MFG
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowMfgFilter(!showMfgFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">FG
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowFgFilter(!showFgFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Export
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowExportFilter(!showExportFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Pending
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowPendingFilter(!showPendingFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Total Dia Qty
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowT_Dia_QtyFilter(!showT_Dia_QtyFilter)}
                                        />

                                    </th>
                                    <th className="p-0 py-2 fw-bold">Polish Avail
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowPolish_AvailFilter(!showPolish_AvailFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Rough Short
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowRough_ShortFilter(!showRough_ShortFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Due_Date
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowDue_DateFilter(!showDue_DateFilter)}
                                        />
                                    </th>
                                    {/* <th className="p-0 py-2 fw-bold">ChatBox</th> */}
                                    <th className="p-0 py-2 fw-bold">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bulkOrderFilter.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={`${selectedRows.includes(item.id) ? 'bg-selected' : 'bulkMain'}`}
                                    >
                                        <td id="showUserTablebody">
                                            <input
                                                type='checkbox'
                                                checked={selectedRows.includes(item.id)}
                                                onChange={() => handleCheckboxChange(item.id)}
                                            />
                                        </td>
                                        <td id="showUserTablebody">B{item.id || "N/A"}</td>
                                        <td id="showUserTablebody">{item.ref_by || "N/A"}</td>
                                        <td id="showUserTablebody">{item.project_name || "N/A"}</td>
                                        <td id="showUserTablebody">{formatDate(item.created_date)}</td>
                                        <td id="showUserTablebody">
                                            <button className='totalSku' id="showUserTablebody" onClick={() => handleSubOrderClick(item.id)}><span>{item.total_sku}</span></button>
                                        </td>
                                        <td id="showUserTablebody">
                                            {item.total_qty}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.mfg}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.fg}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.export}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.pending}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.total_dia_qty}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.polish_avail}
                                        </td>
                                        <td id="showUserTablebody">
                                            {item.rough_short}
                                        </td>
                                        <td id="showUserTablebody">{formatDate(item.due_date)}</td>
                                        {/* <td id="showUserTablebody">
                                            <MDBBtn
                                                className="userChat"
                                                id="chatBox"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#popup-${index}`}
                                                onClick={() => setChatId(item.id)}
                                            >
                                                <MDBIcon far icon="comments" />
                                            </MDBBtn>
                                            <div
                                                className="modal fade"
                                                id={`popup-${index}`}
                                                tabIndex="-1"
                                                aria-labelledby={`popupLabel-${index}`}
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <div className="text-start">
                                                                <span>Order No-B{item.id}</span>
                                                                <br />
                                                                <span className="text-danger">
                                                                    *Content can be deleted within 3 minutes
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                            ></button>
                                                        </div>
                                                        <div
                                                            className="modal-body"
                                                            ref={chatBoxRef}
                                                            onScroll={handleScroll}
                                                        >
                                                            <BulkChatBox  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td> */}

                                        <td id="showUserTablebody">
                                            <DeleteForeverIcon onClick={() => handleBulkOrderDelete(item.id)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {showSubOrder && selectedSubOrder && (
                            <AdminSkuOrder
                                bulkID={bulkID}
                                fetchBulkOrder={fetchBulkOrder}
                                showSubOrder={showSubOrder}
                                selectedSubOrder={selectedSubOrder}
                            />
                        )}
                    </>

                )}
            </div>
        </>
    )
}

