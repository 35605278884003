import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/Url";

export default function TotalUsers({ fetchRemittanceData, userCode }) {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [centredModal, setCentredModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [code, setCode] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [approved, setApproved] = useState("1");
  const [allow_Outlate, setAllow_Outlate] = useState(0);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [Client_name, setClient_name] = useState([]);
  const [filterName, setFilterName] = useState(""); // State variable for filtering by name
  const [subUserModalOpen, setSubUserModalOpen] = useState(false); // State variable for sub user modal

  const [subUserCode, setSubUserCode] = useState(""); // State for Sub User Name
  const [subUserName, setSubUserName] = useState(""); // State for Sub User Name
  const [subUserEmail, setSubUserEmail] = useState(""); // State for Sub User Email
  const [subUserPassword, setSubUserPassword] = useState(""); // State for Sub User Password
  const [outlet_name, setOutlet_Name] = useState(""); // State for Sub User Password

  const toggleResetModal = () => setResetModalOpen(!resetModalOpen);

  const toggleOpen = () => setCentredModal(!centredModal);

  const toggleSubUserModal = () => setSubUserModalOpen(!subUserModalOpen); // Toggle sub user modal

  const fetchUserRoles = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/roles`,
        requestOptions
      );
      if (response.ok) {
        const responseData = await response.json();
        setRoles(responseData);
      } else {
        console.error("Error fetching user roles:", response.error);
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const handleEdit = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/users/${userId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const result = await response.json();

      setSelectedUserId(userId);
      setName(result.name);
      setEmail(result.email);
      setRoleId(result.role_id);
      setCode(result.client_code);
      setClient_name(result.client_name);
      toggleOpen();
      fetchRemittanceData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify({
          name,
          email,
          role_id: roleId,
          client_code: code,
          newPassword: newPassword,
          approved: approved,
          client_name: Client_name,
          allow_outlet: allow_Outlate
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/users/${selectedUserId}`,
        requestOptions
      );
      const result = await response.json();
      Swal.fire({
        title: "Good job!",
        text: "You clicked the button!",
        icon: "success",
      });
      toggleOpen();
      fetchRemittanceData();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/users/${userId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      fetchRemittanceData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleSaveResetPassword = async () => {
    try {
      const token = localStorage.getItem("token");

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("password", newPassword);
      urlencoded.append("approved", "1");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${baseUrl}/api/users/${selectedUserId}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Password Successfully Changed",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: "my-swal",
            },
            didOpen: () => {
              const swalContainer = document.querySelector(".my-swal");
              swalContainer.style.zIndex = 9999; // Set the z-index to a high value
            },
          });
          toggleResetModal();
          fetchRemittanceData();
        });
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  const handleChange = (e) => {
    // Use setFormData instead of formData
    setRoleId(e.target.value);

    // If the changed field is 'role_id', fetch user roles
    if (e.target.name === "role_id") {
      fetchUserRoles();
    }
  };

  const handleResetPassword = (userId) => {
    setSelectedUserId(userId); // Set the selected user ID
    setNewPassword(""); // Reset the new password field
    toggleResetModal(); // Open the reset password modal
  };

  const handleSubUser = (client_code) => {
    setSubUserCode(client_code)
    toggleSubUserModal()
  }



  const handleSubUserSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formData = new FormData();
      formData.append("outlet_name", outlet_name);
      formData.append("client_name", subUserName); // Add Sub User Name
      formData.append("email", subUserEmail); // Add Sub User Email
      formData.append("password", subUserPassword); // Add Sub User Password
      formData.append("client_code", subUserCode); // Add Client Code

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      fetch(`${baseUrl}/api/outlet`, requestOptions)
        .then((response) => response.text())
        .then(() => {
          toggleSubUserModal();
          fetchRemittanceData();
        });
    } catch (error) {
      console.error("Error adding sub user:", error);
    }
  };

  const filteredUsers = userCode.filter((user) =>
    user.name.toLowerCase().includes(filterName.toLowerCase())
  );

  return (
    <div>
      <h3 className="text-center fw-semibold">User Table</h3>
      <div className="d-flex align-items-center gap-2">
        <label>Client Name:</label>
        <input
          type="text"
          id="inputPassword6"
          className="form-control w-25"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          placeholder="Client Name"
        />
      </div>

      <table className="table table-bordered my-4 ">
        <thead id="addProductTableHead">
          <tr>
            <th className="p-2 fw-bold">User Name</th>
            <th className="p-2 fw-bold">Email</th>
            <th className="p-2 fw-bold">Role ID</th>
            <th className="p-2 fw-bold">Outlet_ID</th>
            <th className="p-2 fw-bold">Edit</th>
            <th className="p-2 fw-bold">Delete</th>
            <th className="p-2 fw-bold">Reset_Password</th>
            {/* <th className="p-2 fw-bold">Sub_User</th> */}
          </tr>
        </thead>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        ) : (
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={index} className="table-hover">
                <td id="showUserTablebody">{user.name}</td>
                <td id="showUserTablebody">{user.email}</td>
                <td id="showUserTablebody">{user.role_id}</td>
                <td id="showUserTablebody">{user.client_code}</td>
                <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => handleEdit(user.id)}
                  >
                    Edit
                  </MDBBtn>
                </td>
                <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDelete(user.id);
                          Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                          });
                        }
                      });
                    }}
                  >
                    Delete
                  </MDBBtn>
                </td>
                <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => handleResetPassword(user.id)}
                  >
                    Reset
                  </MDBBtn>
                </td>
                {/* <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => handleSubUser(user.client_code)}
                  >
                    Sub_User
                  </MDBBtn>
                </td> */}
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <MDBModal tabIndex="-1" open={centredModal} setOpen={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Edit User</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nameInput"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">
                  Outlet Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nameInput"
                  placeholder="Enter name"
                  value={Client_name}
                  onChange={(e) => setClient_name(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailInput"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="roleInput" className="form-label">
                  Role Id
                </label>
                <select
                  id="role_id"
                  name="role_id"
                  value={roleId}
                  onChange={handleChange}
                  className="form-control"
                  onClick={fetchUserRoles}
                  required
                >
                  {roles.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="roleInput" className="form-label">
                  Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="roleInput"
                  placeholder="Enter code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              {/* <div className="mb-3">
                <label htmlFor="Allow_Outlate" className="form-label">
                  Allow_Outlate
                </label>
                <br></br>
                <select
                  id="allowOutlateInput"
                  className="form-control"
                  onChange={(e) => setAllow_Outlate(e.target.value)}
                >
                  <option>Select</option>
                  <option value="1">Yes</option>
                  <option value="0" >No</option>
                </select> */}
              {/* <input
                  type="text"
                  className="form-control"
                  id="Allow_Outlate"
                  placeholder="Enter Allow_Outlate"
                  value={allow_Outlate}
                  onChange={(e) => setAllow_Outlate(e.target.value)}
                /> */}
              {/* </div> */}
              <div className="mb-3">
                <label htmlFor="roleInput" className="form-label">
                  Approved
                </label>

                <select className="form-control" onChange={(e) => setApproved(e.target.value)}>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {/* <input
                  type="text"
                  className="form-control"
                  id="approvedInput"
                  placeholder="Enter approved"
                  value={approved}
                  onChange={(e) => setApproved(e.target.value)}
                /> */}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleSaveChanges}>Save changes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal tabIndex="-1" open={resetModalOpen} setOpen={setResetModalOpen}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Reset Password</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleResetModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-3">
                <label htmlFor="newPasswordInput" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="newPasswordInput"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleResetModal}>
                Cancel
              </MDBBtn>
              <MDBBtn onClick={handleSaveResetPassword}>Reset Password</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        tabIndex="-1"
        open={subUserModalOpen}
        setOpen={setSubUserModalOpen}
      >
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Sub User</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleSubUserModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-3">
                <label htmlFor="subUserName" className="form-label">
                  Sub User Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subUserName"
                  placeholder="Enter sub user name"
                  value={subUserName}
                  onChange={(e) => setSubUserName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="outlet_name" className="form-label">
                  outlet_name
                </label>
                <input
                  type="outlet_name"
                  className="form-control"
                  id="outlet_name"
                  placeholder="Enter sub user outlet_name"
                  value={outlet_name}
                  onChange={(e) => setOutlet_Name(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subUserEmail" className="form-label">
                  Sub User Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="subUserEmail"
                  placeholder="Enter sub user email"
                  value={subUserEmail}
                  onChange={(e) => setSubUserEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subUserPassword" className="form-label">
                  Sub User Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="subUserPassword"
                  placeholder="Enter sub user password"
                  value={subUserPassword}
                  onChange={(e) => setSubUserPassword(e.target.value)}
                />
              </div>

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSubUserModal}>
                Cancel
              </MDBBtn>
              <MDBBtn onClick={handleSubUserSubmit}>Save Sub User</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}
