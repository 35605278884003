import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCardTitle, MDBCarousel, MDBCarouselCaption, MDBCarouselItem, MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../../../utils/Url';

export default function AdminCadMasterRequirement({
    csvData,
    setCsvData,
}) {
    const [showVideo, setShowVideo] = useState({});
    const [searchCadNo, setSearchCadNo] = useState("");
    const [searchType, setSearchType] = useState("");
    const [searchSubType, setSearchSubType] = useState("");
    const [searchSetting, setSearchSetting] = useState("");
    const [searchOrientation, setSearchOrientation] = useState("");
    const [searchShape, setSearchShape] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchMinJewSize, setSearchMinJewSize] = useState("");
    const [searchMaxJewSize, setSearchMaxJewSize] = useState("");
    const [searchMinDiaSize, setSearchMinDiaSize] = useState("");
    const [searchMaxDiaSize, setSearchMaxDiaSize] = useState("");
    const [searchMinTotalCt, setSearchMinTotalCt] = useState("");
    const [searchMaxTotalCt, setSearchMaxTotalCt] = useState("");
    const [searchMinDiaQty, setSearchMinDiaQty] = useState("");
    const [searchMaxDiaQty, setSearchMaxDiaQty] = useState("");
    const [searchMinDiaLength, setSearchMinDiaLength] = useState("");
    const [searchMaxDiaLength, setSearchMaxDiaLength] = useState("");
    const [searchMinDiaWidth, setSearchMinDiaWidth] = useState("");
    const [searchMaxDiaWidth, setSearchMaxDiaWidth] = useState("");
    const [searchMinDiaHeight, setSearchMinDiaHeight] = useState("");
    const [searchMaxDiaHeight, setSearchMaxDiaHeight] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [media, setMedia] = useState([]);
    const [isRemark1ModalOpen, setIsRemark1ModalOpen] = useState(false);
    const [isRemark2ModalOpen, setIsRemark2ModalOpen] = useState(false);
    const [isEditingRemark1, setIsEditingRemark1] = useState(false);
    const [isEditingRemark2, setIsEditingRemark2] = useState(false);
    const [remark1Input, setRemark1Input] = useState('');
    const [remark2Input, setRemark2Input] = useState('');

    const [rows, setRows] = useState([
        {
            date: "",
            cad_no: "",
            type: "",
            sub_type: "",
            setting: "",
            orientation: "",
            shape: "",
            jew_size: "",
            dia_size: "",
            dia_qty: "",
            dia_length: "",
            dia_width: "",
            dia_heigth: "",
            total_ct: ""
        },
    ]);
    const [focusedInput, setFocusedInput] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);

    const handleImageClick = (id) => {
        setShowVideo((prevState) => ({ ...prevState, [id]: true }));
    };
    const cadNoOptions = csvData?.map(data => data.cad_no);
    const cadTypeSet = new Set(csvData?.map(data => data.type));
    const cadType = Array.from(cadTypeSet);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                const response = await fetch(
                    `${baseUrl}/api/cadmaster`,
                    requestOptions
                );

                if (response.ok) {
                    const responseData = await response.json();
                    setCsvData(responseData);
                } else {
                    console.error("Error uploading CSV data:", response.error);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [setCsvData]);

    const data = csvData?.length > 0 ? csvData[0] : {};


    const toggleRemark1Modal = () => {
        setIsRemark1ModalOpen(!isRemark1ModalOpen);
    };

    const toggleRemark2Modal = () => {
        setIsRemark2ModalOpen(!isRemark2ModalOpen);
    };

    const handleEditRemark1 = () => {
        setRemark1Input(editedData.remark_1 || '');
        setIsEditingRemark1(true);
    };

    const handleSaveRemark1 = () => {
        setEditedData({ ...editedData, remark_1: remark1Input });
        setIsEditingRemark1(false);
        setIsRemark1ModalOpen(false);
    };

    const handleEditRemark2 = () => {
        setRemark2Input(editedData.remark_2 || '');
        setIsEditingRemark2(true);
    };

    const handleSaveRemark2 = () => {
        setEditedData({ ...editedData, remark_2: remark2Input });
        setIsEditingRemark2(false);
        setIsRemark2ModalOpen(false);
    };

    const handleEditClick = (data) => {
        setEditedData(data);
        setIsEditing(true);
    };


    const handleSaveClick = async (CadId) => {

        // Find the original data based on CadId
        const originalData = csvData.find(item => item.id === CadId);

        // Create a payload object starting with the id
        const payload = { id: CadId };

        // Add only the changed fields to the payload
        Object.keys(editedData).forEach(key => {
            if (editedData[key] !== originalData[key]) {
                payload[key] = editedData[key];
            }
        });

        console.log("Payload:", payload); // To see the payload being sent

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: JSON.stringify(payload),
                redirect: "follow",
            };

            const response = await fetch(
                `${baseUrl}/api/cadmaster/${CadId}`,
                requestOptions
            );

            if (response.ok) {
                // Update CSV data with the edited data
                const updatedCsvData = csvData.map(item =>
                    item.id === CadId ? { ...item, ...payload } : item
                );
                setCsvData(updatedCsvData);
                setIsEditing(false);
            } else {
                const responseData = await response.json();
                console.error("Error saving data:", responseData);
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const SubTypeOptions = (type) => {
        return csvData
            ?.filter(data => data.type === type)
            ?.map(data => data.sub_type)
            ?.filter((value, index, self) => self.indexOf(value) === index);
    };

    const SettingOptions = (type, subType) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType)
                ?.map(data => data.setting)
        ));
    };

    const OrientationOptions = (type, subType, setting) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType && data.setting === setting)
                ?.map(data => data.orientation)
        ));
    };

    const ShapeOptions = (type, subType, setting, orientation) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType && data.setting === setting && data.orientation === orientation)
                ?.map(data => data.shape)
        ));
    };

    const JewSizeOptions = (type, subType, setting, orientation, shape) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType && data.setting === setting && data.orientation === orientation && data.shape === shape)
                ?.map(data => data.jew_size)
        ));
    };

    const DiaSizeOptions = (type, subType, setting, orientation, shape) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType && data.setting === setting && data.orientation === orientation && data.shape === shape)
                ?.map(data => data.dia_size)
        ));
    };

    const DiaQtyOptions = (type, subType, setting, orientation, shape) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType && data.setting === setting && data.orientation === orientation && data.shape === shape)
                ?.map(data => data.dia_qty)
        ));
    };

    const DiaLengthOptions = (type, subType) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType)
                ?.map(data => data.dia_length)
        ));
    };


    const DiaWidthOptions = (type, subType) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType)
                ?.map(data => data.dia_width)
        ));
    };

    const DiaHieghtOptions = (type, subType) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType)
                ?.map(data => data.dia_heigth)
        ));
    };


    const TotalCtOptions = (type, subType, setting, orientation, shape) => {
        return Array.from(new Set(
            csvData
                ?.filter(data => data.type === type && data.sub_type === subType && data.setting === setting && data.orientation === orientation && data.shape === shape)
                ?.map(data => data.total_ct)
        ));
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newRows = [...rows];
        newRows[index][name] = value;
        setRows(newRows);

        if (name === "cad_no") {
            setSearchCadNo(value);
        } else if (name === "type") {
            setSearchType(value);
        } else if (name === "sub_type") {
            setSearchSubType(value);
        } else if (name === "setting") {
            setSearchSetting(value);
        } else if (name === "orientation") {
            setSearchOrientation(value);
        } else if (name === "shape") {
            setSearchShape(value);
        } else if (name === "min_jew_size") {
            setSearchMinJewSize(value);
        } else if (name === "max_jew_size") {
            setSearchMaxJewSize(value);
        } else if (name === "min_dia_size") {
            setSearchMinDiaSize(value);
        } else if (name === "max_dia_size") {
            setSearchMaxDiaSize(value);
        } else if (name === "min_dia_qty") {
            setSearchMinDiaQty(value);
        } else if (name === "max_dia_qty") {
            setSearchMaxDiaQty(value);
        } else if (name === "min_dia_length") {
            setSearchMinDiaLength(value);
        } else if (name === "max_dia_length") {
            setSearchMaxDiaLength(value);
        } else if (name === "min_dia_width") {
            setSearchMinDiaWidth(value);
        } else if (name === "max_dia_width") {
            setSearchMaxDiaWidth(value);
        } else if (name === "min_dia_heigth") {
            setSearchMinDiaHeight(value);
        } else if (name === "max_dia_heigth") {
            setSearchMaxDiaHeight(value);
        } else if (name === "min_total_ct") {
            setSearchMinTotalCt(value);
        } else if (name === "max_total_ct") {
            setSearchMaxTotalCt(value);
        } else if (name === "start_date") {
            setStartDate(value);
        } else if (name === "end_date") {
            setEndDate(value);
        }
    };


    const handleFocus = (inputName, index) => {
        setFocusedInput(inputName);
        setCurrentIndex(index);
    };

    const handleBlur = () => {
        setFocusedInput(null);
        setCurrentIndex(null);
    };


    const handleClear = (event) => {
        event.preventDefault();

        setRows([{
            date: "",
            cad_no: "",
            type: "",
            sub_type: "",
            setting: "",
            orientation: "",
            shape: "",
            jew_size: "",
            dia_size: "",
            dia_qty: "",
            dia_length: "",
            dia_width: "",
            dia_heigth: "",
            total_ct: ""
        }]);

        setSearchCadNo("");
        setSearchType("");
        setSearchSubType("");
        setSearchSetting("");
        setSearchOrientation("");
        setSearchShape("");
        setSearchMinJewSize("");
        setSearchMaxJewSize("");
        setSearchMinDiaSize("");
        setSearchMaxDiaSize("");
        setSearchMinDiaQty("");
        setSearchMaxDiaQty("");
        setSearchMinDiaLength("");
        setSearchMaxDiaLength("");
        setSearchMinDiaWidth("");
        setSearchMaxDiaWidth("");
        setSearchMinDiaHeight("");
        setSearchMaxDiaHeight("");
        setSearchMinTotalCt("");
        setSearchMaxTotalCt("");
        setStartDate("");
        setEndDate("");
    }


    const isSearchEmpty = !startDate && !endDate && !searchCadNo && !searchType && !searchSubType && !searchSetting && !searchOrientation && !searchShape && !searchMinJewSize && !searchMaxJewSize && !searchMinDiaSize && !searchMaxDiaSize && !searchMinDiaQty && !searchMaxDiaQty && !searchMinDiaLength && !searchMaxDiaLength && !searchMinDiaWidth && !searchMaxDiaWidth && !searchMinDiaHeight && !searchMaxDiaHeight && !searchMinTotalCt && !searchMaxTotalCt;


    const filteredData = isSearchEmpty ? [] : csvData?.filter(data => {
        const dateMatch = (!startDate || new Date(data.date) >= new Date(startDate)) &&
            (!endDate || new Date(data.date) <= new Date(endDate));

        const cadNoMatch = (data.cad_no || "").toLowerCase().includes(searchCadNo.toLowerCase().trim());
        const typeMatch = (searchType.trim() === '' || (data.type || "").toLowerCase().trim() === searchType.toLowerCase().trim());
        const subTypeMatch = (data.sub_type || "").toLowerCase().includes(searchSubType.toLowerCase().trim());
        const settingMatch = (data.setting || "").toLowerCase().includes(searchSetting.toLowerCase().trim());
        const orientationMatch = (data.orientation || "").toLowerCase().includes(searchOrientation.toLowerCase().trim());
        const shapeMatch = (data.shape || "").toLowerCase().includes(searchShape.toLowerCase().trim());

        const jewSizeMatch = (!searchMinJewSize || (parseFloat(data.jew_size) >= parseFloat(searchMinJewSize))) &&
            (!searchMaxJewSize || (parseFloat(data.jew_size) <= parseFloat(searchMaxJewSize)));
        const diaSizeMatch = (!searchMinDiaSize || (parseFloat(data.dia_size) >= parseFloat(searchMinDiaSize))) &&
            (!searchMaxDiaSize || (parseFloat(data.dia_size) <= parseFloat(searchMaxDiaSize)));

        const diaQtyMatch = (!searchMinDiaQty || (parseFloat(data.dia_qty) >= parseFloat(searchMinDiaQty))) &&
            (!searchMaxDiaQty || (parseFloat(data.dia_qty) <= parseFloat(searchMaxDiaQty)));
        const diaLengthMatch = (!searchMinDiaLength || (parseFloat(data.dia_length) >= parseFloat(searchMinDiaLength))) &&
            (!searchMaxDiaLength || (parseFloat(data.dia_length) <= parseFloat(searchMaxDiaLength)));
        const diaWidthMatch = (!searchMinDiaWidth || (parseFloat(data.dia_width) >= parseFloat(searchMinDiaWidth))) &&
            (!searchMaxDiaWidth || (parseFloat(data.dia_width) <= parseFloat(searchMaxDiaWidth)));
        const diaHeightMatch = (!searchMinDiaHeight || (parseFloat(data.dia_heigth) >= parseFloat(searchMinDiaHeight))) &&
            (!searchMaxDiaHeight || (parseFloat(data.dia_heigth) <= parseFloat(searchMaxDiaHeight)));

        const totalCtMatch = (!searchMinTotalCt || (parseFloat(data.total_ct) >= parseFloat(searchMinTotalCt))) &&
            (!searchMaxTotalCt || (parseFloat(data.total_ct) <= parseFloat(searchMaxTotalCt)));

        return dateMatch && cadNoMatch && typeMatch && subTypeMatch && settingMatch && orientationMatch && shapeMatch && jewSizeMatch && diaSizeMatch && diaQtyMatch && diaLengthMatch && diaWidthMatch && diaHeightMatch && totalCtMatch;
    });

    return (
        <div>
            <h4 className='fw-bold'>Cad Master:</h4>
            <div className='fw-bold'>Total Cad:{csvData?.length}</div>
            <p className='fw-bold'>Filter Cad: {filteredData?.length}</p>

            <form>
                <div className='d-flex justify-content-end mb-2 clearT'>
                    <MDBBtn className='ClearBtn' onClick={handleClear}>
                        Clear
                    </MDBBtn>
                </div>
                <table className="table table-bordered">
                    <thead id="addProductTableHead">
                        <tr>
                            <th className="p-0 py-2 fw-bold">Date</th>
                            <th className="p-0 py-2 fw-bold">Cad No.</th>
                            <th className="p-0 py-2 fw-bold">Type</th>
                            <th className="p-0 py-2 fw-bold">Sub Type</th>
                            <th className="p-0 py-2 fw-bold">Setting</th>
                            <th className="p-0 py-2 fw-bold">Orientation</th>
                            <th className="p-0 py-2 fw-bold">Shape</th>
                            <th className="p-0 py-2 fw-bold">Jew Size</th>
                            <th className="p-0 py-2 fw-bold">Diam. Size</th>
                            <th className="p-0 py-2 fw-bold">Diam. Qty</th>
                            <th className="p-0 py-2 fw-bold">Dia.Length</th>
                            <th className="p-0 py-2 fw-bold">Dia.Width</th>
                            <th className="p-0 py-2 fw-bold">Dia.Heigth</th>
                            <th className="p-0 py-2 fw-bold">Total Ct.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div>
                                        <input
                                            type="date"
                                            name="start_date"
                                            value={startDate}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("start_date", index)}
                                            onBlur={handleBlur}
                                            className="form-control rounded-0 text-center"
                                        />
                                        <input
                                            type="date"
                                            name="end_date"
                                            value={endDate}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("end_date", index)}
                                            onBlur={handleBlur}
                                            className="form-control rounded-0 text-center"
                                        />
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center cadSize" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Cad No"
                                            className="w-100"
                                            type="text"
                                            name="cad_no"
                                            value={row.cad_no || ""}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("cad_no", index)}
                                            onBlur={handleBlur}
                                        />
                                        {focusedInput === "cad_no" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {csvData
                                                    ?.map(data => data.cad_no || "")
                                                    ?.filter(option => (option || "").toLowerCase().includes((row.cad_no || "").toLowerCase()))
                                                    ?.map((option, idx) => (
                                                        <div
                                                            key={idx}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'cad_no', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </td>


                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Type"
                                            className="w-100"
                                            type="text"
                                            name="type"
                                            value={row.type}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("type", index)}
                                            onBlur={handleBlur}
                                        />
                                        {focusedInput === "type" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {cadType
                                                    ?.filter(option => (option || "").toLowerCase().includes((row.type || "").toLowerCase()))
                                                    ?.map((option, idx) => (
                                                        <div
                                                            key={idx}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'type', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Sub Type"
                                            className="w-100"
                                            type="text"
                                            name="sub_type"
                                            value={row.sub_type}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("sub_type", index)}
                                            onBlur={handleBlur}
                                        />
                                        {focusedInput === "sub_type" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {SubTypeOptions(row.type)
                                                    ?.filter(option => (option || "").toLowerCase().includes((row.sub_type || "").toLowerCase()))
                                                    ?.map((option, idx) => (
                                                        <div
                                                            key={idx}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'sub_type', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Setting"
                                            className="w-100"
                                            type="text"
                                            name="setting"
                                            value={row.setting}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("setting", index)}
                                            onBlur={handleBlur}
                                        />
                                        {focusedInput === "setting" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {SettingOptions(row.type, row.sub_type)
                                                    ?.filter(option => (option || "").toLowerCase().includes((row.setting || "").toLowerCase()))
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'setting', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </td>


                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Orientation"
                                            className="w-100"
                                            type="text"
                                            name="orientation"
                                            value={row.orientation}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("orientation", index)}
                                            onBlur={handleBlur}
                                        />
                                        {focusedInput === "orientation" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {OrientationOptions(row.type, row.sub_type, row.setting)
                                                    ?.filter(option => (option || "").toLowerCase().includes((row.orientation || "").toLowerCase()))
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'orientation', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </td>


                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Shape"
                                            className="w-100"
                                            type="text"
                                            name="shape"
                                            value={row.shape}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("shape", index)}
                                            onBlur={handleBlur}
                                        />
                                        {focusedInput === "shape" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {ShapeOptions(row.type, row.sub_type, row.setting, row.orientation)
                                                    ?.filter(option => (option || "").toLowerCase().includes((row.shape || "").toLowerCase()))
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'shape', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}

                                            </div>
                                        )}
                                    </div>
                                </td>


                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <div>
                                            <input
                                                placeholder="Min"
                                                type="text"
                                                name="min_jew_size"
                                                value={searchMinJewSize}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onFocus={() => handleFocus("min_jew_size", index)}
                                                onBlur={handleBlur}
                                            />
                                            <input
                                                placeholder="Max"
                                                type="text"
                                                name="max_jew_size"
                                                value={searchMaxJewSize}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onFocus={() => handleFocus("max_jew_size", index)}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        {/* {focusedInput && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {JewSizeOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.jew_size.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: "jew_size", value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                            </div>
                                        )} */}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <div>
                                            <input
                                                placeholder="Min"
                                                type="text"
                                                name="min_dia_size"
                                                value={searchMinDiaSize}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onFocus={() => handleFocus("min_dia_size", index)}
                                                onBlur={handleBlur}
                                            />
                                            <input
                                                placeholder="Max"
                                                type="text"
                                                name="max_dia_size"
                                                value={searchMaxDiaSize}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onFocus={() => handleFocus("max_dia_size", index)}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        {/* {focusedInput === "dia_size" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {DiaSizeOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.dia_size.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'dia_size', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}


                                            </div>
                                        )} */}
                                    </div>
                                </td>


                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Min"
                                            className="w-100"
                                            type="text"
                                            name="min_dia_qty"
                                            value={searchMinDiaQty}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("min_dia_qty", index)}
                                            onBlur={handleBlur}
                                        />
                                        <input
                                            placeholder="Max"
                                            className="w-100"
                                            type="text"
                                            name="max_dia_qty"
                                            value={searchMaxDiaQty}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("max_dia_qty", index)}
                                            onBlur={handleBlur}
                                        />
                                        {/* {focusedInput === "dia_qty" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {DiaQtyOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.dia_qty.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'dia_qty', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}


                                            </div>
                                        )} */}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Min"
                                            className="w-100"
                                            type="text"
                                            name="min_dia_length"
                                            value={searchMinDiaLength}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("min_dia_length", index)}
                                            onBlur={handleBlur}
                                        />
                                        <input
                                            placeholder="Max"
                                            className="w-100"
                                            type="text"
                                            name="max_dia_length"
                                            value={searchMaxDiaLength}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("max_dia_length", index)}
                                            onBlur={handleBlur}
                                        />
                                        {/* {focusedInput === "dia_length" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {DiaLengthOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.dia_length.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'dia_length', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}


                                            </div>
                                        )} */}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Min"
                                            className="w-100"
                                            type="text"
                                            name="min_dia_width"
                                            value={searchMinDiaWidth}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("min_dia_width", index)}
                                            onBlur={handleBlur}
                                        />
                                        <input
                                            placeholder="Max"
                                            className="w-100"
                                            type="text"
                                            name="max_dia_width"
                                            value={searchMaxDiaWidth}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("max_dia_width", index)}
                                            onBlur={handleBlur}
                                        />
                                        {/* {focusedInput === "dia_width" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {DiaWidthOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.dia_width.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'dia_width', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}


                                            </div>
                                        )} */}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            placeholder="Min"
                                            className="w-100"
                                            type="text"
                                            name="min_dia_heigth"
                                            value={searchMinDiaHeight}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("min_dia_heigth", index)}
                                            onBlur={handleBlur}
                                        />
                                        <input
                                            placeholder="Max"
                                            className="w-100"
                                            type="text"
                                            name="max_dia_heigth"
                                            value={searchMaxDiaHeight}
                                            onChange={(event) => handleInputChange(index, event)}
                                            onFocus={() => handleFocus("max_dia_heigth", index)}
                                            onBlur={handleBlur}
                                        />
                                        {/* {focusedInput === "dia_heigth" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {DiaHieghtOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.dia_heigth.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'dia_heigth', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}


                                            </div>
                                        )} */}
                                    </div>
                                </td>

                                <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <div>
                                            <input
                                                placeholder="Min"
                                                type="text"
                                                name="min_total_ct"
                                                value={searchMinTotalCt}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onFocus={() => handleFocus("min_total_ct", index)}
                                                onBlur={handleBlur}
                                            />
                                            <input
                                                placeholder="Max"
                                                type="text"
                                                name="max_total_ct"
                                                value={searchMaxTotalCt}
                                                onChange={(event) => handleInputChange(index, event)}
                                                onFocus={() => handleFocus("max_total_ct", index)}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        {/* {focusedInput === "total_ct" && currentIndex === index && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    maxWidth: "200px",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "white",
                                                    zIndex: 1000,
                                                    padding: "10px",
                                                    fontWeight: "700",
                                                }}
                                                className="scrollable"
                                            >
                                                {TotalCtOptions(row.type, row.sub_type, row.setting, row.orientation, row.shape)
                                                    .filter((option) =>
                                                        option.toLowerCase().includes(row.total_ct.toLowerCase())
                                                    )
                                                    .map((option, i) => (
                                                        <div
                                                            key={i}
                                                            onMouseDown={() => handleInputChange(index, { target: { name: 'total_ct', value: option } })}
                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                            className="dropdown-option"
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}

                                            </div>
                                        )} */}
                                    </div>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </form>

            <br></br>


            <div className='d-flex flex-wrap'>
                {(startDate && endDate || searchCadNo || searchType || searchSubType || searchSetting || searchOrientation || searchShape || searchMinJewSize || searchMaxJewSize || searchMinDiaSize || searchMaxDiaSize || searchMinDiaQty || searchMaxDiaQty || searchMinDiaLength || searchMaxDiaLength || searchMinDiaWidth || searchMaxDiaWidth || searchMinDiaHeight || searchMaxDiaHeight || searchMinTotalCt || searchMaxTotalCt)
                    ? filteredData?.map((data, index) => (
                        <MDBCard key={data.id} className="cadmaster_card">
                            <span
                                className="position-absolute cursor-pointer edit_button"
                                onClick={() => handleEditClick(data)}
                            >
                                Edit
                            </span>
                            {isEditing && editedData.id === data.id && (
                                <span
                                    className="position-absolute cursor-pointer edit_button"
                                    onClick={() => handleSaveClick(data.id)}
                                >
                                    Save
                                </span>
                            )}

                            <div id={`carouselExampleControlsNoTouching${index}`} className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    {data.cad_img_url || data.vedio_url ? (
                                        <>
                                            {data.cad_img_url && (
                                                <div className="carousel-item active">
                                                    <a href={data.cad_img_url} target="_blank" rel="noopener noreferrer">
                                                        <img src={data.cad_img_url} className="d-block w-100" alt="Image" />
                                                    </a>
                                                </div>
                                            )}
                                            {data.vedio_url && (
                                                <div className={`carousel-item ${!data.cad_img_url ? 'active' : ''}`}>
                                                    {!showVideo[data.id] ? (
                                                        <img
                                                            src="./Play Image1.png"
                                                            className="d-block w-100"
                                                            alt="Play Video"
                                                            onClick={() => handleImageClick(data.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    ) : (
                                                        <video className="d-block w-100" controls>
                                                            <source src={data.vedio_url} type="video/mp4" />
                                                        </video>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="carousel-item active">
                                            <img src="./Cad Image Not Found_01.jpg" alt="No Images" className="d-block w-100" loading="lazy" />
                                        </div>
                                    )}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleControlsNoTouching${index}`} data-bs-slide="prev" style={{ height: "50%", alignItems: "end" }}>
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleControlsNoTouching${index}`} data-bs-slide="next" style={{ height: "50%", alignItems: "end" }}>
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>

                            <MDBCardBody>
                                <MDBCardText className='row d-flex'>
                                    {isEditing && editedData.id === data.id ? (
                                        <>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Cad No:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.cad_no}
                                                    placeholder='Cad No'
                                                    onChange={(e) => setEditedData({ ...editedData, cad_no: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Type:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.type}
                                                    placeholder='Type'
                                                    onChange={(e) => setEditedData({ ...editedData, type: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Sub Type:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.sub_type}
                                                    placeholder='Sub Type'
                                                    onChange={(e) => setEditedData({ ...editedData, sub_type: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Setting:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.setting}
                                                    placeholder='Setting'
                                                    onChange={(e) => setEditedData({ ...editedData, setting: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Orientation:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.orientation}
                                                    placeholder='Orientation'
                                                    onChange={(e) => setEditedData({ ...editedData, orientation: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Jew Size:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.jew_size}
                                                    placeholder='Jew Size'
                                                    onChange={(e) => setEditedData({ ...editedData, jew_size: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Shape:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.shape}
                                                    placeholder='Shape'
                                                    onChange={(e) => setEditedData({ ...editedData, shape: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Size:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.dia_size}
                                                    placeholder='Dia Size'
                                                    onChange={(e) => setEditedData({ ...editedData, dia_size: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Qty:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.dia_qty}
                                                    placeholder='Dia Qty'
                                                    onChange={(e) => setEditedData({ ...editedData, dia_qty: e.target.value })}
                                                />
                                            </MDBCol>


                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Length:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.dia_length}
                                                    placeholder='Dia Length'
                                                    onChange={(e) => setEditedData({ ...editedData, dia_length: e.target.value })}
                                                />
                                            </MDBCol>

                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Width:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.dia_width}
                                                    placeholder='Dia Width'
                                                    onChange={(e) => setEditedData({ ...editedData, dia_width: e.target.value })}
                                                />
                                            </MDBCol>

                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Height:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.dia_heigth}
                                                    placeholder='Dia Height'
                                                    onChange={(e) => setEditedData({ ...editedData, dia_heigth: e.target.value })}
                                                />
                                            </MDBCol>

                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Total Ct.:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.total_ct}
                                                    placeholder='Total Ct'
                                                    onChange={(e) => setEditedData({ ...editedData, total_ct: e.target.value })}
                                                />
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Remark 1:</label>
                                                <span className='fw-bold'>{data.remark_1}</span>
                                                <i
                                                    className="far fa-comment-dots fs-5"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={toggleRemark1Modal}
                                                ></i>
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Remark 2:</label>
                                                <span className='fw-bold'>{data.remark_2}</span>
                                                <i
                                                    className="far fa-comment-dots fs-5"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={toggleRemark2Modal}
                                                ></i>
                                            </MDBCol>

                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='fw-bold' style={{ fontSize: "13px" }}>Image URL:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.cad_img_url}
                                                    placeholder='Image URL'
                                                    onChange={(e) => setEditedData({ ...editedData, cad_img_url: e.target.value })}
                                                />
                                            </MDBCol>

                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='fw-bold' style={{ fontSize: "13px" }}>Video URL:</label>
                                                <input
                                                    type="text"
                                                    value={editedData.vedio_url}
                                                    placeholder='Video URL'
                                                    onChange={(e) => setEditedData({ ...editedData, vedio_url: e.target.value })}
                                                />
                                            </MDBCol>

                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='fw-bold' style={{ fontSize: "13px" }}>Cad URL:</label>
                                                <input
                                                    type="text"
                                                    placeholder='Cad URL'
                                                    value={editedData.cadURL}
                                                    onChange={(e) => setEditedData({ ...editedData, cadURL: e.target.value })}
                                                />
                                            </MDBCol>

                                        </>
                                    ) : (
                                        <>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Cad No:</label>
                                                <span className='fw-bold'>{data.cad_no}</span>
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Type:</label>
                                                <span className='fw-bold'>{data.type}</span>
                                            </MDBCol>
                                            <MDBCol md="4" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Sub Type:</label>
                                                <span className='fw-bold'>{data.sub_type}</span>
                                            </MDBCol>
                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Setting:</label>
                                                <span className='fw-bold'>{data.setting}</span>
                                            </MDBCol>
                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Orientation:</label>
                                                <span className='fw-bold'>{data.orientation}</span>
                                            </MDBCol>
                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Jew Size:</label>
                                                <span className='fw-bold'>{data.jew_size}</span>
                                            </MDBCol>

                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia.Measurement:</label>
                                                <span className='fw-bold'>{data.dia_length}X{data.dia_width}X{data.dia_heigth}</span>
                                            </MDBCol>

                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Shape:</label>
                                                <span className='fw-bold'>{data.shape}</span>
                                            </MDBCol>
                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Size:</label>
                                                <span className='fw-bold'>{data.dia_size}</span>
                                            </MDBCol>
                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Dia. Qty:</label>
                                                <span className='fw-bold'>{data.dia_qty}</span>
                                            </MDBCol>
                                            <MDBCol md="3" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Total Ct.:</label>
                                                <span className='fw-bold'>{data.total_ct}</span>
                                            </MDBCol>
                                            <MDBCol md="12" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Remark 1:</label>
                                                <span className='fw-bold'>{data.remark_1}</span>
                                                <i
                                                    className="far fa-comment-dots fs-5"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={toggleRemark1Modal}
                                                ></i>
                                            </MDBCol>
                                            <MDBCol md="12" className='border-bottom d-flex flex-column py-1'>
                                                <label className='cadLabel'>Remark 2:</label>
                                                <span className='fw-bold'>{data.remark_2}</span>
                                                <i
                                                    className="far fa-comment-dots fs-5"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={toggleRemark2Modal}
                                                ></i>
                                            </MDBCol>

                                            <div className='d-flex justify-content-end'>
                                                <div className='border-bottom d-flex flex-column py-1'>
                                                    <MDBBtn className='down_btn' target="cad_img_url" href={data.cad_img_url}>
                                                        Download Card Image
                                                    </MDBBtn>
                                                </div>
                                                <div className='border-bottom d-flex flex-column py-1'>
                                                    <MDBBtn className='down_btn'>
                                                        Download Card 3dm
                                                    </MDBBtn>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </MDBCardText>
                            </MDBCardBody>

                            {/* Remark 1 Modal */}
                            <MDBModal open={isRemark1ModalOpen} setShow={toggleRemark1Modal} tabIndex='-1'>
                                <MDBModalDialog centered>
                                    <MDBModalContent>
                                        <MDBModalHeader>
                                            <MDBModalTitle>Remark 1</MDBModalTitle>
                                            <MDBBtn className='btn-close' color='none' onClick={toggleRemark1Modal}></MDBBtn>
                                        </MDBModalHeader>
                                        <MDBModalBody>
                                            {isEditingRemark1 ? (
                                                <input
                                                    type="text"
                                                    value={remark1Input}
                                                    onChange={(e) => setRemark1Input(e.target.value)}
                                                    placeholder='Edit Remark 1'
                                                />
                                            ) : (
                                                <span>{data.remark_1}</span>
                                            )}
                                        </MDBModalBody>
                                        <MDBModalFooter>
                                            <MDBBtn color='secondary' onClick={toggleRemark1Modal}>Close</MDBBtn>
                                            {!isEditingRemark1 ? (
                                                <MDBBtn color='primary' onClick={handleEditRemark1}>Edit</MDBBtn>
                                            ) : (
                                                <MDBBtn color='primary' onClick={handleSaveRemark1}>Save</MDBBtn>
                                            )}
                                        </MDBModalFooter>
                                    </MDBModalContent>
                                </MDBModalDialog>
                            </MDBModal>

                            {/* Remark 2 Modal */}
                            <MDBModal open={isRemark2ModalOpen} setShow={toggleRemark2Modal} tabIndex='-1'>
                                <MDBModalDialog centered>
                                    <MDBModalContent>
                                        <MDBModalHeader>
                                            <MDBModalTitle>Remark 2</MDBModalTitle>
                                            <MDBBtn className='btn-close' color='none' onClick={toggleRemark2Modal}></MDBBtn>
                                        </MDBModalHeader>
                                        <MDBModalBody>
                                            {isEditingRemark2 ? (
                                                <input
                                                    type="text"
                                                    value={remark2Input}
                                                    onChange={(e) => setRemark2Input(e.target.value)}
                                                    placeholder='Edit Remark 2'
                                                />
                                            ) : (
                                                <span>{data.remark_2}</span>
                                            )}
                                        </MDBModalBody>
                                        <MDBModalFooter>
                                            <MDBBtn color='secondary' onClick={toggleRemark2Modal}>Close</MDBBtn>
                                            {!isEditingRemark2 ? (
                                                <MDBBtn color='primary' onClick={handleEditRemark2}>Edit</MDBBtn>
                                            ) : (
                                                <MDBBtn color='primary' onClick={handleSaveRemark2}>Save</MDBBtn>
                                            )}
                                        </MDBModalFooter>
                                    </MDBModalContent>
                                </MDBModalDialog>
                            </MDBModal>

                        </MDBCard>
                    ))
                    : null
                }
            </div>
        </div>
    );
}