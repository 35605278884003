import React, { useState } from 'react'
import Navbar from '../SideBar/Navbar'
import Sidebar from '../SideBar/Sidebar'
import ReportDeatials from './Components/ReportDeatials'

export default function Report() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div id='admin-body'>
        <Navbar />
        <div style={{ display: 'flex' }}>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <div style={{ flex: 1, padding: '20px', marginTop: '30px', marginLeft: isSidebarOpen ? '10%' : '4.5%' }}>
            <p className="fs-1">Report</p>
            <p></p>
            <div>
              <ReportDeatials/>
            </div>
            </div>
        </div>
     </div>
  )
}
