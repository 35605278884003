import { MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import Requirement from "../components/Requirment";
import ShowUser from "../components/ShowUser";
import JewelryProduction from "../components/JewelryProduction";
import UploadPopup from "../CsvUploader/UploadPopup";

export default function AddProduct() {
  return (
    <MDBRow>
      <MDBCol md="12"  id="userrequimentForm">
        {/* <div>
          <UploadPopup />
        </div> */}
        <div>
          <Requirement />
        </div>
      </MDBCol>
      <MDBCol md="12" id="usertableData">
        <div>
          <ShowUser />
        </div>
      </MDBCol>
    </MDBRow>
  );
}
