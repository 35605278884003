import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCarousel, MDBCarouselItem, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CommentIcon from "@mui/icons-material/Comment";
import BulkChatBox from './BulkChatBox';
import Swal from 'sweetalert2';
import SaveIcon from "@mui/icons-material/Save";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { redirect } from 'react-router-dom';
import { Loader } from '../../SVG/Svg';
import axios from 'axios';
import { baseUrl } from '../../../utils/Url';

export default function BulkOrderTable({
    loading,
    setLoading,
    fetchBulkOrder,
    setGetBulk,
    getBulk
}) {
    const [loading1, setLoading1] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsTotalDiaQty, setRowsTotalDiaQty] = useState([]);
    const [rowsMFG, setRowsMFG] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPolishOpen, setModalPolishOpen] = useState(false);
    const [modalRoughOpen, setModalRoughOpen] = useState(false);
    const [modalMFGOpen, setModalMFGOpen] = useState(false);
    const [rowsFG, setRowsFG] = useState([]);
    const [modalFGOpen, setModalFGOpen] = useState(false);
    const [rowsExport, setRowsExport] = useState([]);
    const [rowsExportQty, setRowsExportQty] = useState([]);
    const [modalExportOpen, setModalExportOpen] = useState(false);
    const [modalExportQtyOpen, setModalExportQtyOpen] = useState(false);

    const [rowsPending, setRowsPending] = useState([]);
    const [modalPendingOpen, setModalPendingOpen] = useState(false);

    const [chatId, setChatId] = useState(null);
    const chatBoxRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [selectedRemittance, setSelectedRemittance] = useState(null);
    const [selectedSubOrder, setSelectedSubOrder] = useState(null);
    const [showSubOrder, setShowSubOrder] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsSub, setSelectedRowsSub] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commentIndex, setCommentIndex] = useState(null);
    const [commentTotalQtyIndex, setCommentTotalQtyIndex] = useState(null);
    const [mfg_statusIndex, setMfg_StatusIndex] = useState(null);
    const [bulkSubOrder, setBulkSubOrder] = useState([]);
    const [editedRowIndex, setEditedRowIndex] = useState(null);
    const [showRef_byFilter, setShowRef_byFilter] = useState(false);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [selectedRef_byType, setSelectedRef_byType] = useState("all");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [bulkID, setBulkID] = useState("");
    const [orderNo, setOrderNoFilter] = useState("");
    const [showOrderNoFilter, setShowOrderNoFilter] = useState(false);
    const [showProjectNameFilter, setShowProjectNameFilter] = useState(false);
    const [projectNameFilter, setProjectNameFilter] = useState("");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showT_QtyFilter, setshowT_QtyFilter] = useState(false);
    const [filterT_Qty, setFilterT_Qty] = useState("");
    const [showT_SkuFilter, setShowT_SkuFilter] = useState(false)
    const [filterT_Sku, setFilterT_Sku] = useState("");
    const [showMfgFilter, setShowMfgFilter] = useState(false)
    const [filterMfg, setFilterMfg] = useState("");
    const [showFgFilter, setShowFgFilter] = useState(false)
    const [filterFg, setFilterFg] = useState("");
    const [showExportFilter, setShowExportFilter] = useState(false)
    const [filterExport, setFilterExport] = useState("");
    const [showPendingFilter, setShowPendingFilter] = useState(false)
    const [filterPending, setFilterPending] = useState("");
    const [showT_Dia_QtyFilter, setShowT_Dia_QtyFilter] = useState(false)
    const [filterT_Dia_Qty, setFilterT_Dia_Qty] = useState("");
    const [showPolish_AvailFilter, setShowPolish_AvailFilter] = useState(false)
    const [filterPolish_Avail, setFilterPolish_Avail] = useState("");
    const [showRough_ShortFilter, setShowRough_ShortFilter] = useState(false)
    const [filterRough_Short, setFilterRough_Short] = useState("");
    const [showDue_DateFilter, setShowDue_DateFilter] = useState(false);
    const [startDue_Date, setStartDue_Date] = useState(null);
    const [endDue_Date, setEndDue_Date] = useState(null);
    const [userScrolledUp, setUserScrolledUp] = useState(false);
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedValues, setEditedValues] = useState({});

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [openDropdownMFGIndex, setOpenDropdownMFGIndex] = useState(null);
    const [openDropdownLabNameIndex, setOpenDropdownLabNameIndex] = useState(null);
    const dropdownRef = useRef(null);
    const [selectedRequestStatus, setSelectedRequestStatus] = useState("");
    const [centredModal, setCentredModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState("");
    const [order_Dia_Plan, setOrder_Dia_Plan] = useState([]);
    const [Order_ID, setOrder_ID] = useState("");
    const [export_ID, setExport_ID] = useState("")
    const [exportQty_Value, setExportQty_Value] = useState("");
    const [chatData, setChatData] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);

    const [editCell, setEditCell] = useState({ row: null, column: null });
    const inputRef = useRef(null);


    const handleExportQtyClick = (ex_qtyID, export_qty) => {
        console.log("export_qty:", export_qty);
        setExport_ID(ex_qtyID);
        setExportQty_Value(export_qty);
        setModalExportQtyOpen(true);

        const initialRows = Array.from({ length: export_qty }, () => ({ lotname: "" }));
        setRowsTotalDiaQty(initialRows);
        fetchTotal_Dia_Qty(1, "export_qty");
    };

    // { order_Dia_Plan.map((v) => v.lotname.map((v1) => console.log("LotName:", v1)
    //     ))
    // }

    const handleExportQtyClose = () => {
        setModalExportQtyOpen(false);
        setEditCell({ row: null, column: null });
    }

    const clearDateFilters = () => {
        setStartDate("");
        setEndDate("");
        setShowDateFilter(false);
    };

    const handleStartDateClose = () => {
        clearDateFilters();
    };


    const handleProjectNameFilterClose = () => {
        setProjectNameFilter("");
        setShowProjectNameFilter(false);
    };

    const handleProjectNameFilterChange = (event) => {
        const values = event.target.value;
        setProjectNameFilter(values);
    };

    const handleCheckboxChange = (Bulkid) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(Bulkid)
                ? prevSelectedRows.filter(row => row !== Bulkid)
                : [...prevSelectedRows, Bulkid]
        );
    };

    const handleCheckboxSubChange = (sub) => {
        setSelectedRowsSub((prevSelectedRowsSub) =>
            prevSelectedRowsSub.includes(sub)
                ? prevSelectedRowsSub.filter(row => row !== sub)
                : [...prevSelectedRowsSub, sub]
        );
    };

    useEffect(() => {
        addRowTotalDiaQty();
        addRowMFG();
        addRowFG();
        addRowExport();
        addRowPending();
    }, []);


    const addRowMFG = () => {
        setRowsMFG([
            ...rowsMFG,
            {
                pocessing_mfg: "",
                mfg_due_date: "",
            },
        ]);
    }

    const addRowFG = () => {
        setRowsFG([
            ...rowsFG,
            {
                newFG: "",
            },
        ]);
    }

    const addRowExport = () => {
        setRowsExport([
            ...rowsExport,
            {
                newExport: "",
            },
        ]);
    }

    const addRowPending = () => {
        setRowsPending([
            ...rowsPending,
            {
                newPending: "",
            },
        ]);
    }

    const addRowTotalDiaQty = () => {
        setRowsTotalDiaQty([
            ...rowsTotalDiaQty,
            {
                shape: "",
                dia_weight: "",
                dia_size: "",
                dia_qty: "",
                lab_name: "",
                comment: "",
                new_mfg: "",
                mfg_status_jewelery: "",
                in_stock: "",
                rough_short: "",
                rs_mfg: "",
                rs_purchase: "",
                rough_short_date: "",
                pocessing_mfg: "",
                mfg_due_date: "",
                finish_goods_qty: "",
                export_no: "",
                export_qty: "",
                lotname: ""
            },
        ]);
    };

    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        setRows(updatedRows);
    };


    const handleTotalDiaQtyChange = (index, field, value) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows[index][field] = value;
        setRowsTotalDiaQty(updatedRows);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedValues(prevValues => ({ ...prevValues, [name]: value }));
    };


    const handleBulkOrderDelete = async (id) => {

        try {

            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({ isdeleted: 1 }),
            };

            const response = await fetch(`${baseUrl}/api/blk-order/${id}`, requestOptions);

            if (response.ok) {
                console.log('Order deleted successfully');
                const data = getBulk.filter((v) => v.id !== id);
                setGetBulk(data);
                fetchBulkOrder();
            } else {
                console.error('Failed to delete the order');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchSkuData();
    }, [bulkID]);

    const fetchSkuData = async () => {
        try {
            const token = localStorage.getItem("token")
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${bulkID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                setBulkSubOrder(responseData);
            } else {
                console.log("Error Fetching BulkSubOrder Data", response.error);
            }

        } catch (error) {
            console.log("Error Fetching BulkSubOrder Data", error);
        }
    }

    useEffect(() => {
        addRow();
    }, []);

    const addRow = () => {
        setRows([
            ...rows,
            {
                sub_order: '',
                order_date: '',
                jewelryType: '',
                jewelrySubType: '',
                Setting: '',
                ref_lot: '',
                shapeType: '',
                metal: '',
                jew_size: '',
                dia_size: '',
                dia_weight: '',
                comment: '',
                t_qty: '',
                MFG: '',
                FG: '',
                Export: '',
                Pending: '',
                Total_DiaQty: '',
                Polish_Avail: '',
                Rough_Short: '',
                R_Date: '',
                EstDate: '',
                Due_Date: ''
            },
        ]);
    };

    const clearOrderNoFilter = () => {
        setOrderNoFilter("");
        setShowOrderNoFilter(false);
    };

    const handleOrderNoClose = () => {
        clearOrderNoFilter();
    };

    const handleOrderNoFilterChange = (e) => {
        const value = e.target.value;
        setOrderNoFilter(value.startsWith("B") ? value.slice(1) : value);
    };

    const bulkOrderFilter = getBulk.filter((item) => {

        const toLowerCase = (str) =>
            typeof str === "string" ? str.toLowerCase() : str;

        if (orderNo && item.id != orderNo) {
            return false;
        }

        if (
            projectNameFilter &&
            !toLowerCase(item.project_name)?.includes(toLowerCase(projectNameFilter))
        ) {
            return false;
        }


        if (showRef_byFilter) {
            const selectedRefByTypes = Object.keys(checkboxValues).filter(
                (key) => checkboxValues[key]
            );

            if (
                selectedRefByTypes.length > 0 &&
                !selectedRefByTypes.includes("all") &&
                !(
                    selectedRefByTypes.includes("N/A") &&
                    (item.ref_by === null || item.ref_by.trim() === "")
                ) &&
                !selectedRefByTypes.includes(item.ref_by)
            ) {
                return false;
            }
        }


        if (filterT_Qty !== "" && parseFloat(item.total_qty) !== parseFloat(filterT_Qty)) {
            return false;
        }

        if (filterT_Sku !== "" && parseFloat(item.total_sku) !== parseFloat(filterT_Sku)) {
            return false;
        }


        if (filterMfg !== "" && parseFloat(item.mfg) !== parseFloat(filterMfg)) {
            return false;
        }


        if (filterFg !== "" && parseFloat(item.fg) !== parseFloat(filterFg)) {
            return false;
        }

        if (filterExport !== "" && parseFloat(item.export) !== parseFloat(filterExport)) {
            return false;
        }

        if (filterPending !== "" && parseFloat(item.pending) !== parseFloat(filterPending)) {
            return false;
        }

        if (filterT_Dia_Qty !== "" && parseFloat(item.total_dia_qty) !== parseFloat(filterT_Dia_Qty)) {
            return false;
        }

        if (filterPolish_Avail !== "" && parseFloat(item.polish_avail) !== parseFloat(filterPolish_Avail)) {
            return false;
        }

        if (filterRough_Short !== "" && parseFloat(item.rough_short) !== parseFloat(filterRough_Short)) {
            return false;
        }



        if (startDate && endDate) {
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const requestDate = new Date(item.created_date);

            if (!(requestDate >= startDateObj && requestDate <= endDateObj)) {
                return false;
            }
        } else if (!startDate && !endDate) {
            return true;
        } else if (!startDate && endDate) {
            const endDateObj = new Date(endDate);
            const requestDate = new Date(item.created_date);
            if (!(requestDate <= endDateObj)) {
                return false;
            }
        } else if (startDate && !endDate) {
            const startDateObj = new Date(startDate);
            const requestDate = new Date(item.created_date);
            if (!(requestDate >= startDateObj)) {
                return false;
            }
        }


        if (startDue_Date && endDue_Date) {
            const startDue_DateObj = new Date(startDue_Date);
            const endDue_DateObj = new Date(endDue_Date);
            const requestDue_Date = new Date(item.due_date);

            if (!(requestDue_Date >= startDue_DateObj && requestDue_Date <= endDue_DateObj)) {
                return false;
            }
        } else if (!startDue_Date && !endDue_Date) {
            return true;
        } else if (!startDue_Date && endDue_Date) {
            const endDue_DateObj = new Date(endDue_Date);
            const requestDue_Date = new Date(item.due_date);
            if (!(requestDue_Date <= endDue_DateObj)) {
                return false;
            }
        } else if (startDue_Date && !endDue_Date) {
            const startDue_DateObj = new Date(startDue_Date);
            const requestDue_Date = new Date(item.due_date);
            if (!(requestDue_Date >= startDue_DateObj)) {
                return false;
            }
        }

        return true;
    })


    const handleSubOrderClick = (subOrder) => {
        setBulkID(subOrder);
        setShowSubOrder(prev => prev === subOrder ? !prev : true);
        setSelectedSubOrder(prev => prev === subOrder ? null : subOrder);
    };

    const handleSubOrderSubmit = async (event) => {
        event.preventDefault();

        if (!selectedSubOrder) {
            Swal.fire({
                icon: "warning",
                title: "No Sub-Order Selected",
                text: "Please select a sub-order before submitting.",
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formDataSubOrder = new FormData();

            rows.forEach((row, index) => {
                formDataSubOrder.append(`rows[${index}][jewelryType]`, row.jewelryType);
                formDataSubOrder.append(`rows[${index}][jewelrySubType]`, row.jewelrySubType);
                formDataSubOrder.append(`rows[${index}][setting]`, row.Setting);
                formDataSubOrder.append(`rows[${index}][refLotId]`, row.ref_lot);
                formDataSubOrder.append(`rows[${index}][shapeType]`, row.shapeType);
                formDataSubOrder.append(`rows[${index}][metalType]`, row.metal);
                formDataSubOrder.append(`rows[${index}][jewelrySize]`, row.jew_size);
                formDataSubOrder.append(`rows[${index}][diamondApoSize]`, row.dia_size);
                formDataSubOrder.append(`rows[${index}][comment]`, row.comment);
                formDataSubOrder.append(`rows[${index}][qty]`, row.t_qty);
                formDataSubOrder.append(`rows[${index}][due_date]`, row.Due_Date);
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formDataSubOrder
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${bulkID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                await Swal.fire({
                    title: "Good job!",
                    text: "Order Successfully Saved",
                    icon: "success",
                });
                setRows([]);

            }
            fetchSkuData();
        } catch (error) {
            console.log("Error", error);
            Swal.fire({
                title: "The Internet?",
                text: "That thing is still around?",
                icon: "question",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const deleteRow = (index) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows.splice(index, 1);
        setRowsTotalDiaQty(updatedRows);
    };

    const deleteMFGRow = (index) => {
        const updatedRows = [...rowsMFG];
        updatedRows.splice(index, 1);
        setRowsMFG(updatedRows);
    };

    const deleteFGRow = (index) => {
        const updatedRows = [...rowsFG];
        updatedRows.splice(index, 1);
        setRowsFG(updatedRows);
    };

    const deleteExportRow = (index) => {
        const updatedRows = [...rowsExport];
        updatedRows.splice(index, 1);
        setRowsExport(updatedRows);
    };

    const deleteExportQtyRow = (index) => {
        const updatedRows = [...rowsExportQty];
        updatedRows.splice(index, 1);
        setRowsExportQty(updatedRows);
    };


    const deletePendingRow = (index) => {
        const updatedRows = [...rowsPending];
        updatedRows.splice(index, 1);
        setRowsPending(updatedRows);
    };


    const deleteRowSubOrder = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };


    const handleClose = () => {
        setModalOpen(false);
    }

    const handleModalPolishClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalPolishOpen(true)
        fetchTotal_Dia_Qty(0);
    }

    const handlePolishClose = () => {
        setModalPolishOpen(false)
    }

    const handleModalRoughClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalRoughOpen(true)
        fetchTotal_Dia_Qty(0);
    }

    const handleRoughClose = () => {
        setModalRoughOpen(false)
    }

    const handleModalMFGClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalMFGOpen(true)
        fetchTotal_Dia_Qty(1, "mfg");
    }

    const handleMFGClose = () => {
        setModalMFGOpen(false)
    }

    const handleModalFGClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalFGOpen(true)
        fetchTotal_Dia_Qty(1, "fg");
    }

    const handleFGClose = () => {
        setModalFGOpen(false)
    }

    const handleModalExportClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalExportOpen(true)
        fetchTotal_Dia_Qty(1, "export");
    }

    const handleModalExportClose = () => {
        setModalExportOpen(false)
    }

    const handleModalPendingClick = () => {
        setModalPendingOpen(true)
    }

    const handleModalPendingClose = () => {
        setModalPendingOpen(false)
    }


    const handleOpenModal = (remittance) => {
        setSelectedRemittance(remittance);
    };

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handleCommentClick = (index) => {
        setCommentIndex(index);
    };

    const handleCommentTotalQtyClick = (index) => {
        setCommentTotalQtyIndex(index);
    };

    const handleCommentMFGStatusClick = (index) => {
        setMfg_StatusIndex(index);
    };

    const handleCheckboxChangeCheckBox = (type) => {
        const newCheckboxValues = {
            ...checkboxValues,
            [type]: !checkboxValues[type],
        };
        setCheckboxValues(newCheckboxValues);

        const selectedTypes = Object.keys(newCheckboxValues).filter(
            (key) => newCheckboxValues[key]
        );
        setSelectedRef_byType(selectedTypes.join(", "));
    };

    const ref_by = [...new Set(getBulk.map((item) => item.ref_by))];

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };


    const clearRef_byTypeFilter = () => {
        setSelectedRef_byType("all");
        setShowRef_byFilter(false);
    };


    const handleRef_byTypeClose = () => {
        clearRef_byTypeFilter();
        setCheckboxValues({});
        setSelectedRef_byType("");
    };


    const formatDate = (dateString) => {
        if (!dateString) return null;

        const date = new Date(dateString);

        if (isNaN(date.getTime())) return null;

        // Format the date as MM-DD-YYYY
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };


    const clearT_QtyFilter = () => {
        setFilterT_Qty("");
        setshowT_QtyFilter(false);
    };


    const handleT_QtyClose = () => {
        clearT_QtyFilter();
    };

    const handleT_QtyChange = (e) => {
        setFilterT_Qty(e.target.value);
    };

    const clearT_SkuFilter = () => {
        setFilterT_Sku("");
        setShowT_SkuFilter(false);
    };

    const handleT_SkuClose = () => {
        clearT_SkuFilter();
    };

    const handleT_SkuChange = (e) => {
        setFilterT_Sku(e.target.value);
    };

    const clearMfgFilter = () => {
        setFilterMfg("");
        setShowMfgFilter(false);
    };


    const handleMfgClose = () => {
        clearMfgFilter();
    };

    const handleMfgChange = (e) => {
        setFilterMfg(e.target.value);
    };

    const clearFgFilter = () => {
        setFilterFg("");
        setShowFgFilter(false);
    };


    const handleFgClose = () => {
        clearFgFilter();
    };

    const handleFgChange = (e) => {
        setFilterFg(e.target.value);
    };

    const clearExportFilter = () => {
        setFilterExport("");
        setShowExportFilter(false);
    };


    const handleExportClose = () => {
        clearExportFilter();
    };

    const handleExportChange = (e) => {
        setFilterExport(e.target.value);
    };

    const clearPendingFilter = () => {
        setFilterPending("");
        setShowPendingFilter(false);
    };


    const handlePendingClose = () => {
        clearPendingFilter();
    };

    const handlePendingChange = (e) => {
        setFilterPending(e.target.value);
    };

    const clearT_Dia_QtyFilter = () => {
        setFilterT_Dia_Qty("");
        setShowT_Dia_QtyFilter(false);
    };


    const handleT_Dia_QtyClose = () => {
        clearT_Dia_QtyFilter();
    };

    const handleT_Dia_QtyChange = (e) => {
        setFilterT_Dia_Qty(e.target.value);
    };

    const clearPolish_AvailFilter = () => {
        setFilterPolish_Avail("");
        setShowPolish_AvailFilter(false);
    };

    const handlePolish_AvailClose = () => {
        clearPolish_AvailFilter();
    };

    const handlePolish_AvailChange = (e) => {
        setFilterPolish_Avail(e.target.value);
    };

    const clearRough_ShortFilter = () => {
        setFilterRough_Short("");
        setShowRough_ShortFilter(false);
    };


    const handleRough_ShortClose = () => {
        clearRough_ShortFilter();
    };

    const handleRough_ShortChange = (e) => {
        setFilterRough_Short(e.target.value);
    };

    const clearDue_DateFilters = () => {
        setStartDue_Date("");
        setEndDue_Date("");
        setShowDue_DateFilter(false);
    };

    const handleStartDue_DateChange = (e) => {
        setStartDue_Date(e.target.value);
    };

    const handleEndDue_DateChange = (e) => {
        setEndDue_Date(e.target.value);
    };

    const handleStartDue_DateClose = () => {
        clearDue_DateFilters();
    };

    const handleScroll = () => {
        setUserScrolledUp(true);
    };

    useEffect(() => {
        if (!userScrolledUp && chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [userScrolledUp]);

    // const handleSubOrderDelete = async (Sku_ID) => {
    //     console.log("Delete:", Sku_ID);

    //     try {
    //         const token = localStorage.getItem("token");
    //         const myHeaders = new Headers();
    //         myHeaders.append("Authorization", `Bearer ${token}`);

    //         const requestOptions = {
    //             method: "DELETE",
    //             headers: myHeaders,
    //             redirect: "follow",
    //         };

    //         const response = await fetch(`${baseUrl}/api/blk-sku/${Sku_ID}`, requestOptions);

    //         if (response.ok) {
    //             const newDataBulk = bulkSubOrder.filter((data) => data.sku_id !== Sku_ID);
    //             setBulkSubOrder(newDataBulk);
    //             fetchSkuData();
    //             // setShowPopup(false);
    //         } else {
    //             console.error("Error Deleting SubOrder", response.statusText);
    //         }

    //     } catch (error) {
    //         console.log("Error Delete Data", error);
    //     }
    // }

    const handleUpload = async (id) => {

        if (selectedFiles) {
            setUploading(true);
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("finalImg[]", selectedFiles[i]);
            }

            try {
                const token = localStorage.getItem("token");
                const response = await fetch(
                    `${baseUrl}/api/upload-final-media/${id}`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to upload files");
                }

                setSelectedFiles(null);
                setUploading(false);
                fetchSkuData();
            } catch (error) {
                console.error("Upload failed:", error);
                setUploading(false);
            }
        }
    };


    const handleChat = async (chatId) => {
        try {
            const token = localStorage.getItem("token");
            const userCode = localStorage.getItem("userCode");

            const [chatResponse, markReadResponse] = await Promise.all([
                axios.get(
                    `${baseUrl}/api/order-chat/show/${chatId}`,
                    {
                        params: { user_id: userCode },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                ),
            ]);

            if (chatResponse.status === 200) {
                setChatData(chatResponse.data);
            } else {
                throw new Error(
                    `Failed to fetch status: ${chatResponse.status} ${chatResponse.statusText}`
                );
            }

            if (markReadResponse.status !== 200) {
                throw new Error(
                    `Failed to mark messages as read: ${markReadResponse.status} ${markReadResponse.statusText}`
                );
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            // setIsLoading(false);
        }
    };

    const fetchDataChat = useCallback(async () => {
        if (chatId) {
            await handleChat(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        fetchDataChat();
    }, [fetchDataChat]);

    const handleBulkOrderEdit = (id) => {
        console.log("handleBulkOrderEdit", id);

    }


    const handleSubOrderDelete = async (Sku_ID) => {
        console.log("Attempting to delete:", Sku_ID);

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${Sku_ID}`, requestOptions);

            if (response.ok) {
                console.log("SubOrder deleted successfully");
                await Swal.fire({
                    title: "Delete!",
                    text: "Order Delete Successfully",
                    icon: "success",
                });
            } else {
                const errorData = await response.text();
                console.error("Error Deleting SubOrder:", response.status, response.statusText, errorData);
            }
            setBulkSubOrder(prev => prev.filter(data => data.sku_id !== Sku_ID));
            fetchSkuData();
        } catch (error) {
            console.error("Error during DELETE operation:", error);
        }
    };

    const handleSubOrderSave = async (Sku_ID) => {

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestBody = {};
            const currentRow = bulkSubOrder[editingRowIndex];
            for (const key in editedValues) {
                if (editedValues[key] !== currentRow[key]) {
                    requestBody[key] = editedValues[key];
                }
            }

            if (Object.keys(requestBody).length === 0) {
                // No changes detected
                console.log("No changes detected.");
                setEditMode(false);
                setEditingRowIndex(null);
                return;
            }

            // const requestBody = {
            //     jewelryType: editedValues.jewelryType,
            //     jewelrySubType: editedValues.jewelrySubType,
            //     setting: editedValues.setting,
            //     refLotId: editedValues.refLotId,
            //     shapeType: editedValues.shapeType,
            //     metalType: editedValues.metalType,
            //     jewelrySize: editedValues.jewelrySize,
            //     diamondApoSize: editedValues.diamondApoSize,
            //     // DiaWeight:editedValues.DiaWeight,
            //     comment: editedValues.comment,
            //     qty: editedValues.qty,
            //     due_date: editedValues.due_date,
            // };

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${Sku_ID}`, requestOptions);

            if (!response.ok) {
                throw new Error("Error updating data. Please try again");
            }

            const responseData = await response.json();
            setEditedValues(responseData);
            setEditedValues({});
            setEditMode(false);
            setEditingRowIndex(null);
            fetchSkuData();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }

    const handleBulkOrderSave = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestBody = {
                project_name: editedValues.project_name || ""
            };

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(`${baseUrl}/api/blk-order/${id}`, requestOptions);

            if (!response.ok) {
                throw new Error("Error updating data. Please try again");
            }

            const responseData = await response.json();
            setEditedValues({});
            setEditMode(false);
            setEditingRowIndex(null);
            fetchBulkOrder();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }

    const toggleEditMode = (index) => {
        setEditMode(true);
        // setEditedValues({ project_name: item.project_name });
        setEditingRowIndex(index);
        setEditedValues({ ...bulkSubOrder[index] });
    };

    const handleDropdownClick = (e, index, itemId) => {
        e.stopPropagation();
        setOpenDropdownIndex(index);
        setSelectedItemId(itemId);
    };

    const handleDropdownMFGClick = (e, index, itemId) => {
        e.stopPropagation();
        setOpenDropdownMFGIndex(index);
    };

    const handleDropdownLabNameClick = (e, index, itemId) => {
        e.stopPropagation();
        setOpenDropdownLabNameIndex(index);
    };

    const saveRequestChanges = () => {
        const token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({ requestStatus: selectedRequestStatus }),
            redirect: "follow",
        };

        fetch(`${baseUrl}/api/blk-sku/${selectedItemId}`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    closeModal();
                    setOpenDropdownIndex(null);
                    setSelectedRequestStatus("");
                    fetchSkuData();
                } else {
                    throw new Error(
                        `Failed to post status: ${response.status} ${response.statusText}`
                    );
                }
            })
            .catch((error) => {
                console.error("Error posting status:", error);
            });
    };

    const handleSelectChange = (e) => {
        const { value } = e.target;
        setSelectedRequestStatus(value);
        setOpenDropdownIndex(null);
        setCentredModal(true);
    };

    const closeModal = () => {
        setCentredModal(false);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenDropdownIndex(null);
            setOpenDropdownMFGIndex(null);
            setOpenDropdownLabNameIndex(null);
            setEditCell({ row: null, column: null });
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mouseover", handleClickOutside);
        };
    }, []);

    const handleModalT_DiaQtyClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalOpen(true);
        fetchTotal_Dia_Qty(0)
    }


    useEffect(() => {
        let method = null;

        if (modalOpen && Order_ID) {
            fetchTotal_Dia_Qty(0);
        } else if (modalMFGOpen && Order_ID) {
            method = "mfg";
        } else if (modalFGOpen && Order_ID) {
            method = "fg";
        } else if (modalExportOpen && Order_ID) {
            method = "export";
        } else if (modalExportQtyOpen && Order_ID) {
            method = "export_qty"
        }

        if (method) {
            fetchTotal_Dia_Qty(1, method);
        } else {
            fetchTotal_Dia_Qty(1);
        }
    }, [modalOpen, modalMFGOpen, modalFGOpen, modalExportOpen, modalExportQtyOpen, Order_ID, bulkID]);


    // useEffect(() => {
    //     if ((modalOpen && Order_ID) || (modalPolishOpen && Order_ID) || (modalRoughOpen && Order_ID)) {
    //         fetchTotal_Dia_Qty(0);
    //     } else {
    //         fetchTotal_Dia_Qty(1);
    //     }
    // }, [modalOpen, modalPolishOpen, modalRoughOpen, modalMFGOpen, bulkID, Order_ID]);

    const fetchTotal_Dia_Qty = async (typeData, method) => {
        console.log("method:", method);

        setLoading1(true);
        if (!bulkID || !Order_ID) {
            console.error("bulkID or Order_ID is missing");
            return;
        }
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}?type=${typeData}&method=${method}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                setLoading1(false);
                setOrder_Dia_Plan(responseData);
            } else {
                console.log("Error Fetching order-dia-plan Data", response.error);
            }

        } catch (error) {
            console.log("Error Fetching order-dia-plan Data", error);
        }
    }

    const handleTotal_Dia_Qty = async (type) => {
        setIsSubmitting(true);
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formDataTotal_Dia_Qty = new FormData();
            const combinedRows = [...rowsTotalDiaQty, ...order_Dia_Plan];
            console.log("combinedRows:", combinedRows);


            if (type === "t_dia_qty") {
                combinedRows.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);

                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][lab_name]`, row.lab_name);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][comment]`, row.comment);

                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock || 0);
                });
            } else if (type === "in_stock") {
                order_Dia_Plan.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);

                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][new_mfg]`, row.new_mfg);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][mfg_status_jewelery]`, row.mfg_status_jewelery);

                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rough_short]`, "0");
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "rough_short") {
                order_Dia_Plan.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][purchase_short]`, row.purchase_short);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rs_purchase]`, row.rs_purchase);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rough_short_date]`, row.rough_short_date);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "mfg") {
                combinedRows.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][id]`, row.id);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][pocessing_mfg]`, row.pocessing_mfg);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][mfg_due_date]`, row.mfg_due_date);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][mfg_status]`, row.mfg_status || "Pending");
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][type]`, 1);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "fg") {
                combinedRows.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][id]`, row.id);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][finish_goods_qty]`, row.finish_goods_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][type]`, 1);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "export") {
                combinedRows.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][id]`, row.id);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][export_no]`, row.export_no);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][export_qty]`, row.export_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][type]`, 1);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "export_qty") {
                combinedRows.forEach((row, index) => {
                    // Check if lotname exists and is a string
                    if (row.lotname && typeof row.lotname === 'string' && row.lotname.trim() !== '') {
                        if (export_ID !== null) {
                            formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][id]`, export_ID);
                        }
                        if (export_ID !== null) {
                            formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[0][lot][${index}][lotname]`, row.lotname.trim());
                        }
                        // Append type
                        formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][type]`, 1);
                    }
                });
            }


            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formDataTotal_Dia_Qty,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                await Swal.fire({
                    title: "Good job!",
                    text: "Order Successfully Saved",
                    icon: "success",
                });
                fetchTotal_Dia_Qty();
                fetchSkuData();
                fetchBulkOrder();
                setRowsTotalDiaQty([{
                    shape: "",
                    dia_weight: "",
                    dia_size: "",
                    dia_qty: "",
                    lab_name: "",
                    comment: "",
                    new_mfg: "",
                    mfg_status_jewelery: "",
                    in_stock: "",
                    rough_short: "",
                    purchase_short: "",
                    rs_purchase: "",
                    rs_date: "",
                    pocessing_mfg: "",
                    mfg_due_date: "",
                    finish_goods_qty: "",
                    export_no: "",
                    export_qty: "",
                    lotname: ""
                }]);
                setModalExportQtyOpen(false);
                setModalOpen(false);
                setModalPolishOpen(false);
                setModalRoughOpen(false);
                setModalMFGOpen(false);
                setModalFGOpen(false);
                setModalExportOpen(false);
                setModalExportQtyOpen(false);
            } else {
                console.error("Error:", response.statusText);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                });
            }
        } catch (error) {
            console.log("Error", error);
            Swal.fire({
                title: "The Internet?",
                text: "That thing is still around?",
                icon: "question",
            });
        } finally {
            setIsSubmitting(false);
        }
    }

    // const handleTotal_Dia_Qty = async (type) => {
    //     setIsSubmitting(true);

    //     try {
    //         const token = localStorage.getItem("token");
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         myHeaders.append("Authorization", `Bearer ${token}`);

    //         // Initialize payload object
    //         const payload = { rowsTotalDiaQty: [] };

    //         // Build payload based on type
    //         if (type === "t_dia_qty") {
    //             payload.rowsTotalDiaQty = rowsTotalDiaQty.map(row => ({
    //                 shape: row.shape,
    //                 dia_weight: row.dia_weight,
    //                 dia_size: row.dia_size,
    //                 dia_qty: row.dia_qty
    //             }));
    //         } else if (type === "in_stock") {
    //             payload.rowsTotalDiaQty = order_Dia_Plan.map(row => ({
    //                 shape: row.shape,
    //                 dia_weight: row.dia_weight,
    //                 dia_size: row.dia_size,
    //                 dia_qty: row.dia_qty,
    //                 rough_short: "0"
    //             })).concat(rowsTotalDiaQty.map(row => ({
    //                 shape: row.shape,
    //                 dia_weight: row.dia_weight,
    //                 dia_size: row.dia_size,
    //                 dia_qty: row.dia_qty,
    //                 in_stock: row.in_stock
    //             })));
    //         } else if (type === "rough_short") {
    //             payload.rowsTotalDiaQty = rowsTotalDiaQty.map(row => ({
    //                 shape: row.shape,
    //                 dia_weight: row.dia_weight,
    //                 dia_size: row.dia_size,
    //                 dia_qty: row.dia_qty,
    //                 in_stock: row.in_stock,
    //                 rs_mfg: row.rs_mfg,
    //                 rs_purchase: row.rs_purchase
    //             }));
    //         }

    //         console.log("payload:", payload);

    //         const requestOptions = {
    //             method: "POST",
    //             headers: myHeaders,
    //             body: JSON.stringify(payload), // Convert the payload to JSON string
    //             redirect: "follow"
    //         };

    //         const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}`, requestOptions);

    //         if (response.ok) {
    //             const responseData = await response.json();
    //             console.log("responseData:", responseData);
    //             await Swal.fire({
    //                 title: "Good job!",
    //                 text: "Order Successfully Saved",
    //                 icon: "success",
    //             });
    //             fetchTotal_Dia_Qty();
    //             setRowsTotalDiaQty([{
    //                 shape: "",
    //                 dia_weight: "",
    //                 dia_size: "",
    //                 dia_qty: "",
    //                 in_stock: "",
    //                 rough_short: "",
    //                 rs_mfg: "",
    //                 rs_purchase: ""
    //             }]);
    //         } else {
    //             console.error("Error:", response.statusText);
    //             Swal.fire({
    //                 icon: "error",
    //                 title: "Oops...",
    //                 text: "Something went wrong!",
    //             });
    //         }
    //     } catch (error) {
    //         console.log("Error", error);
    //         Swal.fire({
    //             title: "The Internet?",
    //             text: "That thing is still around?",
    //             icon: "question",
    //         });
    //     } finally {
    //         setIsSubmitting(false);
    //     }
    // };


    const shapeOptions = [
        "Round", "Emerald", "Radiant", "Oval", "Pear", "Marquise", "Cushion",
        "Heart", "Princess", "Asscher", "Mix (As.Comment)", "Anti. Asscher",
        "Anti. Cushion", "Anti. Oval", "Anti. Marquise", "Sq. Anti. Cushion",
        "Sq. Cushion", "Sq. Emerald", "Sq. Radiant", "Step Pear", "Step Cushion",
        "Step Marquise", "Step Oval", "Duchess", "Trapezoid", "Bull", "Horse",
        "Bullet", "Flower", "Butterflies", "Briolet", "Baguette", "Kite",
        "Kriss", "Moon Half", "Octagon", "Octagon H&A", "Old European",
        "Old Mine", "Rose Cut", "Rose Oval", "Rose Pear", "Trapper Baguette",
        "Triangle",
    ];

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput("");
    };


    const handleCellClick = (index, column) => {
        setEditCell({ row: index, column });
    };

    const handleInputTd_QtyChange = (e, rowIndex, field) => {
        const updatedPlan = [...order_Dia_Plan];
        updatedPlan[rowIndex][field] = e.target.value;
        setOrder_Dia_Plan(updatedPlan);
    };

    const handleDeleteRow = async (index, id) => {

        const updatedRows = [...order_Dia_Plan];
        updatedRows[index].isdeleted = 1;
        setOrder_Dia_Plan(updatedRows);

        try {
            await handleTotal_Dia_Qty("in_stock", "rough_short", "t_dia_qty");
            fetchTotal_Dia_Qty();
        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };


    const handleDeleteJewRow = async (index, id) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[index].isdeleted = 1;
        setOrder_Dia_Plan(updatedRows);
        try {
            await handleTotal_Dia_Qty("mfg", "fg", "export", "export_qty");
            fetchTotal_Dia_Qty();
        } catch (error) {
            console.error("Error deleting row:", error);
        }
    }

    const handleInputPolishChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].in_stock = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };

    const handleInputLotnameChange = (e, rowIndex) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows[rowIndex].lotname = e.target.value;
        setRowsTotalDiaQty(updatedRows);
    };

    const handleInputNewMFGChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].new_mfg = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };
    const handleInputMFGStatusChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].mfg_status_jewelery = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };

    const handleInputPurchase_ShortChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].purchase_short = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    const handleInputRoughPurchaseChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rs_purchase = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    // const handleInputLotnameChange = (e, index) => {
    //     const { value } = e.target;
    //     setOrder_Dia_Plan(prevRows => {
    //         const updatedRows = [...prevRows];
    //         updatedRows[index].lotname = value;
    //         return updatedRows;
    //     });
    // };


    const handleInputRoughS_dateChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rough_short_date = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    const handleMFGStatusChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].mfg_status = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };

    const handleLabNameChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].lab_name = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };

    const getUniqueNonNullLotNames = (data) => {
        const lotNames = new Set();
        data.forEach(item => item.lotname.forEach(name => {
            if (name) lotNames.add(name);
        }));
        return Array.from(lotNames);
    };


    const [modalCommentOpen, setModalCommentOpen] = useState(null);

    const handleOpenCommentModal = (index) => {
        setModalCommentOpen(index);
        setEditCell({ row: index, column: 'comment' });
    };

    const handleCloseCommentModal = () => {
        setModalCommentOpen(null);
        setEditCell({ row: null, column: null });
    };


    return (
        <>
            <div
                className="d-flex  align-items-center gap-2 px-2 rounded-5 bg_report w-100"
                style={{ top: "162px", zIndex: 999, position: "sticky" }}
            >
                {showOrderNoFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column  w-100">
                            <label htmlFor="" className="fs-6  pb-1">
                                Order No
                                <CloseRoundedIcon
                                    className="ms-2"
                                    onClick={handleOrderNoClose}
                                />
                            </label>
                            <input
                                type="text"
                                value={`B${orderNo}`}
                                onChange={handleOrderNoFilterChange}
                                className="form-control  w-50"
                            />
                        </div>
                    </div>
                )}

                {showRef_byFilter && (
                    <div className="d-flex gap-3">
                        <div className="d-flex flex-column">
                            <label htmlFor="" className="text-center pb-1">
                                Ref_by
                            </label>
                            <div className="dropdown">
                                <button
                                    className="form-select w-100"
                                    type="button"
                                    onClick={handleDropdownToggle}
                                >
                                    {selectedRef_byType || "Select"}
                                </button>
                                {dropdownOpen && (
                                    <div className="dropdown-menu show scrollable-menu">
                                        <div className="dropdown-item">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="checkbox-N/A"
                                                    checked={checkboxValues["N/A"]}
                                                    onChange={() =>
                                                        handleCheckboxChangeCheckBox("N/A")
                                                    }
                                                />
                                                <label className="checkBox" htmlFor="checkbox-N/A">
                                                    N/A
                                                </label>
                                            </div>
                                        </div>
                                        <div className="dropdown-item">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    // className="form-check-input"
                                                    id="checkbox-all"
                                                    checked={checkboxValues["all"]}
                                                    onChange={() =>
                                                        handleCheckboxChangeCheckBox("all")
                                                    }
                                                />
                                                <label
                                                    className="form-check-label checkBox"
                                                    htmlFor="checkbox-all"
                                                >
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        <div className="scrollable-content">
                                            {ref_by
                                                .filter(
                                                    (type) => type !== null && type.trim() !== ""
                                                )
                                                .map((type, index) => (
                                                    <div key={index} className="dropdown-item">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                id={`checkbox-${type.trim()}`}
                                                                checked={checkboxValues[type.trim()]}
                                                                onChange={() =>
                                                                    handleCheckboxChangeCheckBox(type.trim())
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label checkBox"
                                                                htmlFor={`checkbox-${type.trim()}`}
                                                            >
                                                                {type.trim()}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <CloseRoundedIcon onClick={handleRef_byTypeClose} />
                    </div>
                )}

                {showDateFilter && (
                    <div className=" d-flex gap-1">
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">Start Order_Date</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                className="form-control "
                            />
                        </div>
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">End Order_Date</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className="form-control"
                            />
                        </div>
                        <CloseRoundedIcon onClick={handleStartDateClose} />
                    </div>
                )}

                {showProjectNameFilter && (
                    <div className="d-flex gap-3">
                        <div className="d-flex flex-column  w-100">
                            <label htmlFor="" className="fs-6  pb-1">
                                Project Name
                                <CloseRoundedIcon
                                    className="ms-2"
                                    onClick={handleProjectNameFilterClose}
                                />
                            </label>
                            <input
                                type="text"
                                value={projectNameFilter}
                                onChange={handleProjectNameFilterChange}
                                className="form-control w-50"
                            />
                        </div>
                    </div>
                )}

                {showT_QtyFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Total Quantity
                                <CloseRoundedIcon onClick={handleT_QtyClose} />
                            </label>
                            <input
                                type="text"
                                value={filterT_Qty}
                                onChange={handleT_QtyChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showT_SkuFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Total SKU
                                <CloseRoundedIcon onClick={handleT_SkuClose} />
                            </label>
                            <input
                                type="text"
                                value={filterT_Sku}
                                onChange={handleT_SkuChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showMfgFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                MFG
                                <CloseRoundedIcon onClick={handleMfgClose} />
                            </label>
                            <input
                                type="text"
                                value={filterMfg}
                                onChange={handleMfgChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showFgFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                FG
                                <CloseRoundedIcon onClick={handleFgClose} />
                            </label>
                            <input
                                type="text"
                                value={filterFg}
                                onChange={handleFgChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showExportFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Export
                                <CloseRoundedIcon onClick={handleExportClose} />
                            </label>
                            <input
                                type="text"
                                value={filterExport}
                                onChange={handleExportChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showPendingFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Pending
                                <CloseRoundedIcon onClick={handlePendingClose} />
                            </label>
                            <input
                                type="text"
                                value={filterPending}
                                onChange={handlePendingChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showT_Dia_QtyFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                T_Dia_Qty
                                <CloseRoundedIcon onClick={handleT_Dia_QtyClose} />
                            </label>
                            <input
                                type="text"
                                value={filterT_Dia_Qty}
                                onChange={handleT_Dia_QtyChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showPolish_AvailFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Polish_Avail
                                <CloseRoundedIcon onClick={handlePolish_AvailClose} />
                            </label>
                            <input
                                type="text"
                                value={filterPolish_Avail}
                                onChange={handlePolish_AvailChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showRough_ShortFilter && (
                    <div className=" d-flex gap-3">
                        <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="fs-6 pb-1">
                                Rough_Short
                                <CloseRoundedIcon onClick={handleRough_ShortClose} />
                            </label>
                            <input
                                type="text"
                                value={filterRough_Short}
                                onChange={handleRough_ShortChange}
                                className="form-control  w-50"
                                required
                            />
                        </div>
                    </div>
                )}

                {showDue_DateFilter && (
                    <div className=" d-flex gap-1">
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">
                                Start Due_Date
                            </label>
                            <input
                                type="date"
                                value={startDue_Date}
                                onChange={handleStartDue_DateChange}
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column ">
                            <label className="me-2  text-center pb-1 ">End Due_Date</label>
                            <input
                                type="date"
                                value={endDue_Date}
                                onChange={handleEndDue_DateChange}
                                className="form-control"
                            />
                        </div>
                        <CloseRoundedIcon onClick={handleStartDue_DateClose} />
                    </div>
                )}

            </div>

            <div>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        <table className="table table-bordered table-responsive bulkOrderTable">
                            <thead id="addProductTableHead">
                                <tr>
                                    <th className="p-0 py-2 fw-bold">Select</th>
                                    <th className="p-0 py-2 fw-bold">
                                        Order_No
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowOrderNoFilter(!showOrderNoFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Ref_by
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowRef_byFilter(!showRef_byFilter)}
                                        />
                                    </th>

                                    <th className="p-0 py-2 fw-bold">
                                        Project_Name
                                        <ArrowDropDownRoundedIcon
                                            onClick={() =>
                                                setShowProjectNameFilter(!showProjectNameFilter)
                                            }
                                        />
                                    </th>

                                    <th className="p-0 py-2 fw-bold">
                                        Order Date
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowDateFilter(!showDateFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Total SKU
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowT_SkuFilter(!showT_SkuFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">
                                        T.Qty
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setshowT_QtyFilter(!showT_QtyFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">MFG
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowMfgFilter(!showMfgFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">FG
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowFgFilter(!showFgFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Export
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowExportFilter(!showExportFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Pending
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowPendingFilter(!showPendingFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Total Dia Qty
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowT_Dia_QtyFilter(!showT_Dia_QtyFilter)}
                                        />

                                    </th>
                                    <th className="p-0 py-2 fw-bold">Polish Avail
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowPolish_AvailFilter(!showPolish_AvailFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Rough Short
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowRough_ShortFilter(!showRough_ShortFilter)}
                                        />
                                    </th>
                                    <th className="p-0 py-2 fw-bold">Due_Date
                                        <ArrowDropDownRoundedIcon
                                            onClick={() => setShowDue_DateFilter(!showDue_DateFilter)}
                                        />
                                    </th>
                                    {/* <th className="p-0 py-2 fw-bold">ChatBox</th> */}
                                    {/* <th className="p-0 py-2 fw-bold">Delete</th> */}
                                    <th className="p-0 py-2 fw-bold">EDIT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bulkOrderFilter.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={`${selectedRows.includes(item.id) ? 'bg-selected' : 'bulkMain'}`}
                                    >
                                        <td id="showUserTablebody">
                                            <input
                                                type='checkbox'
                                                checked={selectedRows.includes(item.id)}
                                                onChange={() => handleCheckboxChange(item.id)}
                                            />
                                        </td>
                                        <td id="showUserTablebody">B{item.id || "N/A"}</td>
                                        <td id="showUserTablebody">{item.ref_by || "N/A"}</td>
                                        <td id="showUserTablebody">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='Project Name'
                                                    name="project_name"
                                                    value={editedValues.project_name || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                item.project_name || "N/A"
                                            )}
                                        </td>
                                        {/* <td id="showUserTablebody">{item.project_name || "N/A"}</td> */}
                                        <td id="showUserTablebody">{formatDate(item.created_date)}</td>
                                        <td id="showUserTablebody">
                                            <button className='totalSku' id="showUserTablebody" onClick={() => handleSubOrderClick(item.id)}><span>{item.total_sku}</span></button>
                                        </td>
                                        <td id="showUserTablebody">
                                            {item.total_qty}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.mfg}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.fg}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.export}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.pending}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.total_dia_qty}
                                        </td>

                                        <td id="showUserTablebody">
                                            {item.polish_avail}
                                        </td>
                                        <td id="showUserTablebody">
                                            {item.rough_short}
                                        </td>
                                        <td id="showUserTablebody">{formatDate(item.due_date)}</td>
                                        {/* <td id="showUserTablebody">
                                            <MDBBtn
                                                className="userChat"
                                                id="chatBox"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#popup-${index}`}
                                                onClick={() => setChatId(item.id)}
                                            >
                                                <MDBIcon far icon="comments" />
                                            </MDBBtn>
                                            <div
                                                className="modal fade"
                                                id={`popup-${index}`}
                                                tabIndex="-1"
                                                aria-labelledby={`popupLabel-${index}`}
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <div className="text-start">
                                                                <span>Order No-B{item.id}</span>
                                                                <br />
                                                                <span className="text-danger">
                                                                    *Content can be deleted within 3 minutes
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                            ></button>
                                                        </div>
                                                        <div
                                                            className="modal-body"
                                                            ref={chatBoxRef}
                                                            onScroll={handleScroll}
                                                        >
                                                            <BulkChatBox  />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td> */}

                                        {/* <td id="showUserTablebody">
                                            <DeleteForeverIcon onClick={() => handleBulkOrderDelete(item.id)} />
                                        </td> */}
                                        {/* <td id="showUserTablebody">
                                            <EditIcon onClick={() => handleBulkOrderEdit(item.id)} />
                                        </td> */}

                                        <td id="showUserTablebody">
                                            {editMode && editingRowIndex === index ? (
                                                <>
                                                    <SaveIcon
                                                        onClick={() =>
                                                            handleBulkOrderSave(item.id)
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <EditIcon onClick={() => toggleEditMode(index)} />
                                            )}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {showSubOrder && selectedSubOrder && (
                            <>
                                <h6 className='fw-bold' style={{ color: "rgb(84 99 145)" }}>SKU of - B{bulkID}</h6>
                                <form
                                    onSubmit={handleSubOrderSubmit}
                                    encType="multipart/form-data"
                                    id="requirementForm"
                                >
                                    <table className="table table-bordered table-responsive bulkSkuTable">
                                        <thead id="subOrderTableHead">
                                            <tr>
                                                <th className="p-0 py-2 fw-bold">Select</th>
                                                <th className="p-0 py-2 fw-bold">Sub_Order</th>
                                                <th className="p-0 py-2 fw-bold">Order Date</th>
                                                <th className="p-0 py-2 fw-bold">Status</th>
                                                <th className="p-0 py-2 fw-bold">Type</th>
                                                <th className="p-0 py-2 fw-bold">Sub_Types</th>
                                                <th className="p-0 py-2 fw-bold">Setting</th>
                                                <th className="p-0 py-2 fw-bold">Ref. Piece</th>
                                                <th className="p-0 py-2 fw-bold">Shape</th>
                                                <th className="p-0 py-2 fw-bold">Metal</th>
                                                <th className="p-0 py-2 fw-bold">Size</th>
                                                <th className="p-0 py-2 fw-bold">Dia Size</th>
                                                {/* <th className="p-0 py-2 fw-bold">Dia Weight</th> */}
                                                <th className="p-0 py-2 fw-bold">Comment</th>
                                                <th className="p-0 py-2 fw-bold">T.Qty</th>
                                                <th className="p-0 py-2 fw-bold">MFG</th>
                                                <th className="p-0 py-2 fw-bold">FG</th>
                                                <th className="p-0 py-2 fw-bold">Export</th>
                                                <th className="p-0 py-2 fw-bold">Pending</th>
                                                <th className="p-0 py-2 fw-bold">Total Dia Qty</th>
                                                <th className="p-0 py-2 fw-bold">Polish Avail</th>
                                                <th className="p-0 py-2 fw-bold">Rough Short</th>
                                                <th className="p-0 py-2 fw-bold"> Purchase Pending</th>
                                                {/* <th className="p-0 py-2 fw-bold">R.Date</th> */}
                                                <th className="p-0 py-2 fw-bold">EstDate</th>
                                                <th className="p-0 py-2 fw-bold">DueDate</th>
                                                <th className="p-0 py-2 fw-bold">F_Img</th>
                                                <th className="p-0 py-2 fw-bold">ChatBox</th>
                                                {/* <th className="p-0 py-2 fw-bold">Delete</th> */}
                                                <th className="p-0 py-2 fw-bold">Edit</th>
                                                <th className="p-0 py-2 fw-bold">Del.</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {bulkSubOrder.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="px-0 py-1 text-center">
                                                        <input
                                                            type='checkbox'
                                                        />
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        B{row.blk_order_id}-J{row.order_id}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {formatDate(row.created_at)}
                                                    </td>

                                                    <td
                                                        className="px-0 py-1 text-center"
                                                        onClick={(e) =>
                                                            handleDropdownClick(e, index, row.sku_id)
                                                        }
                                                        style={{
                                                            color:
                                                                openDropdownIndex === index
                                                                    ? "black"
                                                                    : !row.id || !row.requestStatus
                                                                        ? "orange"
                                                                        : row.requestStatus === "Req_Approved"
                                                                            ? "green"
                                                                            : row.requestStatus === "Designing"
                                                                                ? "blue"
                                                                                : row.requestStatus ===
                                                                                    "Design Approval Pending"
                                                                                    ? "#9A208C"
                                                                                    : row.requestStatus === "Design Approved"
                                                                                        ? "#4FA095"
                                                                                        : row.requestStatus === "Hold"
                                                                                            ? "#096C87"
                                                                                            : row.requestStatus === "Export"
                                                                                                ? "#000000"
                                                                                                : row.requestStatus === "Manufacturing"
                                                                                                    ? "#905241"
                                                                                                    : row.requestStatus === "Canceled Order"
                                                                                                        ? "red"
                                                                                                        : "inherit",
                                                        }}
                                                    >
                                                        {openDropdownIndex === index ? (
                                                            <select
                                                                ref={dropdownRef}
                                                                value={selectedRequestStatus}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="Req_Pending">Req_Pending</option>
                                                                <option value="Req_Approved">Req_Approved</option>
                                                                <option value="Designing">Designing</option>
                                                                <option value="Design Approval Pending">Design Approval Pending</option>
                                                                <option value="Design Approved">Design Approved</option>
                                                                <option value="Export">Export</option>
                                                                <option value="Manufacturing">Manufacturing</option>
                                                                <option value="Hold">Hold</option>
                                                                <option value="Canceled Order">Canceled Order</option>
                                                            </select>
                                                        ) : !row.sku_id || !row.requestStatus ? (
                                                            "Req_Pending"
                                                        ) : (
                                                            row.requestStatus
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='jewelryType'
                                                                name="jewelryType"
                                                                value={editedValues.jewelryType || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.jewelryType || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='jewelrySubType'
                                                                name="jewelrySubType"
                                                                value={editedValues.jewelrySubType || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.jewelrySubType || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='Setting'
                                                                name="setting"
                                                                value={editedValues.setting || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.setting || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='refLotId'
                                                                name="refLotId"
                                                                value={editedValues.refLotId || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.refLotId || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='shapeType'
                                                                name="shapeType"
                                                                value={editedValues.shapeType || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.shapeType || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='metalType'
                                                                name="metalType"
                                                                value={editedValues.metalType || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.metalType || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='jewelrySize'
                                                                name="jewelrySize"
                                                                value={editedValues.jewelrySize || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.jewelrySize || "N/A"
                                                        )}
                                                    </td>
                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='diamondApoSize'
                                                                name="diamondApoSize"
                                                                value={editedValues.diamondApoSize || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.diamondApoSize || "N/A"
                                                        )}
                                                    </td>

                                                    {/* <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='dia_weight'
                                                                name="dia_weight"
                                                                value={editedValues.dia_weight || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.dia_weight || "N/A"
                                                        )}
                                                    </td> */}

                                                    <td td id="showUserTablebody" >
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='comment'
                                                                name="comment"
                                                                value={editedValues.comment}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            <div>
                                                                <CommentIcon
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target={`#modalfade-${index}`}
                                                                    // onClick={handleOpenModal}
                                                                    style={{ color: "#008000" }}
                                                                />
                                                                <div
                                                                    className="modal fade"
                                                                    id={`modalfade-${index}`}
                                                                    tabIndex="-1"
                                                                    aria-labelledby={`popupLabel-${index}`}
                                                                    aria-hidden="true"
                                                                >
                                                                    <div className="modal-dialog modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5
                                                                                    className="fw-bold text-start"
                                                                                    id={`popupLabel-${index}`}
                                                                                >
                                                                                    Comment- B{row.blk_order_id}-J{row.order_id}
                                                                                </h5>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-close"
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                ></button>
                                                                            </div>
                                                                            <div className="modal-body text-center">
                                                                                <span>{row.comment || "N/A"}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center" id='t_qty'>
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="text"
                                                                placeholder='T_qty'
                                                                name="qty"
                                                                value={editedValues.qty || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.qty || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='mf_fg_exp' onClick={() => handleModalMFGClick(row.order_id)}>
                                                        {row.mfg || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='mf_fg_exp' onClick={() => handleModalFGClick(row.order_id)}>
                                                        {row.fg || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='mf_fg_exp' onClick={() => handleModalExportClick(row.order_id)}>
                                                        {row.export || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='pen_ding' onClick={() => handleModalPendingClick()}>
                                                        {row.pending || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='td_qty' onClick={() => handleModalT_DiaQtyClick(row.order_id)}>
                                                        {row.total_dia_qty || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='polish' onClick={() => handleModalPolishClick(row.order_id)}>
                                                        {row.in_stock || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center cursor-pointer" id='rough_short' onClick={() => handleModalRoughClick(row.order_id)}>
                                                        {row.rough_short || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center" id='purchase_pending'>
                                                        {row.purchase_pending || 0}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="date"
                                                                placeholder='est_date'
                                                                name="est_date"
                                                                value={editedValues.est_date || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            row.est_date || "N/A"
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        {editMode && editingRowIndex === index ? (
                                                            <input
                                                                type="date"
                                                                name="due_date"
                                                                value={editedValues.due_date || ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        ) : (
                                                            formatDate(row.due_date || "N/A")
                                                        )}
                                                    </td>

                                                    <td id="showUserTablebody">
                                                        <div key={row.id}>
                                                            <MDBBtn
                                                                id={`chatBox-${row.id}`}
                                                                data-bs-toggle="modal"
                                                                data-bs-target={`#viewImage-${row.id}`}
                                                                className="userChat"
                                                                style={{
                                                                    backgroundColor: row.finalImg
                                                                        ? ""
                                                                        : "#EBEBEB",
                                                                }}
                                                            >
                                                                <MDBIcon fas icon="photo-video" />
                                                            </MDBBtn>
                                                            <div
                                                                className="modal fade"
                                                                id={`viewImage-${row.id}`}
                                                                tabIndex="-1"
                                                                aria-labelledby={`popupLabel-${row.id}`}
                                                                aria-hidden="true"
                                                            >
                                                                <div className="modal-dialog modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5
                                                                                className="modal-title"
                                                                                id={`popupLabel-${row.id}`}
                                                                            >
                                                                                Final Image -  B{row.blk_order_id}-J{row.order_id}
                                                                            </h5>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            ></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            {uploading ? (
                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                    <Loader />
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    {row.finalImg ? (
                                                                                        <MDBCarousel
                                                                                            showControls
                                                                                            style={{ color: "red" }}
                                                                                        >
                                                                                            <div>
                                                                                                {JSON.parse(
                                                                                                    row.finalImg
                                                                                                ).map((media, mediaIndex) => (
                                                                                                    <MDBCarouselItem
                                                                                                        key={mediaIndex}
                                                                                                        itemId={mediaIndex + 1}
                                                                                                    >
                                                                                                        <div key={mediaIndex}>
                                                                                                            {media.endsWith(".mp4") ? (
                                                                                                                <video
                                                                                                                    controls
                                                                                                                    className="w-100"
                                                                                                                >
                                                                                                                    <source
                                                                                                                        src={`${baseUrl}/${media}`}
                                                                                                                        type="video/mp4"
                                                                                                                    />
                                                                                                                    Your browser does not
                                                                                                                    support the video
                                                                                                                </video>
                                                                                                            ) : (
                                                                                                                <img
                                                                                                                    src={`${baseUrl}/${media}`}
                                                                                                                    alt={`Media ${mediaIndex}`}
                                                                                                                    className="w-100"
                                                                                                                    loading="lazy"
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </MDBCarouselItem>
                                                                                                ))}
                                                                                            </div>
                                                                                        </MDBCarousel>
                                                                                    ) : (
                                                                                        <img
                                                                                            src="No Image Fund.jpg"
                                                                                            alt="No Images"
                                                                                            className=" w-100 h-100 mw-50 mh-50"
                                                                                            loading="lazy"
                                                                                        />
                                                                                    )}
                                                                                    <hr />
                                                                                    <div>
                                                                                        <h5>Upload Images or Videos</h5>
                                                                                        <input
                                                                                            type="file"
                                                                                            id={`imageUpload-${row.id}`}
                                                                                            accept="image/*, video/*"
                                                                                            name="finalImg[]"
                                                                                            onChange={(e) =>
                                                                                                handleFileChange(e, row.id)
                                                                                            }
                                                                                            multiple
                                                                                        />

                                                                                        <button
                                                                                            onClick={() =>
                                                                                                handleUpload(row.id)
                                                                                            }
                                                                                            disabled={!selectedFiles}
                                                                                        >
                                                                                            Upload
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td id="showUserTablebody">
                                                        <MDBBtn
                                                            className="userChat"
                                                            id="chatBox"
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#popup-${index}`}
                                                            onClick={() => setChatId(row.order_id)}
                                                        >
                                                            <MDBIcon far icon="comments" />
                                                        </MDBBtn>
                                                        <div
                                                            className="modal fade"
                                                            id={`popup-${index}`}
                                                            tabIndex="-1"
                                                            aria-labelledby={`popupLabel-${index}`}
                                                            aria-hidden="true"
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <div className="text-start">
                                                                            <span>ChatBox-B{row.blk_order_id}-J{row.order_id}</span>
                                                                            <br />
                                                                            <span className="text-danger">
                                                                                *Content can be deleted within 3 minutes
                                                                            </span>
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                        ></button>
                                                                    </div>
                                                                    <div
                                                                        className="modal-body"
                                                                        ref={chatBoxRef}
                                                                        onScroll={handleScroll}
                                                                    >
                                                                        <BulkChatBox
                                                                            chatPostId={row.order_id}
                                                                            chatData={chatData}
                                                                            handleChat={handleChat}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {/* <td id="showUserTablebody" >
                                                        <DeleteForeverIcon
                                                            onClick={() => {
                                                                handleSubOrderDelete(row.sku_id);
                                                                setShowPopup(true);
                                                            }}
                                                        />
                                                    </td> */}

                                                    <td id="showUserTablebody">
                                                        {editMode && editingRowIndex === index ? (
                                                            <>
                                                                <SaveIcon
                                                                    onClick={() =>
                                                                        handleSubOrderSave(row.sku_id)
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <EditIcon onClick={() => toggleEditMode(index)} />
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-2 text-center">
                                                        <i
                                                            className="fas fa-circle-minus fs-5"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => deleteRowSubOrder(index)}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))}

                                            {rows.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="px-0 py-1 text-center">
                                                        <input
                                                            type='checkbox'
                                                        />
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                placeholder='JewelryType'
                                                                className='JewelryTypesku'
                                                                value={row.jewelryType || ""}
                                                                required
                                                                onBlur={handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange(index, "jewelryType", e.target.value)
                                                                }
                                                                onFocus={() => handleFocus(`jewelryType-${index}`)}
                                                            />
                                                            {focusedInput === `jewelryType-${index}` && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "calc(100% + 5px)",
                                                                        left: 0,
                                                                        width: "100%",
                                                                        maxHeight: "150px",
                                                                        overflowY: "auto",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor: "white",
                                                                        zIndex: 1000,
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                    className="scrollable"
                                                                >
                                                                    {[
                                                                        "BRACELET",
                                                                        "NECKLACE",
                                                                        "EARRING",
                                                                        "ETERNITY BAND",
                                                                        "RING",
                                                                        "PENDANT",
                                                                    ].filter((option) =>
                                                                        option
                                                                            .toLowerCase()
                                                                            .includes((row.jewelryType || "").toLowerCase())
                                                                    )
                                                                        .map((option, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                onMouseDown={() => {
                                                                                    handleChange(index, "jewelryType", option);
                                                                                    handleBlur();
                                                                                }}
                                                                                style={{ cursor: "pointer", padding: "5px" }}
                                                                                className="dropdown-option"
                                                                            >
                                                                                {option}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                placeholder='Sub Type'
                                                                type='text'
                                                                value={row.jewelrySubType}
                                                                onBlur={handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange(index, "jewelrySubType", e.target.value)
                                                                }
                                                                onFocus={() => handleFocus(`jewelrySubType-${index}`)}
                                                            />
                                                            {focusedInput === `jewelrySubType-${index}` && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "calc(100% + 5px)",
                                                                        left: 0,
                                                                        width: "100%",
                                                                        maxHeight: "300px",
                                                                        overflowY: "auto",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor: "white",
                                                                        zIndex: 1000,
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                    className="scrollable"
                                                                    onMouseDown={(e) => e.preventDefault()}
                                                                >
                                                                    {row.jewelryType === "BRACELET" &&
                                                                        ["Station", "Tennis", "Bangle"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jewelrySubType.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(
                                                                                            index,
                                                                                            "jewelrySubType",
                                                                                            subtype
                                                                                        );
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "NECKLACE" &&
                                                                        ["Riviera", "Station", "Tennis", "Lariat", "Special"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jewelrySubType.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(
                                                                                            index,
                                                                                            "jewelrySubType",
                                                                                            subtype
                                                                                        );
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        [
                                                                            "Hoops",
                                                                            "Huggies",
                                                                            "Omega Hoops",
                                                                            "Studs",
                                                                            "Hanging",
                                                                            "Crawler",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jewelrySubType.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(
                                                                                            index,
                                                                                            "jewelrySubType",
                                                                                            subtype
                                                                                        );
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "ETERNITY BAND" &&
                                                                        [
                                                                            "Eternity Band",
                                                                            "Half Eternity",
                                                                            "3/4 Band",
                                                                            "5 Stones",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jewelrySubType.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(
                                                                                            index,
                                                                                            "jewelrySubType",
                                                                                            subtype
                                                                                        );
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        [
                                                                            "3 Stones",
                                                                            "3 Stones Halo",
                                                                            "Hidden Halo",
                                                                            "Semi Hidden Halo",
                                                                            "Solitaire",
                                                                            "Solitaire Diamond Shank",
                                                                            "Split Shank",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jewelrySubType.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(
                                                                                            index,
                                                                                            "jewelrySubType",
                                                                                            subtype
                                                                                        );
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "PENDANT" &&
                                                                        ["Pendant"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jewelrySubType.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(
                                                                                            index,
                                                                                            "jewelrySubType",
                                                                                            subtype
                                                                                        );
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                placeholder='Setting'
                                                                type='text'
                                                                value={row.Setting}
                                                                onBlur={handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange(index, "Setting", e.target.value)
                                                                }
                                                                onFocus={() => handleFocus(`Setting-${index}`)}
                                                            />
                                                            {focusedInput === `Setting-${index}` && (
                                                                <div
                                                                    id={`Setting-${index}`}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "calc(100% + 5px)",
                                                                        left: 0,
                                                                        width: "100%",
                                                                        maxHeight: "300px",
                                                                        overflowY: "auto",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor: "white",
                                                                        zIndex: 1000,
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                    className="scrollable"
                                                                >
                                                                    {row.jewelryType === "BRACELET" &&
                                                                        row.jewelrySubType === "Station" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "BRACELET" &&
                                                                        row.jewelrySubType === "Tennis" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            "Prong (Inclined)",
                                                                            "Bezel (Inclined)",
                                                                            "Prong",
                                                                            "Bezel",
                                                                            "Bar Set",
                                                                            "Bar Set (NS)",
                                                                            "Bar Set (WS)"
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "BRACELET" &&
                                                                        row.jewelrySubType === "Bangle" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            "Prong (Inclined)",
                                                                            "Bezel (Inclined)",
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Hoops" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Huggies" &&
                                                                        ["Prong (NS)", "Bezel (NS)", "Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Omega Hoops" &&
                                                                        ["Bezel (EW)", "Bezel (NS)", "Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Prong" &&
                                                                        ["Bezel (EW)", "Bezel (NS)", "Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Studs" &&
                                                                        [
                                                                            "Martini",
                                                                            "Prong (EW)",
                                                                            "Bezel (EW)",
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Hanging" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        row.jewelrySubType === "Crawler" &&
                                                                        ["Prong"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "NECKLACE" &&
                                                                        row.jewelrySubType === "Riviera" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "NECKLACE" &&
                                                                        row.jewelrySubType === "Station" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "NECKLACE" &&
                                                                        row.jewelrySubType === "Tennis" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            "Prong (Inclined)",
                                                                            "Bezel (Inclined)",
                                                                            ,
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "NECKLACE" &&
                                                                        row.jewelrySubType === "Lariat" &&
                                                                        ["Prong (NS)"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "PENDANT" &&
                                                                        row.jewelrySubType === "Pendant" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            ,
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "3 Stones" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "3 Stones Halo" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "Hidden Halo" &&
                                                                        ["Prong"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "Semi Hidden Halo" &&
                                                                        ["Prong"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "Solitaire" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "Solitaire Diamond Shank" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "Split Shank" &&
                                                                        ["Prong"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        row.jewelrySubType === "Special" &&
                                                                        ["Prong", "Bezel"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "ETERNITY BAND" &&
                                                                        row.jewelrySubType === "3/4 Band" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            ,
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "ETERNITY BAND" &&
                                                                        row.jewelrySubType === "5 Stones" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            ,
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "ETERNITY BAND" &&
                                                                        row.jewelrySubType === "Eternity Band" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            ,
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "ETERNITY BAND" &&
                                                                        row.jewelrySubType === "Half Eternity" &&
                                                                        [
                                                                            "Prong (EW)",
                                                                            "Prong (NS)",
                                                                            "Bezel (EW)",
                                                                            "Bezel (NS)",
                                                                            ,
                                                                            "Prong",
                                                                            "Bezel",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.Setting.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "Setting", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        <input
                                                            placeholder='Ref. Piece	'
                                                            type='text'
                                                            value={row.ref_lot}
                                                            onChange={(e) => handleChange(index, 'ref_lot', e.target.value)}
                                                        />
                                                    </td>

                                                    {/* <td className="px-0 py-1 text-center">
                                                        <input
                                                            placeholder='Shape'
                                                            type='text'
                                                            value={row.shape}
                                                            onChange={(e) => handleChange(index, 'shape', e.target.value)}
                                                        />
                                                    </td> */}

                                                    {/* <td id="showUserTablebody">
                                                        <input
                                                            placeholder='ShapeType'
                                                            value={row.shapeType}
                                                            required
                                                            onBlur={handleBlur}
                                                            onChange={(e) =>
                                                                handleChange(index, "shapeType", e.target.value)
                                                            }
                                                            onFocus={() => handleFocus(`shapeType-${index}`)}
                                                        />
                                                        {focusedInput === `shapeType-${index}` && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "calc(100% + 5px)",
                                                                    left: 20,
                                                                    width: "20%",
                                                                    maxHeight: "150px",
                                                                    overflowY: "auto",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor: "white",
                                                                    zIndex: 1000,
                                                                    padding: "10px",
                                                                    fontWeight: "700",
                                                                }}
                                                                className="scrollable"
                                                            >
                                                                {shapeOptions
                                                                    .filter((option) =>
                                                                        option
                                                                            .toLowerCase()
                                                                            .includes(row.shapeType.toLowerCase())
                                                                    )
                                                                    .map((option, idx) => (
                                                                        <div
                                                                            key={idx}
                                                                            onMouseDown={() => {
                                                                                handleChange(index, "shapeType", option);
                                                                                handleBlur();
                                                                            }}
                                                                            style={{ cursor: "pointer", padding: "5px" }}
                                                                            className="dropdown-option"
                                                                        >
                                                                            {option}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </td> */}
                                                    <td id="showUserTablebody" style={{ position: "relative" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                placeholder='ShapeType'
                                                                className='shapeTypeSku'
                                                                value={row.shapeType || ""}
                                                                required
                                                                onBlur={handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange(index, "shapeType", e.target.value)
                                                                }
                                                                onFocus={() => handleFocus(`shapeType-${index}`)}
                                                            />
                                                            {focusedInput === `shapeType-${index}` && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "calc(100% + 5px)",
                                                                        left: 0,
                                                                        width: "100%",
                                                                        maxHeight: "150px",
                                                                        overflowY: "auto",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor: "white",
                                                                        zIndex: 1000,
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                    className="scrollable"
                                                                >
                                                                    {shapeOptions
                                                                        .filter((option) =>
                                                                            option
                                                                                .toLowerCase()
                                                                                .includes((row.shapeType || "").toLowerCase())
                                                                        )
                                                                        .map((option, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                onMouseDown={() => {
                                                                                    handleChange(index, "shapeType", option);
                                                                                    handleBlur();
                                                                                }}
                                                                                style={{ cursor: "pointer", padding: "5px" }}
                                                                                className="dropdown-option"
                                                                            >
                                                                                {option}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                placeholder='Metal'
                                                                type='text'
                                                                value={row.metal}
                                                                onFocus={() => handleFocus(`metal-${index}`)}
                                                                onBlur={handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange(index, "metal", e.target.value)
                                                                }
                                                            />
                                                            {focusedInput === `metal-${index}` && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "calc(100% + 5px)",
                                                                        left: 0,
                                                                        width: "100%",
                                                                        maxHeight: "150px",
                                                                        overflowY: "auto",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor: "white",
                                                                        zIndex: 1000,
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                    className="scrollable"
                                                                >
                                                                    {["14KW", "14KY", "18KW", "18KY", "RG", "PT"]
                                                                        .filter((option) =>
                                                                            option
                                                                                .toLowerCase()
                                                                                .includes(row.metal.toLowerCase())
                                                                        )
                                                                        .map((option, idx) => (
                                                                            <div
                                                                                key={idx}
                                                                                W
                                                                                onMouseDown={() => {
                                                                                    handleChange(index, "metal", option);
                                                                                    handleBlur();
                                                                                }}
                                                                                style={{ cursor: "pointer", padding: "5px" }}
                                                                                className="dropdown-option"
                                                                            >
                                                                                {option}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                placeholder='Size'
                                                                type='text'
                                                                value={row.jew_size}
                                                                onFocus={() => handleFocus(`jew_size-${index}`)}
                                                                onBlur={handleBlur}
                                                                onChange={(e) => handleChange(index, 'jew_size', e.target.value)}
                                                            />
                                                            {focusedInput === `jew_size-${index}` && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "calc(100% + 5px)",
                                                                        left: 0,
                                                                        width: "100%",
                                                                        maxHeight: "150px", // Adjust height as needed
                                                                        overflowY: "auto",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor: "white",
                                                                        zIndex: 1000,
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                    }}
                                                                    className="scrollable"
                                                                >
                                                                    {row.jewelryType === "BRACELET" &&
                                                                        [
                                                                            "5.00 Inch",
                                                                            "5.50 Inch",
                                                                            "5.75 Inch",
                                                                            "6.00 Inch",
                                                                            "6.25 Inch",
                                                                            "6.50 Inch",
                                                                            "6.75 Inch",
                                                                            "7.00 Inch",
                                                                            "7.50 Inch",
                                                                            "8.00 Inch",
                                                                            "8.50 Inch",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jew_size.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "jew_size", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "NECKLACE" &&
                                                                        [
                                                                            "16.00 Inch",
                                                                            "16.50 Inch",
                                                                            "17.00 Inch",
                                                                            "17.50 Inch",
                                                                            "18.00 Inch",
                                                                            "24.00 Inch",
                                                                            "36.00 Inch",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jew_size.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "jew_size", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "EARRING" &&
                                                                        [
                                                                            "16.00 mm",
                                                                            "19.00 mm",
                                                                            "25.00 mm",
                                                                            "28.00 mm",
                                                                            "32.00 mm",
                                                                            "38.00 mm",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jew_size.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "jew_size", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "ETERNITY BAND" &&
                                                                        [
                                                                            "3.75 US",
                                                                            "4.00 US",
                                                                            "4.25 US",
                                                                            "4.50 US",
                                                                            "4.75 US",
                                                                            "5.00 US",
                                                                            "5.25 US",
                                                                            "5.50 US",
                                                                            "5.75 US",
                                                                            "6.00 US",
                                                                            "6.25 US",
                                                                            "6.50 US",
                                                                            "6.75 US",
                                                                            "7.00 US",
                                                                            "7.25 US",
                                                                            "7.50 US",
                                                                            "7.75 US",
                                                                            "8.00 US",
                                                                            "8.50 US",
                                                                            "9.00 US",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jew_size.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "jew_size", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "RING" &&
                                                                        [
                                                                            "3.75 US",
                                                                            "4.00 US",
                                                                            "4.25 US",
                                                                            "4.50 US",
                                                                            "4.75 US",
                                                                            "5.00 US",
                                                                            "5.25 US",
                                                                            "5.50 US",
                                                                            "5.75 US",
                                                                            "6.00 US",
                                                                            "6.25 US",
                                                                            "6.50 US",
                                                                            "6.75 US",
                                                                            "7.00 US",
                                                                            "7.25 US",
                                                                            "7.50 US",
                                                                            "7.75 US",
                                                                            "8.00 US",
                                                                            "8.50 US",
                                                                            "9.00 US",
                                                                        ]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jew_size.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "jew_size", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                    {row.jewelryType === "PENDANT" &&
                                                                        ["16.00 Inch", "18.00 Inch"]
                                                                            .filter((option) =>
                                                                                option
                                                                                    .toLowerCase()
                                                                                    .includes(row.jew_size.toLowerCase())
                                                                            )
                                                                            .map((subtype, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    onMouseDown={() => {
                                                                                        handleChange(index, "jew_size", subtype);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                >
                                                                                    {subtype}
                                                                                </div>
                                                                            ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        <input
                                                            placeholder='Dia Size'
                                                            type='text'
                                                            value={row.dia_size}
                                                            onChange={(e) => handleChange(index, 'dia_size', e.target.value)}
                                                        />
                                                    </td>

                                                    {/* <td className="px-0 py-1 text-center">
                                                        -
                                                    </td> */}

                                                    <td id="showUserTablebody">
                                                        <i
                                                            className="far fa-comment-dots fs-5"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handleCommentClick(index)}
                                                        ></i>
                                                        {commentIndex === index && (
                                                            <textarea
                                                                className="form-control mt-2"
                                                                rows="3"
                                                                value={row.comment}
                                                                onChange={(e) => handleChange(index, 'comment', e.target.value)}
                                                            ></textarea>
                                                        )}
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        <input
                                                            placeholder='T.Qty'
                                                            type='text'
                                                            value={row.t_qty}
                                                            onChange={(e) => handleChange(index, 't_qty', e.target.value)}
                                                        />
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        <input
                                                            type='date'
                                                            value={row.Due_Date}
                                                            onChange={(e) => handleChange(index, 'Due_Date', e.target.value)}
                                                        />
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    <td className="px-0 py-1 text-center">
                                                        -
                                                    </td>

                                                    {/* <td id="showUserTablebody">-</td> */}
                                                    <td id="showUserTablebody">-</td>
                                                    <td id="showUserTablebody">
                                                        <i
                                                            className="fas fa-circle-minus fs-5"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => deleteRowSubOrder(index)}
                                                        ></i>
                                                    </td>

                                                </tr>
                                            ))}

                                        </tbody>
                                        <i
                                            className="fas fa-circle-plus fs-4"
                                            id="addNewRowBtn"
                                            onClick={addRow}
                                        ></i>

                                        <div style={{ margin: "0 auto" }}>
                                            <button type="submit" id="orderNow" disabled={isSubmitting}>
                                                {isSubmitting ? "Submitting..." : "Submit"}
                                            </button>
                                        </div>
                                    </table>
                                </form>
                            </>
                        )}

                        {/* requestStatus */}
                        <MDBModal open={centredModal} setOpen={setCentredModal}>
                            <MDBModalDialog centered>
                                <MDBModalContent>
                                    <MDBModalHeader>
                                        <MDBBtn
                                            className="btn-close"
                                            color="none"
                                            onClick={closeModal}
                                        ></MDBBtn>
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <p className="text-danger fs-4 text-center fw-semibold">
                                            ARE YOU SURE?
                                        </p>
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn color="danger" onClick={closeModal}>
                                            Close
                                        </MDBBtn>
                                        <MDBBtn color="success" onClick={saveRequestChanges}>
                                            Save changes
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalTotal Dia.QtyOpen */}
                        <MDBModal open={modalOpen} setOpen={setModalOpen}>
                            <MDBModalDialog centered className='modalOpen'>
                                <MDBModalContent>
                                    {loading1 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                handleTotal_Dia_Qty("t_dia_qty");
                                            }}
                                            >
                                                <MDBModalHeader>
                                                    <h5 className="modal-title">Total Dia.Qty - B{bulkID}- J{Order_ID}</h5>
                                                    <MDBBtn className="btn-close" type="button" color="none" onClick={handleClose}></MDBBtn>
                                                </MDBModalHeader>
                                                <MDBModalBody>

                                                    <table className="table table-bordered table-responsive">
                                                        <thead id="addProductTableHead">
                                                            <tr>
                                                                <th className="p-0 py-2 fw-bold">Shape</th>
                                                                <th className="p-0 py-2 fw-bold">Dia Qty</th>
                                                                <th className="p-0 py-2 fw-bold">Dia Weight</th>
                                                                <th className="p-0 py-2 fw-bold">Measurements</th>
                                                                <th className="p-0 py-2 fw-bold">Lab Name</th>
                                                                <th className="p-0 py-2 fw-bold">Comment</th>
                                                                <th className="p-0 py-2 fw-bold">Del.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {order_Dia_Plan?.map((item, index) =>
                                                                <tr key={index}>

                                                                    <td id="showUserTablebody">
                                                                        <td id="showUserTablebody" onClick={() => handleCellClick(index, 'shape')}>
                                                                            {editCell.row === index && editCell.column === 'shape' ? (
                                                                                <input
                                                                                    type='text'
                                                                                    ref={inputRef}
                                                                                    value={item.shape}
                                                                                    onChange={(e) => handleInputTd_QtyChange(e, index, 'shape')}
                                                                                    onBlur={() => setEditCell({ row: null, column: null })}
                                                                                    required
                                                                                />
                                                                            ) : item.shape || "N/A"}
                                                                        </td>
                                                                    </td>

                                                                    <td id="showUserTablebody" onClick={() => handleCellClick(index, 'dia_qty')}>
                                                                        {editCell.row === index && editCell.column === 'dia_qty' ? (
                                                                            <input
                                                                                ref={inputRef}
                                                                                value={item.dia_qty}
                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'dia_qty')}
                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                            />
                                                                        ) : item.dia_qty || "N/A"}
                                                                    </td>

                                                                    <td id="showUserTablebody" onClick={() => handleCellClick(index, 'dia_weight')}>
                                                                        {editCell.row === index && editCell.column === 'dia_weight' ? (
                                                                            <input
                                                                                ref={inputRef}
                                                                                value={item.dia_weight}
                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'dia_weight')}
                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                            />
                                                                        ) : item.dia_weight || "N/A"}
                                                                    </td>

                                                                    <td id="showUserTablebody" onClick={() => handleCellClick(index, 'dia_size')}>
                                                                        {editCell.row === index && editCell.column === 'dia_size' ? (
                                                                            <input
                                                                                ref={inputRef}
                                                                                value={item.dia_size}
                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'dia_size')}
                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                            />
                                                                        ) : item.dia_size || "N/A"}
                                                                    </td>

                                                                    <td
                                                                        id="showUserTablebody"
                                                                        onClick={(e) =>
                                                                            handleDropdownLabNameClick(e, index, item.id)
                                                                        }
                                                                        style={{
                                                                            color:
                                                                                openDropdownLabNameIndex === index
                                                                                    ? "black"
                                                                                    : !item.id || !item.lab_name
                                                                                        ? "orange"
                                                                                        : item.lab_name === "IGI"
                                                                                            ? "green"
                                                                                            : item.lab_name === "GIA"
                                                                                                ? "blue"
                                                                                                : item.lab_name === "NON CERT"
                                                                                                    ? "red"
                                                                                                    : "inherit",
                                                                        }}
                                                                    >
                                                                        {openDropdownLabNameIndex === index ? (
                                                                            <select
                                                                                ref={dropdownRef}
                                                                                value={item.lab_name}
                                                                                onChange={(e) =>
                                                                                    handleLabNameChange(e, index)
                                                                                }
                                                                                onBlur={() => setOpenDropdownMFGIndex(null)}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                <option value="IGI">IGI</option>
                                                                                <option value="GIA">GIA</option>
                                                                                <option value="NON CERT">NON CERT</option>
                                                                            </select>
                                                                        ) : (
                                                                            item.lab_name || "select"
                                                                        )}
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <CommentIcon
                                                                            onClick={() => handleOpenCommentModal(index)}
                                                                            style={{ color: "#008000", cursor: "pointer" }}
                                                                        />
                                                                        <MDBModal open={modalCommentOpen === index} tabIndex="-1">
                                                                            <MDBModalDialog centered>
                                                                                <MDBModalContent>
                                                                                    <MDBModalHeader>
                                                                                        <h5 className="fw-bold text-start">Comment - B{item.id}</h5>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn-close"
                                                                                            onClick={handleCloseCommentModal}
                                                                                            aria-label="Close"
                                                                                        ></button>
                                                                                    </MDBModalHeader>
                                                                                    <MDBModalBody className="text-center">
                                                                                        {editCell.row === index && editCell.column === 'comment' ? (
                                                                                            <input
                                                                                                value={item.comment || ""}
                                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'comment')}
                                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                                            />
                                                                                        ) : (
                                                                                            <span>{item.comment || "N/A"}</span>
                                                                                        )}
                                                                                    </MDBModalBody>
                                                                                    <MDBModalFooter>
                                                                                        <MDBBtn color="secondary" type='button' onClick={handleCloseCommentModal}>
                                                                                            Close
                                                                                        </MDBBtn>
                                                                                    </MDBModalFooter>
                                                                                </MDBModalContent>
                                                                            </MDBModalDialog>
                                                                        </MDBModal>
                                                                    </td>

                                                                    <td id="showUserTablebody" >
                                                                        <DeleteForeverIcon
                                                                            onClick={() => handleDeleteRow(index, item.id)}
                                                                        />
                                                                    </td>

                                                                </tr>
                                                            )}
                                                            {rowsTotalDiaQty?.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td id="showUserTablebody" style={{ position: "relative" }}>
                                                                        <div style={{ position: "relative" }}>
                                                                            <input
                                                                                type='text'
                                                                                placeholder='Shape'
                                                                                value={row?.shape}
                                                                                onBlur={handleBlur}
                                                                                onChange={(e) =>
                                                                                    handleTotalDiaQtyChange(index, "shape", e.target.value)
                                                                                }
                                                                                onFocus={() => handleFocus(`shape-${index}`)}
                                                                                required
                                                                            />
                                                                            {focusedInput === `shape-${index}` && (
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: "calc(100% + 5px)",
                                                                                        left: 0,
                                                                                        width: "100%",
                                                                                        maxHeight: "150px",
                                                                                        overflowY: "auto",
                                                                                        border: "1px solid #ccc",
                                                                                        backgroundColor: "white",
                                                                                        zIndex: 1000,
                                                                                        padding: "10px",
                                                                                        fontWeight: "700",
                                                                                    }}
                                                                                    className="scrollable"
                                                                                >
                                                                                    {shapeOptions?.filter((option) =>
                                                                                        option
                                                                                            .toLowerCase()
                                                                                            .includes(row.shape.toLowerCase())
                                                                                    )
                                                                                        ?.map((option, idx) => (
                                                                                            <div
                                                                                                key={idx}
                                                                                                onMouseDown={() => {
                                                                                                    handleTotalDiaQtyChange(index, "shape", option);
                                                                                                    handleBlur();
                                                                                                }}
                                                                                                style={{ cursor: "pointer", padding: "5px" }}
                                                                                                className="dropdown-option"
                                                                                                required
                                                                                            >
                                                                                                {option}
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <input
                                                                            placeholder='Dia Qty'
                                                                            value={row?.dia_qty}
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].dia_qty = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                            required
                                                                        />
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <input
                                                                            placeholder='Dia Weight'
                                                                            value={row?.dia_weight}
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].dia_weight = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                            required
                                                                        />
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <input
                                                                            placeholder='Dia Size'
                                                                            value={row?.dia_size}
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].dia_size = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                            required
                                                                        />
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <select
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].lab_name = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            <option value="IGI">IGI</option>
                                                                            <option value="GIA">GIA</option>
                                                                            <option value="NON CERT">NON CERT</option>
                                                                        </select>
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <i
                                                                            className="far fa-comment-dots fs-5"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => handleCommentTotalQtyClick(index)}
                                                                        ></i>
                                                                        {commentTotalQtyIndex === index && (
                                                                            <textarea
                                                                                className="form-control mt-2"
                                                                                rows="3"
                                                                                value={row?.comment}
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...rowsTotalDiaQty];
                                                                                    updatedRows[index].comment = e.target.value;
                                                                                    setRowsTotalDiaQty(updatedRows);
                                                                                }}
                                                                            ></textarea>
                                                                        )}
                                                                    </td>

                                                                    <td id="showUserTablebody">
                                                                        <i
                                                                            className="fas fa-circle-minus fs-5"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => deleteRow(index)}
                                                                        ></i>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <i
                                                        className="fas fa-circle-plus fs-4"
                                                        id="addNewRowBtn"
                                                        onClick={addRowTotalDiaQty}
                                                    ></i>
                                                    <div className='d-flex justify-content-between'>
                                                        <span className='fw-bold'>Total:</span>
                                                        <span className='fw-bold'>T.Dia Qty: {order_Dia_Plan[order_Dia_Plan.length - 1]?.totalDiaQty || 0}</span>
                                                    </div>

                                                </MDBModalBody>
                                                <MDBModalFooter>
                                                    <MDBBtn color="secondary" disabled={isSubmitting}>
                                                        {isSubmitting ? "Submitting..." : "Submit"}
                                                    </MDBBtn>
                                                    <MDBBtn color="secondary" type="button" onClick={handleClose}>Close</MDBBtn>
                                                </MDBModalFooter>
                                            </form>
                                        </div>
                                    )}
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalPolishOpen */}
                        <MDBModal open={modalPolishOpen} setOpen={setModalPolishOpen}>
                            <MDBModalDialog centered className='modalPolishOpen'>
                                <MDBModalContent>
                                    {loading1 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <MDBModalHeader>
                                                <h5 className="modal-title">Dia Polish & Mfg Rough - B{bulkID}- J{Order_ID}</h5>
                                                <MDBBtn className="btn-close" color="none" type="button" onClick={handlePolishClose}></MDBBtn>
                                            </MDBModalHeader>
                                            <MDBModalBody>
                                                <table className="table table-bordered table-responsive w-100">
                                                    <thead id="addProductTableHead">
                                                        <tr>
                                                            <th className="p-0 py-2 fw-bold">Shape</th>
                                                            <th className="p-0 py-2 fw-bold">Dia Qty</th>
                                                            <th className="p-0 py-2 fw-bold">Dia Weight</th>
                                                            <th className="p-0 py-2 fw-bold">Measurements</th>
                                                            <th className="p-0 py-2 fw-bold">Lab Name</th>
                                                            <th className="p-0 py-2 fw-bold">Comment</th>
                                                            <th className="p-0 py-2 fw-bold">Polish Qty</th>

                                                            <th className="p-0 py-2 fw-bold">Mfg Short</th>
                                                            <th className="p-0 py-2 fw-bold">New Mfg</th>
                                                            <th className="p-0 py-2 fw-bold">Mfg Status</th>

                                                            <th className="p-0 py-2 fw-bold">Rough Short</th>
                                                            <th className="p-0 py-2 fw-bold">Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {order_Dia_Plan.map((item, index) => (
                                                            <tr key={index}>
                                                                <td id="showUserTablebody">{item.shape || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.dia_qty || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.dia_weight || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.dia_size || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.lab_name}</td>
                                                                <td id="showUserTablebody">
                                                                    <CommentIcon
                                                                        onClick={() => handleOpenCommentModal(index)}
                                                                        style={{ color: "#008000", cursor: "pointer" }}
                                                                    />
                                                                    <MDBModal open={modalCommentOpen === index}>
                                                                        <MDBModalDialog centered>
                                                                            <MDBModalContent>
                                                                                <MDBModalHeader>
                                                                                    <h5 className="fw-bold text-start">Comment - B{item.id}</h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        onClick={handleCloseCommentModal}
                                                                                        aria-label="Close"
                                                                                    ></button>
                                                                                </MDBModalHeader>
                                                                                <MDBModalBody className="text-center">
                                                                                    <span>{item.comment || "N/A"}</span>
                                                                                </MDBModalBody>
                                                                                <MDBModalFooter>
                                                                                    <MDBBtn color="secondary" type='button' onClick={handleCloseCommentModal}>
                                                                                        Close
                                                                                    </MDBBtn>
                                                                                </MDBModalFooter>
                                                                            </MDBModalContent>
                                                                        </MDBModalDialog>
                                                                    </MDBModal>
                                                                </td>

                                                                <td id="showUserTablebody" onClick={() => handleCellClick(index, 'in_stock')} style={{ width: "12%" }}>
                                                                    {editCell.row === index && editCell.column === 'in_stock' ? (
                                                                        <input
                                                                            ref={inputRef}
                                                                            type="number"
                                                                            value={item.in_stock || ""}
                                                                            onChange={(e) => handleInputPolishChange(e, index, "in_stock")}
                                                                            onBlur={() => setEditCell({ row: null, column: null })}
                                                                            autoFocus
                                                                            required
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            {item.in_stock !== 0 ? item.in_stock :
                                                                                <input
                                                                                    type="number"
                                                                                    placeholder="Polish Avail"
                                                                                    onChange={(e) => handleInputPolishChange(e, index)}
                                                                                    required
                                                                                />
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </td>

                                                                <td id="showUserTablebody">{item.mfg_short}</td>

                                                                <td id="showUserTablebody" onClick={() => handleCellClick(index, 'new_mfg')}>
                                                                    {editCell.row === index && editCell.column === 'new_mfg' ? (
                                                                        <input
                                                                            ref={inputRef}
                                                                            type="number"
                                                                            value={item.new_mfg || ""}
                                                                            placeholder="New MFG"
                                                                            onChange={(e) => handleInputNewMFGChange(e, index, "new_mfg")}
                                                                            onBlur={() => setEditCell({ row: null, column: null })}
                                                                            autoFocus
                                                                            required
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            {item.new_mfg !== null ? item.new_mfg :
                                                                                <input
                                                                                    type="number"
                                                                                    placeholder="New MFG"
                                                                                    onChange={(e) => handleInputNewMFGChange(e, index)}
                                                                                    required
                                                                                />
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </td>

                                                                <td id="showUserTablebody" onClick={() => handleCellClick(index, 'mfg_status_jewelery')}>
                                                                    {editCell.row === index && editCell.column === 'mfg_status_jewelery' ? (
                                                                        <input
                                                                            ref={inputRef}
                                                                            type="text"
                                                                            value={item.mfg_status_jewelery}
                                                                            placeholder="MFG Status"
                                                                            onChange={(e) => handleInputMFGStatusChange(e, index, "mfg_status_jewelery")}
                                                                            onBlur={() => setEditCell({ row: null, column: null })}
                                                                            autoFocus
                                                                            required
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            <i
                                                                                className="far fa-comment-dots fs-5"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => handleCommentMFGStatusClick(index)}
                                                                            ></i>
                                                                            {mfg_statusIndex === index && (
                                                                                <textarea
                                                                                    className="form-control mt-2"
                                                                                    rows="3"
                                                                                    value={item.mfg_status_jewelery || ""}
                                                                                    onChange={(e) => {
                                                                                        const updatedRows = [...rowsTotalDiaQty];
                                                                                        updatedRows[index].mfg_status_jewelery = e.target.value;
                                                                                        setRowsTotalDiaQty(updatedRows);
                                                                                    }}
                                                                                ></textarea>
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </td>

                                                                <td id="showUserTablebody">{item.rough_short}</td>
                                                                <td id="showUserTablebody" >
                                                                    <DeleteForeverIcon
                                                                        onClick={() => handleDeleteRow(index, item.id)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                <div className='d-flex justify-content-between'>
                                                    <span className='fw-bold'>Total:</span>
                                                    <span className='fw-bold'>T.Dia Qty: {order_Dia_Plan[order_Dia_Plan.length - 1]?.totalDiaQty || 0}</span>
                                                    <span className='fw-bold'>Total Polish Qty:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalPolishQty || 0}</span>
                                                    <span className='fw-bold'>Total MFG Short:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalMfgShort || 0}</span>
                                                    <span className='fw-bold'>Total New MFG:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalNewMfg || 0}</span>
                                                    <span className='fw-bold'>Rough Short:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalRoughShort || 0}</span>
                                                </div>
                                            </MDBModalBody>
                                            <MDBModalFooter>
                                                <MDBBtn color="secondary" onClick={() => handleTotal_Dia_Qty("in_stock")}>
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </MDBBtn>
                                                <MDBBtn color="secondary" type="button" onClick={handlePolishClose}>Close</MDBBtn>
                                            </MDBModalFooter>
                                        </div>
                                    )}
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalRoughOpen */}
                        <MDBModal open={modalRoughOpen} setOpen={setModalRoughOpen}>
                            <MDBModalDialog centered className='modalRoughOpen'>
                                <MDBModalContent>
                                    {loading1 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <MDBModalHeader>
                                                <h5 className="modal-title">Dia Polish Purchase - B{bulkID}- J{Order_ID}</h5>
                                                <MDBBtn className="btn-close" type="button" color="none" onClick={handleRoughClose}></MDBBtn>
                                            </MDBModalHeader>
                                            <MDBModalBody>
                                                <table className="table table-bordered table-responsive w-100">
                                                    <thead id="addProductTableHead">
                                                        <tr>
                                                            <th className="p-0 py-2 fw-bold">Shape</th>
                                                            <th className="p-0 py-2 fw-bold">Dia Qty</th>
                                                            <th className="p-0 py-2 fw-bold">Dia Weight</th>
                                                            <th className="p-0 py-2 fw-bold">Measurements</th>
                                                            <th className="p-0 py-2 fw-bold">Lab Name</th>
                                                            <th className="p-0 py-2 fw-bold">Comment</th>

                                                            {/* <th className="p-0 py-2 fw-bold">Polish Avail</th>
                                                            <th className="p-0 py-2 fw-bold">Rough Short</th> */}
                                                            <th className="p-0 py-2 fw-bold">Purchase Short</th>
                                                            <th className="p-0 py-2 fw-bold">Purchase Qty</th>
                                                            <th className="p-0 py-2 fw-bold">Purchase Pending</th>
                                                            {/* <th className="p-0 py-2 fw-bold">Rough Short Date</th> */}
                                                            <th className="p-0 py-2 fw-bold">Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {order_Dia_Plan.map((item, index) => (
                                                            <tr key={index}>
                                                                <td id="showUserTablebody">{item.shape || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.dia_qty || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.dia_weight || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.dia_size || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.lab_name || "N/A"}</td>
                                                                <td id="showUserTablebody">
                                                                    <CommentIcon
                                                                        onClick={() => handleOpenCommentModal(index)}
                                                                        style={{ color: "#008000", cursor: "pointer" }}
                                                                    />
                                                                    <MDBModal open={modalCommentOpen === index} tabIndex="-1">
                                                                        <MDBModalDialog centered>
                                                                            <MDBModalContent>
                                                                                <MDBModalHeader>
                                                                                    <h5 className="fw-bold text-start">Comment - B{item.id}</h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        onClick={handleCloseCommentModal}
                                                                                        aria-label="Close"
                                                                                    ></button>
                                                                                </MDBModalHeader>
                                                                                <MDBModalBody className="text-center">
                                                                                    <span>{item.comment || "N/A"}</span>
                                                                                </MDBModalBody>
                                                                                <MDBModalFooter>
                                                                                    <MDBBtn color="secondary" type='button' onClick={handleCloseCommentModal}>
                                                                                        Close
                                                                                    </MDBBtn>
                                                                                </MDBModalFooter>
                                                                            </MDBModalContent>
                                                                        </MDBModalDialog>
                                                                    </MDBModal>
                                                                </td>

                                                                {/* <td id="showUserTablebody">{item.in_stock || "N/A"}</td>
                                                                <td id="showUserTablebody">{item.rough_short}</td> */}

                                                                <td id="showUserTablebody" onClick={() => handleCellClick(index, 'purchase_short')}>
                                                                    {editCell.row === index && editCell.column === 'purchase_short' ? (
                                                                        <input
                                                                            ref={inputRef}
                                                                            type="number"
                                                                            value={item.purchase_short || ""}
                                                                            placeholder="Purchase Short"
                                                                            onChange={(e) => handleInputPurchase_ShortChange(e, index, "purchase_short")}
                                                                            onBlur={() => setEditCell({ row: null, column: null })}
                                                                            autoFocus
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            {item.purchase_short === null ?
                                                                                <input
                                                                                    type="number"
                                                                                    placeholder="Purchase Short"
                                                                                    onChange={(e) => {
                                                                                        const updatedRows = [...order_Dia_Plan];
                                                                                        updatedRows[index].purchase_short = e.target.value;
                                                                                    }}
                                                                                />
                                                                                :
                                                                                item.purchase_short
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </td>

                                                                <td id="showUserTablebody" onClick={() => handleCellClick(index, 'rs_purchase')}>
                                                                    {editCell.row === index && editCell.column === 'rs_purchase' ? (
                                                                        <input
                                                                            ref={inputRef}
                                                                            type="number"
                                                                            value={item.rs_purchase || ""}
                                                                            placeholder="Dia.Purchase"
                                                                            onChange={(e) => handleInputRoughPurchaseChange(e, index, "rs_purchase")}
                                                                            onBlur={() => setEditCell({ row: null, column: null })}
                                                                            autoFocus
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            {item.rs_purchase === null ?
                                                                                <input
                                                                                    type="number"
                                                                                    placeholder="Dia.Purchase"
                                                                                    onChange={(e) => {
                                                                                        const updatedRows = [...order_Dia_Plan];
                                                                                        updatedRows[index].rs_purchase = e.target.value;
                                                                                    }}
                                                                                />
                                                                                :
                                                                                item.rs_purchase
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </td>

                                                                <td id="showUserTablebody">{item.purchase_pending || 0}</td>

                                                                {/* <td id="showUserTablebody" onClick={() => handleCellClick(index, 'rough_short_date')}>
                                                                    {editCell.row === index && editCell.column === 'rough_short_date' ? (
                                                                        <input
                                                                            ref={inputRef}
                                                                            type="date"
                                                                            value={item.rough_short_date || ""}
                                                                            onChange={(e) => handleInputRoughS_dateChange(e, index, "rough_short_date")}
                                                                            onBlur={() => setEditCell({ row: null, column: null })}
                                                                            autoFocus
                                                                        />
                                                                    ) : (
                                                                        <span>
                                                                            {item.rough_short_date === null ?
                                                                                <input
                                                                                    type="date"
                                                                                    onChange={(e) => {
                                                                                        const updatedRows = [...order_Dia_Plan];
                                                                                        updatedRows[index].rough_short_date = e.target.value;
                                                                                    }}
                                                                                />
                                                                                :
                                                                                item.rough_short_date
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </td> */}
                                                                <td id="showUserTablebody">
                                                                    <DeleteForeverIcon
                                                                        onClick={() => handleDeleteRow(index, item.id)}
                                                                    />
                                                                </td>

                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>


                                                <div className='d-flex justify-content-between'>
                                                    <span className='fw-bold'>Total:</span>
                                                    <span className='fw-bold'>T.Dia Qty: {order_Dia_Plan[order_Dia_Plan.length - 1]?.totalDiaQty || 0}</span>
                                                    <span className='fw-bold'>Total PurchaseShort:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalPurchaseShort || 0}</span>
                                                    <span className='fw-bold'>Total PurchaseQty:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalPurchaseQty || 0}</span>
                                                    <span className='fw-bold'>Total PurchasePending:{order_Dia_Plan[order_Dia_Plan.length - 1]?.totalPurchasePending || 0}</span>
                                                </div>
                                            </MDBModalBody>
                                            <MDBModalFooter>
                                                <MDBBtn color="secondary" onClick={() => handleTotal_Dia_Qty("rough_short")}>
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </MDBBtn>
                                                <MDBBtn color="secondary" type="button" onClick={handleRoughClose}>Close</MDBBtn>
                                            </MDBModalFooter>
                                        </div>
                                    )}
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalMFGOpen */}
                        <MDBModal open={modalMFGOpen} setOpen={setModalMFGOpen}>
                            <MDBModalDialog centered className='modalMFGOpen'>
                                <MDBModalContent>
                                    {loading1 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                handleTotal_Dia_Qty("mfg");
                                            }}
                                            >
                                                <MDBModalHeader>
                                                    <h5 className="modal-title">MFG - B{bulkID}- J{Order_ID}</h5>
                                                    <MDBBtn className="btn-close" color="none" type="button" onClick={handleMFGClose}></MDBBtn>
                                                </MDBModalHeader>
                                                <MDBModalBody>
                                                    <table className="table table-bordered table-responsive w-100">
                                                        <thead id="addProductTableHead">
                                                            <tr>
                                                                <th className="p-0 py-2 fw-bold">Date</th>
                                                                <th className="p-0 py-2 fw-bold">Processing MFG</th>
                                                                <th className="p-0 py-2 fw-bold">Status</th>
                                                                <th className="p-0 py-2 fw-bold">Pending MFG</th>
                                                                <th className="p-0 py-2 fw-bold">New MFG Due_Date</th>
                                                                <th className="p-0 py-2 fw-bold">New MFG Day</th>
                                                                <th className="p-0 py-2 fw-bold">Del.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {order_Dia_Plan?.map((item, index) =>
                                                                <tr key={index}>

                                                                    <td id="showUserTablebody">{formatDate(item.created_at)}</td>

                                                                    <td id="showUserTablebody" onClick={() => handleCellClick(index, 'pocessing_mfg')}>
                                                                        {editCell.row === index && editCell.column === 'pocessing_mfg' ? (
                                                                            <input
                                                                                type='text'
                                                                                ref={inputRef}
                                                                                value={item.pocessing_mfg}
                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'pocessing_mfg')}
                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                                required
                                                                            />
                                                                        ) : item.pocessing_mfg || "N/A"}
                                                                    </td>

                                                                    <td
                                                                        id="showUserTablebody"
                                                                        onClick={(e) =>
                                                                            handleDropdownMFGClick(e, index, item.id)
                                                                        }
                                                                        style={{
                                                                            color:
                                                                                openDropdownMFGIndex === index
                                                                                    ? "black"
                                                                                    : !item.id || !item.mfg_status
                                                                                        ? "orange"
                                                                                        : item.mfg_status === "Wax & Cast"
                                                                                            ? "green"
                                                                                            : item.mfg_status === "Filing"
                                                                                                ? "blue"
                                                                                                : item.mfg_status === "Pre Polishing"
                                                                                                    ? "#9A208C"
                                                                                                    : item.mfg_status === "Assembly Filling"
                                                                                                        ? "#4FA095"
                                                                                                        : item.mfg_status === "Diamond"
                                                                                                            ? "#4FA095"
                                                                                                            : item.mfg_status === "Metal Setting"
                                                                                                                ? "#4FA095"
                                                                                                                : item.mfg_status === "Final Polishing"
                                                                                                                    ? "#4FA095"
                                                                                                                    : item.mfg_status === "Finished Good"
                                                                                                                        ? "#4FA095"
                                                                                                                        : item.mfg_status === "Hold"
                                                                                                                            ? "#096C87"
                                                                                                                            : item.mfg_status === "Export"
                                                                                                                                ? "#000000"
                                                                                                                                : item.mfg_status === "Canceled"
                                                                                                                                    ? "red"
                                                                                                                                    : "inherit",
                                                                        }}
                                                                    >
                                                                        {openDropdownMFGIndex === index ? (
                                                                            <select
                                                                                ref={dropdownRef}
                                                                                value={item.mfg_status}
                                                                                onChange={(e) =>
                                                                                    handleMFGStatusChange(e, index)
                                                                                }
                                                                                onBlur={() => setOpenDropdownMFGIndex(null)}
                                                                            >
                                                                                <option value="Pending">Pending</option>
                                                                                <option value="Wax & Cast">Wax & Cast</option>
                                                                                <option value="Filing">Filing</option>
                                                                                <option value="Pre Polishing">
                                                                                    Pre Polishing
                                                                                </option>
                                                                                <option value="Assembly Filling">
                                                                                    Assembly Filling
                                                                                </option>
                                                                                <option value="Diamond">Diamond</option>
                                                                                <option value="Metal Setting">
                                                                                    Metal Setting
                                                                                </option>
                                                                                <option value="Final Polishing">
                                                                                    Final Polishing
                                                                                </option>
                                                                                <option value="Finished Good">
                                                                                    Finished Good
                                                                                </option>
                                                                                <option value="Hold">Hold</option>
                                                                                <option value="Export">Export</option>
                                                                                <option value="Canceled">Canceled</option>
                                                                            </select>
                                                                        ) : (
                                                                            item.mfg_status || "Pending"
                                                                        )}
                                                                    </td>


                                                                    <td id="showUserTablebody">{(item.pending_mfg || 0)}</td>

                                                                    <td id="showUserTablebody" onClick={() => handleCellClick(index, 'mfg_due_date')}>
                                                                        {editCell.row === index && editCell.column === 'mfg_due_date' ? (
                                                                            <input
                                                                                type='date'
                                                                                ref={inputRef}
                                                                                value={item.mfg_due_date}
                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'mfg_due_date')}
                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                                required
                                                                            />
                                                                        ) : item.mfg_due_date || "N/A"}
                                                                    </td>

                                                                    <td id="showUserTablebody">{(item.pending_days || 0)}</td>

                                                                    <td id="showUserTablebody" >
                                                                        <DeleteForeverIcon
                                                                            onClick={() => handleDeleteJewRow(index, item.id)}
                                                                        />
                                                                    </td>

                                                                </tr>
                                                            )}

                                                            {rowsTotalDiaQty?.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td id="showUserTablebody">-</td>
                                                                    <td id="showUserTablebody">
                                                                        <input
                                                                            type='number'
                                                                            placeholder='Processing MFG'
                                                                            value={row?.pocessing_mfg}
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].pocessing_mfg = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                            required
                                                                        />
                                                                    </td>

                                                                    <td id="showUserTablebody">-</td>

                                                                    <td id="showUserTablebody">-</td>
                                                                    <td id="showUserTablebody" style={{ width: "12%" }}>
                                                                        <input
                                                                            type='date'
                                                                            value={row?.mfg_due_date}
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].mfg_due_date = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                            required
                                                                        />
                                                                    </td>

                                                                    <td id="showUserTablebody">-</td>

                                                                    <td id='showUserTablebody'>
                                                                        <i
                                                                            className="fas fa-circle-minus fs-5"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => deleteRow(index)}
                                                                        ></i>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <i
                                                        className="fas fa-circle-plus fs-4"
                                                        id="addNewRowBtn"
                                                        onClick={addRowTotalDiaQty}
                                                    ></i>

                                                    <div className='d-flex justify-content-between'>
                                                        <span>Total</span>
                                                        <span className='fw-bold'>Total PocessingMFG:{order_Dia_Plan[order_Dia_Plan.length - 1]?.total_pocessing_mfg || 0}</span>
                                                    </div>
                                                </MDBModalBody>
                                                <MDBModalFooter>
                                                    <MDBBtn type="submit" color="secondary" disabled={isSubmitting}>
                                                        {isSubmitting ? "Submitting..." : "Submit"}
                                                    </MDBBtn>
                                                    <MDBBtn color="secondary" type="button" onClick={handleMFGClose}>Close</MDBBtn>
                                                </MDBModalFooter>
                                            </form>
                                        </div>
                                    )}
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalFGOpen */}
                        <MDBModal open={modalFGOpen} setOpen={setModalFGOpen}>
                            <MDBModalDialog centered className='modalFGOpen'>
                                <MDBModalContent>
                                    {loading1 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                handleTotal_Dia_Qty("fg");
                                            }}
                                            >
                                                <MDBModalHeader>
                                                    <h5 className="modal-title">FG - B{bulkID}- J{Order_ID}</h5>
                                                    <MDBBtn className="btn-close" type="button" color="none" onClick={handleFGClose}></MDBBtn>
                                                </MDBModalHeader>
                                                <MDBModalBody>
                                                    <table className="table table-bordered table-responsive w-100">
                                                        <thead id="addProductTableHead">
                                                            <tr>
                                                                <th className="p-0 py-2 fw-bold">Date</th>
                                                                <th className="p-0 py-2 fw-bold">Finish Goods Qty</th>
                                                                <th className="p-0 py-2 fw-bold">Pending FG</th>
                                                                <th className="p-0 py-2 fw-bold">Del.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {order_Dia_Plan.map((item, index) =>
                                                                <tr key={index}>

                                                                    <td id="showUserTablebody">{formatDate(item.created_at)}</td>

                                                                    <td id="showUserTablebody" onClick={() => handleCellClick(index, 'finish_goods_qty')}>
                                                                        {editCell.row === index && editCell.column === 'finish_goods_qty' ? (
                                                                            <input
                                                                                type='text'
                                                                                ref={inputRef}
                                                                                placeholder='Finish Goods Qty'
                                                                                value={item.finish_goods_qty}
                                                                                onChange={(e) => handleInputTd_QtyChange(e, index, 'finish_goods_qty')}
                                                                                onBlur={() => setEditCell({ row: null, column: null })}
                                                                                required
                                                                            />
                                                                        ) : item.finish_goods_qty || "N/A"}
                                                                    </td>

                                                                    <td id="showUserTablebody">{item.pending_fg}</td>

                                                                    <td id="showUserTablebody" >
                                                                        <DeleteForeverIcon
                                                                            onClick={() => handleDeleteJewRow(index, item.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {rowsTotalDiaQty?.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td id="showUserTablebody">-</td>

                                                                    <td id="showUserTablebody">
                                                                        <input
                                                                            type='number'
                                                                            placeholder='Finish Goods Qty'
                                                                            value={row?.finish_goods_qty}
                                                                            onChange={(e) => {
                                                                                const updatedRows = [...rowsTotalDiaQty];
                                                                                updatedRows[index].finish_goods_qty = e.target.value;
                                                                                setRowsTotalDiaQty(updatedRows);
                                                                            }}
                                                                            required
                                                                        />
                                                                    </td>

                                                                    <td id="showUserTablebody">-</td>

                                                                    <td id='showUserTablebody'>
                                                                        <i
                                                                            className="fas fa-circle-minus fs-5"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => deleteRow(index)}
                                                                        ></i>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <i
                                                        className="fas fa-circle-plus fs-4"
                                                        id="addNewRowBtn"
                                                        onClick={addRowTotalDiaQty}
                                                    ></i>

                                                    <div className='d-flex justify-content-between'>
                                                        <span className='fw-bold'>Total:</span>
                                                        <span className='fw-bold'>Total FinishgoodsQty:{order_Dia_Plan[order_Dia_Plan.length - 1]?.total_finish_goods_qty || 0}</span>
                                                    </div>
                                                </MDBModalBody>
                                                <MDBModalFooter>
                                                    <MDBBtn color="secondary">Submit</MDBBtn>
                                                    <MDBBtn color="secondary" type="button" onClick={handleFGClose}>Close</MDBBtn>
                                                </MDBModalFooter>
                                            </form>
                                        </div>
                                    )}
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalExportOpen */}
                        <MDBModal open={modalExportOpen} setOpen={setModalExportOpen}>
                            <MDBModalDialog centered className='modalExportOpen'>
                                <MDBModalContent>
                                    {/* {loading1 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="loader"></div>
                                        </div>
                                    ) : (
                                        <div> */}
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleTotal_Dia_Qty("export");
                                    }}
                                    >
                                        <MDBModalHeader>
                                            <h5 className="modal-title">Export - B{bulkID}- J{Order_ID}</h5>
                                            <MDBBtn className="btn-close" type="button" color="none" onClick={handleModalExportClose}></MDBBtn>
                                        </MDBModalHeader>
                                        <MDBModalBody>
                                            <table className="table table-bordered table-responsive w-100">
                                                <thead id="addProductTableHead">
                                                    <tr>
                                                        <th className="p-0 py-2 fw-bold">Date</th>
                                                        <th className="p-0 py-2 fw-bold">Export No</th>
                                                        <th className="p-0 py-2 fw-bold">Export Qty</th>
                                                        <th className="p-0 py-2 fw-bold">Pending Export</th>
                                                        <th className="p-0 py-2 fw-bold">Del.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order_Dia_Plan.map((item, index) =>
                                                        <tr key={index}>

                                                            <td id="showUserTablebody">{formatDate(item.created_at)}</td>

                                                            <td id="showUserTablebody" onClick={() => handleCellClick(index, 'export_no')}>
                                                                {editCell.row === index && editCell.column === 'export_no' ? (
                                                                    <input
                                                                        type='text'
                                                                        ref={inputRef}
                                                                        placeholder='Export No'
                                                                        value={item.export_no}
                                                                        onChange={(e) => handleInputTd_QtyChange(e, index, 'export_no')}
                                                                        onBlur={() => setEditCell({ row: null, column: null })}
                                                                        required
                                                                    />
                                                                ) : item.export_no || "N/A"}
                                                            </td>

                                                            <td id="showUserTablebody" onClick={() => handleCellClick(index, 'export_qty')}>
                                                                {editCell.row === index && editCell.column === 'export_qty' ? (
                                                                    <input
                                                                        type='text'
                                                                        ref={inputRef}
                                                                        value={item.export_qty}
                                                                        placeholder='Export Qty'
                                                                        onChange={(e) => handleInputTd_QtyChange(e, index, 'export_qty')}
                                                                        onBlur={() => setEditCell({ row: null, column: null })}
                                                                        required
                                                                    />
                                                                ) :
                                                                    <span className='cursor-pointer' onClick={() => handleExportQtyClick(item.id, item.export_qty)}>{item.export_qty || "N/A"}</span>
                                                                }
                                                            </td>

                                                            <td id="showUserTablebody">{item.pending_export}</td>

                                                            <td id="showUserTablebody" >
                                                                <DeleteForeverIcon
                                                                    onClick={() => handleDeleteJewRow(index, item.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {rowsTotalDiaQty?.map((row, index) => (
                                                        <tr key={index}>
                                                            <td id="showUserTablebody">-</td>
                                                            <td id="showUserTablebody" style={{ width: "20%" }}>
                                                                <input
                                                                    type='text'
                                                                    placeholder='Export No'
                                                                    value={row?.export_no}
                                                                    onChange={(e) => {
                                                                        const updatedRows = [...rowsTotalDiaQty];
                                                                        updatedRows[index].export_no = e.target.value;
                                                                        setRowsTotalDiaQty(updatedRows);
                                                                    }}
                                                                    required
                                                                />
                                                            </td>

                                                            <td id="showUserTablebody" className='cursor-pointer' style={{ width: "20%" }}>
                                                                <input
                                                                    type='text'
                                                                    placeholder='Export Qty'
                                                                    value={row?.export_qty}
                                                                    onChange={(e) => {
                                                                        const updatedRows = [...rowsTotalDiaQty];
                                                                        updatedRows[index].export_qty = e.target.value;
                                                                        setRowsTotalDiaQty(updatedRows);
                                                                    }}
                                                                    required
                                                                />
                                                            </td>

                                                            <td id="showUserTablebody">-</td>

                                                            <td id='showUserTablebody'>
                                                                <i
                                                                    className="fas fa-circle-minus fs-5"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => deleteRow(index)}
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <i
                                                className="fas fa-circle-plus fs-4"
                                                id="addNewRowBtn"
                                                onClick={addRowTotalDiaQty}
                                            ></i>

                                            <div className='d-flex justify-content-between'>
                                                <span className='fw-bold'>Total:</span>
                                                <span className='fw-bold'>Total ExportQty:{order_Dia_Plan[order_Dia_Plan.length - 1]?.total_export_qty || 0}</span>
                                            </div>
                                        </MDBModalBody>
                                        <MDBModalFooter>
                                            <MDBBtn color="secondary">Submit</MDBBtn>
                                            <MDBBtn type="button" color="secondary" onClick={handleModalExportClose}>Close</MDBBtn>
                                        </MDBModalFooter>
                                    </form>
                                    {/* </div>
                                    )} */}
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>

                        {/* modalExportQtyOpen */}
                        <MDBModal open={modalExportQtyOpen} setOpen={setModalExportQtyOpen}>
                            <MDBModalDialog centered className="modalExportQtyOpen">
                                <MDBModalContent>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleTotal_Dia_Qty("export_qty");
                                        }}
                                    >
                                        <MDBModalHeader>
                                            <MDBModalTitle>Export Quantity - {exportQty_Value}</MDBModalTitle>
                                            <MDBBtn
                                                type='button'
                                                className="btn-close"
                                                color="none"
                                                onClick={handleExportQtyClose}
                                            ></MDBBtn>
                                        </MDBModalHeader>
                                        <MDBModalBody>
                                            {order_Dia_Plan?.length > 0 || rowsTotalDiaQty.length > 0 ? (
                                                <table className="table table-bordered table-responsive w-100">
                                                    <thead id="addProductTableHead">
                                                        <tr>
                                                            <th className="p-0 py-1 fw-bold">Sr No.</th>
                                                            <th className="p-0 py-1 fw-bold">Lot Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {order_Dia_Plan?.reduce((rows, item, i) => {
                                                            const filteredLotnames = item.lotname?.filter(name => name !== "null") || [];
                                                            const newRows = filteredLotnames.map((name, index) => ({
                                                                id: rows.length + index + 1,
                                                                name
                                                            }));
                                                            return rows.concat(newRows);
                                                        }, []).map(row => (
                                                            <tr key={row.id}>
                                                                <td id="showUserTablebody">{row.id}</td>
                                                                <td id="showUserTablebody">{row.name}</td>
                                                            </tr>
                                                        ))}


                                                        {rowsTotalDiaQty.map((row, index) => (
                                                            <tr key={index}>
                                                                <td id="showUserTablebody">{index + 1}</td>
                                                                <td id="showUserTablebody">
                                                                    <input
                                                                        type="text"
                                                                        value={row.lotname || ""}
                                                                        placeholder="Enter Lot Name"
                                                                        onChange={(e) => handleInputLotnameChange(e, index)}
                                                                        required
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <p>No data available to display.</p>
                                            )}
                                        </MDBModalBody>
                                        <MDBModalFooter>
                                            <MDBBtn color="secondary" className="exportQtybtn" type="submit">
                                                Submit
                                            </MDBBtn>
                                            <MDBBtn
                                                type="button"
                                                color="secondary"
                                                className="exportQtybtn"
                                                onClick={handleExportQtyClose}
                                            >
                                                Close
                                            </MDBBtn>
                                        </MDBModalFooter>
                                    </form>
                                </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal>
                    </>
                )}
            </div >
        </>
    )
}