import React, { useEffect, useState } from "react";
import { MDBCheckbox } from "mdb-react-ui-kit"; // Assuming MDB React for checkboxes
import ShowPermissionTable from "./ShowPermissionTable";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/Url";

export default function PermissionTable() {
  const [selectedRecords, setSelectedRecords] = useState({});
  const [selectedShow1, setSelectedShow1] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [modulesName, setModulesName] = useState([]);
  const [permissionUser, setPermissionUser] = useState([]);
  const [permissionUserEdit, setPermissionUserEdit] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const fetchMPermission = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/mpermission`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        responseData.forEach((permission) => {
          permission.row_level = JSON.parse(permission?.row_level);
          permission.col_level = JSON.parse(permission.col_level);
        });
        setPermissionUser(responseData);
      } else {
        console.error("Error fetching remittance data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };

  useEffect(() => {
    fetchMPermission();
  }, []);

  const sendPermissionEditId = (permission) => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(permission),
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/api/mpermission/${permission.id}`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            `Failed to post status: ${response.status} ${response.statusText}`
          );
        }
      })
      .then((responseData) => {
        setPermissionUserEdit(responseData);
      })
      .catch((error) => {
        console.error("Error posting status:", error);
      });
  };

  useEffect(() => {
    fetchModuleData();
    fetchUserData();
  }, []);

  const fetchModuleData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/modules`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setModules(responseData);
      } else {
        console.error("Error fetching module data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
  };

  const columnModuleName = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/modules/${selectedModule}`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setModulesName(responseData);
      } else {
        console.error("Error fetching module data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
  };

  useEffect(() => {
    if (selectedModule) {
      columnModuleName();
    }
  }, [selectedModule]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/users`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setUsers(responseData);
      } else {
        console.error("Error fetching user data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleCheckboxChange = (event, columnName, actionType) => {
    const { checked } = event.target;
    setSelectedRecords((prevSelectedRecords) => {
      const updatedRecords = { ...prevSelectedRecords };
      if (!updatedRecords[columnName]) {
        updatedRecords[columnName] = [];
      }
      if (checked) {
        updatedRecords[columnName] = [
          ...updatedRecords[columnName],
          actionType,
        ];
      } else {
        updatedRecords[columnName] = updatedRecords[columnName].filter(
          (type) => type !== actionType
        );
      }
      return updatedRecords;
    });
  };

  const handleSelectAll = () => {
    const allRecords = {};
    modulesName.forEach((columnName) => {
      allRecords[columnName] = ["edit", "show"];
    });
    if (selectAll) {
      setSelectedRecords({});
      setSelectAll(false);
    } else {
      setSelectedRecords(allRecords);
      setSelectAll(true);
    }
  };

  const filteredSelectedRecords = Object.fromEntries(
    Object.entries(selectedRecords).filter(([key, value]) => value.length > 0)
  );

  const handleRecordCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setSelectedShow1((prevSelectedRecords) => {
      let updatedRecords = [...prevSelectedRecords];

      if (checked) {
        if (!updatedRecords.includes(name)) {
          updatedRecords.push(name);
        }
      } else {
        updatedRecords = updatedRecords.filter((record) => record !== name);
      }

      return updatedRecords;
    });
  };

  const handlePostAPI = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const payload = {
        module: selectedModule,
        user_id: selectedUser,
        col_level: filteredSelectedRecords,
        row_level: selectedShow1,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/mpermission`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setSelectedModule("");
        setSelectedUser("");
        setSelectedRecords({});

        setSelectedShow1([]);
        fetchMPermission();

        if (responseData === "user module permission already exist") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "User module permission already exists!",
          });
        } else {
          Swal.fire({
            title: "Good job!",
            text: "Permission data updated successfully!",
            icon: "success",
          });
        }
      } else {
        console.error("Error updating permissions:", response.error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
      Swal.fire({
        title: "The Internet?",
        text: "That thing is still around?",
        icon: "question",
      });
    }
  };

  const handleModuleChange = (event) => {
    const selectedModuleName = event.target.value;
    setSelectedModule(selectedModuleName);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <div className="row">
      <h1 className="text-center pb-2">Permission</h1>
      <div className="border p-2 col-md-6 bg-light">
        <div className="sticky-container">
          <div>
            <div>
              <div className="w-100 border p-3">
                <label>Module</label>
                <select
                  className="form-select w-100"
                  aria-label="Module select"
                  value={selectedModule}
                  onChange={handleModuleChange}
                >
                  <option value="">Select Module</option>
                  {modules.map((module, index) => (
                    <option key={index} value={module.name}>
                      {module.name}
                    </option>
                  ))}
                </select>

                <label>User</label>
                <select
                  className="form-select w-100"
                  aria-label="User select"
                  value={selectedUser}
                  onChange={handleUserChange}
                >
                  <option value="">Select User</option>
                  {users.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                <div className="my-3 d-flex gap-2 align-items-center justify-content-between">
                  <div className=" d-flex gap-2 align-items-center">
                    <label className="d-flex align-items-center">
                      Record :{" "}
                    </label>
                    <input
                      type="checkbox"
                      name="add"
                      value="Add"
                      onChange={handleRecordCheckboxChange}
                      label="recordadd"
                      inline
                    />
                    <label className="d-flex align-items-center"> Add </label>
                    <input
                      type="checkbox"
                      name="delete"
                      value="Delete"
                      onChange={handleRecordCheckboxChange}
                      label="recorddelete"
                      inline
                    />
                    <label className="d-flex align-items-center"> Delete</label>
                  </div>

                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={handleSelectAll}
                    >
                      {selectAll ? "Deselect All" : "Select All"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-bordered">
            <thead id="addProductTableHead">
              <tr>
                <th className="p-2 fw-bold">Column</th>
                <th className="p-2 fw-bold col-md-1">Edit</th>
                <th className="p-2 fw-bold col-md-1">Show</th>
              </tr>
            </thead>
            <tbody>
              {modulesName.map((columnName, index) => (
                <tr key={index}>
                  <td id="showUserTablebody">{columnName}</td>
                  <td id="showUserTablebody">
                    <input
                      type="checkbox"
                      name={`edit`}
                      value="edit"
                      checked={
                        selectedRecords[columnName]?.includes("edit") || false
                      }
                      onChange={(event) =>
                        handleCheckboxChange(event, columnName, "edit")
                      }
                    />
                  </td>
                  <td id="showUserTablebody">
                    <input
                      type="checkbox"
                      name={`show`}
                      value="show"
                      checked={
                        selectedRecords[columnName]?.includes("show") || false
                      }
                      onChange={(event) =>
                        handleCheckboxChange(event, columnName, "show")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn btn-primary" onClick={handlePostAPI}>
            UPDATE
          </button>
        </div>
      </div>
      <div className="col-md-6">
        <ShowPermissionTable
          permissionUser={permissionUser}
          permissionUserEdit={permissionUserEdit}
          sendPermissionEditId={sendPermissionEditId}
          fetchMPermission={fetchMPermission}
        />
      </div>
    </div>
  );
}
