import React, { useState } from "react";
import UserNavbar from "../../UserSidebar/UserNavbar";
import UserSidebar from "../../UserSidebar/UserSidebar";
import SubUserCreate from "../SubUserCreate";

export default function SubUser() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  return (
    <div id="admin-body d-flex">
      <UserNavbar />
      <div style={{ display: "flex" }}>
        <UserSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <div
          style={{
            flex: 1,
            padding: "20px",
            paddingLeft: "30px",
            marginLeft: isSidebarOpen ? "10%" : "3.5%",
          }}
        >
          <div>
            <SubUserCreate />
          </div>
        </div>
      </div>
    </div>
  );
}

