import React from "react";
import { Link, useLocation } from "react-router-dom";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AlbumIcon from "@mui/icons-material/Album";
import { Ring } from "../SVG/Svg";
import SecurityIcon from "@mui/icons-material/Security";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import { Permission, Permissionclient } from "../../utils/Url";

export default function SuperAdminSidebar({
  closeSidebar,
  isSidebarOpen,
  toggleSidebar,
}) {
  const location = useLocation();

  return (
    <nav
      aria-label=""
      className={`sidebar1 ${isSidebarOpen ? "open" : ""}`}
      style={{
        position: "fixed",
        top: 70 ,
        height: "100%",
        paddingTop: 10,
        zIndex: 9999,
      }}
    >
      <ul
        className="sidebar-nav1 my-4"
        style={{ width: isSidebarOpen ? "250px" : "" }}
      >
        {Permissionclient.includes("sadminorder") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/superAdminOrder" ? "active-link" : ""
              }`}
              to="/superAdminOrder"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/superAdminOrder"
                    ? "white"
                    : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <LocalMallIcon
                  style={{
                    color:
                      location.pathname === "/superAdminOrder"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/superAdminOrder"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Create Order
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}

        {Permissionclient.includes("sadminbulkorder") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/superAdminBulk" ? "active-link" : ""
              }`}
              to="/superAdminBulk"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/superAdminBulk" ? "white" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <AlbumIcon
                  style={{
                    color:
                      location.pathname === "/superAdminBulk"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/superAdminBulk"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Bulk Order
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}

        {/*<li>
                    <Link
                        className={`text-dark ${location.pathname === '/superAdminStatus' ? 'active-link' : ''}`}
                        to='/superAdminStatus'
                        onClick={closeSidebar}
                        style={{ color: location.pathname === '/superAdminStatus' ? 'white' : 'inherit' }}>             
                        <div className='d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold'>
                            <LocalMallIcon style={{ color: location.pathname === '/superAdminStatus' ? 'white' : 'inherit' }} />
                            {isSidebarOpen && <span style={{ color: location.pathname === '/superAdminStatus' ? 'white' : 'inherit' }}>Status Summary</span>}
                        </div>
                    </Link>
                </li>*/}
        {Permissionclient.includes("sadminstyle") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/superAdminJewelleryStyle"
                  ? "active-link"
                  : ""
              }`}
              to="/superAdminJewelleryStyle"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/superAdminJewelleryStyle"
                    ? "white"
                    : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <Ring
                  style={{
                    color:
                      location.pathname === "/superAdminJewelleryStyle"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/superAdminJewelleryStyle"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Jewellery Style
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {Permissionclient.includes("sadminnewuser") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/superAdminAddNewUser"
                  ? "active-link"
                  : ""
              }`}
              to="/superAdminAddNewUser"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/superAdminAddNewUser"
                    ? "white"
                    : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <PersonAddAltIcon
                  style={{
                    color:
                      location.pathname === "/superAdminAddNewUser"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/superAdminAddNewUser"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Add New User
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {Permissionclient.includes("sadminpermision") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/superAdminPermission"
                  ? "active-link"
                  : ""
              }`}
              to="/superAdminPermission"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/superAdminPermission"
                    ? "white"
                    : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <SecurityIcon
                  style={{
                    color:
                      location.pathname === "/superAdminPermission"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/superAdminPermission"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Permision
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {Permissionclient.includes("sadmincadmaster") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/CadMasterOrder" ? "active-link" : ""
              }`}
              to="/CadMasterOrder"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/CadMasterOrder" ? "white" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <FormatListNumberedRtlIcon
                  style={{
                    color:
                      location.pathname === "/CadMasterOrder"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/CadMasterOrder"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Cad Master
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}

        <li>
          <div
            className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold drowwer_btn"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? (
              <div className="vertical-layout">
                <ArrowCircleLeftIcon  style={{ color: "#83193E" }}/>
                <span className="mt-2">Close</span>
              </div>
            ) : (
              <div className="vertical-layout">
                <ArrowCircleRightRoundedIcon  style={{ color: "#83193E" }}/>
                <span className="mt-2">Open</span>
              </div>
            )}
          </div>
        </li>
      </ul>
    </nav>
  );
}
