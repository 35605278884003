import React, { useEffect, useRef, useState } from 'react'
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCarousel, MDBCarouselItem, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Swal from 'sweetalert2';
import { baseUrl } from '../../../utils/Url';


export default function AdminSkuModal({
    closeModal,
    bulkID,
    Order_ID,
    setOrder_ID,
    shapeOptions,
    fetchBulkOrder,
    fetchSkuData,
    modalOpen,
    setModalOpen,
    handleModalT_DiaQtyClick
}) {
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsTotalDiaQty, setRowsTotalDiaQty] = useState([]);
    const [rowsMFG, setRowsMFG] = useState([]);
    const [modalPolishOpen, setModalPolishOpen] = useState(false);
    const [modalRoughOpen, setModalRoughOpen] = useState(false);
    const [modalMFGOpen, setModalMFGOpen] = useState(false);
    const [rowsFG, setRowsFG] = useState([]);
    const [modalFGOpen, setModalFGOpen] = useState(false);
    const [rowsExport, setRowsExport] = useState([]);
    const [modalExportOpen, setModalExportOpen] = useState(false);
    const [modalExportQtyOpen, setModalExportQtyOpen] = useState(false);
    const [rowsPending, setRowsPending] = useState([]);
    const [modalPendingOpen, setModalPendingOpen] = useState(false);
    const [centredModal, setCentredModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState("");
    const [order_Dia_Plan, setOrder_Dia_Plan] = useState([]);
    const [focusedInput, setFocusedInput] = useState(null);
    const [editCell, setEditCell] = useState({ row: null, column: null });
    const inputRef = useRef(null);
    const [rowsExportQty, setRowsExportQty] = useState([]);


    const handleExportQty = () => setModalExportQtyOpen(true);
    const handleExportQtyClose = () => setModalExportQtyOpen(false);


    const addRowExportQty = () => {
        setRowsExportQty([
            ...rowsExportQty,
            {
                lotname: "",
            },
        ]);
    }

    useEffect(() => {
        addRowTotalDiaQty();
    }, []);

    const addRowTotalDiaQty = () => {
        setRowsTotalDiaQty([
            ...rowsTotalDiaQty,
            {
                shape: "",
                dia_weight: "",
                dia_size: "",
                dia_qty: "",
                in_stock: "",
                rough_short: "",
                rs_mfg: "",
                rs_purchase: "",
                rough_short_date: ""
            },
        ]);
    };

    const handleCellClick = (index, column) => {
        setEditCell({ row: index, column });
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenDropdownIndex(null);
            setEditCell({ row: null, column: null });
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mouseover", handleClickOutside);
        };
    }, []);


    const handleInputTd_QtyChange = (e, rowIndex, field) => {
        const updatedPlan = [...order_Dia_Plan];
        updatedPlan[rowIndex][field] = e.target.value;
        setOrder_Dia_Plan(updatedPlan);
    };

    const handleDeleteRow = async (index, id) => {
        console.log("handleDeleteRow:", id);

        const updatedRows = [...order_Dia_Plan];
        updatedRows[index].isdeleted = 1;
        setOrder_Dia_Plan(updatedRows);

        try {
            await handleTotal_Dia_Qty("in_stock", "rough_short", "t_dia_qty");
            fetchTotal_Dia_Qty();
        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    // const handleModalT_DiaQtyClick = (Order_id) => {
    //     setOrder_ID(Order_id);
    //     setModalOpen(true);
    //     fetchTotal_Dia_Qty();
    // }

    const handleClose = () => {
        setModalOpen(false);
    }

    const handleModalPolishClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalPolishOpen(true)
        fetchTotal_Dia_Qty();
    }

    const handlePolishClose = () => {
        setModalPolishOpen(false)
    }

    const handleModalRoughClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalRoughOpen(true)
        fetchTotal_Dia_Qty();
    }

    const handleRoughClose = () => {
        setModalRoughOpen(false)
    }

    const handleModalMFGClick = () => {
        setModalMFGOpen(true)
    }

    const handleMFGClose = () => {
        setModalMFGOpen(false)
    }

    const handleModalFGClick = () => {
        setModalFGOpen(true)
    }

    const handleFGClose = () => {
        setModalFGOpen(false)
    }

    const handleModalExportClick = () => {
        setModalExportOpen(true)
    }

    const handleModalExportClose = () => {
        setModalExportOpen(false)
    }

    const handleModalPendingClick = () => {
        setModalPendingOpen(true)
    }

    const handleModalPendingClose = () => {
        setModalPendingOpen(false)
    }

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput("");
    };

    const deleteRow = (index) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows.splice(index, 1);
        setRowsTotalDiaQty(updatedRows);
    };


    const handleTotalDiaQtyChange = (index, field, value) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows[index][field] = value;
        setRowsTotalDiaQty(updatedRows);
    };

    const handleInputPolishChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].in_stock = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };

    const handleInputRoughChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rs_mfg = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    const handleInputRoughPurchaseChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rs_purchase = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    const handleInputRoughS_dateChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rough_short_date = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    

    const fetchTotal_Dia_Qty = async () => {

        setLoading1(true);
        if (!bulkID || !Order_ID) {
            console.error("bulkID or Order_ID is missing");
            return;
        }
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                setLoading1(false);
                setOrder_Dia_Plan(responseData);
            } else {
                console.log("Error Fetching order-dia-plan Data", response.error);
            }

        } catch (error) {
            console.log("Error Fetching order-dia-plan Data", error);
        }
    }

    const handleTotal_Dia_Qty = async (type) => {
        setIsSubmitting(true);
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formDataTotal_Dia_Qty = new FormData();

            if (type === "t_dia_qty") {
                const combinedRows = [...rowsTotalDiaQty, ...order_Dia_Plan];

                combinedRows.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock || 0);
                });
            } else if (type === "in_stock") {
                order_Dia_Plan.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rough_short]`, "0");
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "rough_short") {
                order_Dia_Plan.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rs_mfg]`, row.rs_mfg);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rs_purchase]`, row.rs_purchase);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rough_short_date]`, row.rough_short_date);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            }

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formDataTotal_Dia_Qty,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                await Swal.fire({
                    title: "Good job!",
                    text: "Order Successfully Saved",
                    icon: "success",
                });
                fetchTotal_Dia_Qty();
                fetchSkuData();
                fetchBulkOrder();
                setRowsTotalDiaQty([{
                    shape: "",
                    dia_weight: "",
                    dia_size: "",
                    dia_qty: "",
                    in_stock: "",
                    rough_short: "",
                    rs_mfg: "",
                    rs_purchase: "",
                    rs_date: "",
                }]);
                setModalOpen(false);
                setModalPolishOpen(false);
                setModalRoughOpen(false);
            } else {
                console.error("Error:", response.statusText);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                });
            }
        } catch (error) {
            console.log("Error", error);
            Swal.fire({
                title: "The Internet?",
                text: "That thing is still around?",
                icon: "question",
            });
        } finally {
            setIsSubmitting(false);
        }
    }


    return (
        <div>

            {/* modalTotal Dia.QtyOpen */}
            <MDBModal open={modalOpen} setOpen={setModalOpen}>
                <MDBModalDialog centered className='modalOpen'>
                    <MDBModalContent>
                        {loading1 ? (
                            <div className="d-flex justify-content-center">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleTotal_Dia_Qty("t_dia_qty");
                                }}
                                >
                                    <MDBModalHeader>
                                        <h5 className="modal-title">Total Dia.Qty - B{bulkID}- J{Order_ID}</h5>
                                        <MDBBtn className="btn-close" color="none" onClick={handleClose}></MDBBtn>
                                    </MDBModalHeader>
                                    <MDBModalBody>

                                        <table className="table table-bordered table-responsive">
                                            <thead id="addProductTableHead">
                                                <tr>
                                                    <th className="p-0 py-2 fw-bold">Shape</th>
                                                    <th className="p-0 py-2 fw-bold">Dia Qty</th>
                                                    <th className="p-0 py-2 fw-bold">Dia Weight</th>
                                                    <th className="p-0 py-2 fw-bold">Measurements</th>
                                                    <th className="p-0 py-2 fw-bold">Lab Name</th>
                                                    <th className="p-0 py-2 fw-bold">Comment</th>
                                                    <th className="p-0 py-2 fw-bold">Del.</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {order_Dia_Plan.map((item, index) =>
                                                    <tr key={index}>

                                                        <td id="showUserTablebody">
                                                            <td id="showUserTablebody" onClick={() => handleCellClick(index, 'shape')}>
                                                                {editCell.row === index && editCell.column === 'shape' ? (
                                                                    <input
                                                                        type='text'
                                                                        ref={inputRef}
                                                                        value={item.shape}
                                                                        onChange={(e) => handleInputTd_QtyChange(e, index, 'shape')}
                                                                        onBlur={() => setEditCell({ row: null, column: null })}
                                                                        required
                                                                    />
                                                                ) : item.shape || "N/A"}
                                                            </td>
                                                        </td>

                                                        <td id="showUserTablebody" onClick={() => handleCellClick(index, 'dia_qty')}>
                                                            {editCell.row === index && editCell.column === 'dia_qty' ? (
                                                                <input
                                                                    ref={inputRef}
                                                                    value={item.dia_qty}
                                                                    onChange={(e) => handleInputTd_QtyChange(e, index, 'dia_qty')}
                                                                    onBlur={() => setEditCell({ row: null, column: null })}
                                                                />
                                                            ) : item.dia_qty || "N/A"}
                                                        </td>

                                                        <td id="showUserTablebody" onClick={() => handleCellClick(index, 'dia_weight')}>
                                                            {editCell.row === index && editCell.column === 'dia_weight' ? (
                                                                <input
                                                                    ref={inputRef}
                                                                    value={item.dia_weight}
                                                                    onChange={(e) => handleInputTd_QtyChange(e, index, 'dia_weight')}
                                                                    onBlur={() => setEditCell({ row: null, column: null })}
                                                                />
                                                            ) : item.dia_weight || "N/A"}
                                                        </td>

                                                        <td id="showUserTablebody" onClick={() => handleCellClick(index, 'dia_size')}>
                                                            {editCell.row === index && editCell.column === 'dia_size' ? (
                                                                <input
                                                                    ref={inputRef}
                                                                    value={item.dia_size}
                                                                    onChange={(e) => handleInputTd_QtyChange(e, index, 'dia_size')}
                                                                    onBlur={() => setEditCell({ row: null, column: null })}
                                                                />
                                                            ) : item.dia_size || "N/A"}
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            -
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            -
                                                        </td>

                                                        <td id="showUserTablebody" >
                                                            <DeleteForeverIcon
                                                                onClick={() => handleDeleteRow(index, item.id)}
                                                            />
                                                        </td>

                                                    </tr>
                                                )}

                                                {rowsTotalDiaQty?.map((row, index) => (
                                                    <tr key={index}>
                                                        <td id="showUserTablebody" style={{ position: "relative" }}>
                                                            <div style={{ position: "relative" }}>
                                                                <input
                                                                    type='text'
                                                                    placeholder='Shape'
                                                                    value={row?.shape}
                                                                    onBlur={handleBlur}
                                                                    onChange={(e) =>
                                                                        handleTotalDiaQtyChange(index, "shape", e.target.value)
                                                                    }
                                                                    onFocus={() => handleFocus(`shape-${index}`)}
                                                                    required
                                                                />
                                                                {focusedInput === `shape-${index}` && (
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "calc(100% + 5px)",
                                                                            left: 0,
                                                                            width: "100%",
                                                                            maxHeight: "150px",
                                                                            overflowY: "auto",
                                                                            border: "1px solid #ccc",
                                                                            backgroundColor: "white",
                                                                            zIndex: 1000,
                                                                            padding: "10px",
                                                                            fontWeight: "700",
                                                                        }}
                                                                        className="scrollable"
                                                                    >
                                                                        {shapeOptions?.filter((option) =>
                                                                            option
                                                                                .toLowerCase()
                                                                                .includes(row.shape.toLowerCase())
                                                                        )
                                                                            ?.map((option, idx) => (
                                                                                <div
                                                                                    key={idx}
                                                                                    onMouseDown={() => {
                                                                                        handleTotalDiaQtyChange(index, "shape", option);
                                                                                        handleBlur();
                                                                                    }}
                                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                                    className="dropdown-option"
                                                                                    required
                                                                                >
                                                                                    {option}
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            <input
                                                                placeholder='Dia Qty'
                                                                value={row?.dia_qty}
                                                                onChange={(e) => {
                                                                    const updatedRows = [...rowsTotalDiaQty];
                                                                    updatedRows[index].dia_qty = e.target.value;
                                                                    setRowsTotalDiaQty(updatedRows);
                                                                }}
                                                                required
                                                            />
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            <input
                                                                placeholder='Dia Weight'
                                                                value={row?.dia_weight}
                                                                onChange={(e) => {
                                                                    const updatedRows = [...rowsTotalDiaQty];
                                                                    updatedRows[index].dia_weight = e.target.value;
                                                                    setRowsTotalDiaQty(updatedRows);
                                                                }}
                                                                required
                                                            />
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            <input
                                                                placeholder='Dia Size'
                                                                value={row?.dia_size}
                                                                onChange={(e) => {
                                                                    const updatedRows = [...rowsTotalDiaQty];
                                                                    updatedRows[index].dia_size = e.target.value;
                                                                    setRowsTotalDiaQty(updatedRows);
                                                                }}
                                                                required
                                                            />
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            <select
                                                                onChange={(e) => {
                                                                    const updatedRows = [...rowsTotalDiaQty];
                                                                    updatedRows[index].labname = e.target.value;
                                                                    setRowsTotalDiaQty(updatedRows);
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="IGI">IGI</option>
                                                                <option value="GIA">GIA</option>
                                                                <option value="NON CERT">NON CERT</option>
                                                            </select>
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            <i
                                                                className="far fa-comment-dots fs-5"
                                                                style={{ cursor: "pointer" }}
                                                            // onClick={() => handleCommentClick(index)}
                                                            ></i>
                                                            {/* {commentIndex === index && (
                                                                <textarea
                                                                    className="form-control mt-2"
                                                                    rows="3"
                                                                    value={row.comment}
                                                                    onChange={(e) => handleChange(index, 'comment', e.target.value)}
                                                                ></textarea>
                                                            )} */}
                                                        </td>

                                                        <td id="showUserTablebody">
                                                            <i
                                                                className="fas fa-circle-minus fs-5"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => deleteRow(index)}
                                                            ></i>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <i
                                            className="fas fa-circle-plus fs-4"
                                            id="addNewRowBtn"
                                            onClick={addRowTotalDiaQty}
                                        ></i>
                                        <div className='d-flex justify-content-between'>
                                            <span>T.Dia Weight: 102</span>
                                            <span>T.Dia Qty:{order_Dia_Plan.reduce((acc, item) => acc + (item.dia_qty), 0)}</span>
                                        </div>

                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn color="secondary" disabled={isSubmitting}>
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </MDBBtn>
                                        <MDBBtn color="secondary" onClick={handleClose}>Close</MDBBtn>
                                    </MDBModalFooter>
                                </form>
                            </div>
                        )}
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>
    )
}
