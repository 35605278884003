import React, { useState } from 'react'
import Navbar from '../SideBar/Navbar';
import Sidebar from '../SideBar/Sidebar';
import AdminBulkRequirement from './Component/AdminBulkRequirement';

export default function AdminBulkOrder() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div id='admin-body'>
            <Navbar toggleSidebar={toggleSidebar} />
            <div style={{ display: 'flex' }}>
                <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div style={{ flex: 1, padding: '20px', marginTop: '30px', marginLeft: isSidebarOpen ? '13%' : '4.5%' }}>
                    <div id="superAdrequimentForm">
                        <AdminBulkRequirement />
                    </div>
                </div>
            </div>
        </div>
    )
}
