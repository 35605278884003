import { MDBBtn } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import AdminBulkTable from "./AdminBulkTable";
import { baseUrl } from "../../../utils/Url";

export default function AdminBulkRequirement() {
    const [getBulk, setGetBulk] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        ref_by: "",
        project_name: "",
        due_date: ""
    });

    const fetchBulkOrder = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/blk-order`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                console.log("getBulk:", responseData);

                setGetBulk(responseData);
            } else {
                console.log("Error Fetching BulkOrder Data", response.statusText);
            }
        } catch (error) {
            console.log("Error Fetching BulkOrder Data", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBulkOrder();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(formData)
            };

            const response = await fetch(`${baseUrl}/api/blk-order`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                console.log("responseData:", responseData);
                await Swal.fire({
                    title: "Good job!",
                    text: "Order Successfully Saved",
                    icon: "success",
                });
                fetchBulkOrder();
                setFormData({
                    ref_by: "",
                    project_name: "",
                    due_date: ""
                })
            } else {
                console.error("Error:", response.statusText);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                });
            }

        } catch (error) {
            console.error("Error:", error);
            Swal.fire({
                title: "The Internet?",
                text: "That thing is still around?",
                icon: "question",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const totalRunningSKU = getBulk.reduce((acc, item) => acc + (item.total_sku), 0);

    const roughShort = getBulk.reduce((acc, item) => {
        const roughShortValue = Number(item.rough_short) || 0;
        return acc + roughShortValue;
    }, 0);

    // const fgData = getBulk.reduce((acc, item) => acc + (item.fg), 0);

    const fgData = getBulk.reduce((acc, item) => {
        const fgValue = Number(item.fg) || 0;
        return acc + fgValue;
    }, 0);

    const exportData = getBulk.reduce((acc, item) => {
        const exportValue = Number(item.export) || 0;
        return acc + exportValue;
    }, 0);

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h4 className="pb-2">Bulk Order Requirement Form</h4>
                <h4 className="pb-2 m-auto">Dashboard</h4>
            </div>
            <div className="d-flex justify-content-between">
                <form
                    onSubmit={handleSubmit}
                    id="requirementForm"
                >
                    <table className="table table-bordered" style={{ width: "400px" }}>
                        <thead id="addProductTableHead">
                            <tr>
                                <th className="p-0 py-2 fw-bold">Ref_by<span className="text-danger fs-6">*</span></th>
                                <th className="p-0 py-2 fw-bold">
                                    Project_Name<span className="text-danger fs-6">*</span>
                                </th>
                                <th className="p-0 py-2 fw-bold">Due_Date </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    className="px-0 py-1 text-center"
                                    style={{ position: "relative" }}
                                >
                                    <input
                                        name="ref_by"
                                        placeholder="ref_by"
                                        className="w-100"
                                        type="text"
                                        value={formData.ref_by}
                                        onChange={handleChange}
                                        required
                                    />
                                </td>
                                <td
                                    className="px-0 py-1 text-center"
                                    style={{ position: "relative" }}
                                >
                                    <input
                                        name="project_name"
                                        placeholder="Project Name"
                                        className="w-100"
                                        type="text"
                                        value={formData.project_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </td>
                                <td className="px-0 py-1 text-center">
                                    <input
                                        name="due_date"
                                        className="w-100"
                                        type="date"
                                        value={formData.due_date}
                                        onChange={handleChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div>
                        <MDBBtn type="submit" id="orderNow" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </MDBBtn>
                    </div>
                </form>

                <div className="lineBulk"></div>

                <div className="row g-3 w-75">
                    <div className="col-lg-2">
                        <div className="border rounded-3 bg-light p-3 text-center">
                            <p className="mb-0 fw-bold">Projects</p>
                            <span className="fw-bold">{getBulk.length}</span>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="border rounded-3 bg-light p-3 text-center">
                            <p className="mb-0 fw-bold">Running SKU</p>
                            <span className="fw-bold">{totalRunningSKU}</span>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="border rounded-3 bg-light p-3 text-center">
                            <p className="mb-0 fw-bold">FG</p>
                            <span className="fw-bold">{fgData}</span>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="border rounded-3 bg-light p-3 text-center">
                            <p className="mb-0 fw-bold">Export</p>
                            <span className="fw-bold">{exportData}</span>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="border rounded-3 bg-light p-3 text-center">
                            <p className="mb-0 fw-bold">Rough Short</p>
                            <span className="fw-bold">{roughShort}</span>
                        </div>
                    </div>
                </div>
            </div>

            <AdminBulkTable
                getBulk={getBulk}
                loading={loading}
                setGetBulk={setGetBulk}
                fetchBulkOrder={fetchBulkOrder}
            />
        </div>
    )
}
