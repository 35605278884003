import React, { useState } from 'react'
import SuperAdminNavbar from '../SuperAdminSidebar/SuperAdminNavbar';
import SuperAdminSidebar from '../SuperAdminSidebar/SuperAdminSidebar';
import SuperAdminRequirement from './components/SuperAdminRequirement';

export default function SuperAdminOrder() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div id='admin-body'>
      <SuperAdminNavbar toggleSidebar={toggleSidebar} />
      <div style={{ display: 'flex' }}>
        <SuperAdminSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div style={{ flex: 1, padding: '20px', marginTop: '30px', marginLeft: isSidebarOpen ? '13%' : '4.5%' }}>
          <div id="superAdrequimentForm">
          <SuperAdminRequirement />


          </div>
        </div>
      </div>
    </div>
  )
}

