import React, { useEffect, useRef, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBModal, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBDropdown, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle, } from "mdb-react-ui-kit";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Swal from "sweetalert2";
import { baseUrl } from "../../utils/Url";

const VideoLazyLoader = ({ src }) => {
  const videoRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setVideoLoaded(true);
        }
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {!imageLoaded && (
        <div className="spinner-border position-absolute" role="status" style={{ zIndex: 1 }}>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <img
        src={src}
        alt="Video Thumbnail"
        style={{ display: imageLoaded ? "block" : "none" }}
        onLoad={handleImageLoad}
        className={`w-100 ${imageLoaded ? "" : "d-none"}`}
      />
      {imageLoaded && videoLoaded && (
        <video
          ref={videoRef}
          src={src}
          preload="auto"
          controls
          className="w-100"
        />
      )}
    </>
  );
};

export default function UserStyleProduct() {
  const [csvDataUser, setCsvDataUser] = useState([]);
  const [userSelectedCards, setUserSelectedCards] = useState([]);
  const [userCentredModal, setUserCentredModal] = useState(false);
  const [UserCurrentVideo, setUserCurrentVideo] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCardHeader, setShowCardHeader] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState(null);

  const filteredDataSerch = csvDataUser.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseUrl}/api/jstype-show-all`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const uniqueCategories = [
          ...new Set(result.map((item) => item.category)),
        ];
        const categoriesWithSubcategories = uniqueCategories.map((category) => {
          const subcategories = [
            ...new Set(
              result
                .filter((item) => item.category === category)
                .map((item) => item.subcategory)
            ),
          ];
          return [category, subcategories];
        });
        setCategories(categoriesWithSubcategories);
        setCsvDataUser(result);
        setCsvHeaders(Object.keys(result[0]));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const filteredData = csvDataUser
    ?.filter((item) => {
      if (selectedSetting) {
        return (
          item.subcategory === selectedSubcategory &&
          item.category === selectedCategory &&
          item.setting === selectedSetting
        );
      } else if (selectedSubcategory) {
        return (
          item.subcategory === selectedSubcategory &&
          item.category === selectedCategory
        );
      } else if (selectedCategory) {
        return item.category === selectedCategory;
      }
      return true;
    })
    .filter((item) => {
      if (filteredDataSerch && filteredDataSerch.length > 0) {
        return filteredDataSerch.includes(item);
      }
      return true;
    });

  const mergedData = filteredDataSerch && filteredData;

  const userToggleOpen = (item) => {
    setUserCurrentVideo(item);
    setUserCentredModal(!userCentredModal);
  };
  const userHandleCheckboxChange = (id) => {
    const isSelected = userSelectedCards.includes(id);
    if (!isSelected) {
      setUserSelectedCards([...userSelectedCards, id]);
    } else {
      setUserSelectedCards(userSelectedCards.filter((cardId) => cardId !== id));
    }
  };

  const handleSelectAll = () => {
    if (userSelectedCards.length === filteredData.length) {
      setUserSelectedCards([]);
    } else {
      const allSelected = filteredData.map((item) => item.id);
      setUserSelectedCards(allSelected);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCardHeader(true); // After 2 seconds, show the card header
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const downloadSelectedData = () => {

    // Filter the csvDataUser based on userSelectedCards
    const selectedData = csvDataUser.filter((el) => userSelectedCards.includes(el.id));

    const selectedDataWithHeaders = [
      csvHeaders,
      ...selectedData.map((row) => csvHeaders.map((header) => row[header])),
    ];
    const csvContent = selectedDataWithHeaders
      .map((row) => row.join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "selected_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
  };
  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const copyToClipboardVideo = () => {
    if (UserCurrentVideo) {
      const textArea = document.createElement("textarea");
      textArea.value = UserCurrentVideo.video_url;
      textArea.style.position = "fixed"; // To make sure it's out of view
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const message = successful ? "Link copied to clipboard!" : "Failed to copy the link.";
        const icon = successful ? "success" : "error";

        Swal.fire({
          title: "Copy to Clipboard",
          text: message,
          icon: icon,
        });
      } catch (err) {
        console.error("Error copying text to clipboard:", err);
      }

      document.body.removeChild(textArea);
    }
  };

  const copyToClipboardImage = () => {
    if (UserCurrentVideo) {
      const textArea = document.createElement("textarea");
      textArea.value = UserCurrentVideo.image_url;
      textArea.style.position = "fixed"; // To make sure it's out of view
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const message = successful ? "Link copied to clipboard!" : "Failed to copy the link.";
        const icon = successful ? "success" : "error";

        Swal.fire({
          title: "Copy to Clipboard",
          text: message,
          icon: icon,
        });
      } catch (err) {
        console.error("Error copying text to clipboard:", err);
      }

      document.body.removeChild(textArea);
    }
  };


  const shareOnWhatsAppVideo = (item) => {
    if (!item) {
      console.error("Item is undefined");
      return;
    }

    const messagePart = "*Check Out This Item:*";
    const videoLink = item?.video_url?.trim().replace(/\s/g, "%20");

    const additionalInfo = `
    *Video*: ${videoLink}
    `;

    const message = `${messagePart}\n${additionalInfo}`;

    const encodedMessage = encodeURIComponent(message);

    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  };


  const shareOnWhatsAppImage = (item) => {
    if (!item) {
      console.error("Item is undefined");
      return;
    }

    const messagePart = "*Check Out This Item:*";
    const imgLink = item?.image_url?.trim().replace(/\s/g, "%20");

    const additionalInfo = `
    *Image*: ${imgLink}
    `;

    const message = `${messagePart}\n${additionalInfo}`;

    const encodedMessage = encodeURIComponent(message);

    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  }


  const handleSettingFilter = (setting) => {
    setSelectedSetting(setting);
  };

  // const shareOnWhatsApp = (item) => {
  //   if (!item) {
  //     console.error("Item is undefined");
  //     return;
  //   }

  //   const messagePart = "*Check Out This Item:*";
  //   const videoLink = item?.video_url?.trim().replace(/\s/g, "%20");
  //   const imgLink = item?.image_url?.trim().replace(/\s/g, "%20");

  //   const additionalInfo = `
  //   1. *Style Name*: ${item.name}
  //   2. *Category*: ${item.category}
  //   3. *Subcategory*: ${item.subcategory}
  //   4. *Metal*: ${item.metal}
  //   5. *Shape*: ${item.shape}
  //   6. *Color*: ${item.color}
  //   7. *Center Color Range*: ${item.center_color_range}
  //   6. *Avg Stone Size*: ${item.avg_stone_size}
  //   7. *Total Ct Weight*: ${item.ct_weight_total}
  //   8. *Total Diamond Range*: ${item.total_diamond_range}
  //   9. *Total Orientation*: ${item.orientation}
  //   10. *Total Setting*: ${item.setting}
  //   11. *Design No*: ${item.design_no}
  //   12. *Compnt_Total_Qty_Dia*: ${item.compnt_total_qty_dia}
  //   13. *Image*: ${imgLink}
  //   14. *Video*: ${videoLink}
  //   `;
  //   const message = `${messagePart}\n${additionalInfo}`;
  //   const encodedMessage = encodeURIComponent(message);
  //   window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  // };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <MDBBtn className="btn-primary" onClick={handleSelectAll}>
            Select All
          </MDBBtn>
          <MDBBtn className="bg-danger" onClick={downloadSelectedData}>
            Download CSV
          </MDBBtn>
        </div>
        <span>
          <div className="search-box">
            <button className="btn-search">
              <i className="fas fa-search"></i>
            </button>
            <input
              type="text"
              className="input-search"
              placeholder="Type to Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </span>
      </div>
      <div className="submenu-container py-3">
        <nav className="d-flex  ">
          <ul
            className="subcategory p-2 d-flex  align-items-center"
            style={{ width: "35%" }}
          >
            {categories.map(([category, subcategories], index) => (
              <li className="col-lg-3" key={index}>
                <a href="#" onClick={() => handleCategoryChange(category)}>
                  {category}
                </a>
                {selectedCategory === category && (
                  <ul className="z-3">
                    {[...subcategories]?.map((subcategory, subIndex) => (
                      <li className="z-3" key={subIndex}>
                        <a
                          href="#"
                          onClick={() => handleSubcategoryChange(subcategory)}
                        >
                          {subcategory}
                        </a>

                        {selectedSubcategory === subcategory && (
                          <ul className="setting_filter">
                            {[...new Set(
                              csvDataUser
                                .filter(item => item.subcategory === selectedSubcategory)
                                .map(item => item.setting)
                            )].map((setting, index) => (
                              <li key={index}>
                                <a onClick={() => handleSettingFilter(setting)}>
                                  {setting}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}

                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-wrap">
            {mergedData?.map((item, rowIndex) => (
              <MDBCard key={rowIndex} className="custom-card">
                <input
                  type="checkbox"
                  className="position-absolute chack_box"
                  checked={userSelectedCards.includes(item.id)}
                  onChange={() => userHandleCheckboxChange(item.id)}
                />
                <MDBCardHeader
                  className="p-0 d-flex justify-content-center align-items-center h-100"
                  onClick={() => userToggleOpen(item)}
                >
                  {item.image_url ? (
                    <>
                      <PlayCircleIcon
                        className="card_desc cursor-pointer"
                        style={{ fontSize: 70, color: "white", zIndex: 999 }}
                      />
                      <VideoLazyLoader src={item["image_url"]} />
                    </>
                  ) : (
                    <img
                      src="./No Video Found.jpg"
                      alt="Image"
                      className="w-100"
                      loading="lazy"
                    />
                  )}
                </MDBCardHeader>

                <MDBCardBody className=" row d-flex ">
                  {Object.keys(item).map((key) => {
                    if (
                      [
                        "id",
                        "updated_at",
                        "created_at",
                        "image_url",
                        "video_url",
                      ].includes(key)
                    ) {
                      return null;
                    }

                    return (
                      <MDBCol
                        md="4"
                        key={key}
                        className="text-center py-1 border-bottom d-flex flex-column"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        <label style={{ fontSize: "11px" }}>{key}:</label>{" "}
                        {item[key]}
                      </MDBCol>
                    );
                  })}
                </MDBCardBody>
              </MDBCard>
            ))}

            <MDBModal
              tabIndex="-1"
              open={userCentredModal}
              setOpen={setUserCentredModal}
            >
              <MDBModalDialog centered>
                <MDBModalContent
                  className="6"
                  style={{ border: "3px solid white" }}
                >
                  <MDBModalHeader className="bg-black border-bottom-0">
                    <MDBBtn
                      className="btn-close bg-white"
                      onClick={() => userToggleOpen(null)}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalContent>
                    {UserCurrentVideo && (
                      <div>
                        <video
                          src={UserCurrentVideo.video_url}
                          controls
                          className="w-100"
                        />

                        <div className="mt-3 d-flex">
                          <MDBDropdown>
                            <MDBDropdownToggle color="primary">Share</MDBDropdownToggle>
                            <MDBDropdownMenu className="dropdown_menu">
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={copyToClipboardVideo}
                              >Copy Link Video
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={copyToClipboardImage}
                              >Copy Link Image
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={() => shareOnWhatsAppVideo(UserCurrentVideo)}
                              >
                                Share on WhatsApp Video
                              </MDBDropdownItem>
                              <MDBDropdownItem
                                className="dropdown_item"
                                onClick={() => shareOnWhatsAppImage(UserCurrentVideo)}
                              >
                                Share on WhatsApp Image
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                          {/* <MDBBtn onClick={downloadVideo} className="ms-2">Download</MDBBtn> */}
                        </div>
                      </div>
                    )}
                  </MDBModalContent>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </div>
        </>
      )}
    </>
  );
}