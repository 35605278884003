import { MDBTabsItem, MDBTabsPane, MDBTabs, MDBTabsContent, MDBTabsLink, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { useState } from 'react'
import PendingOrders from './PendingOrders';
import OrderHistory from './OrderHistory';

export default function ReportDeatials() {
    const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <div>
        <MDBTabs >
          <MDBTabsItem id='reportIconsTabs'>
            <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                <div>
                    <MDBIcon fas icon="thumbs-up" className='fs-2 pb-4'/>
                    <p>Request Pending</p>
                </div>
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem id='reportIconsTabs'>
            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                <div>
                    <MDBIcon fas icon="history" className='fs-2 pb-4'/>
                    <p>Order History</p>
                </div>
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

      
        <MDBTabsContent className='py-4'>
          <MDBTabsPane open={basicActive === 'tab1'}> 
              <PendingOrders/>
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab2'}>
              <OrderHistory/>
          </MDBTabsPane>
        </MDBTabsContent>
     
    </div>
  )
}
