
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCarousel, MDBCarouselItem, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CommentIcon from "@mui/icons-material/Comment";
import Swal from 'sweetalert2';
import SaveIcon from "@mui/icons-material/Save";
import axios from 'axios';
import { Loader } from '../../../Super_Admin/SVG/Svg';
import AdminSkuModal from './AdminSkuModal';
import { baseUrl } from '../../../utils/Url';

export default function AdminSkuOrder({ showSubOrder, bulkID, fetchBulkOrder, selectedSubOrder }) {
    const [loading1, setLoading1] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsTotalDiaQty, setRowsTotalDiaQty] = useState([]);
    const [rowsMFG, setRowsMFG] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPolishOpen, setModalPolishOpen] = useState(false);
    const [modalRoughOpen, setModalRoughOpen] = useState(false);
    const [modalMFGOpen, setModalMFGOpen] = useState(false);
    const [rowsFG, setRowsFG] = useState([]);
    const [modalFGOpen, setModalFGOpen] = useState(false);
    const [rowsExport, setRowsExport] = useState([]);
    const [modalExportOpen, setModalExportOpen] = useState(false);
    const [modalExportQtyOpen, setModalExportQtyOpen] = useState(false);
    const [rowsPending, setRowsPending] = useState([]);
    const [modalPendingOpen, setModalPendingOpen] = useState(false);
    const [chatId, setChatId] = useState(null);
    const chatBoxRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commentIndex, setCommentIndex] = useState(null);
    const [bulkSubOrder, setBulkSubOrder] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userScrolledUp, setUserScrolledUp] = useState(false);
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedValues, setEditedValues] = useState({});
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRef = useRef(null);
    const [selectedRequestStatus, setSelectedRequestStatus] = useState("");
    const [centredModal, setCentredModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState("");
    const [order_Dia_Plan, setOrder_Dia_Plan] = useState([]);
    const [Order_ID, setOrder_ID] = useState("");
    const [chatData, setChatData] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [editCell, setEditCell] = useState({ row: null, column: null });
    const inputRef = useRef(null);
    const [rowsExportQty, setRowsExportQty] = useState([]);

    console.log("bulkSubOrder:", bulkSubOrder);

    const handleExportQty = () => setModalExportQtyOpen(true);
    const handleExportQtyClose = () => setModalExportQtyOpen(false);

    const addRowExportQty = () => {
        setRowsExportQty([
            ...rowsExportQty,
            {
                lotname: "",
            },
        ]);
    }

    useEffect(() => {
        addRowTotalDiaQty();
        addRowMFG();
        addRowFG();
        addRowExport();
        addRowPending();
    }, []);

    const addRowMFG = () => {
        setRowsMFG([
            ...rowsMFG,
            {
                newMFG: "",
            },
        ]);
    }

    const addRowFG = () => {
        setRowsFG([
            ...rowsFG,
            {
                newFG: "",
            },
        ]);
    }

    const addRowExport = () => {
        setRowsExport([
            ...rowsExport,
            {
                newExport: "",
            },
        ]);
    }

    const deleteExportQtyRow = (index) => {
        const updatedRows = [...rowsExportQty];
        updatedRows.splice(index, 1);
        setRowsExportQty(updatedRows);
    };

    const addRowPending = () => {
        setRowsPending([
            ...rowsPending,
            {
                newPending: "",
            },
        ]);
    }

    const addRowTotalDiaQty = () => {
        setRowsTotalDiaQty([
            ...rowsTotalDiaQty,
            {
                shape: "",
                dia_weight: "",
                dia_size: "",
                dia_qty: "",
                in_stock: "",
                rough_short: "",
                rs_mfg: "",
                rs_purchase: "",
                rough_short_date: ""
            },
        ]);
    };

    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        setRows(updatedRows);
    };

    const handleTotalDiaQtyChange = (index, field, value) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows[index][field] = value;
        setRowsTotalDiaQty(updatedRows);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const fetchSkuData = async () => {
        try {
            const token = localStorage.getItem("token")
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${bulkID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                console.log("responseData:", responseData);

                setBulkSubOrder(responseData);
            } else {
                console.log("Error Fetching BulkSubOrder Data", response.error);
            }

        } catch (error) {
            console.log("Error Fetching BulkSubOrder Data", error);
        }
    }

    useEffect(() => {
        if (bulkID) {
            fetchSkuData();
        }
    }, [bulkID]);

    useEffect(() => {
        addRow();
    }, []);

    const addRow = () => {
        setRows([
            ...rows,
            {
                sub_order: '',
                order_date: '',
                jewelryType: '',
                jewelrySubType: '',
                Setting: '',
                ref_lot: '',
                shapeType: '',
                metal: '',
                jew_size: '',
                dia_size: '',
                dia_weight: '',
                comment: '',
                t_qty: '',
                MFG: '',
                FG: '',
                Export: '',
                Pending: '',
                Total_DiaQty: '',
                Polish_Avail: '',
                Rough_Short: '',
                R_Date: '',
                EstDate: '',
                Due_Date: ''
            },
        ]);
    };

    const handleSubOrderSubmit = async (event) => {
        event.preventDefault();

        if (!selectedSubOrder) {
            Swal.fire({
                icon: "warning",
                title: "No Sub-Order Selected",
                text: "Please select a sub-order before submitting.",
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formDataSubOrder = new FormData();

            rows.forEach((row, index) => {
                formDataSubOrder.append(`rows[${index}][jewelryType]`, row.jewelryType);
                formDataSubOrder.append(`rows[${index}][jewelrySubType]`, row.jewelrySubType);
                formDataSubOrder.append(`rows[${index}][setting]`, row.Setting);
                formDataSubOrder.append(`rows[${index}][refLotId]`, row.ref_lot);
                formDataSubOrder.append(`rows[${index}][shapeType]`, row.shapeType);
                formDataSubOrder.append(`rows[${index}][metalType]`, row.metal);
                formDataSubOrder.append(`rows[${index}][jewelrySize]`, row.jew_size);
                formDataSubOrder.append(`rows[${index}][diamondApoSize]`, row.dia_size);
                formDataSubOrder.append(`rows[${index}][comment]`, row.comment);
                formDataSubOrder.append(`rows[${index}][qty]`, row.t_qty);
                formDataSubOrder.append(`rows[${index}][due_date]`, row.Due_Date);
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formDataSubOrder
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${bulkID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                console.log("responseData:", responseData);
                await Swal.fire({
                    title: "Good job!",
                    text: "Order Successfully Saved",
                    icon: "success",
                });
                setRows([]);
                fetchSkuData();
            }
        } catch (error) {
            console.log("Error", error);
            Swal.fire({
                title: "The Internet?",
                text: "That thing is still around?",
                icon: "question",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const deleteRow = (index) => {
        const updatedRows = [...rowsTotalDiaQty];
        updatedRows.splice(index, 1);
        setRowsTotalDiaQty(updatedRows);
    };

    const deleteMFGRow = (index) => {
        const updatedRows = [...rowsMFG];
        updatedRows.splice(index, 1);
        setRowsMFG(updatedRows);
    };

    const deleteFGRow = (index) => {
        const updatedRows = [...rowsFG];
        updatedRows.splice(index, 1);
        setRowsFG(updatedRows);
    };

    const deleteExportRow = (index) => {
        const updatedRows = [...rowsExport];
        updatedRows.splice(index, 1);
        setRowsExport(updatedRows);
    };

    const deletePendingRow = (index) => {
        const updatedRows = [...rowsPending];
        updatedRows.splice(index, 1);
        setRowsPending(updatedRows);
    };

    const deleteRowSubOrder = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const handleModalT_DiaQtyClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalOpen(true);
        fetchTotal_Dia_Qty();
    }

    const handleClose = () => {
        setModalOpen(false);
    }

    const handleModalPolishClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalPolishOpen(true)
        fetchTotal_Dia_Qty();
    }

    const handlePolishClose = () => {
        setModalPolishOpen(false)
    }

    const handleModalRoughClick = (Order_id) => {
        setOrder_ID(Order_id);
        setModalRoughOpen(true)
        fetchTotal_Dia_Qty();
    }

    const handleRoughClose = () => {
        setModalRoughOpen(false)
    }

    const handleModalMFGClick = () => {
        setModalMFGOpen(true)
    }

    const handleMFGClose = () => {
        setModalMFGOpen(false)
    }

    const handleModalFGClick = () => {
        setModalFGOpen(true)
    }

    const handleFGClose = () => {
        setModalFGOpen(false)
    }

    const handleModalExportClick = () => {
        setModalExportOpen(true)
    }

    const handleModalExportClose = () => {
        setModalExportOpen(false)
    }

    const handleModalPendingClick = () => {
        setModalPendingOpen(true)
    }

    const handleModalPendingClose = () => {
        setModalPendingOpen(false)
    }

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handleCommentClick = (index) => {
        setCommentIndex(index);
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return null;
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    };

    const handleScroll = () => {
        setUserScrolledUp(true);
    };

    useEffect(() => {
        if (!userScrolledUp && chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [userScrolledUp]);

    const handleUpload = async (id) => {
        console.log("sku_id:", id);
        console.log("id:", id);

        if (selectedFiles) {
            setUploading(true);
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("finalImg[]", selectedFiles[i]);
            }

            try {
                const token = localStorage.getItem("token");
                const response = await fetch(
                    `${baseUrl}/api/upload-final-media/${id}`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to upload files");
                }

                setSelectedFiles(null);
                setUploading(false);
                fetchSkuData();
            } catch (error) {
                console.error("Upload failed:", error);
                setUploading(false);
            }
        }
    };

    const handleChat = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const userCode = localStorage.getItem("userCode");

            const [chatResponse, markReadResponse] = await Promise.all([
                axios.get(
                    `${baseUrl}/api/order-chat/show/${id}`,
                    {
                        params: { user_id: userCode },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                ),
            ]);

            if (chatResponse.status === 200) {
                setChatData(chatResponse.data);
            } else {
                throw new Error(
                    `Failed to fetch status: ${chatResponse.status} ${chatResponse.statusText}`
                );
            }

            if (markReadResponse.status !== 200) {
                throw new Error(
                    `Failed to mark messages as read: ${markReadResponse.status} ${markReadResponse.statusText}`
                );
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            // setIsLoading(false);
        }
    };

    const fetchDataChat = useCallback(async () => {
        if (chatId) {
            await handleChat(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        fetchDataChat();
    }, [fetchDataChat]);

    const handleSubOrderDelete = async (Sku_ID) => {
        console.log("Attempting to delete:", Sku_ID);
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${Sku_ID}`, requestOptions);

            if (response.ok) {
                console.log("SubOrder deleted successfully");
                await Swal.fire({
                    title: "Delete!",
                    text: "Order Delete Successfully",
                    icon: "success",
                });
            } else {
                const errorData = await response.text();
                console.error("Error Deleting SubOrder:", response.status, response.statusText, errorData);
            }
            setBulkSubOrder(prev => prev.filter(data => data.sku_id !== Sku_ID));
            fetchSkuData();
        } catch (error) {
            console.error("Error during DELETE operation:", error);
        }
    };

    const handleSubOrderSave = async (Sku_ID) => {
        console.log("EDIT:", Sku_ID);

        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const requestBody = {};
            const currentRow = bulkSubOrder[editingRowIndex];
            for (const key in editedValues) {
                if (editedValues[key] !== currentRow[key]) {
                    requestBody[key] = editedValues[key];
                }
            }

            if (Object.keys(requestBody).length === 0) {
                console.log("No changes detected.");
                setEditMode(false);
                setEditingRowIndex(null);
                return;
            }

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(`${baseUrl}/api/blk-sku/${Sku_ID}`, requestOptions);

            if (!response.ok) {
                throw new Error("Error updating data. Please try again");
            }

            const responseData = await response.json();
            console.log("Update response:", responseData);
            setEditedValues(responseData);
            setEditedValues({});
            setEditMode(false);
            setEditingRowIndex(null);
            fetchSkuData();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }

    const toggleEditMode = (index) => {
        setEditMode(true);
        setEditingRowIndex(index);
        setEditedValues({ ...bulkSubOrder[index] });
    };

    const handleDropdownClick = (e, index, itemId) => {
        console.log("SKU:", itemId);

        e.stopPropagation();
        setOpenDropdownIndex(index);
        setSelectedItemId(itemId);
    };

    const saveRequestChanges = () => {
        const token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify({ requestStatus: selectedRequestStatus }),
            redirect: "follow",
        };

        fetch(`${baseUrl}/api/blk-sku/${selectedItemId}`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    closeModal();
                    setOpenDropdownIndex(null);
                    setSelectedRequestStatus("");
                    fetchSkuData();
                } else {
                    throw new Error(
                        `Failed to post status: ${response.status} ${response.statusText}`
                    );
                }
            })
            .catch((error) => {
                console.error("Error posting status:", error);
            });
    };

    const handleSelectChange = (e) => {
        const { value } = e.target;
        setSelectedRequestStatus(value);
        setOpenDropdownIndex(null);
        setCentredModal(true);
    };

    const closeModal = () => {
        setCentredModal(false);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenDropdownIndex(null);
            setEditCell({ row: null, column: null });
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mouseover", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if ((modalOpen && Order_ID) || (modalPolishOpen && Order_ID) || (modalRoughOpen && Order_ID)) {
            fetchTotal_Dia_Qty();
        }
    }, [modalOpen, modalPolishOpen, modalRoughOpen, bulkID, Order_ID]);

    const fetchTotal_Dia_Qty = async () => {

        setLoading1(true);
        if (!bulkID || !Order_ID) {
            console.error("bulkID or Order_ID is missing");
            return;
        }
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                setLoading1(false);
                setOrder_Dia_Plan(responseData);
            } else {
                console.log("Error Fetching order-dia-plan Data", response.error);
            }

        } catch (error) {
            console.log("Error Fetching order-dia-plan Data", error);
        }
    }

    const handleTotal_Dia_Qty = async (type) => {
        setIsSubmitting(true);
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formDataTotal_Dia_Qty = new FormData();

            if (type === "t_dia_qty") {
                const combinedRows = [...rowsTotalDiaQty, ...order_Dia_Plan];

                combinedRows.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock || 0);
                });
            } else if (type === "in_stock") {
                order_Dia_Plan.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rough_short]`, "0");
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            } else if (type === "rough_short") {
                order_Dia_Plan.forEach((row, index) => {
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][shape]`, row.shape);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_weight]`, row.dia_weight);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_size]`, row.dia_size);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][dia_qty]`, row.dia_qty);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][in_stock]`, row.in_stock);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rs_mfg]`, row.rs_mfg);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rs_purchase]`, row.rs_purchase);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][rough_short_date]`, row.rough_short_date);
                    formDataTotal_Dia_Qty.append(`rowsTotalDiaQty[${index}][isdeleted]`, row.isdeleted || 0);
                });
            }

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formDataTotal_Dia_Qty,
                redirect: "follow"
            };

            const response = await fetch(`${baseUrl}/api/order-dia-plan/${bulkID}/${Order_ID}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                await Swal.fire({
                    title: "Good job!",
                    text: "Order Successfully Saved",
                    icon: "success",
                });
                fetchTotal_Dia_Qty();
                fetchSkuData();
                fetchBulkOrder();
                setRowsTotalDiaQty([{
                    shape: "",
                    dia_weight: "",
                    dia_size: "",
                    dia_qty: "",
                    in_stock: "",
                    rough_short: "",
                    rs_mfg: "",
                    rs_purchase: "",
                    rs_date: "",
                }]);
                setModalOpen(false);
                setModalPolishOpen(false);
                setModalRoughOpen(false);
            } else {
                console.error("Error:", response.statusText);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                });
            }
        } catch (error) {
            console.log("Error", error);
            Swal.fire({
                title: "The Internet?",
                text: "That thing is still around?",
                icon: "question",
            });
        } finally {
            setIsSubmitting(false);
        }
    }

    const shapeOptions = [
        "Round", "Emerald", "Radiant", "Oval", "Pear", "Marquise", "Cushion",
        "Heart", "Princess", "Asscher", "Mix (As.Comment)", "Anti. Asscher",
        "Anti. Cushion", "Anti. Oval", "Anti. Marquise", "Sq. Anti. Cushion",
        "Sq. Cushion", "Sq. Emerald", "Sq. Radiant", "Step Pear", "Step Cushion",
        "Step Marquise", "Step Oval", "Duchess", "Trapezoid", "Bull", "Horse",
        "Bullet", "Flower", "Butterflies", "Briolet", "Baguette", "Kite",
        "Kriss", "Moon Half", "Octagon", "Octagon H&A", "Old European",
        "Old Mine", "Rose Cut", "Rose Oval", "Rose Pear", "Trapper Baguette",
        "Triangle",
    ];

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput("");
    };

    const handleCellClick = (index, column) => {
        setEditCell({ row: index, column });
    };

    const handleInputTd_QtyChange = (e, rowIndex, field) => {
        const updatedPlan = [...order_Dia_Plan];
        updatedPlan[rowIndex][field] = e.target.value;
        setOrder_Dia_Plan(updatedPlan);
    };

    const handleDeleteRow = async (index, id) => {
        console.log("handleDeleteRow:", id);

        const updatedRows = [...order_Dia_Plan];
        updatedRows[index].isdeleted = 1;
        setOrder_Dia_Plan(updatedRows);

        try {
            await handleTotal_Dia_Qty("in_stock", "rough_short", "t_dia_qty");
            fetchTotal_Dia_Qty();
        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const handleInputPolishChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].in_stock = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    };

    const handleInputRoughChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rs_mfg = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    const handleInputRoughPurchaseChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rs_purchase = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    const handleInputRoughS_dateChange = (e, rowIndex) => {
        const updatedRows = [...order_Dia_Plan];
        updatedRows[rowIndex].rough_short_date = e.target.value;
        setOrder_Dia_Plan(updatedRows);
    }

    return (
        <div>
            {showSubOrder && selectedSubOrder && (
                <>
                    <h6 className='fw-bold' style={{ color: "rgb(84 99 145)" }}>SKU of - B{bulkID}</h6>
                    <form
                        onSubmit={handleSubOrderSubmit}
                        encType="multipart/form-data"
                        id="requirementForm"
                    >
                        <table className="table table-bordered table-responsive bulkSkuTable">
                            <thead id="subOrderTableHead">
                                <tr>
                                    <th className="p-0 py-2 fw-bold">Select</th>
                                    <th className="p-0 py-2 fw-bold">Sub_Order</th>
                                    <th className="p-0 py-2 fw-bold">Order Date</th>
                                    <th className="p-0 py-2 fw-bold">Status</th>
                                    <th className="p-0 py-2 fw-bold">Type</th>
                                    <th className="p-0 py-2 fw-bold">Sub_Types</th>
                                    <th className="p-0 py-2 fw-bold">Setting</th>
                                    <th className="p-0 py-2 fw-bold">Ref. Piece</th>
                                    <th className="p-0 py-2 fw-bold">Shape</th>
                                    <th className="p-0 py-2 fw-bold">Metal</th>
                                    <th className="p-0 py-2 fw-bold">Size</th>
                                    <th className="p-0 py-2 fw-bold">Dia Size</th>
                                    <th className="p-0 py-2 fw-bold">Dia Weight</th>
                                    <th className="p-0 py-2 fw-bold">Comment</th>
                                    <th className="p-0 py-2 fw-bold">T.Qty</th>
                                    <th className="p-0 py-2 fw-bold">MFG</th>
                                    <th className="p-0 py-2 fw-bold">FG</th>
                                    <th className="p-0 py-2 fw-bold">Export</th>
                                    <th className="p-0 py-2 fw-bold">Pending</th>
                                    <th className="p-0 py-2 fw-bold">Total Dia Qty</th>
                                    <th className="p-0 py-2 fw-bold">Polish Avail</th>
                                    <th className="p-0 py-2 fw-bold">Rough Short</th>
                                    <th className="p-0 py-2 fw-bold"> Purchase Pending</th>
                                    {/* <th className="p-0 py-2 fw-bold">R.Date</th> */}
                                    <th className="p-0 py-2 fw-bold">EstDate</th>
                                    <th className="p-0 py-2 fw-bold">DueDate</th>
                                    <th className="p-0 py-2 fw-bold">F_Img</th>
                                    <th className="p-0 py-2 fw-bold">ChatBox</th>
                                    <th className="p-0 py-2 fw-bold">Delete</th>
                                    <th className="p-0 py-2 fw-bold">Edit</th>
                                    <th className="p-0 py-2 fw-bold">Del.</th>
                                </tr>
                            </thead>
                            <tbody>

                                {bulkSubOrder.map((row, index) => (
                                    <tr key={index}>
                                        <td className="px-0 py-1 text-center">
                                            <input
                                                type='checkbox'
                                            />
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            B{row.blk_order_id}-J{row.order_id}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {formatDate(row.created_at)}
                                        </td>

                                        <td
                                            className="px-0 py-1 text-center"
                                            onClick={(e) =>
                                                handleDropdownClick(e, index, row.sku_id)
                                            }
                                            style={{
                                                color:
                                                    openDropdownIndex === index
                                                        ? "black"
                                                        : !row.id || !row.requestStatus
                                                            ? "orange"
                                                            : row.requestStatus === "Req_Approved"
                                                                ? "green"
                                                                : row.requestStatus === "Designing"
                                                                    ? "blue"
                                                                    : row.requestStatus ===
                                                                        "Design Approval Pending"
                                                                        ? "#9A208C"
                                                                        : row.requestStatus === "Design Approved"
                                                                            ? "#4FA095"
                                                                            : row.requestStatus === "Hold"
                                                                                ? "#096C87"
                                                                                : row.requestStatus === "Export"
                                                                                    ? "#000000"
                                                                                    : row.requestStatus === "Canceled Order"
                                                                                        ? "red"
                                                                                        : "inherit",
                                            }}
                                        >
                                            {openDropdownIndex === index ? (
                                                <select
                                                    ref={dropdownRef}
                                                    value={selectedRequestStatus}
                                                    onChange={handleSelectChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Req_Pending">Req_Pending</option>
                                                    <option value="Req_Approved">Req_Approved</option>
                                                    <option value="Designing">Designing</option>
                                                    <option value="Design Approval Pending">Design Approval Pending</option>
                                                    <option value="Design Approved">Design Approved</option>
                                                    <option value="Export">Export</option>
                                                    <option value="Hold">Hold</option>
                                                    <option value="Canceled Order">Canceled Order</option>
                                                </select>
                                            ) : !row.sku_id || !row.requestStatus ? (
                                                "Req_Pending"
                                            ) : (
                                                row.requestStatus
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='jewelryType'
                                                    name="jewelryType"
                                                    value={editedValues.jewelryType || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.jewelryType || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='jewelrySubType'
                                                    name="jewelrySubType"
                                                    value={editedValues.jewelrySubType || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.jewelrySubType || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='Setting'
                                                    name="setting"
                                                    value={editedValues.setting || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.setting || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='refLotId'
                                                    name="refLotId"
                                                    value={editedValues.refLotId || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.refLotId || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='shapeType'
                                                    name="shapeType"
                                                    value={editedValues.shapeType || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.shapeType || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='metalType'
                                                    name="metalType"
                                                    value={editedValues.metalType || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.metalType || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">

                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='jewelrySize'
                                                    name="jewelrySize"
                                                    value={editedValues.jewelrySize || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.jewelrySize || "N/A"
                                            )}
                                        </td>
                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='diamondApoSize'
                                                    name="diamondApoSize"
                                                    value={editedValues.diamondApoSize || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.diamondApoSize || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='dia_weight'
                                                    name="dia_weight"
                                                    value={editedValues.dia_weight || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.dia_weight || "N/A"
                                            )}
                                        </td>

                                        <td id="showUserTablebody">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='comment'
                                                    name="comment"
                                                    value={editedValues.comment}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                <div>
                                                    <CommentIcon
                                                        data-bs-toggle="modal"
                                                        data-bs-target={`#modalfade-${index}`}
                                                        // onClick={handleOpenModal}
                                                        style={{ color: "#008000" }}
                                                    />
                                                    <div
                                                        className="modal fade"
                                                        id={`modalfade-${index}`}
                                                        tabIndex="-1"
                                                        aria-labelledby={`popupLabel-${index}`}
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="fw-bold text-start"
                                                                        id={`popupLabel-${index}`}
                                                                    >
                                                                        Comment- B{row.blk_order_id}-J{row.order_id}
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>
                                                                <div className="modal-body text-center">
                                                                    <span>{row.comment || "N/A"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center" id='t_qty'>
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="text"
                                                    placeholder='T_qty'
                                                    name="qty"
                                                    value={editedValues.qty || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.qty || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='mf_fg_exp' onClick={() => handleModalMFGClick(row.order_id)}>
                                            {row.mfg || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='mf_fg_exp' onClick={() => handleModalFGClick(row.order_id)}>
                                            {row.fg || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='mf_fg_exp' onClick={() => handleModalExportClick(row.order_id)}>
                                            {row.export || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='pen_ding' onClick={() => handleModalPendingClick()}>
                                            {row.pending || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='td_qty' onClick={() => handleModalT_DiaQtyClick(row.order_id)}>
                                            {row.total_dia_qty || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='polish' onClick={() => handleModalPolishClick(row.order_id)}>
                                            {row.in_stock || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center cursor-pointer" id='rough_short' onClick={() => handleModalRoughClick(row.order_id)}>
                                            {row.rough_short || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center" id='purchase_pending'>
                                            {row.purchase_pending || 0}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="date"
                                                    placeholder='est_date'
                                                    name="est_date"
                                                    value={editedValues.est_date || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                row.est_date || "N/A"
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            {editMode && editingRowIndex === index ? (
                                                <input
                                                    type="date"
                                                    name="due_date"
                                                    value={editedValues.due_date || ""}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                formatDate(row.due_date || "N/A")
                                            )}
                                        </td>

                                        <td id="showUserTablebody">
                                            <div key={row.id}>
                                                <MDBBtn
                                                    id={`chatBox-${row.id}`}
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#viewImage-${row.id}`}
                                                    className="userChat"
                                                    style={{
                                                        backgroundColor: row.finalImg
                                                            ? ""
                                                            : "#EBEBEB",
                                                    }}
                                                >
                                                    <MDBIcon fas icon="photo-video" />
                                                </MDBBtn>
                                                <div
                                                    className="modal fade"
                                                    id={`viewImage-${row.id}`}
                                                    tabIndex="-1"
                                                    aria-labelledby={`popupLabel-${row.id}`}
                                                    aria-hidden="true"
                                                >
                                                    <div className="modal-dialog modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5
                                                                    className="modal-title"
                                                                    id={`popupLabel-${row.id}`}
                                                                >
                                                                    Final Image -  B{row.blk_order_id}-J{row.order_id}
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                ></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                {uploading ? (
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {row.finalImg ? (
                                                                            <MDBCarousel
                                                                                showControls
                                                                                style={{ color: "red" }}
                                                                            >
                                                                                <div>
                                                                                    {JSON.parse(
                                                                                        row.finalImg
                                                                                    ).map((media, mediaIndex) => (
                                                                                        <MDBCarouselItem
                                                                                            key={mediaIndex}
                                                                                            itemId={mediaIndex + 1}
                                                                                        >
                                                                                            <div key={mediaIndex}>
                                                                                                {media.endsWith(".mp4") ? (
                                                                                                    <video
                                                                                                        controls
                                                                                                        className="w-100"
                                                                                                    >
                                                                                                        <source
                                                                                                            src={`${baseUrl}/${media}`}
                                                                                                            type="video/mp4"
                                                                                                        />
                                                                                                        Your browser does not
                                                                                                        support the video
                                                                                                    </video>
                                                                                                ) : (
                                                                                                    <img
                                                                                                        src={`${baseUrl}/${media}`}
                                                                                                        alt={`Media ${mediaIndex}`}
                                                                                                        className="w-100"
                                                                                                        loading="lazy"
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                        </MDBCarouselItem>
                                                                                    ))}
                                                                                </div>
                                                                            </MDBCarousel>
                                                                        ) : (
                                                                            <img
                                                                                src="No Image Fund.jpg"
                                                                                alt="No Images"
                                                                                className=" w-100 h-100 mw-50 mh-50"
                                                                                loading="lazy"
                                                                            />
                                                                        )}
                                                                        <hr />
                                                                        <div>
                                                                            <h5>Upload Images or Videos</h5>
                                                                            <input
                                                                                type="file"
                                                                                id={`imageUpload-${row.id}`}
                                                                                accept="image/*, video/*"
                                                                                name="finalImg[]"
                                                                                onChange={(e) =>
                                                                                    handleFileChange(e, row.id)
                                                                                }
                                                                                multiple
                                                                            />

                                                                            <button
                                                                                onClick={() =>
                                                                                    handleUpload(row.id)
                                                                                }
                                                                                disabled={!selectedFiles}
                                                                            >
                                                                                Upload
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td id="showUserTablebody">
                                            <MDBBtn
                                                className="userChat"
                                                id="chatBox"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#popup-${index}`}
                                                onClick={() => setChatId(row.order_id)}
                                            >
                                                <MDBIcon far icon="comments" />
                                            </MDBBtn>
                                            <div
                                                className="modal fade"
                                                id={`popup-${index}`}
                                                tabIndex="-1"
                                                aria-labelledby={`popupLabel-${index}`}
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <div className="text-start">
                                                                <span>ChatBox-B{row.blk_order_id}-J{row.order_id}</span>
                                                                <br />
                                                                <span className="text-danger">
                                                                    *Content can be deleted within 3 minutes
                                                                </span>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                            ></button>
                                                        </div>
                                                        <div
                                                            className="modal-body"
                                                            ref={chatBoxRef}
                                                            onScroll={handleScroll}
                                                        >
                                                            {/* <BulkChatBox
                                                                chatPostId={row.order_id}
                                                                chatData={chatData}
                                                                handleChat={handleChat}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td id="showUserTablebody" >
                                            <DeleteForeverIcon
                                                onClick={() => {
                                                    handleSubOrderDelete(row.sku_id);
                                                    setShowPopup(true);
                                                }}
                                            />
                                        </td>

                                        <td id="showUserTablebody">
                                            {editMode && editingRowIndex === index ? (
                                                <>
                                                    <SaveIcon
                                                        onClick={() =>
                                                            handleSubOrderSave(row.sku_id)
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <EditIcon onClick={() => toggleEditMode(index)} />
                                            )}
                                        </td>

                                        <td className="px-0 py-2 text-center">
                                            <i
                                                className="fas fa-circle-minus fs-5"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => deleteRowSubOrder(index)}
                                            ></i>
                                        </td>
                                    </tr>
                                ))}

                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        <td className="px-0 py-1 text-center">
                                            <input
                                                type='checkbox'
                                            />
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    placeholder='JewelryType'
                                                    className='JewelryTypesku'
                                                    value={row.jewelryType || ""}
                                                    required
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        handleChange(index, "jewelryType", e.target.value)
                                                    }
                                                    onFocus={() => handleFocus(`jewelryType-${index}`)}
                                                />
                                                {focusedInput === `jewelryType-${index}` && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "calc(100% + 5px)",
                                                            left: 0,
                                                            width: "100%",
                                                            maxHeight: "150px",
                                                            overflowY: "auto",
                                                            border: "1px solid #ccc",
                                                            backgroundColor: "white",
                                                            zIndex: 1000,
                                                            padding: "10px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="scrollable"
                                                    >
                                                        {[
                                                            "BRACELET",
                                                            "NECKLACE",
                                                            "EARRING",
                                                            "ETERNITY BAND",
                                                            "RING",
                                                            "PENDANT",
                                                        ].filter((option) =>
                                                            option
                                                                .toLowerCase()
                                                                .includes((row.jewelryType || "").toLowerCase())
                                                        )
                                                            .map((option, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    onMouseDown={() => {
                                                                        handleChange(index, "jewelryType", option);
                                                                        handleBlur();
                                                                    }}
                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                    className="dropdown-option"
                                                                >
                                                                    {option}
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    placeholder='Sub Type'
                                                    type='text'
                                                    value={row.jewelrySubType}
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        handleChange(index, "jewelrySubType", e.target.value)
                                                    }
                                                    onFocus={() => handleFocus(`jewelrySubType-${index}`)}
                                                />
                                                {focusedInput === `jewelrySubType-${index}` && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "calc(100% + 5px)",
                                                            left: 0,
                                                            width: "100%",
                                                            maxHeight: "300px",
                                                            overflowY: "auto",
                                                            border: "1px solid #ccc",
                                                            backgroundColor: "white",
                                                            zIndex: 1000,
                                                            padding: "10px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="scrollable"
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {row.jewelryType === "BRACELET" &&
                                                            ["Station", "Tennis", "Bangle"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jewelrySubType.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(
                                                                                index,
                                                                                "jewelrySubType",
                                                                                subtype
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "NECKLACE" &&
                                                            ["Riviera", "Station", "Tennis", "Lariat", "Special"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jewelrySubType.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(
                                                                                index,
                                                                                "jewelrySubType",
                                                                                subtype
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            [
                                                                "Hoops",
                                                                "Huggies",
                                                                "Omega Hoops",
                                                                "Studs",
                                                                "Hanging",
                                                                "Crawler",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jewelrySubType.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(
                                                                                index,
                                                                                "jewelrySubType",
                                                                                subtype
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "ETERNITY BAND" &&
                                                            [
                                                                "Eternity Band",
                                                                "Half Eternity",
                                                                "3/4 Band",
                                                                "5 Stones",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jewelrySubType.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(
                                                                                index,
                                                                                "jewelrySubType",
                                                                                subtype
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            [
                                                                "3 Stones",
                                                                "3 Stones Halo",
                                                                "Hidden Halo",
                                                                "Semi Hidden Halo",
                                                                "Solitaire",
                                                                "Solitaire Diamond Shank",
                                                                "Split Shank",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jewelrySubType.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(
                                                                                index,
                                                                                "jewelrySubType",
                                                                                subtype
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "PENDANT" &&
                                                            ["Pendant"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jewelrySubType.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(
                                                                                index,
                                                                                "jewelrySubType",
                                                                                subtype
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    placeholder='Setting'
                                                    type='text'
                                                    value={row.Setting}
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        handleChange(index, "Setting", e.target.value)
                                                    }
                                                    onFocus={() => handleFocus(`Setting-${index}`)}
                                                />
                                                {focusedInput === `Setting-${index}` && (
                                                    <div
                                                        id={`Setting-${index}`}
                                                        style={{
                                                            position: "absolute",
                                                            top: "calc(100% + 5px)",
                                                            left: 0,
                                                            width: "100%",
                                                            maxHeight: "300px",
                                                            overflowY: "auto",
                                                            border: "1px solid #ccc",
                                                            backgroundColor: "white",
                                                            zIndex: 1000,
                                                            padding: "10px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="scrollable"
                                                    >
                                                        {row.jewelryType === "BRACELET" &&
                                                            row.jewelrySubType === "Station" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "BRACELET" &&
                                                            row.jewelrySubType === "Tennis" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                "Prong (Inclined)",
                                                                "Bezel (Inclined)",
                                                                "Prong",
                                                                "Bezel",
                                                                "Bar Set",
                                                                "Bar Set (NS)",
                                                                "Bar Set (WS)"
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "BRACELET" &&
                                                            row.jewelrySubType === "Bangle" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                "Prong (Inclined)",
                                                                "Bezel (Inclined)",
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Hoops" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Huggies" &&
                                                            ["Prong (NS)", "Bezel (NS)", "Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Omega Hoops" &&
                                                            ["Bezel (EW)", "Bezel (NS)", "Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Prong" &&
                                                            ["Bezel (EW)", "Bezel (NS)", "Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Studs" &&
                                                            [
                                                                "Martini",
                                                                "Prong (EW)",
                                                                "Bezel (EW)",
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Hanging" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            row.jewelrySubType === "Crawler" &&
                                                            ["Prong"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "NECKLACE" &&
                                                            row.jewelrySubType === "Riviera" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "NECKLACE" &&
                                                            row.jewelrySubType === "Station" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "NECKLACE" &&
                                                            row.jewelrySubType === "Tennis" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                "Prong (Inclined)",
                                                                "Bezel (Inclined)",
                                                                ,
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "NECKLACE" &&
                                                            row.jewelrySubType === "Lariat" &&
                                                            ["Prong (NS)"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "PENDANT" &&
                                                            row.jewelrySubType === "Pendant" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                ,
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "3 Stones" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "3 Stones Halo" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "Hidden Halo" &&
                                                            ["Prong"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "Semi Hidden Halo" &&
                                                            ["Prong"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "Solitaire" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "Solitaire Diamond Shank" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "Split Shank" &&
                                                            ["Prong"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            row.jewelrySubType === "Special" &&
                                                            ["Prong", "Bezel"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "ETERNITY BAND" &&
                                                            row.jewelrySubType === "3/4 Band" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                ,
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "ETERNITY BAND" &&
                                                            row.jewelrySubType === "5 Stones" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                ,
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "ETERNITY BAND" &&
                                                            row.jewelrySubType === "Eternity Band" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                ,
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "ETERNITY BAND" &&
                                                            row.jewelrySubType === "Half Eternity" &&
                                                            [
                                                                "Prong (EW)",
                                                                "Prong (NS)",
                                                                "Bezel (EW)",
                                                                "Bezel (NS)",
                                                                ,
                                                                "Prong",
                                                                "Bezel",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.Setting.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "Setting", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            <input
                                                placeholder='Ref. Piece	'
                                                type='text'
                                                value={row.ref_lot}
                                                onChange={(e) => handleChange(index, 'ref_lot', e.target.value)}
                                            />
                                        </td>

                                        <td id="showUserTablebody" style={{ position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    placeholder='ShapeType'
                                                    className='shapeTypeSku'
                                                    value={row.shapeType || ""}
                                                    required
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        handleChange(index, "shapeType", e.target.value)
                                                    }
                                                    onFocus={() => handleFocus(`shapeType-${index}`)}
                                                />
                                                {focusedInput === `shapeType-${index}` && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "calc(100% + 5px)",
                                                            left: 0,
                                                            width: "100%",
                                                            maxHeight: "150px",
                                                            overflowY: "auto",
                                                            border: "1px solid #ccc",
                                                            backgroundColor: "white",
                                                            zIndex: 1000,
                                                            padding: "10px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="scrollable"
                                                    >
                                                        {shapeOptions
                                                            .filter((option) =>
                                                                option
                                                                    .toLowerCase()
                                                                    .includes((row.shapeType || "").toLowerCase())
                                                            )
                                                            .map((option, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    onMouseDown={() => {
                                                                        handleChange(index, "shapeType", option);
                                                                        handleBlur();
                                                                    }}
                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                    className="dropdown-option"
                                                                >
                                                                    {option}
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    placeholder='Metal'
                                                    type='text'
                                                    value={row.metal}
                                                    onFocus={() => handleFocus(`metal-${index}`)}
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        handleChange(index, "metal", e.target.value)
                                                    }
                                                />
                                                {focusedInput === `metal-${index}` && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "calc(100% + 5px)",
                                                            left: 0,
                                                            width: "100%",
                                                            maxHeight: "150px",
                                                            overflowY: "auto",
                                                            border: "1px solid #ccc",
                                                            backgroundColor: "white",
                                                            zIndex: 1000,
                                                            padding: "10px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="scrollable"
                                                    >
                                                        {["14KW", "14KY", "18KW", "18KY", "RG", "PT"]
                                                            .filter((option) =>
                                                                option
                                                                    .toLowerCase()
                                                                    .includes(row.metal.toLowerCase())
                                                            )
                                                            .map((option, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    W
                                                                    onMouseDown={() => {
                                                                        handleChange(index, "metal", option);
                                                                        handleBlur();
                                                                    }}
                                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                                    className="dropdown-option"
                                                                >
                                                                    {option}
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-0 py-1 text-center" style={{ position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                <input
                                                    placeholder='Size'
                                                    type='text'
                                                    value={row.jew_size}
                                                    onFocus={() => handleFocus(`jew_size-${index}`)}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => handleChange(index, 'jew_size', e.target.value)}
                                                />
                                                {focusedInput === `jew_size-${index}` && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "calc(100% + 5px)",
                                                            left: 0,
                                                            width: "100%",
                                                            maxHeight: "150px", // Adjust height as needed
                                                            overflowY: "auto",
                                                            border: "1px solid #ccc",
                                                            backgroundColor: "white",
                                                            zIndex: 1000,
                                                            padding: "10px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="scrollable"
                                                    >
                                                        {row.jewelryType === "BRACELET" &&
                                                            [
                                                                "5.00 Inch",
                                                                "5.50 Inch",
                                                                "5.75 Inch",
                                                                "6.00 Inch",
                                                                "6.25 Inch",
                                                                "6.50 Inch",
                                                                "6.75 Inch",
                                                                "7.00 Inch",
                                                                "7.50 Inch",
                                                                "8.00 Inch",
                                                                "8.50 Inch",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jew_size.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "jew_size", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "NECKLACE" &&
                                                            [
                                                                "16.00 Inch",
                                                                "16.50 Inch",
                                                                "17.00 Inch",
                                                                "17.50 Inch",
                                                                "18.00 Inch",
                                                                "24.00 Inch",
                                                                "36.00 Inch",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jew_size.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "jew_size", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "EARRING" &&
                                                            [
                                                                "16.00 mm",
                                                                "19.00 mm",
                                                                "25.00 mm",
                                                                "28.00 mm",
                                                                "32.00 mm",
                                                                "38.00 mm",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jew_size.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "jew_size", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "ETERNITY BAND" &&
                                                            [
                                                                "3.75 US",
                                                                "4.00 US",
                                                                "4.25 US",
                                                                "4.50 US",
                                                                "4.75 US",
                                                                "5.00 US",
                                                                "5.25 US",
                                                                "5.50 US",
                                                                "5.75 US",
                                                                "6.00 US",
                                                                "6.25 US",
                                                                "6.50 US",
                                                                "6.75 US",
                                                                "7.00 US",
                                                                "7.25 US",
                                                                "7.50 US",
                                                                "7.75 US",
                                                                "8.00 US",
                                                                "8.50 US",
                                                                "9.00 US",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jew_size.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "jew_size", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "RING" &&
                                                            [
                                                                "3.75 US",
                                                                "4.00 US",
                                                                "4.25 US",
                                                                "4.50 US",
                                                                "4.75 US",
                                                                "5.00 US",
                                                                "5.25 US",
                                                                "5.50 US",
                                                                "5.75 US",
                                                                "6.00 US",
                                                                "6.25 US",
                                                                "6.50 US",
                                                                "6.75 US",
                                                                "7.00 US",
                                                                "7.25 US",
                                                                "7.50 US",
                                                                "7.75 US",
                                                                "8.00 US",
                                                                "8.50 US",
                                                                "9.00 US",
                                                            ]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jew_size.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "jew_size", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                        {row.jewelryType === "PENDANT" &&
                                                            ["16.00 Inch", "18.00 Inch"]
                                                                .filter((option) =>
                                                                    option
                                                                        .toLowerCase()
                                                                        .includes(row.jew_size.toLowerCase())
                                                                )
                                                                .map((subtype, i) => (
                                                                    <div
                                                                        key={i}
                                                                        onMouseDown={() => {
                                                                            handleChange(index, "jew_size", subtype);
                                                                            handleBlur();
                                                                        }}
                                                                        style={{ cursor: "pointer", padding: "5px" }}
                                                                        className="dropdown-option"
                                                                    >
                                                                        {subtype}
                                                                    </div>
                                                                ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            <input
                                                placeholder='Dia Size'
                                                type='text'
                                                value={row.dia_size}
                                                onChange={(e) => handleChange(index, 'dia_size', e.target.value)}
                                            />
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td id="showUserTablebody">
                                            <i
                                                className="far fa-comment-dots fs-5"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCommentClick(index)}
                                            ></i>
                                            {commentIndex === index && (
                                                <textarea
                                                    className="form-control mt-2"
                                                    rows="3"
                                                    value={row.comment}
                                                    onChange={(e) => handleChange(index, 'comment', e.target.value)}
                                                ></textarea>
                                            )}
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            <input
                                                placeholder='T.Qty'
                                                type='text'
                                                value={row.t_qty}
                                                onChange={(e) => handleChange(index, 't_qty', e.target.value)}
                                            />
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            <input
                                                type='date'
                                                value={row.Due_Date}
                                                onChange={(e) => handleChange(index, 'Due_Date', e.target.value)}
                                            />
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td className="px-0 py-1 text-center">
                                            -
                                        </td>

                                        <td id="showUserTablebody">-</td>
                                        <td id="showUserTablebody">-</td>
                                        <td id="showUserTablebody">
                                            <i
                                                className="fas fa-circle-minus fs-5"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => deleteRowSubOrder(index)}
                                            ></i>
                                        </td>

                                    </tr>
                                ))}

                            </tbody>
                            <i
                                className="fas fa-circle-plus fs-4"
                                id="addNewRowBtn"
                                onClick={addRow}
                            ></i>

                            <div style={{ margin: "0 auto" }}>
                                <button type="submit" id="orderNow" disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </table>
                    </form>
                </>
            )}

            <MDBModal open={centredModal} setOpen={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={closeModal}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p className="text-danger fs-4 text-center fw-semibold">
                                ARE YOU SURE?
                            </p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="danger" onClick={closeModal}>
                                Close
                            </MDBBtn>
                            <MDBBtn color="success" onClick={saveRequestChanges}>
                                Save changes
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <AdminSkuModal
                handleTotal_Dia_Qty={handleTotal_Dia_Qty}
                bulkID={bulkID}
                Order_ID={Order_ID}
                setOrder_ID={setOrder_ID}
                shapeOptions={shapeOptions}
                fetchSkuData={fetchSkuData}
                fetchBulkOrder={fetchBulkOrder}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                // handleModalMFGClick={handleModalMFGClick}
                // handleModalFGClick={handleModalFGClick}
                // handleModalExportClick={handleModalExportClick}
                // handleModalPendingClick={handleModalPendingClick}
                handleModalT_DiaQtyClick={handleModalT_DiaQtyClick}
            // handleModalPolishClick={handleModalPolishClick}
            // handleModalRoughClick={handleModalRoughClick}

            />
        </div>
    )
}
