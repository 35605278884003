import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBBtn, MDBCarousel, MDBCarouselItem, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import ChatApp from '../../../Users/chatbot/ChatApp';

const OrderHistory = () => {
  const [remittanceData, setRemittanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to display per page

  useEffect(() => {
    const fetchRemittanceData = async () => {
      try {
        const response = await axios.get('https://651116a6829fa0248e3f7c6e.mockapi.io/RemittanceIDPMS');
        setRemittanceData(response.data);
      } catch (error) {
        console.error('Error fetching remittance data:', error);
      }
    };

    fetchRemittanceData();
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRemittanceData = remittanceData?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <table className='table table-bordered'>
        <thead id='addProductTableHead'>
          <tr>
            <th className='p-2 fw-bold'>Order_No</th>
            <th className='p-2 fw-bold'>Req_Date</th>
            <th className='p-2 fw-bold'>Delivery_Date</th>
            <th className='p-2 fw-bold'>Req._Status</th>
            <th className='p-2 fw-bold'>Jewelry_Type</th>
            <th className='p-2 fw-bold'>Jewelry_Sub_Type</th>
            <th className='p-2 fw-bold'>Ref_Lot_ID</th>
            <th className='p-2 fw-bold'>Shape_Type</th>
            <th className='p-2 fw-bold'>Metal_Type</th>
            <th className='p-2 fw-bold'>Jewelry_Size</th>
            <th className='p-2 fw-bold'>Diamond_a/p_Size</th>
            <th className='p-2 fw-bold'>Qty</th>
            <th className='p-2 fw-bold'>Final Image</th>
          </tr>
        </thead>
        <tbody style={{ overflow: "auto", width: '100%', height: '200px' }}>
          {currentRemittanceData?.map((remittance, index) => (
            <tr key={index} >
              <td id='showUserTablebody'>{remittance.id*54}</td>
              <td id='showUserTablebody'>10/01/2024</td>
              <td id='showUserTablebody'>02/05/2024</td>
              <td id='showUserTablebody' className='text-success'>Aproved</td>
              <td id='showUserTablebody'>{remittance['0'].jewelryType}</td>
              <td id='showUserTablebody'>{remittance['0'].jewelrySubType}</td>
              <td id='showUserTablebody'>{remittance['0'].refLotId}</td>
              <td id='showUserTablebody'>{remittance['0'].shapeType}</td>
              <td id='showUserTablebody'>{remittance['0'].metalType}</td>
              <td id='showUserTablebody'>{remittance['0'].jewelrySize}</td>
              <td id='showUserTablebody'>{remittance['0'].diamondApoSize} ct</td>
              <td id='showUserTablebody'>{remittance['0'].Qty}</td>
                        
              <td id='showUserTablebody'>
              <MDBBtn   id='chatBox' data-bs-toggle="modal" data-bs-target='#viewImage'><MDBIcon fas icon="photo-video" /></MDBBtn>
                <div className="modal fade" id='viewImage' tabIndex="-1" aria-labelledby={`popupLabel-${index}`} aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                                <h5 className="modal-title" id={`popupLabel-${index}`}>Final Image</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <div>
                                <MDBCarousel showControls fade>
                                <MDBCarouselItem itemId={1}>
                                  <img src='cadimg.png' className='d-block w-100' alt='...' loading="lazy" />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={2}>
                                  <img src='cadimg1.png' className='d-block w-100' alt='...' loading="lazy" />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={3}>
                                  <img src='cadimg2.png' className='d-block w-100' alt='...' loading="lazy" />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={4}>
                                  <img src='cadimg3.png' className='d-block w-100' alt='...' loading="lazy" />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={5}>
                                  <img src='cadimg4.png' className='d-block w-100' alt='...' loading="lazy" />
                                </MDBCarouselItem>
                              </MDBCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <ul className="pagination">
        {Array.from({ length: Math.ceil(remittanceData.length / itemsPerPage) }).map((_, index) => (
          <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderHistory;
