import React, { useEffect, useState } from 'react';
import Papa from "papaparse";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import Swal from 'sweetalert2';
import { baseUrl } from '../../../utils/Url';

export default function CadMasterCsvUpload({ setCsvData }) {
    const [file, setFile] = useState(null);
    const [showUploadSection, setShowUploadSection] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) {
            alert("Please select a CSV file to upload.");
            return;
        }
        const token = localStorage.getItem("token");

        if (!token) {
            alert("Please log in to upload CSV files.");
            return;
        }
        Papa.parse(file, {
            complete: (result) => {
                const parsedData = result.data;
                sendToApi(parsedData, token);
            },
            header: true,
        });
    };

    const sendToApi = async (parsedData, token) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formdata = new FormData();
            formdata.append("csv_file", file, "style_name.csv");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            const response = await fetch(`${baseUrl}/api/cadmaster-upload`, requestOptions);
            const responseBody = await response.text();

            if (response.status === 200) {
                ShowAllData();
                await Swal.fire({
                    title: "Upload successful!!",
                    text: "Upload Successfully Submitted!",
                    icon: "success",
                });
                setShowUploadSection(false);
                setModalOpen(false);
            } else {
                console.error("Error response from server:", response.status, responseBody);
                alert("An error occurred while storing data. Please try again.");
            }
        } catch (error) {
            console.error("Error uploading CSV data:", error);
            Swal.fire({
                icon: "error",
                title: "Try again...",
                text: "Signup failed. Please check your details.",
            });
        }
    };

    const ShowAllData = async () => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(
                `${baseUrl}/api/cadmaster`,
                requestOptions
            );

            if (response.ok) {
                const responseData = await response.json();
                setCsvData(responseData);
            } else {
                console.error("Error uploading CSV data:", response.error);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        ShowAllData();
    }, []);

    return (
        <div>
            <div className='d-flex justify-content-end CadMaster_Card'>
                <MDBBtn onClick={() => setModalOpen(true)} className="btn-bg-color me-2">
                    Upload
                </MDBBtn>
            </div>
            {showUploadSection && (
                <MDBModal open={modalOpen} setShow={setModalOpen} tabIndex="-1">
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Upload CSV File</MDBModalTitle>
                                <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <input type="file" accept=".csv" onChange={handleFileChange} />
                                <MDBBtn onClick={handleUpload} className="btn-bg-color">
                                    Upload CSV
                                </MDBBtn>
                            </MDBModalBody>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            )}
        </div>
    );
}






// import React, { useEffect, useState } from 'react'
// import Papa from "papaparse";


// export default function CadMasterCsvUpload() {
//     const [file, setFile] = useState(null);

//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//     };

//     const handleUpload = () => {
//         if (!file) {
//             alert("Please select a CSV file to upload.");
//             return;
//         }
//         const token = localStorage.getItem("token");

//         if (!token) {
//             alert("Please log in to upload CSV files.");
//             return;
//         }
//         Papa.parse(file, {
//             complete: (result) => {
//                 const parsedData = result.data;
//                 sendToApi(parsedData, token);
//             },
//             header: true,
//         });
//     };

//     const sendToApi = async (parsedData, token) => {
//         try {
//             const myHeaders = new Headers();
//             myHeaders.append("Authorization", `Bearer ${token}`);

//             const formdata = new FormData();
//             formdata.append("csv_file", file, "style_name.csv");

//             const requestOptions = {
//                 method: "POST",
//                 headers: myHeaders,
//                 body: formdata,
//                 redirect: "follow",
//             };

//             const response = await fetch(`${baseUrl}/api/cadmaster-upload`, requestOptions);
//             const responseBody = await response.text(); // read the response body

//             if (response.status === 200) {
//                 ShowAllData();
//                 alert("Upload successful!");
//             } else {
//                 console.error("Error response from server:", response.status, responseBody);
//                 alert("An error occurred while storing data. Please try again.");
//             }
//         } catch (error) {
//             console.error("Error uploading CSV data:", error);
//             alert("An error occurred while storing data. Please try again.");
//         }
//     };


//     const ShowAllData = async () => {
//         try {
//             const token = localStorage.getItem("token");
//             const myHeaders = new Headers();
//             myHeaders.append("Authorization", `Bearer ${token}`);

//             const requestOptions = {
//                 method: "GET",
//                 headers: myHeaders,
//                 redirect: "follow",
//             };

//             const response = await fetch(`${baseUrl}/api/cadmaster`, requestOptions);
//             const data = await response.text();
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };


//     useEffect(() => {
//         ShowAllData();
//     }, []);
//     return (
//         <div>
//             <input type="file" accept=".csv" onChange={handleFileChange} />
//             <button onClick={handleUpload} className="btn-bg-color">
//                 Upload CSV
//             </button>
//         </div>
//     )
// }