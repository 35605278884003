import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBBtnGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import AdminCadMasterCsvUpload from './AdminCadMasterCsvUpload';
import Swal from 'sweetalert2';
import { baseUrl } from '../../../utils/Url';

export default function AdminCadMasterUploadPopup({ setCsvData }) {
    const [existingCadNos, setExistingCadNos] = useState([]);
    const [scrollableModal, setScrollableModal] = useState(false);
    const [formData, setFormData] = useState({
        date: new Date().toISOString().split('T')[0],
        cad_no: '',
        type: '',
        sub_type: '',
        setting: '',
        orientation: '',
        shape: '',
        jew_size: '',
        dia_size: '',
        dia_qty: '',
        dia_length: '',
        dia_width: '',
        dia_heigth: '',
        total_ct: '',
        remark_1: '',
        remark_2: '',
        cad_img_url: '',
        vedio_url: '',
        cad_file: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };


    const fetchData = async () => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            const response = await fetch(
                `${baseUrl}/api/cadmaster`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCsvData(data);
            setExistingCadNos(data.map(item => item.cad_no));
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (existingCadNos.map(cad => cad.toLowerCase()).includes(formData.cad_no.toLowerCase())) {
            Swal.fire({
                icon: 'error',
                title: 'CAD Number Exists',
                text: 'The CAD number you entered already exists. Please use a different number.',
            });
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${baseUrl}/api/cadmaster`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log("Response data:", result);
            await Swal.fire({
                title: "Good job!",
                text: "Successfully Submitted!",
                icon: "success",
            });
            setFormData({
                date: new Date().toISOString().split('T')[0],
                cad_no: '',
                type: '',
                sub_type: '',
                setting: '',
                orientation: '',
                shape: '',
                jew_size: '',
                dia_size: '',
                dia_qty: '',
                dia_length: '',
                dia_width: '',
                dia_heigth: '',
                total_ct: '',
                remark_1: '',
                remark_2: '',
                cad_img_url: '',
                vedio_url: '',
                cad_file: ''
            });
            setScrollableModal(false);
            fetchData();
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: "error",
                title: "Try again...",
                text: "Signup failed. Please check your details.",
            });
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-end align-items-center add_Card">
                <MDBBtn onClick={() => setScrollableModal(!scrollableModal)} >
                    Add Cad
                </MDBBtn>
                <MDBBtnGroup className="justify-content-between d-flex">
                    {/* <MDBBtn
                        data-bs-toggle="modal"
                        data-bs-target="#UploadCSV"
                        className="btn-bg-color me-2 uploadCsv"
                    >
                        Upload
                    </MDBBtn> */}
                    {/* <MDBBtn className="bg-danger uploadCsv" onClick={handleDownload}>
                        Download
                    </MDBBtn> */}
                </MDBBtnGroup>
            </div>

            <div>
                <AdminCadMasterCsvUpload setCsvData={setCsvData} />
            </div>

            <MDBModal open={scrollableModal} onClose={() => setScrollableModal(false)}>
                <MDBModalDialog className='cadModal' centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Cad Master</MDBModalTitle>
                            <MDBBtn
                                className='btn-close'
                                color='none'
                                onClick={() => setScrollableModal(false)}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className='cadForm'>
                            <form className='row' onSubmit={handleSubmit}>
                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Cad Date:</label>
                                        <input
                                            type="date"
                                            id="date"
                                            name="date"
                                            className="form-control"
                                            value={formData.date}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Cad Number:</label>
                                        <input
                                            type="text"
                                            id="cad_no"
                                            placeholder='Cad Number'
                                            name="cad_no"
                                            className="form-control"
                                            value={formData.cad_no}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Type:</label>
                                        <input
                                            type="text"
                                            id="type"
                                            placeholder='Type'
                                            name="type"
                                            className="form-control"
                                            value={formData.type}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Sub Type:</label>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder='Sub Type'
                                            name="sub_type"
                                            className="form-control"
                                            value={formData.sub_type}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Setting:</label>
                                        <input
                                            type="text"
                                            id="setting"
                                            placeholder='Setting'
                                            name="setting"
                                            className="form-control"
                                            value={formData.setting}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Orientation:</label>
                                        <input
                                            type="text"
                                            id="orientation"
                                            placeholder='Orientation'
                                            name="orientation"
                                            className="form-control"
                                            value={formData.orientation}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Shape:</label>
                                        <input
                                            type="text"
                                            id="shape"
                                            placeholder='Shape'
                                            name="shape"
                                            className="form-control"
                                            value={formData.shape}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>


                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Jew Size:</label>
                                        <input
                                            type="text"
                                            id="jew_size"
                                            placeholder='Jew Size'
                                            name="jew_size"
                                            className="form-control"
                                            value={formData.jew_size}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Dia. Size:</label>
                                        <input
                                            type="text"
                                            id="dia_size"
                                            placeholder='Dia. Size'
                                            name="dia_size"
                                            className="form-control"
                                            value={formData.dia_size}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Total.Ct:</label>
                                        <input
                                            type="text"
                                            id="total_ct"
                                            placeholder='Total Ct'
                                            name="total_ct"
                                            className="form-control"
                                            value={formData.total_ct}
                                            onChange={handleChange}
                                        />
                                    </div>

                                </div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Dia. Qty:</label>
                                        <input
                                            type="text"
                                            id="dia_qty"
                                            placeholder='Dia. Qty'
                                            name="dia_qty"
                                            className="form-control"
                                            value={formData.dia_qty}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Dia. Length:</label>
                                        <input
                                            type="text"
                                            id="dia_length"
                                            placeholder='Dia. Qty'
                                            name="dia_length"
                                            className="form-control"
                                            value={formData.dia_length}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Dia. Width:</label>
                                        <input
                                            type="text"
                                            id="dia_width"
                                            placeholder='Dia. Width'
                                            name="dia_width"
                                            className="form-control"
                                            value={formData.dia_width}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Dia. Height:</label>
                                        <input
                                            type="text"
                                            id="dia_heigth"
                                            placeholder='Dia. Height'
                                            name="dia_heigth"
                                            className="form-control"
                                            value={formData.dia_heigth}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Remark 1:</label>
                                        <input
                                            type="text"
                                            id="remark_1"
                                            placeholder='Remark 1'
                                            name="remark_1"
                                            className="form-control"
                                            value={formData.remark_1}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Remark 2:</label>
                                        <input
                                            type="text"
                                            id="remark_2"
                                            placeholder='Remark 2'
                                            name="remark_2"
                                            className="form-control"
                                            value={formData.remark_2}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="form-group">
                                        <label className='fw-bold'>Cad Image:</label>
                                        <input
                                            type="text"
                                            id="cad_img_url"
                                            placeholder='Cad Image URL'
                                            name="cad_img_url"
                                            className="form-control"
                                            value={formData.cad_img_url}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className='fw-bold'>Cad Video:</label>
                                        <input
                                            type="text"
                                            id="vedio_url"
                                            placeholder='Cad Video URL'
                                            name="vedio_url"
                                            className="form-control"
                                            value={formData.vedio_url}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mt-3">
                                    <label className='fw-bold'>Cad File:</label>
                                    <input
                                        type="text"
                                        id="cad_file"
                                        name="cad_file"
                                        placeholder='Cad File URL'
                                        className="form-control"
                                        value={formData.cad_file}
                                        onChange={handleChange}
                                    />
                                </div>

                                <MDBModalFooter className='d-flex justify-content-center'>
                                    <MDBBtn color='secondary' onClick={() => setScrollableModal(false)}>
                                        Close
                                    </MDBBtn>
                                    <MDBBtn type="submit">Submit</MDBBtn>
                                </MDBModalFooter>
                            </form>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}
