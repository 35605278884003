import React, { lazy, Suspense, useState } from 'react'
import SuperAdminSidebar from '../SuperAdminSidebar/SuperAdminSidebar';
import SuperAdminNavbar from '../SuperAdminSidebar/SuperAdminNavbar';
import CadMasterUploadPopup from './Component/CadMasterUploadPopup';

// Lazy load the SuperAdminCardFilter component
const CadMasterRequirement = lazy(() => import('./Component/CadMasterRequirement'));

export default function CadMasterOrder() {
    const [csvData, setCsvData] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div id='admin-body'>
            <SuperAdminNavbar />
            <div className='d-flex'>
                <SuperAdminSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div style={{ flex: 1, padding: '20px', marginLeft: isSidebarOpen ? '13%' : '4.5%' }}>

                    <div className='pb-3'>
                        <CadMasterUploadPopup
                            setCsvData={setCsvData}
                        />
                    </div>
                    <div id="superAdrequimentForm">
                        <Suspense fallback={<div>Loading...</div>}>
                            <CadMasterRequirement
                                csvData={csvData} setCsvData={setCsvData}
                            />
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
}
