import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { baseUrl } from "../../../utils/Url";

const SuperAdminCsvUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please select a CSV file to upload.");
      return;
    }
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Please log in to upload CSV files.");
      return;
    }
    Papa.parse(file, {
      complete: (result) => {
        const parsedData = result.data;
        sendToApi(parsedData, token);
      },
      header: true,
    });
  };

  const sendToApi = async (parsedData, token) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      const formdata = new FormData();
      formdata.append("csv_file", file, "style_name.csv");
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
  
      const response = await fetch(`${baseUrl}/api/jstype-upload-csv`, requestOptions);
      const responseBody = await response.text(); // read the response body
  
      if (response.status === 200) { 
        ShowAllData();
        alert("Upload successful!");
      } else {
        console.error("Error response from server:", response.status, responseBody);
        alert("An error occurred while storing data. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading CSV data:", error);
      alert("An error occurred while storing data. Please try again.");
    }
  };
  

  const ShowAllData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
  
      const response = await fetch(`${baseUrl}/api/jstype-show-all`, requestOptions);
      const data = await response.text();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    ShowAllData();
  }, []);

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload} className="btn-bg-color">
        Upload CSV
      </button>
    </div>
  );
};

export default SuperAdminCsvUpload;
