import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Ring } from "../../Super_Admin/SVG/Svg";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import { useSelector } from "react-redux";
import { Permission, Permissionclient } from "../../utils/Url";

export default function UserSidebar({
  closeSidebar,
  isSidebarOpen,
  toggleSidebar,
}) {
  const location = useLocation();
  const allowOutletlocal = localStorage.getItem("allowOutlet");

  return (
    <nav
      aria-label=""
      className={`sidebar ${isSidebarOpen ? "open" : ""}`}
      style={{ position: "fixed", top: 80, height: "100%" }}
    >
      <ul
        className="sidebar-nav1 mb-4"
        style={{ width: isSidebarOpen ? "250px" : "" }}
      >
        {Permissionclient.includes("userorder") && (
          <li className="sidebar-item">
            <Link
              className={`text-dark ${
                location.pathname === "/userOrder" ? "active-link" : ""
              }`}
              to="/userOrder"
              onClick={closeSidebar}
              style={{
                color: location.pathname === "/userOrder" ? "white" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <LocalMallIcon
                  style={{
                    color:
                      location.pathname === "/userOrder" ? "white" : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/userOrder"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Order
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}

        {Permissionclient.includes("userstyle") && (
          <li className="sidebar-item">
            <Link
              className={`text-dark ${
                location.pathname === "/userStyle" ? "active-link" : ""
              }`}
              to="/userStyle"
              onClick={closeSidebar}
              style={{
                color: location.pathname === "/userStyle" ? "white" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2  text-uppercase fw-bold">
                {
                  <Ring
                    style={{
                      color:
                        location.pathname === "/userStyle"
                          ? "white"
                          : "inherit",
                    }}
                  />
                }
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/userStyle"
                          ? "white"
                          : "inherit",
                      minWidth: "max-content",
                    }}
                  >
                    Jewellery Style
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}

        {Permissionclient.includes("useroutlet") && (
          <>
            <>
              {allowOutletlocal == 1 && (
                <li className="sidebar-item">
                  <Link
                    className={`text-dark ${
                      location.pathname === "/SubUser" ? "active-link" : ""
                    }`}
                    to="/SubUser"
                    onClick={closeSidebar}
                    style={{
                      color:
                        location.pathname === "/SubUser" ? "white" : "inherit",
                    }}
                  >
                    <div className="d-flex justify-content-between gap-2  text-uppercase fw-bold">
                      {
                        <GroupAddRoundedIcon
                          style={{
                            color:
                              location.pathname === "/SubUser"
                                ? "white"
                                : "inherit",
                          }}
                        />
                      }
                      {isSidebarOpen && (
                        <span
                          style={{
                            color:
                              location.pathname === "/SubUser"
                                ? "white"
                                : "inherit",
                          }}
                        >
                          Sub_User
                        </span>
                      )}
                    </div>
                  </Link>
                </li>
              )}
            </>
          </>
        )}

        <li>
          <div
            className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold drowwer_btn"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? (
              <div className="vertical-layout">
                <ArrowCircleLeftIcon  style={{ color: "#83193E" }}/>
                <span className="mt-2">Close</span>
              </div>
            ) : (
              <div className="vertical-layout">
                <ArrowCircleRightRoundedIcon  style={{ color: "#83193E" }}/>
                <span className="mt-2">Open</span>
              </div>
            )}
          </div>
        </li>
      </ul>
    </nav>
  );
}
