import React from 'react'

export function DashbordSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="dashboard"><path fill='#fff' d="M8.5 3h-3a2.5 2.5 0 0 0 0 5h3a2.5 2.5 0 0 0 0-5zm0 4h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zm0 3h-3A2.5 2.5 0 0 0 3 12.5v6A2.5 2.5 0 0 0 5.5 21h3a2.5 2.5 0 0 0 2.5-2.5v-6A2.5 2.5 0 0 0 8.5 10zm1.5 8.5A1.5 1.5 0 0 1 8.5 20h-3A1.5 1.5 0 0 1 4 18.5v-6A1.5 1.5 0 0 1 5.5 11h3a1.5 1.5 0 0 1 1.5 1.5zm8.5-2.5h-3a2.5 2.5 0 0 0 0 5h3a2.5 2.5 0 0 0 0-5zm0 4h-3a1.5 1.5 0 0 1 0-3h3a1.5 1.5 0 0 1 0 3zm0-17h-3A2.5 2.5 0 0 0 13 5.5v6a2.5 2.5 0 0 0 2.5 2.5h3a2.5 2.5 0 0 0 2.5-2.5v-6A2.5 2.5 0 0 0 18.5 3zm1.5 8.5a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 0 1-1.5-1.5v-6A1.5 1.5 0 0 1 15.5 4h3A1.5 1.5 0 0 1 20 5.5z"></path></svg>
  )
}
export function Loader() {
  return (
    <div className="loader"></div>
  )
}
export function Ring() {
  const svgString = `
    <svg fill="#000000" height="24px" width="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 297 297">
      <g>
        <path d="m170.652,116.14c9.803-4.788 20.801-7.489 32.424-7.489 40.944,0 74.256,33.332 74.256,74.302 0,40.97-33.312,74.3-74.256,74.3-14.485,0-28.004-4.187-39.441-11.393-5.587-3.52-10.678-7.754-15.135-12.59-12.207-13.245-19.681-30.922-19.681-50.317 0-16.536 5.45-31.81 14.618-44.165-3.988-4.328-10.115-9.453-15.111-12.601-12.009,15.783-19.175,35.443-19.175,56.766 0,24.173 9.172,46.239 24.215,62.907 4.54,5.032 9.614,9.569 15.134,13.526 15.393,11.032 34.239,17.534 54.576,17.534 51.79,0 93.924-42.154 93.924-93.968 0-51.815-42.134-93.97-93.924-93.97-17.336,0-33.579,4.736-47.53,12.967 5.02,3.831 10.875,9.501 15.106,14.191z"/>
        <path d="m126.337,249.75c-9.801,4.79-20.789,7.502-32.411,7.502-40.945,0-74.257-33.33-74.257-74.3 0-40.97 33.312-74.302 74.257-74.302 14.484,0 28.003,4.188 39.44,11.393 5.588,3.52 10.678,7.756 15.134,12.59 12.208,13.246 19.682,30.924 19.682,50.319 0,16.536-5.45,31.81-14.62,44.162 3.986,4.326 10.125,9.459 15.123,12.608 12.008-15.784 19.165-35.449 19.165-56.771 0-24.173-9.172-46.241-24.215-62.909-4.54-5.031-9.614-9.569-15.135-13.526-15.392-11.032-34.238-17.534-54.574-17.534-51.791-2.84217e-14-93.926,42.155-93.926,93.97 0,51.813 42.135,93.968 93.926,93.968 17.333,0 33.572-4.742 47.523-12.971-5.019-3.832-10.884-9.513-15.112-14.199z"/>
        <path d="m170.889,86.336c10.282-3.442 21.112-5.221 32.188-5.221 10.787,0 21.186,1.693 30.95,4.818 12.98-12.713 19.329-24.144 19.329-34.82 0-17.112-13.668-31.033-30.468-31.033-7.952,0-15.203,3.12-20.635,8.22-5.432-5.1-12.683-8.22-20.636-8.22-16.801,0-30.47,13.921-30.47,31.033-2.84217e-14,10.789 6.486,22.35 19.742,35.223z"/>
        <path d="m61.739,86.336c10.121-3.384 20.942-5.221 32.186-5.221 10.632,0 21.034,1.646 30.946,4.821 12.982-12.713 19.333-24.147 19.333-34.823 0-17.112-13.668-31.033-30.468-31.033-7.952,0-15.203,3.12-20.635,8.22-5.433-5.1-12.683-8.22-20.636-8.22-16.801,0-30.47,13.921-30.47,31.033 0.002,10.789 6.487,22.35 19.744,35.223z"/>
      </g>
    </svg>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: svgString }} />
  );
}


