import { MDBBtn } from "mdb-react-ui-kit";
import React, { useState, useEffect, useRef } from "react";
import { IconButton } from "@mui/material";
import FilterIcon from "@mui/icons-material/Filter";
import Swal from "sweetalert2";
import SuperAdminOrderTable from "../components/SuperAdminOrderTable";
import { baseUrl } from "../../../utils/Url";

export default function SuperAdminRequirement() {
  const [rows, setRows] = useState([]);
  const [commentIndex, setCommentIndex] = useState(null);
  const [stonesIndex, setStonesIndex] = useState(null);
  const [userData, setUserData] = useState([]);
  const [dummyUserData, setDummyUserData] = useState([]);
  const [remittanceData, setRemittanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [filteredUserData, setFilteredUserData] = useState([
    userData,
    dummyUserData,
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedShape, setSelectedShape] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewClientNameChange = (e) => {
    const { value } = e.target;
    setNewClientName(value);
  };
  const handleButtonClick = () => {
    setShowPopup(true);
    document.body.classList.add("blur-background");
  };

  const closePopup = () => {
    setShowPopup(false);
    document.body.classList.remove("blur-background");
    setNewClientName("");
  };

  // const handleNewUserSubmit = () => {
  //   const clientDetails = {
  //     clientName: newClientName,
  //   };
  //   console.log(clientDetails);
  //   closePopup();
  //   setNewClientName("")
  // };

  const handleNewUserSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("client_name", newClientName);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/c-d-c`,
        requestOptions
      );
      const result = await response.json();
      closePopup();
      setNewClientName("");
      fetchUserData();
      setRows(rows.map((row) => ({ ...row, client_name: "" })));
      Swal.fire({
        title: "Good job!",
        text: "Successfully Submitted!",
        icon: "success",
      });
    } catch (error) {
      console.error("There was an error!", error);
      Swal.fire({
        icon: "error",
        title: "Try again...",
        text: "Signup failed. Please check your details.",
      });
    }
  };

  const isUnrecognizedValue = (value) => {
    return !userData.some(
      (client) => client.client_name?.toLowerCase() === value?.toLowerCase()
    );
  };

  const handleFocus = (inputName, index) => {
    setFocusedInput(inputName);
    setCurrentIndex(index);
    setFilteredUserData([...userData, dummyUserData]);
  };

  const handleBlur = () => {
    setFocusedInput("");
    setCurrentIndex(null);
  };

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const fetchRemittanceData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/get_customize_order_list`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setRemittanceData(responseData);
      } else {
        console.error("Error fetching remittance data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };

  const fetchData = async () => {
    await Promise.all([fetchRemittanceData()]);
  };

  useEffect(() => {
    setLoading(true);
    fetchRemittanceData().then(() => setLoading(false));

    fetchData();
    fetchRemittanceData();

    // const intervalId = setInterval(fetchRemittanceData, 10000);
    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    addRow();
  }, []);

  const addRow = () => {
    const today = new Date();
    const options = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const [month, day, year] = formatter.format(today).split("/");
    const requestDate = `${year}-${month}-${day}`;

    const uniqueOrderNumber = generateUniqueOrderNumber();

    setRows([
      ...rows,
      {
        ref_by: "",
        client_code: "",
        client_name: "",
        customer: "",
        jewelryType: "",
        jewelrySubType: "",
        refLotId: "",
        vendor_style: "",
        shapeType: "",
        metalType: "",
        milgrain: "",
        jewelrySize: "",
        diamondApoSize: "",
        refLink: "",
        comment: "",
        qty: "",
        quote: "",
        PoNo: "",
        stones: "",
        head: "",
        DesignNeed: "",
        due_date: "",
        setting: "",
        estDate: "",
        estRate: "",
        requestStatus: "",
        priority: "",
        requestDate: requestDate,
        orderNumber: uniqueOrderNumber,
        refImge: null,
      },
    ]);
  };

  const generateUniqueOrderNumber = () => {
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    return randomSuffix.toString();
  };

  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleImgeChange = (event) => {
    setSelectedFiles(event.target.files);
  };


  const handleChange = (index, field, value, files) => {
    const updatedRows = [...rows];
    // if (field === "refImg") {
    //   if (value.target && value.target.files && value.target.files.length > 0) {
    //     const file = value.target.files[0];
    //     updatedRows[index][field] = file;
    //   }
    // } else {
    // const file = value.target.files;
    // updatedRows[index][field] = files;

    updatedRows[index][field] = value;

    // }
    setRows(updatedRows);

    const filteredData = userData.filter((client) => {
      if (typeof value === "string") {
        return (
          client.client_name &&
          client.client_name.toLowerCase().includes(value.toLowerCase())
        );
      }
      return false; // Return false if value is not a string
    });
    setFilteredUserData(filteredData);
  };

  const handleCommentClick = (index) => {
    setCommentIndex(commentIndex === index ? null : index);
  };


  const handleStonesClick = (index) => {
    setStonesIndex(stonesIndex === index ? null : index);
  };

  const handleCommentChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index]["comment"] = value;
    setRows(updatedRows);
  };

  const handleStonesChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index]["stones"] = value;
    setRows(updatedRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formDataToSend = new FormData();
      let fileTooLarge = false;
      rows.forEach((row, index) => {
        formDataToSend.append(`rows[${index}][ref_by]`, row.ref_by);
        formDataToSend.append(`rows[${index}][client_id]`, row.client_code);
        formDataToSend.append(`rows[${index}][clientName]`, row.client_name);
        formDataToSend.append(`rows[${index}][jewelryType]`, row.jewelryType);
        formDataToSend.append(
          `rows[${index}][jewelrySubType]`,
          row.jewelrySubType
        );
        formDataToSend.append(`rows[${index}][customer]`, row.customer);
        formDataToSend.append(`rows[${index}][setting]`, row.setting);
        formDataToSend.append(`rows[${index}][refLotId]`, row.refLotId);
        formDataToSend.append(`rows[${index}][vendor_style]`, row.vendor_style);
        // formDataToSend.append(`rows[${index}][shapeType]`, row.shapeType);
        formDataToSend.append(`rows[${index}][metalType]`, row.metalType);
        formDataToSend.append(`rows[${index}][milgrain]`, row.milgrain);
        formDataToSend.append(`rows[${index}][head]`, row.head);
        formDataToSend.append(`rows[${index}][jewelrySize]`, row.jewelrySize);
        formDataToSend.append(`rows[${index}][stones]`, row.stones);
        // formDataToSend.append(`rows[${index}][PoNo]`, row.PoNo);
        formDataToSend.append(`rows[${index}][DesignNeed]`, row.DesignNeed);
        formDataToSend.append(`rows[${index}][qty]`, row.qty);
        formDataToSend.append(`rows[${index}][quote]`, row.quote);
        formDataToSend.append(`rows[${index}][due_date]`, row.due_date);
        // formDataToSend.append(
        //   `rows[${index}][diamondApoSize]`,
        //   row.diamondApoSize
        // );
        formDataToSend.append(`rows[${index}][comment]`, row.comment);

        // formDataToSend.append(`rows[${index}][refLink]`, row.refLink);

        // formDataToSend.append(`rows[${index}][estDate]`, row.estDate);
        // formDataToSend.append(`rows[${index}][estRate]`, row.estRate);
        formDataToSend.append(
          `rows[${index}][requestStatus]`,
          row.requestStatus
        );
        // formDataToSend.append(
        //   `rows[${index}][priority]`,
        //   row.priority
        // );
        formDataToSend.append(`rows[${index}][requestDate]`, row.requestDate);
        // if (selectedFiles) {
        //   for (let i = 0; i < selectedFiles.length; i++) {
        //     // formDataToSend.append(`rows[${index}][refImge][${i}]`, row.refImge[i]);
        //     formDataToSend.append(`rows[${index}][refImge][]`, selectedFiles[i]);
        //   }
        // }

        if (selectedFiles) {
          for (let i = 0; i < selectedFiles.length; i++) {
            if (selectedFiles[i].size > 20 * 1024 * 1024) {
              fileTooLarge = true;
              break;
            } else {
              formDataToSend.append(
                `rows[${index}][refImge][]`,
                selectedFiles[i]
              );
            }
          }
        }

      });

      if (fileTooLarge) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "Oops ! The Size Limit For Images Is 20.0MB Reduce The File Size And Try Again.",
        });
        setIsSubmitting(false);
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formDataToSend,
      };

      const response = await fetch(
        `${baseUrl}/api/add_custom_order`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        fetchRemittanceData();
        // alert("Successfully Saved", responseData);
        Swal.fire({
          title: "Good job!",
          text: "Order Successfully Saved",
          icon: "success",
        });
        setRows([]);
      } else {
        console.error("Error:", response.statusText);
        // alert("An error occurred while storing data. Please try again.");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "The Internet?",
        text: "That thing is still around?",
        icon: "question",
      });
    } finally {
      setIsSubmitting(false); // Set loading state to false after the request completes
    }
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // First API call
      const response = await fetch(
        `${baseUrl}/api/users`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setUserData(responseData);
      } else {
        console.error("Error fetching User data:", response.error);
        // alert("An error occurred while fetching data. Please try again.");
      }

      // Second API call with utype=1
      const responseWithParams = await fetch(
        `${baseUrl}/api/users?utype=1`,
        requestOptions
      );

      if (responseWithParams.ok) {
        const responseDataWithParams = await responseWithParams.json();
        setDummyUserData(responseDataWithParams);
      } else {
        console.error(
          "Error fetching User data with params:",
          responseWithParams.error
        );
        // alert("An error occurred while fetching data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching User data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();

    // const intervalId = setInterval(fetchUserData, 5000);
    // return () => clearInterval(intervalId);
  }, []);

  const resetForm = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to reset all data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setRows([]);
        Swal.fire({
          title: "Reset!",
          text: "Your form has been reset.",
          icon: "success",
        });
      }
    });
  };

  // const handleClientIdChange = (e, index) => {
  //   const value = e.target.value;
  //   const updatedRows = [...rows];
  //   updatedRows[index].client_code = value;
  //   if (value === "") {
  //     updatedRows[index].client_name = "";
  //     setRows(updatedRows);
  //     return;
  //   }
  //   const selectedClient = userData.find(
  //     (client) => client.client_code === value
  //   );
  //   if (selectedClient) {
  //     updatedRows[index].client_name = selectedClient.client_name;
  //     setRows(updatedRows);
  //   } else {
  //     updatedRows[index].client_name = "";
  //     setRows(updatedRows);
  //   }
  // };

  const flattenedUserData = filteredUserData.flat();

  const handleClientNameChange = (e, index) => {
    const { value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index].client_name = value;

    const foundClient = flattenedUserData?.find(
      ((client) => client.client_name?.toLowerCase() === value?.toLowerCase()) ||
      ((client) => client.client_name?.toUpperCase() === value?.toUpperCase())
    );

    if (foundClient) {
      updatedRows[index].client_code = foundClient.client_code;
    } else {
      updatedRows[index].client_code = "";
    }

    setRows(updatedRows);

    const filteredData = userData.filter((client) =>
      client.client_name.toLowerCase().includes(value.toLowerCase())
    );
    const filteredDummyData = dummyUserData.filter((client) =>
      client.client_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredUserData([filteredData, filteredDummyData]);
  };

  const handleDropdownSelect = (client, index) => {
    handleClientNameChange({ target: { value: client.client_name } }, index);
  };

  const handleOptionSelect = (option) => {
    setSelectedShape(option);
    setSearchTerm(""); // Clear search term when option is selected
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedShape(""); // Clear selected shape when input value changes
  };

  const filteredShapes = [
    "Round",
    "Emerald",
    "Radiant",
    "Oval",
    "Pear",
    "Marquise",
    "Cushion",
    "Heart",
    "Princess",
    "Asscher",
    "Mix (As.Comment)",
    "Anti. Asscher",
    "Anti. Cushion",
    "Anti. Oval",
    "Anti. Marquise",
    "Sq. Anti. Cushion",
    "Sq. Cushion",
    "Sq. Emerald",
    "Sq. Radiant",
    "Step Pear",
    "Step Cushion",
    "Step Marquise",
    "Step Oval",
    "Duchess",
    "Trapezoid",
    "Bull",
    "Horse",
    "Bullet",
    "Flower",
    "Butterflies",
    "Briolet",
    "Baguette",
    "Kite",
    "Kriss",
    "Moon Half",
    "Octagon",
    "Octagon H&A",
    "Old European",
    "Old Mine",
    "Rose Cut",
    "Rose Oval",
    "Rose Pear",
    "Trapper Baguette",
    "Triangle",
  ].filter((shape) => shape.toLowerCase().includes(searchTerm.toLowerCase()));

  // const handleClientNameChange = (e, index) => {
  //   const value = e.target.value;
  //   const updatedRows = [...rows];
  //   updatedRows[index].client_name = value;
  //   const foundClient = userData.find((client) => client.client_name === value);

  //   if (foundClient) {
  //     updatedRows[index].client_code = foundClient.client_code;
  //   } else {
  //     updatedRows[index].client_code = "";
  //   }

  //   setRows(updatedRows);
  // };

  return (
    <div>
      <h2 className="pb-2">Requirement Form</h2>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        id="requimentForm"
      >
        <table className="table table-bordered">
          <thead id="addProductTableHead">
            <tr>
              <th className="p-0 py-2 fw-bold">Order #</th>
              {/* <th className="p-0 py-2 fw-bold">
                C_ID<span className="text-danger fs-6">*</span>
              </th> */}
              <th className="p-0 py-2 fw-bold">
                Outlet<span className="text-danger fs-6">*</span>
              </th>
              <th className="p-0 py-2 fw-bold">
                Customer
              </th>
              <th className="p-0 py-2 fw-bold">
                Type
              </th>
              <th className="p-0 py-2 fw-bold">
                Sub_Types
              </th>
              <th className="p-0 py-2 fw-bold">Setting</th>
              <th className="p-0 py-2 fw-bold">Gdc Style</th>
              <th className="p-0 py-2 fw-bold">Vendor Style</th>
              {/* <th className="p-0 py-2 fw-bold">Shape</th> */}
              <th className="p-0 py-2 fw-bold">
                Metal
              </th>
              <th className="p-0 py-2 fw-bold">
                Milgrain
              </th>
              <th className="p-0 py-2 fw-bold">Head</th>
              <th className="p-0 py-2 fw-bold">Size</th>
              {/* <th className="p-0 py-2 fw-bold">Diamond Size</th> */}
              <th className="p-0 py-2 fw-bold">Stones</th>
              {/* <th className="p-0 py-2 fw-bold">Ref.link</th> */}
              {/* <th className="p-0 py-2 fw-bold">Po.No.</th> */}
              <th className="p-0 py-2 fw-bold">
                Cad Cam
              </th>
              {/* <th className="p-0 py-2 fw-bold">Ref.Image</th> */}
              <th className="p-0 py-2 fw-bold">
                Quantity
              </th>
              <th className="p-0 py-2 fw-bold">
                Quote
              </th>
              <th className="p-0 py-2 fw-bold">Instructions</th>
              <th className="p-0 py-2 fw-bold">Due Date</th>
              {/* <th className="p-0 py-2 fw-bold">priority</th> */}
              <th className="p-0 py-2 fw-bold">Del.</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => (
              <tr key={index}>
                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <input
                    placeholder="Order #"
                    className="w-100"
                    type="text"
                    value={row.ref_by}
                    onFocus={() => handleFocus("ref_by", index)}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      handleChange(index, "ref_by", e.target.value)
                    }
                    style={{ width: "100%" }}
                  />

                  {focusedInput === "ref_by" && currentIndex === index && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        maxWidth: "200px",
                        maxHeight: "300px",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        backgroundColor: "white",
                        zIndex: 1000,
                        padding: "10px",
                        fontWeight: "700",
                      }}
                      className="scrollable"
                    >
                      {filteredUserData.map((client, idx) => (
                        <div
                          key={idx}
                          onMouseDown={() =>
                            handleChange(index, "ref_by", client.client_name)
                          }
                          style={{ cursor: "pointer", padding: "5px" }}
                          className="dropdown-option"
                        >
                          {client.client_name}
                        </div>
                      ))}
                    </div>
                  )}
                </td>

                {/* <td className="px-0 py-1 text-center">
                  <input
                    list="ref_by"
                    placeholder="ref_by"
                    className="w-100"
                    type="text"
                    value={row.ref_by}
                    onChange={(e) =>
                      handleChange(index, "ref_by", e.target.value)
                    }
                  />
                  <datalist id="ref_by">
                    {userData.map((client, index) => (
                      <option key={index} value={client.client_name}>
                        {client.client_name}
                      </option>
                    ))}
                  </datalist>
              </td> */}

                {/* <td className="px-0 py-1 text-center">
                  <input
                    list="ClientID"
                    placeholder="Client ID"
                    className="w-100"
                    name={`client_id_${index}`}
                    type="text"
                    value={row.client_code}
                    onChange={(e) => handleClientIdChange(e, index)}
                    required
                  />
                  <datalist id="ClientID">
                    {userData.map((client, index) => (
                      <option key={index} value={client.client_code}>
                        {client.client_code}
                      </option>
                    ))}
                  </datalist>
                </td> */}

                {/* <td className="px-0 py-1 text-center" style={{ position: 'relative' }}>
                  <input
                    placeholder="Client ID"
                    className="w-100"
                    name={`client_id_${index}`}
                    type="text"
                    value={row.client_code}
                    onFocus={() => handleFocus('client_id', index)}
                    onBlur={handleBlur}
                    onChange={(e) => handleClientIdChange(e, index)}
                    style={{ width: '100%' }}
                    required
                  />

                  {focusedInput === 'client_id' && currentIndex === index && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        maxWidth: '200px',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        border: '1px solid #ccc',
                        backgroundColor: 'white',
                        zIndex: 1000,
                        padding: '10px',
                        fontWeight: '700'
                      }}
                      className="scrollable"
                    >
                      {userData
                        .sort((a, b) => parseInt(a.client_code) - parseInt(b.client_code))
                        .map((client, idx) => (
                          <div
                            key={idx}
                            onMouseDown={() => handleClientIdChange({ target: { value: client.client_code } }, index)}
                            style={{ cursor: "pointer", padding: "5px" }}
                            className="dropdown-option"
                          >
                            {client.client_code}
                          </div>
                        ))}
                    </div>
                  )}
                </td> */}

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <input
                    placeholder="Outlet"
                    className="w-100"
                    name={`client_name_${index}`}
                    type="text"
                    value={row.client_name}
                    onFocus={() => handleFocus("client_name", index)}
                    onBlur={handleBlur}
                    onChange={(e) => handleClientNameChange(e, index)}
                    style={{ width: "100%" }}
                    required
                  />
                  {focusedInput === "client_name" && currentIndex === index && (
                    <div
                      style={{
                        position: "absolute",
                        top: "calc(100% + 5px)",
                        left: 0,
                        maxWidth: "200px",
                        maxHeight: "300px",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        backgroundColor: "white",
                        zIndex: 1000,
                        padding: "10px",
                        fontWeight: "700",
                      }}
                      className="scrollable"
                    >
                      {flattenedUserData
                        .sort((a, b) =>
                          a.client_name?.localeCompare(b.client_name)
                        )
                        .map((client, idx) => (
                          <div
                            key={idx}
                            onMouseDown={() =>
                              handleDropdownSelect(client, index)
                            }
                            style={{ cursor: "pointer", padding: "5px" }}
                            className="dropdown-option"
                          >
                            {client.client_name}
                          </div>
                        ))}
                      {/* {isUnrecognizedValue(row.client_name) && (
                        <div
                          onMouseDown={handleButtonClick}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="dropdown-option"
                        >
                          <span
                            style={{ textAlign: "center", padding: "0 10px" }}
                          >
                            + Add New
                          </span>
                        </div>
                      )} */}
                    </div>
                  )}
                  {showPopup && (
                    <>
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: 1500,
                        }}
                        onClick={closePopup}
                      />
                      <div
                        style={{
                          position: "fixed",
                          width: "25%",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          padding: "30px",
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          zIndex: 2000,
                          opacity: 1,
                          borderRadius: "15px",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <h4 className="fw-bold">Add New Client</h4>

                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ minWidth: "100px" }}
                            className="fw-bold"
                          >
                            Client Name :
                          </label>
                          <input
                            type="text"
                            placeholder="Client Name"
                            className="form-control"
                            value={newClientName}
                            onChange={handleNewClientNameChange}
                            required
                            style={{ flex: "1", marginBottom: "10px" }}
                          />
                        </div>

                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ minWidth: "100px" }}
                            className="fw-bold"
                          >
                            Client ID :{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Client ID"
                            className="form-control"
                            value={newClientID}
                            readOnly
                            disabled
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              marginRight: "-34px",
                            }}
                          />
                          <LockIcon style={{ width: "20px" }} />
                        </div> */}

                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ minWidth: "100px", marginBottom: "10px" }}
                            className="fw-bold"
                          >
                            Client Email :
                          </label>
                          <input
                            type="text"
                            placeholder="Client Email"
                            className="form-control"
                            value={newClientEmail}
                            onChange={handleNewClientEmailChange}
                            style={{ marginBottom: "10px" }}
                          />
                        </div> */}

                        <MDBBtn color="primary" onClick={handleNewUserSubmit}>
                          Submit
                        </MDBBtn>
                        <MDBBtn color="secondary" onClick={closePopup}>
                          Close
                        </MDBBtn>
                      </div>
                    </>
                  )}
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    list="ClientName"
                    placeholder="Customer"
                    className="w-100"
                    type="text"
                    value={row.customer}
                    onFocus={() => handleFocus('customer', index)}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      handleChange(index, "customer", e.target.value)
                    }
                  />
                </td>


                {/* <td className="px-0 py-1 text-center">
                  <input
                    list="ClientName"
                    placeholder="Client Name"
                    className="w-100"
                    type="text"
                    value={row.client_name}
                    onFocus={() => handleFocus('client_name', index)}
                    onBlur={handleBlur}
                    onChange={(e) => handleClientNameChange(e, index)}
                    required
                  />
                </td> */}

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Jewelry Types"
                      className="w-100"
                      type="text"
                      value={row.jewelryType}
                      onChange={(e) =>
                        handleChange(index, "jewelryType", e.target.value)
                      }
                      style={{ width: "100%" }}
                      onFocus={() => handleFocus(`jewelryType-${index}`)}
                      onBlur={handleBlur}
                    />
                    {focusedInput === `jewelryType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {[
                          "BRACELET",
                          "NECKLACE",
                          "EARRING",
                          "ETERNITY BAND",
                          "RING",
                          "PENDANT",
                        ]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.jewelryType.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "jewelryType", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Sub Types"
                      className="w-100"
                      type="text"
                      value={row.jewelrySubType}
                      onChange={(e) =>
                        handleChange(index, "jewelrySubType", e.target.value)
                      }
                      onFocus={() => handleFocus(`jewelrySubType-${index}`)}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `jewelrySubType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {row.jewelryType === "BRACELET" &&
                          ["Station", "Tennis", "Bangle"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          ["Riviera", "Station", "Tennis", "Lariat", "Special"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          [
                            "Hoops",
                            "Huggies",
                            "Omega Hoops",
                            "Studs",
                            "Hanging",
                            "Crawler",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          [
                            "Eternity Band",
                            "Half Eternity",
                            "3/4 Band",
                            "5 Stones",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          [
                            "3 Stones",
                            "3 Stones Halo",
                            "Hidden Halo",
                            "Semi Hidden Halo",
                            "Solitaire",
                            "Solitaire Diamond Shank",
                            "Split Shank",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "PENDANT" &&
                          ["Pendant"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Setting"
                      className="w-100"
                      type="text"
                      value={row.setting}
                      onChange={(e) =>
                        handleChange(index, "setting", e.target.value)
                      }
                      onFocus={() => handleFocus(`setting-${index}`)}
                      onBlur={handleBlur}
                    />
                    {focusedInput === `setting-${index}` && (
                      <div
                        id={`setting-${index}`}
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {row.jewelryType === "BRACELET" &&
                          row.jewelrySubType === "Station" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "BRACELET" &&
                          row.jewelrySubType === "Tennis" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong (Inclined)",
                            "Bezel (Inclined)",
                            "Prong",
                            "Bezel",
                            "Bar Set",
                            "Bar Set (NS)",
                            "Bar Set (WS)",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "BRACELET" &&
                          row.jewelrySubType === "Bangle" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong (Inclined)",
                            "Bezel (Inclined)",
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Hoops" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Huggies" &&
                          ["Prong (NS)", "Bezel (NS)", "Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Omega Hoops" &&
                          ["Bezel (EW)", "Bezel (NS)", "Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Prong" &&
                          ["Bezel (EW)", "Bezel (NS)", "Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Studs" &&
                          [
                            "Martini",
                            "Prong (EW)",
                            "Bezel (EW)",
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Hanging" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Crawler" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Riviera" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Station" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Tennis" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong (Inclined)",
                            "Bezel (Inclined)",
                            ,
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Lariat" &&
                          ["Prong (NS)", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "PENDANT" &&
                          row.jewelrySubType === "Pendant" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            ,
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "3 Stones" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "3 Stones Halo" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Hidden Halo" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Semi Hidden Halo" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Solitaire" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Solitaire Diamond Shank" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Split Shank" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Special" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "3/4 Band" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            ,
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "5 Stones" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            ,
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "Eternity Band" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            ,
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "Half Eternity" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            ,
                            "Prong",
                            "Bezel",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Gdc Style"
                    className="w-100"
                    type="text"
                    value={row.refLotId}
                    onChange={(e) =>
                      handleChange(index, "refLotId", e.target.value)
                    }
                  />
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Vendor Style"
                    className="w-100"
                    type="text"
                    value={row.vendor_style}
                    onChange={(e) =>
                      handleChange(index, "vendor_style", e.target.value)
                    }
                  />
                </td>

                {/* <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Diamond Shape"
                      className="w-100"
                      type="text"
                      value={row.shapeType}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "shapeType", e.target.value)
                      }
                      onFocus={() => handleFocus(`shapeType-${index}`)}
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `shapeType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {[
                          "Round",
                          "Emerald",
                          "Radiant",
                          "Oval",
                          "Pear",
                          "Marquise",
                          "Cushion",
                          "Heart",
                          "Princess",
                          "Asscher",
                          "Mix (As.Comment)",
                          "Anti. Asscher",
                          "Anti. Cushion",
                          "Anti. Oval",
                          "Anti. Marquise",
                          "Sq. Anti. Cushion",
                          "Sq. Cushion",
                          "Sq. Emerald",
                          "Sq. Radiant",
                          "Step Pear",
                          "Step Cushion",
                          "Step Marquise",
                          "Step Oval",
                          "Duchess",
                          "Trapezoid",
                          "Bull",
                          "Horse",
                          "Bullet",
                          "Flower",
                          "Butterflies",
                          "Briolet",
                          "Baguette",
                          "Kite",
                          "Kriss",
                          "Moon Half",
                          "Octagon",
                          "Octagon H&A",
                          "Old European",
                          "Old Mine",
                          "Rose Cut",
                          "Rose Oval",
                          "Rose Pear",
                          "Trapper Baguette",
                          "Triangle",
                        ]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.shapeType.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "shapeType", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td> */}

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Metal"
                      className="w-100"
                      type="text"
                      value={row.metalType}
                      onFocus={() => handleFocus(`metalType-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "metalType", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `metalType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {["14KW", "14KY", "18KW", "18KY", "RG", "PT"]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.metalType.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              W
                              onMouseDown={() => {
                                handleChange(index, "metalType", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td>

                <td className="px-0 py-1 text-center">
                  <select
                    className="py-1"
                    aria-label="Default select example"
                    value={row.milgrain}
                    onChange={(e) =>
                      handleChange(index, "milgrain", e.target.value)
                    }
                  >
                    <option>Select</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Head"
                      className="w-100"
                      type="text"
                      value={row.head}
                      onFocus={() => handleFocus(`head-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "head", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `head-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {["14KW", "14KY", "18KW", "18KY", "RG", "PT"]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.head.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              W
                              onMouseDown={() => {
                                handleChange(index, "head", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td>

                {/* <td className="px-0 py-1 text-center">
                  <input
                    list="metalType"
                    placeholder="Metal Type"
                    className="w-100"
                    type="text"
                    value={row.metalType}
                    onChange={(e) =>
                      handleChange(index, "metalType", e.target.value)
                    }
                    required
                  />
                </td> */}

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Size"
                      className="w-100"
                      type="text"
                      value={row.jewelrySize}
                      onFocus={() => handleFocus(`jewelrySize-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "jewelrySize", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                </td>

                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Diamond A/p Size"
                    className="w-100"
                    type="text"
                    value={row.diamondApoSize}
                    onChange={(e) =>
                      handleChange(index, "diamondApoSize", e.target.value)
                    }
                  />
                </td> */}


                <td className="px-0 py-2 text-center">
                  <i
                    className="far fa-comment-dots fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStonesClick(index)}
                  ></i>
                  {stonesIndex === index && (
                    <textarea
                      className="form-control mt-2"
                      placeholder="Stones"
                      rows="3"
                      value={row.stones}
                      onChange={(e) =>
                        handleStonesChange(index, e.target.value)
                      }
                    ></textarea>
                  )}
                </td>


                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Ref. link"
                    className="w-100"
                    type="text"
                    value={row.refLink}
                    onChange={(e) =>
                      handleChange(index, "refLink", e.target.value)
                    }
                  />
                </td> */}
                {/* 
                <td className="px-3 pt-2 text-center">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleChange(index, "refImge", e.target.files[0])
                    }
                    // required
                  />
                </td> */}
                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="PO No."
                    className="w-100"
                    type="text"
                    value={row.PoNo}
                    onChange={(e) =>
                      handleChange(index, "PoNo", e.target.value)
                    }
                  />
                </td> */}
                <td className="px-0 py-1 text-center">
                  <select
                    className="py-1"
                    aria-label="Default select example"
                    value={row.DesignNeed}
                    onChange={(e) =>
                      handleChange(index, "DesignNeed", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>

                {/* <td className="p-0 text-center">
                  <IconButton component="label" className="z-3">
                    <input
                      type="file"
                      name="refImge[]"
                      accept="image/*"
                      multiple
                      onChange={handleImgeChange}
                      hidden
                    />
                    <FilterIcon />
                  </IconButton>
                </td> */}

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Quantity"
                    className="w-100"
                    type="text"
                    value={row.qty}
                    onChange={(e) => handleChange(index, "qty", e.target.value)}
                  />
                </td>

                <td className="px-0 py-1 text-center">
                  <select
                    className="py-1"
                    aria-label="Default select example"
                    value={row.quote}
                    onChange={(e) =>
                      handleChange(index, "quote", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </td>

                <td className="px-0 py-2 text-center">
                  <i
                    className="far fa-comment-dots fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCommentClick(index)}
                  ></i>
                  {commentIndex === index && (
                    <textarea
                      className="form-control mt-2"
                      placeholder="Instructions"
                      rows="3"
                      value={row.comment}
                      onChange={(e) =>
                        handleCommentChange(index, e.target.value)
                      }
                    ></textarea>
                  )}
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Due Date"
                    className="w-100"
                    type="date"
                    value={row.dueDate}
                    onChange={(e) =>
                      handleChange(index, "due_date", e.target.value)
                    }
                  />
                </td>

                {/* <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Priority"
                      className="w-100"
                      type="text"
                      value={row.priority}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "priority", e.target.value)
                      }
                      onFocus={() => handleFocus(`priority-${index}`)}
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `priority-${index}` && ( 
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {[
                          "Urgent",
                          "Normal",
                          "Low",
                        ]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.priority.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "priority", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td> */}

                {/* <td className="px-0 py-1 text-center">
                  <select
                    className="py-1 w-100"
                    aria-label="Default select example"
                    value={row.priority}
                    onChange={(e) =>
                      handleChange(index, "priority", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Urgent">Urgent</option>
                    <option value="Normal">Normal</option>
                    <option value="Low">Low</option>
                  </select>
                </td> */}

                <td className="px-0 py-2 text-center">
                  <i
                    className="fas fa-circle-minus fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteRow(index)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {isInputFocused && (
          <div style={{
            // position: 'absolute',
            left: '100px',
            // zIndex: 1,
            maxWidth: "150px",
            maxHeight: '200px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: '10px',
            // opacity: 0.9
          }}>
            {userData.map((client, idx) => (
              <div key={idx} onClick={() => {
                setSelectedValue(client.client_name);
                setInputFocused(false);
                handleChange(idx, "ref_by", client.client_name);
              }} style={{ cursor: "pointer", padding: "5px" }}
              className="dropdown-option">
                {client.client_name}
              </div>
            ))}
          </div>
        )} */}

        <i
          className="fas fa-circle-plus"
          id="addNewRowBtn"
          onClick={addRow}
        ></i>

        <div style={{ textAlign: "center" }}>
          <MDBBtn type="submit" id="orderNow" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </MDBBtn>
          <MDBBtn onClick={resetForm} id="orderNow" className="bg-danger">
            Reset
          </MDBBtn>
        </div>
      </form>

      <SuperAdminOrderTable
        remittanceData={remittanceData}
        setRemittanceData={setRemittanceData}
        fetchRemittanceData={fetchRemittanceData}
        loading={loading}
      />

      <div>
        {/* <datalist id="ClientID">
          {userData?.map((client, index) => (
            <option key={index} value={client.code}>
              {client.code}
            </option>
          ))}
        </datalist> */}
        {/* <datalist id="jewelryType">
          <option value="BRACELET"></option>
          <option value="NECKLACE"></option>
          <option value="EARRING"></option>
          <option value="ETERNITY BAND"></option>
          <option value="RING"></option>
          <option value="PENDANT"></option>
        </datalist> */}

        {/* <datalist id="DiamondShape">
          <option value="Round"></option>
          <option value="Emerald"></option>
          <option value="Radiant"></option>
          <option value="Oval"></option>
          <option value="Pear"></option>
          <option value="Marquise"></option>
          <option value="Cushion"></option>
          <option value="Heart"></option>
          <option value="Princess"></option>
          <option value="Antique Cushion"></option>
          <option value="Old Euro"></option>
          <option value="Duchess"></option>
        </datalist> */}
        {/* <datalist id="metalType">
          <option value="14KW"></option>
          <option value="14KY"></option>
          <option value="18KW"></option>
          <option value="18KY"></option>
          <option value="RG"></option>
          <option value="PT"></option>
        </datalist> */}
      </div>
    </div>
  );
}
