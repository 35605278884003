import React from "react";
import { Link, useLocation } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DiamondIcon from "@mui/icons-material/Diamond";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { GiBigDiamondRing } from "react-icons/gi";
import { Ring } from "../../Super_Admin/SVG/Svg";
import CalculateIcon from "@mui/icons-material/Calculate";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import AlbumIcon from "@mui/icons-material/Album";
import { Permission, Permissionclient } from "../../utils/Url";

export default function Sidebar({
  closeSidebar,
  isSidebarOpen,
  toggleSidebar,
}) {
  const location = useLocation();
  return (
    <nav
      aria-label=""
      className={`sidebar ${isSidebarOpen ? "open" : ""}`}
      style={{ position: "fixed", top: 80, height: "100%" }}
    >
      <ul
        className="sidebar-nav1 mb-4"
        style={{ width: isSidebarOpen ? "250px" : "" }}
      >
        {/*<li className="sidebar-item">
          <Link
            className={`text-dark ${location.pathname === '/adminDashboard' ? 'active-link' : ''}`}
            to='/adminDashboard'
            onClick={closeSidebar}
            style={{ color: location.pathname === '/adminDashboard' ? 'white' : 'inherit' }}>
            <div className='d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold'>
              <GridViewIcon style={{ color: location.pathname === '/adminDashboard' ? 'white' : 'inherit' }} />
              {isSidebarOpen && <span style={{ color: location.pathname === '/adminDashboard' ? 'white' : 'inherit' }}>Dashboard</span>}
            </div>
          </Link>
  </li>*/}

        {Permissionclient.includes("adminorder") && (
          <li className="sidebar-item">
            <Link
              className={`text-dark ${
                location.pathname === "/adminOrder" ? "active-link" : ""
              }`}
              to="/adminOrder"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/adminOrder" ? "white" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <LocalMallIcon
                  style={{
                    color:
                      location.pathname === "/adminOrder" ? "white" : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/adminOrder"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Order
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {/*<li className="sidebar-item">
          <Link
            className={`text-dark ${location.pathname === '/adminReport' ? 'active-link' : ''}`}
            to='/adminReport'
            onClick={closeSidebar}
            style={{ color: location.pathname === '/adminReport' ? 'white' : 'inherit' }}>
            <div className='d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold'>
              <AssessmentIcon style={{ color: location.pathname === '/adminReport' ? 'white' : 'inherit' }} />
              {isSidebarOpen && <span style={{ color: location.pathname === '/adminReport' ? 'white' : 'inherit' }}>Report</span>}
            </div>
          </Link>
        </li>*/}
        {Permissionclient.includes("adminstyle") && (
          <li className="sidebar-item">
            <Link
              className={`text-dark ${
                location.pathname === "/Style" ? "active-link" : ""
              }`}
              to="/Style"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/Style" ? "white w-1.5em" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                {
                  <Ring
                    style={{
                      color:
                        location.pathname === "/Style" ? "white" : "inherit",
                    }}
                  />
                }
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/Style" ? "white" : "inherit",
                      minWidth: "max-content",
                    }}
                  >
                    Jewellery Style
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {Permissionclient.includes("admincadmaster") && (
          <li className="sidebar-item">
            <Link
              className={`text-dark ${
                location.pathname === "/AdminCadMasterOrder"
                  ? "active-link"
                  : ""
              }`}
              to="/AdminCadMasterOrder"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/AdminCadMasterOrder"
                    ? "white w-1.5em"
                    : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                {
                  <FormatListNumberedRtlIcon
                    style={{
                      color:
                        location.pathname === "/AdminCadMasterOrder"
                          ? "white"
                          : "inherit",
                    }}
                  />
                }
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/AdminCadMasterOrder"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Cad Master
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {Permissionclient.includes("adminbulkorder") && (
          <li>
            <Link
              className={`text-dark ${
                location.pathname === "/AdminBulkOrder" ? "active-link" : ""
              }`}
              to="/AdminBulkOrder"
              onClick={closeSidebar}
              style={{
                color:
                  location.pathname === "/AdminBulkOrder" ? "white" : "inherit",
              }}
            >
              <div className="d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold">
                <AlbumIcon
                  style={{
                    color:
                      location.pathname === "/AdminBulkOrder"
                        ? "white"
                        : "inherit",
                  }}
                />
                {isSidebarOpen && (
                  <span
                    style={{
                      color:
                        location.pathname === "/AdminBulkOrder"
                          ? "white"
                          : "inherit",
                    }}
                  >
                    Bulk Order
                  </span>
                )}
              </div>
            </Link>
          </li>
        )}
        {/* <li className="sidebar-item">
          <Link
            className={`text-dark ${location.pathname === '/Calculator' ? 'active-link' : ''}`}
            to='/Calculator'
            onClick={closeSidebar}
            style={{ color: location.pathname === '/Calculator' ? 'white w-1.5em' : 'inherit' }}>
            <div className='d-flex justify-content-between gap-2 w-100 text-uppercase fw-bold'>
              {<CalculateIcon style={{ color: location.pathname === '/Calculator' ? 'white' : 'inherit' }} />}
              {isSidebarOpen && <span style={{ color: location.pathname === '/Calculator' ? 'white' : 'inherit' }}>Calculator</span>}
            </div>
          </Link>
        </li> */}

        <li className="sidebar-item">
          <div
            className="d-flex justify-content-between w-100 ms-3"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? (
              <div className="vertical-layout">
                <ArrowCircleLeftIcon style={{ color: "#83193E" }}/>
                <span className="mt-2">Close</span>
              </div>
            ) : (
              <div className="vertical-layout">
                <ArrowCircleRightIcon  style={{ color: "#83193E" }}/>
                <span className="mt-2">Open</span>
              </div>
            )}
          </div>
        </li>
      </ul>
    </nav>
  );
}
