import React from 'react';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({ Component, allowedRoles }) {
    const isAuthenticated = !!localStorage.getItem('token');
    const userRoleId = Number(localStorage.getItem('userRoleId')); // Convert userRoleId to number

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    if (userRoleId && allowedRoles && !allowedRoles.includes(userRoleId)) {
        if (userRoleId === 1) {
            return <Navigate to="/userOrder" />;
        }

        if (userRoleId === 2 || userRoleId === 4 || userRoleId === 5) {
            return <Navigate to="/adminOrder" />;
        }

        if (userRoleId === 3) {
            return <Navigate to="/superAdminOrder" />;
        }
    }

    return <Component />;
}
