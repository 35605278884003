import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <div className='notfound_img'>
            <Link to='/' className='buttonperror'>
                <span className='back_btn'>Back To Home</span>
            </Link>
        </div>
    )
}
