import { MDBBtn } from "mdb-react-ui-kit";
import TotalUsers from "./Components/TotalUsers";
import SuperAdminNavbar from "../SuperAdminSidebar/SuperAdminNavbar";
import SuperAdminSidebar from "../SuperAdminSidebar/SuperAdminSidebar";
import TotalClient from "./Components/TotalClient";
import React, { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import TotalDummyUser from "./Components/TotalDummyUser";
import { baseUrl } from "../../utils/Url";

export default function SuperAdminAddNewUser() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [filterCompany, setFilterCompany] = useState("");
  const [dummyUsers, setDummyUsers] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // ------------SignupForm-------------------
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    client_code: "",
    client_name: "",
    approved: 1,
    role_id: "", // Default should not be null
    department: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [userCode, setUserCode] = useState([]);
  const [error, setError] = useState({ client_code: "", client_name: "" });

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/clients`,
        requestOptions
      );
      const result = await response.json();
      const filterData = result.filter((client) => {
        return client.company
          .toLowerCase()
          .includes(filterCompany.toLowerCase());
      });
      setUserData(filterData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRemittanceData();
    fetchDummyRemittanceData()
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "role_id") {
      fetchUserRoles();
    }

    if (e.target.name === "client_code") {
      const existingClientCodes = userCode.map((user) =>
        user.client_code.toLowerCase()
      );
      if (existingClientCodes.includes(e.target.value.toLowerCase())) {
        setError((prevError) => ({
          ...prevError,
          client_code: "Client code already exists.",
        }));
      } else {
        setError((prevError) => ({ ...prevError, client_code: "" }));
      }
    }

    if (e.target.name === "client_name") {
      const existingClientNames = userCode.map((user) =>
        user.client_name.toLowerCase()
      );
      if (existingClientNames.includes(e.target.value.toLowerCase())) {
        setError((prevError) => ({
          ...prevError,
          client_name: "Client name already exists.",
        }));
      } else {
        setError((prevError) => ({ ...prevError, client_name: "" }));
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const fetchRemittanceData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/users`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setUserCode(responseData);
      } else {
        console.error("Error fetching remittance data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };
  const fetchDummyRemittanceData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/users?utype=1`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setDummyUsers(responseData);
      } else {
        console.error("Error fetching remittance data:", response.error);
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for existing client_code and client_name
    if (error.client_code || error.client_name) {
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "Client code or client name already exists. Please check your input.",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("name", formData.name);
      urlencoded.append("email", formData.email);
      urlencoded.append("password", formData.password);
      urlencoded.append("client_code", formData.client_code);
      urlencoded.append("client_name", formData.client_name);
      urlencoded.append("approved", formData.approved);
      urlencoded.append("role_id", formData.role_id);
      urlencoded.append("department", formData.department);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/users`,
        requestOptions
      );
      const result = await response.json();
      setFormData({
        name: "",
        email: "",
        password: "",
        client_code: "",
        client_name: "",
        approved: 1,
        role_id: "",
        department: "",
      });
      fetchRemittanceData();
      Swal.fire({
        title: "Good job!",
        text: "Successfully Submitted!",
        icon: "success",
      });
    } catch (error) {
      console.error("There was an error!", error);
      Swal.fire({
        icon: "error",
        title: "Try again...",
        text: "Signup failed. Please check your details.",
      });
    }
  };

  const fetchUserRoles = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/roles`,
        requestOptions
      );
      if (response.ok) {
        const responseData = await response.json();
        setRoles(responseData);
      } else {
        console.error("Error fetching user roles:", response.error);
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  return (
    <div id="admin-body">
      <SuperAdminNavbar />
      <div style={{ display: "flex" }}>
        <SuperAdminSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginTop: "30px",
            marginLeft: isSidebarOpen ? "13%" : "4.5%",
          }}
        >
          <p className="fs-1">Add New Users</p>
          <div>
            <MDBBtn
              data-bs-toggle="modal"
              data-bs-target="#AddNewUser"
              className="btn-bg-color"
            >
              Add User
            </MDBBtn>

            {/* <Link to='/SuperAdminAddNewDummyUser'>
            <MDBBtn className="btn-bg-color">Dummy Client</MDBBtn>
            </Link> */}
            <div
              className="modal fade"
              id="AddNewUser"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add New Users</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="name">User Name:</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Email:</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-control"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">Password:</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              className="form-control"
                              required
                            />
                            <span
                              onClick={handleTogglePassword}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="client_code">Outlet Code:</label>
                          <input
                            type="text"
                            id="client_code"
                            name="client_code"
                            value={formData.client_code}
                            onChange={handleChange}
                            className={`form-control ${error.client_code ? "is-invalid" : ""
                              }`}
                          />
                          {error.client_code && (
                            <div className="invalid-feedback">
                              {error.client_code}
                            </div>
                          )}
                        </div>

                        <div className="form-group pt-2">
                          <label htmlFor="client_name">Outlet Name:</label>
                          <input
                            type="text"
                            id="client_name"
                            name="client_name"
                            value={formData.client_name}
                            onChange={handleChange}
                            className={`form-control ${error.client_name ? "is-invalid" : ""
                              }`}
                          />
                          {error.client_name && (
                            <div className="invalid-feedback">
                              {error.client_name}
                            </div>
                          )}
                        </div>

                        <div className="form-group pt-2">
                          <label htmlFor="approved">Approved:</label>
                          <select className="form-control" onChange={handleChange}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                          {/* <input
                            type="text"
                            id="approved"
                            name="approved"
                            value={formData.approved}
                            onChange={handleChange}
                            className="form-control"
                          /> */}
                        </div>

                        <div className="form-group">
                          <label htmlFor="role_id">Role ID:</label>
                          <select
                            id="role_id"
                            name="role_id"
                            value={formData.role_id}
                            onChange={handleChange}
                            className="form-control"
                            onClick={fetchUserRoles}
                            required
                          >
                            <option value="">Select Role</option>
                            {roles.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* <div className="form-group">
                          <label htmlFor="department">Department:</label>
                          <input
                            type="text"
                            id="department"
                            name="department"
                            value={formData.department}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div> */}

                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Signup
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-8 border-right">
              <TotalUsers
                fetchRemittanceData={fetchRemittanceData}
                userCode={userCode}
              />
            </div>
            <div className="col-md-4">
              <TotalClient
                fetchUserData={fetchUserData}
                loading={loading}
                userData={userData}
                setFilterCompany={setFilterCompany}
                filterCompany={filterCompany}
              />
            </div>
            <div>
              {/* <TotalDummyUser fetchDummyRemittanceData={fetchDummyRemittanceData} dummyUsers={dummyUsers} fetchRemittanceData={fetchRemittanceData} fetchUserRoles={fetchUserRoles} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
