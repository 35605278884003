import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/Url";

export default function OutletTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const token = localStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${baseUrl}/api/outlet`,
          requestOptions
        );

        if (response.ok) {
          const result = await response.json();
          setData(result);
        } else {
          console.error("Error fetching remittance data:", response.error);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Create a map of unique parents and their outlets
  const parentMap = data.reduce((acc, item) => {
    const parentName = item?.parent?.name;
    if (!acc[parentName]) {
      acc[parentName] = [];
    }
    acc[parentName].push(item);
    return acc;
  }, {});


  return (
    <div>
      <h1>Outlet Table</h1>

      <table className="table table-bordered my-4 outletTable">
        <thead id="addProductTableHead">
          <tr>
            <th className="p-2 fw-bold">Outlet Name</th>
            <th className="p-2 fw-bold">Email</th>
          </tr>
        </thead>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        ) : (
          <tbody>
            {data.map((outlet, index) => (
              <tr key={index} className="table-hover">
                <td id="showUserTablebody">{outlet.name}</td>
                <td id="showUserTablebody">{outlet.email}</td>
              </tr>
            ))}

          </tbody>
        )}
      </table>


    </div>
  );
}
