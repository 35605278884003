import React, { useState } from 'react'
import Navbar from '../SideBar/Navbar';
import Sidebar from '../SideBar/Sidebar';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import AdminCancleTable from './Componet/AdminCancleTable';

export default function AdminCancleOrder() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <div id="admin-body">
            <Navbar />
            <div style={{ display: "flex" }}>
                <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                />
                <div
                    style={{
                        flex: 1,
                        padding: "20px",
                        marginTop: "30px",
                        marginLeft: isSidebarOpen ? "13%" : "4.5%",
                    }}
                >
                    <MDBRow >
                        <MDBCol>
                            <h1>Canceled Data</h1>
                            <AdminCancleTable />
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        </div>
    )
}
