import React, { useState } from 'react'
import SuperAdminNavbar from '../SuperAdminSidebar/SuperAdminNavbar';
import SuperAdminSidebar from '../SuperAdminSidebar/SuperAdminSidebar';

export default function SuperAdminStatus() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div id='admin-body'>
            <SuperAdminNavbar toggleSidebar={toggleSidebar} />
            <div style={{ display: 'flex' }}>
                <SuperAdminSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div style={{ flex: 1, padding: '20px', marginTop: '30px', marginLeft: isSidebarOpen ? '13%' : '4.5%' }}>
                    <p className="fs-1">Status Summary</p>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}
