import React, { useState } from "react";
import Navbar from "../SideBar/Navbar";
import Sidebar from "../SideBar/Sidebar";
import OrderDetials from "./Components/OrderDetials";

export default function Orders() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div id="admin-body">
      <Navbar />
      <div style={{ display: "flex" }}>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginLeft: isSidebarOpen ? "10%" : '4.5%',
          }}
        >
          <p className="fs-1 text-center text-uppercase">Orders</p>

          <div>
            <OrderDetials />
          </div>
        </div>
      </div>
    </div>
  );
}
