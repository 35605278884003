import React, { useState } from "react";
import UploadPopup from "./Components/UploadPopup";
import CardFilter from "./Components/CardFilter";
import Navbar from "../SideBar/Navbar";
import Sidebar from "../SideBar/Sidebar";

export default function Style() {
  const [csvData, setCsvData] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDownload = () => {
    // Filter the csvData based on the selected card IDs
    const dataToDownload = csvData.filter(item => selectedCards.includes(item.id));

    if (dataToDownload && dataToDownload.length > 0) {
      // Extract headers from the first item
      const headers = Object.keys(dataToDownload[0]);

      // Construct CSV content with headers
      let csvContent = headers.join(",") + "\n";
      csvContent += dataToDownload.map(row => headers.map(header => row[header]).join(",")).join("\n");

      // Create a Blob object with the CSV content
      const blob = new Blob([csvContent], { type: "text/csv" });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element for download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "selected_data.csv");

      // Append the link to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link element and revoke the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.error("No data selected for download.");
    }
  };


  // Filter CSV data based on search query
  const filteredDataSearch = csvData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div id="admin-body">
      <Navbar />
      <div style={{ display: "flex" }}>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          style={{
            flex: 1,
            padding: "20px",
            marginTop: "30px",
            marginLeft: isSidebarOpen ? "13%" : "4.5%",
          }}
        >
          <p className="fs-1">Style</p>
          <div className="pb-3">
            <UploadPopup
              setCsvData={setCsvData}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedCards={selectedCards}
              handleDownload={handleDownload}
            />
          </div>

          <div>
            <CardFilter
              csvData={csvData}
              setCsvData={setCsvData}
              filteredDataSearch={filteredDataSearch}
              setSelectedCards={setSelectedCards}
              selectedCards={selectedCards}
              handleDownload={handleDownload}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
