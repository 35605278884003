import { MDBBtn } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import FilterIcon from "@mui/icons-material/Filter";
import Swal from "sweetalert2";
import { baseUrl } from "../../utils/Url";

export default function Requirment() {
  const [rows, setRows] = useState([]);
  const [commentIndex, setCommentIndex] = useState(null);
  const [formError, setFormError] = useState(null);
  const [userData, setUserData] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [stonesIndex, setStonesIndex] = useState(null);

  const handleFocus = (field, index) => {
    setFocusedInput(field);
    setCurrentIndex(index);
  };

  const handleBlur = () => {
    setFocusedInput(null);
    setCurrentIndex(null);
  };

  useEffect(() => {
    addRow();
  }, []);

  const addRow = () => {
    const today = new Date();
    const options = {
      timeZone: "America/New_York",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    const [month, day, year] = today
      .toLocaleDateString("en-US", options)
      .split("/");
    const requestDate = `${year}-${month}-${day}`;

    const uniqueOrderNumber = generateUniqueOrderNumber();

    setRows([
      ...rows,
      {
        jewelryType: "",
        jewelrySubType: "",
        refLotId: "",
        vendor_style: "",
        milgrain: "",
        head: "",
        quote: "",
        shapeType: "",
        metalType: "",
        jewelrySize: "",
        diamondApoSize: "",
        refLink: "",
        comment: "",
        qty: "",
        PoNo: "",
        stones: "",
        DesignNeed: "",
        due_date: "",
        estDate: "",
        setting: "",
        estRate: "",
        requestStatus: "",
        priority: "",
        requestDate: requestDate,
        orderNumber: uniqueOrderNumber,
        refImge: null,
      },
    ]);
  };

  const generateUniqueOrderNumber = () => {
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    return randomSuffix.toString();
  };

  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    // if (field === "refImg") {
    //   if (value.target && value.target.files && value.target.files.length > 0) {
    //     const file = value.target.files[0];
    //     updatedRows[index][field] = file;
    //   }
    // } else {
    updatedRows[index][field] = value;
    // }
    setRows(updatedRows);
  };

  const handleCommentClick = (index) => {
    setCommentIndex(commentIndex === index ? null : index);
  };

  const handleCommentChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index]["comment"] = value;
    setRows(updatedRows);
  };

  const handleImgeChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const formDataToSend = new FormData();
      let fileTooLarge = false;
      rows.forEach((row, index) => {
        formDataToSend.append(`rows[${index}][jewelryType]`, row.jewelryType);
        formDataToSend.append(
          `rows[${index}][jewelrySubType]`,
          row.jewelrySubType
        );
        formDataToSend.append(`rows[${index}][setting]`, row.setting);
        formDataToSend.append(`rows[${index}][refLotId]`, row.refLotId);
        formDataToSend.append(`rows[${index}][vendor_style]`, row.vendor_style);
        // formDataToSend.append(`rows[${index}][shapeType]`, row.shapeType);
        formDataToSend.append(`rows[${index}][metalType]`, row.metalType);
        formDataToSend.append(`rows[${index}][milgrain]`, row.milgrain);
        formDataToSend.append(`rows[${index}][head]`, row.head);
        formDataToSend.append(`rows[${index}][jewelrySize]`, row.jewelrySize);
        formDataToSend.append(`rows[${index}][stones]`, row.stones);
        // formDataToSend.append(`rows[${index}][PoNo]`, row.PoNo);
        formDataToSend.append(`rows[${index}][DesignNeed]`, row.DesignNeed);
        formDataToSend.append(`rows[${index}][due_date]`, row.due_date);
        // formDataToSend.append(
        //   `rows[${index}][diamondApoSize]`,
        //   row.diamondApoSize
        // );
        // formDataToSend.append(`rows[${index}][refLink]`, row.refLink);
        formDataToSend.append(`rows[${index}][comment]`, row.comment);
        formDataToSend.append(`rows[${index}][qty]`, row.qty);
        formDataToSend.append(`rows[${index}][quote]`, row.quote);
        // formDataToSend.append(`rows[${index}][estDate]`, row.estDate);
        // formDataToSend.append(`rows[${index}][estRate]`, row.estRate);
        formDataToSend.append(
          `rows[${index}][requestStatus]`,
          row.requestStatus
        );
        // formDataToSend.append(
        //   `rows[${index}][priority]`,
        //   row.priority
        // );
        formDataToSend.append(`rows[${index}][requestDate]`, row.requestDate);

        // if (selectedFiles) {
        //   for (let i = 0; i < selectedFiles.length; i++) {
        //     // formDataToSend.append(`rows[${index}][refImge][${i}]`, row.refImge[i]);
        //     formDataToSend.append(`rows[${index}][refImge][]`, selectedFiles[i]);
        //   }
        // }

        if (selectedFiles) {
          for (let i = 0; i < selectedFiles.length; i++) {
            if (selectedFiles[i].size > 20 * 1024 * 1024) {
              fileTooLarge = true;
              break;
            } else {
              formDataToSend.append(
                `rows[${index}][refImge][]`,
                selectedFiles[i]
              );
            }
          }
        }

      });


      if (fileTooLarge) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "Oops ! The Size Limit For Images Is 20.0MB Reduce The File Size And Try Again.",
        });
        setIsSubmitting(false);
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formDataToSend,
      };

      const response = await fetch(
        `${baseUrl}/api/add_custom_order`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        // alert("Successfully Saved", responseData);
        Swal.fire({
          title: "Good job!",
          text: "Order Successfully Saved!",
          icon: "success",
        });
        setRows([]);
      } else {
        console.error("Error:", response.statusText);
        // alert("An error occurred while storing data. Please try again.");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "The Internet?",
        text: "That thing is still around?",
        icon: "question",
      });
    } finally {
      setIsSubmitting(false); // Set loading state to false after the request completes
    }
  };

  const resetForm = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to reset all data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setRows([]);
        Swal.fire({
          title: "Reset!",
          text: "Your form has been reset.",
          icon: "success",
        });
      }
    });
  };

  const handleStonesClick = (index) => {
    setStonesIndex(stonesIndex === index ? null : index);
  };


  const handleStonesChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index]["stones"] = value;
    setRows(updatedRows);
  };

  return (
    <div>
      <h2 className="pb-2">Requirement Form</h2>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="w-100"
      >
        {formError && <p style={{ color: "red" }}>{formError}</p>}
        <table className="table table-bordered">
          <thead id="addProductTableHead">
            <tr>
              <th className="p-0 py-2 fw-bold">
                Type<span className="text-danger fs-6">*</span>
              </th>
              <th className="p-0 py-2 fw-bold">
                Sub_Types<span className="text-danger fs-6">*</span>
              </th>
              <th className="p-0 py-2 fw-bold">Setting</th>
              <th className="p-0 py-2 fw-bold">Gdc Style</th>
              <th className="p-0 py-2 fw-bold">Vendor Style</th>
              {/* <th className="p-0 py-2 fw-bold">Shape</th> */}
              <th className="p-0 py-2 fw-bold">
                Metal<span className="text-danger fs-6">*</span>
              </th>
              <th className="p-0 py-2 fw-bold">
                Milgrain
              </th>
              <th className="p-0 py-2 fw-bold">
                Head
              </th>
              <th className="p-0 py-2 fw-bold">Size</th>
              <th className="p-0 py-2 fw-bold">Stones</th>
              {/* <th className="p-0 py-2 fw-bold">Diamond Size</th> */}
              {/* <th className="p-0 py-2 fw-bold">Ref.link</th> */}
              {/* <th className="p-0 py-2 fw-bold">Po.No.</th> */}
              <th className="p-0 py-2 fw-bold">
                Cad Cam
              </th>
              {/* <th className="p-0 py-2 fw-bold">Ref.Image</th> */}
              <th className="p-0 py-2 fw-bold">
                Qty
              </th>
              <th className="p-0 py-2 fw-bold">Quote</th>
              <th className="p-0 py-2 fw-bold">Instructions</th>
              <th className="p-0 py-2 fw-bold">Due Date</th>
              {/* <th className="p-0 py-2 fw-bold">Due Date</th> */}
              {/* <th className="p-0 py-2 fw-bold">priority</th> */}
              <th className="p-0 py-2 fw-bold">Del.</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => (
              <tr key={index}>
                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Jewelry Types"
                      className="w-100"
                      type="text"
                      value={row.jewelryType}
                      onChange={(e) =>
                        handleChange(index, "jewelryType", e.target.value)
                      }
                      required
                      style={{ width: "100%" }}
                      onFocus={() => handleFocus(`jewelryType-${index}`)}
                      onBlur={handleBlur}
                    />
                    {focusedInput === `jewelryType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {[
                          "BRACELET",
                          "NECKLACE",
                          "EARRING",
                          "ETERNITY BAND",
                          "RING",
                          "PENDANT",
                        ]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.jewelryType.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "jewelryType", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Sub Types"
                      className="w-100"
                      type="text"
                      value={row.jewelrySubType}
                      onChange={(e) =>
                        handleChange(index, "jewelrySubType", e.target.value)
                      }
                      required
                      onFocus={() => handleFocus(`jewelrySubType-${index}`)}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `jewelrySubType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {row.jewelryType === "BRACELET" &&
                          ["Station", "Tennis", "Bangle"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          ["Riviera", "Station", "Tennis", "Lariat", "Special"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          [
                            "Hoops",
                            "Huggies",
                            "Omega Hoops",
                            "Studs",
                            "Hanging",
                            "Crawler",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          [
                            "Eternity Band",
                            "Half Eternity",
                            "3/4 Band",
                            "5 Stones",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          [
                            "3 Stones",
                            "3 Stones Halo",
                            "Hidden Halo",
                            "Semi Hidden Halo",
                            "Solitaire",
                            "Solitaire Diamond Shank",
                            "Split Shank",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "PENDANT" &&
                          ["Pendant"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySubType.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(
                                    index,
                                    "jewelrySubType",
                                    subtype
                                  );
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Setting"
                      className="w-100"
                      type="text"
                      value={row.setting}
                      onChange={(e) =>
                        handleChange(index, "setting", e.target.value)
                      }
                      onFocus={() => handleFocus(`setting-${index}`)}
                      onBlur={handleBlur}
                    />
                    {focusedInput === `setting-${index}` && (
                      <div
                        id={`setting-${index}`}
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {row.jewelryType === "BRACELET" &&
                          row.jewelrySubType === "Station" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "BRACELET" &&
                          row.jewelrySubType === "Tennis" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong (Inclined)",
                            "Bezel (Inclined)",
                            "PRONG",
                            "BEZEL",
                            "Bar Set",
                            "Bar Set (NS)",
                            "Bar Set (WS)",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Hoops" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Huggies" &&
                          ["Prong (NS)", "Bezel (NS)", "PRONG", "BEZEL", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Omega Hoops" &&
                          ["Bezel (EW)", "Bezel (NS)", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Prong" &&
                          ["Bezel (EW)", "Bezel (NS)", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Studs" &&
                          ["Martini", "Prong (EW)", "Bezel (EW)", "PRONG",
                            "BEZEL", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Hanging" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          row.jewelrySubType === "Crawler" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Riviera" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Station" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Tennis" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "Prong (Inclined)",
                            "Bezel (Inclined)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          row.jewelrySubType === "Lariat" &&
                          ["Prong (NS)", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "PENDANT" &&
                          row.jewelrySubType === "Pendant" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "3 Stones" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "3 Stones Halo" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Hidden Halo" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Semi Hidden Halo" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Solitaire Diamond Shank" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Split Shank" &&
                          ["Prong", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          row.jewelrySubType === "Special" &&
                          ["Prong", "Bezel", "Claw"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "3/4 Band" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "5 Stones" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "Eternity Band" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          row.jewelrySubType === "Half Eternity" &&
                          [
                            "Prong (EW)",
                            "Prong (NS)",
                            "Bezel (EW)",
                            "Bezel (NS)",
                            "PRONG",
                            "BEZEL",
                            "Claw"
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.setting.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "setting", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Ref.lot ID"
                    className="w-100"
                    type="text"
                    value={row.refLotId}
                    onChange={(e) =>
                      handleChange(index, "refLotId", e.target.value)
                    }
                  />
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Vendor Style"
                    className="w-100"
                    type="text"
                    value={row.vendor_style}
                    onChange={(e) =>
                      handleChange(index, "vendor_style", e.target.value)
                    }
                  />
                </td>

                {/* <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Diamond Shape"
                      className="w-100"
                      type="text"
                      value={row.shapeType}
                      onFocus={() => handleFocus(`shapeType-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "shapeType", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `shapeType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {[
                          "Round",
                          "Emerald",
                          "Radiant",
                          "Oval",
                          "Pear",
                          "Marquise",
                          "Cushion",
                          "Heart",
                          "Princess",
                          "Asscher",
                          "Mix (As.Comment)",
                          "Anti. Asscher",
                          "Anti. Cushion",
                          "Anti. Oval",
                          "Anti. Marquise",
                          "Sq. Anti. Cushion",
                          "Sq. Cushion",
                          "Sq. Emerald",
                          "Sq. Radiant",
                          "Step Pear",
                          "Step Cushion",
                          "Step Marquise",
                          "Step Oval",
                          "Duchess",
                          "Trapezoid",
                          "Bull",
                          "Horse",
                          "Bullet",
                          "Flower",
                          "Butterflies",
                          "Briolet",
                          "Baguette",
                          "Kite",
                          "Kriss",
                          "Moon Half",
                          "Octagon",
                          "Octagon H&A",
                          "Old European",
                          "Old Mine",
                          "Rose Cut",
                          "Rose Oval",
                          "Rose Pear",
                          "Trapper Baguette",
                          "Triangle",
                        ]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.shapeType.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "shapeType", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td> */}

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Metal Type"
                      className="w-100"
                      type="text"
                      value={row.metalType}
                      onFocus={() => handleFocus(`metalType-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "metalType", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `metalType-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {["14KW", "14KY", "18KW", "18KY", "RG", "PT"]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.metalType.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "metalType", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td>

                <td className="px-0 py-1 text-center">
                  <select
                    className="py-1 w-100"
                    aria-label="Default select example"
                    value={row.milgrain}
                    onChange={(e) =>
                      handleChange(index, "milgrain", e.target.value)
                    }
                  >
                    <option>Select</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Head"
                      className="w-100"
                      type="text"
                      value={row.head}
                      onFocus={() => handleFocus(`head-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "head", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `head-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {["14KW", "14KY", "18KW", "18KY", "RG", "PT"]
                          ?.filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.head.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              W
                              onMouseDown={() => {
                                handleChange(index, "head", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Jewelry Size"
                      className="w-100"
                      type="text"
                      value={row.jewelrySize}
                      onFocus={() => handleFocus(`jewelrySize-${index}`)}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "jewelrySize", e.target.value)
                      }
                      disabled={
                        row.jewelryType === "EARRING" &&
                        (row.jewelrySubType === "Studs" ||
                          row.jewelrySubType === "Bezel" ||
                          row.jewelrySubType === "Hanging")
                      }
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `jewelrySize-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {row.jewelryType === "BRACELET" &&
                          [
                            "5.00 Inch",
                            "5.50 Inch",
                            "5.75 Inch",
                            "6.00 Inch",
                            "6.25 Inch",
                            "6.50 Inch",
                            "6.75 Inch",
                            "7.00 Inch",
                            "7.50 Inch",
                            "8.00 Inch",
                            "8.50 Inch",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySize.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "jewelrySize", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "NECKLACE" &&
                          [
                            "16.00 Inch",
                            "16.50 Inch",
                            "17.00 Inch",
                            "17.50 Inch",
                            "18.00 Inch",
                            "24.00 Inch",
                            "36.00 Inch",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySize.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "jewelrySize", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "EARRING" &&
                          [
                            "16.00 mm",
                            "19.00 mm",
                            "25.00 mm",
                            "28.00 mm",
                            "32.00 mm",
                            "38.00 mm",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySize.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "jewelrySize", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "ETERNITY BAND" &&
                          [
                            "3.75 US",
                            "4.00 US",
                            "4.25 US",
                            "4.50 US",
                            "4.75 US",
                            "5.00 US",
                            "5.25 US",
                            "5.50 US",
                            "5.75 US",
                            "6.00 US",
                            "6.25 US",
                            "6.50 US",
                            "6.75 US",
                            "7.00 US",
                            "7.25 US",
                            "7.50 US",
                            "7.75 US",
                            "8.00 US",
                            "8.50 US",
                            "9.00 US",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySize.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "jewelrySize", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "RING" &&
                          [
                            "3.75 US",
                            "4.00 US",
                            "4.25 US",
                            "4.50 US",
                            "4.75 US",
                            "5.00 US",
                            "5.25 US",
                            "5.50 US",
                            "5.75 US",
                            "6.00 US",
                            "6.25 US",
                            "6.50 US",
                            "6.75 US",
                            "7.00 US",
                            "7.25 US",
                            "7.50 US",
                            "7.75 US",
                            "8.00 US",
                            "8.50 US",
                            "9.00 US",
                          ]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySize.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "jewelrySize", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                        {row.jewelryType === "PENDANT" &&
                          ["16.00 Inch", "18.00 Inch"]
                            .filter((option) =>
                              option
                                .toLowerCase()
                                .includes(row.jewelrySize.toLowerCase())
                            )
                            .map((subtype, i) => (
                              <div
                                key={i}
                                onMouseDown={() => {
                                  handleChange(index, "jewelrySize", subtype);
                                  handleBlur();
                                }}
                                style={{ cursor: "pointer", padding: "5px" }}
                                className="dropdown-option"
                              >
                                {subtype}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </td>

                <td className="px-0 py-2 text-center">
                  <i
                    className="far fa-comment-dots fs-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStonesClick(index)}
                  ></i>
                  {stonesIndex === index && (
                    <textarea
                      className="form-control mt-2"
                      placeholder="Stones"
                      rows="3"
                      value={row.stones}
                      onChange={(e) =>
                        handleStonesChange(index, e.target.value)
                      }
                    ></textarea>
                  )}
                </td>

                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Diamond A/p Size"
                    className="w-100"
                    type="text"
                    value={row.diamondApoSize}
                    onChange={(e) =>
                      handleChange(index, "diamondApoSize", e.target.value)
                    }
                  />
                </td> */}

                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Ref. link"
                    className="w-100"
                    type="text"
                    value={row.refLink}
                    onChange={(e) =>
                      handleChange(index, "refLink", e.target.value)
                    }
                  />
                </td> */}
                {/* 
                <td className="px-3 pt-2 text-center">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleChange(index, "refImge", e.target.files[0])
                    }
                    // required
                  />
                </td> */}
                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="PO No."
                    className="w-100"
                    type="text"
                    value={row.PoNo}
                    onChange={(e) =>
                      handleChange(index, "PoNo", e.target.value)
                    }
                  />
                </td> */}

                <td className="px-0 py-1 text-center">
                  <select
                    className="py-1 w-100"
                    aria-label="Default select example"
                    value={row.DesignNeed}
                    onChange={(e) =>
                      handleChange(index, "DesignNeed", e.target.value)
                    }
                    required
                  >
                    <option value="">Select</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>

                {/* <td className="p-0 text-center">
                  <IconButton component="label" className="z-3">
                    <input
                      type="file"
                      multiple
                      onChange={handleImgeChange}
                      hidden
                    />
                    <FilterIcon />
                  </IconButton>
                </td> */}

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Qty"
                    className="w-100"
                    type="text"
                    value={row.qty}
                    onChange={(e) => handleChange(index, "qty", e.target.value)}
                    required
                  />
                </td>

                <td className="px-0 py-1 text-center">
                  <select
                    className="py-1 w-100"
                    aria-label="Default select example"
                    value={row.quote}
                    onChange={(e) =>
                      handleChange(index, "quote", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </td>

                <td className="px-0 py-2 text-center">
                  <i
                    className="far fa-comment-dots fs-5 dropdown-option"
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={() => handleCommentClick(index)}
                  ></i>
                  {commentIndex === index && (
                    <textarea
                      placeholder="Instructions"
                      className="form-control mt-2"
                      rows="3"
                      value={row.comment}
                      onChange={(e) =>
                        handleCommentChange(index, e.target.value)
                      }
                    ></textarea>
                  )}
                </td>

                <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Due Date"
                    className="w-100"
                    type="date"
                    value={row.dueDate}
                    onChange={(e) =>
                      handleChange(index, "due_date", e.target.value)
                    }
                  />
                </td>

                {/* <td className="px-0 py-1 text-center">
                  <input
                    placeholder="Due Date"
                    className="w-100"
                    type="date"
                    value={row.due_date}
                    onChange={(e) =>
                      handleChange(index, "due_date", e.target.value)
                    }
                  />
                </td> */}

                {/* <td
                  className="px-0 py-1 text-center"
                  style={{ position: "relative" }}
                >
                  <div style={{ position: "relative" }}>
                    <input
                      placeholder="Priority"
                      className="w-100"
                      type="text"
                      value={row.priority}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        handleChange(index, "priority", e.target.value)
                      }
                      onFocus={() => handleFocus(`priority-${index}`)}
                      style={{ width: "100%" }}
                    />
                    {focusedInput === `priority-${index}` && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(100% + 5px)",
                          left: 0,
                          width: "100%",
                          maxHeight: "150px", // Adjust height as needed
                          overflowY: "auto",
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          zIndex: 1000,
                          padding: "10px",
                          fontWeight: "700",
                        }}
                        className="scrollable"
                      >
                        {[
                          "Urgent",
                          "Normal",
                          "Low",
                        ]
                          .filter((option) =>
                            option
                              .toLowerCase()
                              .includes(row.priority.toLowerCase())
                          )
                          .map((option, idx) => (
                            <div
                              key={idx}
                              onMouseDown={() => {
                                handleChange(index, "priority", option);
                                handleBlur();
                              }}
                              style={{ cursor: "pointer", padding: "5px" }}
                              className="dropdown-option"
                            >
                              {option}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </td> */}

                {/* <td className="px-0 py-1 text-center">
                  <select
                    className="py-1 w-100"
                    aria-label="Default select example"
                    value={row.priority}
                    onChange={(e) =>
                      handleChange(index, "priority", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Urgent">Urgent</option>
                    <option value="Normal">Normal</option>
                    <option value="Low">Low</option>
                  </select>
                </td> */}

                <td className="px-0 py-2 text-center">
                  <i
                    className="fas fa-circle-minus fs-5 dropdown-option"
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={() => deleteRow(index)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <i
          className="fas fa-circle-plus"
          id="addNewRowBtn"
          onClick={addRow}
        ></i>

        <div style={{ textAlign: "center" }}>
          <MDBBtn type="submit" id="orderNow" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </MDBBtn>
          <MDBBtn onClick={resetForm} id="orderNow" className="bg-danger">
            Reset
          </MDBBtn>
        </div>
      </form>

      <div>
        <datalist id="ClientID">
          {userData?.map((client, index) => (
            <option key={index} value={client.code}>
              {client.code}
            </option>
          ))}
        </datalist>
        <datalist id="jewelryType">
          <option value="BRACELET"></option>
          <option value="NECKLACE"></option>
          <option value="EARRING"></option>
          <option value="ETERNITY BAND"></option>
          <option value="RING"></option>
          <option value="PENDANT"></option>
        </datalist>
        <datalist id="DiamondShape">
          <option value="Round"></option>
          <option value="Emerald"></option>
          <option value="Radiant"></option>
          <option value="Oval"></option>
          <option value="Pear"></option>
          <option value="Marquise"></option>
          <option value="Cushion"></option>
          <option value="Heart"></option>
          <option value="Princess"></option>
          <option value="Antique Cushion"></option>
          <option value="Old Euro"></option>
          <option value="Duchess"></option>
        </datalist>
        <datalist id="metalType">
          <option value="14KW"></option>
          <option value="14KY"></option>
          <option value="18KW"></option>
          <option value="18KY"></option>
          <option value="RG"></option>
          <option value="PT"></option>
        </datalist>
      </div>
    </div>
  );
}
