import {
  MDBBtn,
  MDBCarousel,
  MDBCarouselItem,
  MDBIcon,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import ChatApp from "../chatbot/ChatApp";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/Url";

export default function UserReportProduct() {
  const [remittanceData, setRemittanceData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedJewelryType, setSelectedJewelryType] = useState("all");
  const [selectedJewelrySubType, setSelectedJewelrySubType] = useState("all");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);


  useEffect(() => {
    const fetchRemittanceData = async () => {
      try {
        const token = localStorage.getItem("token");

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${baseUrl}/api/get_customize_order_list`,
          requestOptions
        );

        if (response.ok) {
          const responseData = await response.json();
          setRemittanceData(responseData);
        } else {
          console.error("Error uploading CSV data:", response.error);
          // alert("An error occurred while storing data. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching remittance data:", error);
      }
    };

    fetchRemittanceData();
  }, []);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleJewelryTypeChange = (e) => {
    setSelectedJewelryType(e.target.value);
  };

  const handleJewelrySubTypeChange = (e) => {
    setSelectedJewelrySubType(e.target.value);
  };

  const filteredRemittanceData = remittanceData.filter((item) => {
    if (selectedJewelryType !== "all" && item.jewelryType !== selectedJewelryType) {
      return false;
    }
    if (selectedJewelrySubType !== "all" && item.jewelrySubType !== selectedJewelrySubType) {
      return false;
    }

    if (selectedStatus !== "all") {
      if (selectedStatus === "pending") {
        if (!item.orderNumber || !item.requestStatus) {
          return true;
        } else if (item.requestStatus !== "pending") {
          return false;
        }
      } else if (item.requestStatus !== selectedStatus) {
        return false;
      }
    }

    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const requestDate = new Date(item.requestDate);

      if (requestDate >= startDateObj && requestDate <= endDateObj) {
        // Date falls within the selected range
        return true;
      } else {
        // Date falls outside the selected range
        return false;
      }
    } else if (!startDate && !endDate) {
      // No date filtering applied when both startDate and endDate are null
      return true;
    } else if (!startDate && endDate) {
      // Include items with requestDate less than or equal to endDate
      const endDateObj = new Date(endDate);
      const requestDate = new Date(item.requestDate);
      return requestDate <= endDateObj;
    } else if (startDate && !endDate) {
      // Include items with requestDate greater than or equal to startDate
      const startDateObj = new Date(startDate);
      const requestDate = new Date(item.requestDate);
      return requestDate >= startDateObj;
    }

    return true;
  });

  const jewelryTypes = [
    ...new Set(remittanceData.map((item) => item.jewelryType)),
  ];
  const jewelrySubTypes = [
    ...new Set(remittanceData.map((item) => item.jewelrySubType)),
  ];

  const handleCheckboxChange = (e, orderNumber) => {
    const checked = e.target.checked;

    if (selectAllChecked && !checked) {
      setSelectAllChecked(false);
    }

    setSelectedRows((prevSelectedRows) => {
      if (checked && !prevSelectedRows.includes(orderNumber)) {
        return [...prevSelectedRows, orderNumber];
      } else {
        return prevSelectedRows.filter((item) => item !== orderNumber);
      }
    });
  };

  const handleSelectAll = () => {
    if (!selectAllChecked || selectedRows.length !== remittanceData.length) {
      const allOrderNumbers = remittanceData.map((item) => item.orderNumber);
      setSelectedRows(allOrderNumbers);
      setSelectAllChecked(true);
    } else {
      setSelectedRows([]);
      setSelectAllChecked(false);
    }
  };

  const downloadSelectedCSV = () => {
    let selectedData;

    if (selectAllChecked) {
      selectedData = remittanceData.filter((item) => {
        if (selectedStatus === "all") {
          return true;
        } else if (selectedStatus === "pending") {
          return !item.orderNumber || !item.requestStatus;
        } else {
          return item.requestStatus === selectedStatus;
        }
      });
    } else {
      if (selectedStatus === "all") {
        selectedData = remittanceData.filter((item) =>
          selectedRows.includes(item.orderNumber)
        );
      } else {
        selectedData = remittanceData.filter(
          (item) =>
            selectedRows.includes(item.orderNumber) &&
            (selectedStatus === "pending"
              ? !item.orderNumber || !item.requestStatus
              : item.requestStatus === selectedStatus)
        );
      }
    }

    if (selectedJewelryType !== "all") {
      selectedData = selectedData.filter(
        (item) => item.jewelryType === selectedJewelryType
      );
    }
    if (selectedJewelrySubType !== "all") {
      selectedData = selectedData.filter(
        (item) => item.jewelrySubType === selectedJewelrySubType
      );
    }

    if (selectedData.length === 0) {
      console.error("No data found for the selected criteria.");
      return;
    }

    const headings = Object.keys(selectedData[0]).join(",");
    const data = selectedData.map((item) => Object.values(item).join(",")).join("\n");

    const csvContent = "data:text/csv;charset=utf-8," + headings + "\n" + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "selected_remittance_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div>
        <h1 className="py-2 text-center text-decoration-underline ">REPORT</h1>
      </div>
      <div className="">
        <div className="d-flex justify-content-end mb-3">
          <button onClick={handleSelectAll} className="btn btn-primary me-2">
            {selectAllChecked ? "Deselect All" : "Select All"}
          </button>
          <MDBBtn onClick={downloadSelectedCSV}>
            <MDBIcon fas icon="download" className="me-2" />
            Download Data
          </MDBBtn>
        </div>
        <div className="d-flex justify-content-between align-items-center py-3 px-3 rounded-5  mb-2  bg_report">
          <div className=" d-flex gap-3">
            <div className="d-flex flex-column w-100">
              <label htmlFor="" className="fw-bold text-center pb-1">
                Category
              </label>
              <select
                value={selectedJewelryType}
                onChange={handleJewelryTypeChange}
                className="form-select  w-100"
              >
                <option value="all">All</option>
                {jewelryTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex flex-column w-100">
              <label className="fw-bold text-center pb-1" htmlFor="">
                SubCategory
              </label>
              <select
                value={selectedJewelrySubType}
                onChange={handleJewelrySubTypeChange}
                className="form-select  w-100"
              >
                <option value="all">All</option>
                {jewelrySubTypes.map((subType, index) => (
                  <option key={index} value={subType}>
                    {subType}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex flex-column w-100 ">
              <label className="fw-bold text-center pb-1" htmlFor="">
                Status
              </label>
              <select
                value={selectedStatus}
                onChange={handleStatusChange}
                className="form-select  w-100"
              >
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="Designing">Designing</option>
                <option value="Production">Production</option>
                <option value="Dispatch">Dispatch</option>
              </select>
            </div>
          </div>
          <div className=" d-flex gap-4">
            <div className="d-flex flex-column ">
              <label className="me-2 fw-bold text-center pb-1 ">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                className="form-control me-2"
              />
            </div>
            <div className="d-flex flex-column ">
              <label className="me-2 fw-bold text-center pb-1 ">End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <table className="table table-bordered ">
          <thead id="addProductTableHead">
            <tr>
              <th className="p-2 fw-bold">Select</th>
              <th className="p-2 fw-bold">Req._No.</th>
              <th className="p-2 fw-bold">Req._Date</th>
              <th className="p-2 fw-bold">Req._Status</th>
              <th className="p-2 fw-bold">Jewelry_Type</th>
              <th className="p-2 fw-bold">Jewelry_Sub_Type</th>
              <th className="p-2 fw-bold">Ref_Lot_ID</th>
              <th className="p-2 fw-bold">Shape_Type</th>
              <th className="p-2 fw-bold">Metal_Type</th>
              <th className="p-2 fw-bold">Jewelry_Size</th>
              <th className="p-2 fw-bold">Diamond_a/p_Size</th>
              <th className="p-2 fw-bold">Qty</th>
              <th className="p-2 fw-bold">Comment</th>
              <th className="p-2 fw-bold">Ref.link</th>
              <th className="p-2 fw-bold">Chatbox</th>
              <th className="p-2 fw-bold">Final Image</th>
            </tr>
          </thead>
          <tbody>
            {filteredRemittanceData
              .slice()
              .reverse()
              .map((remittance, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(e, remittance.orderNumber)
                      }
                      checked={
                        selectAllChecked ||
                        selectedRows.includes(remittance.orderNumber)
                      }
                    />
                  </td>
                  <td id="showUserTablebody">{remittance.orderNumber}</td>
                  <td id="showUserTablebody">{remittance.requestDate}</td>
                  <td
                    id="showUserTablebody"
                    style={{
                      color:
                        remittance.requestStatus === "approved"
                          ? "green"
                          : remittance.requestStatus === "rejected"
                            ? "red"
                            : !remittance.orderNumber || !remittance.requestStatus
                              ? "orange"
                              : "black",
                    }}
                  >
                    {!remittance.orderNumber || !remittance.requestStatus
                      ? "pending"
                      : remittance.requestStatus}
                  </td>
                  <td id="showUserTablebody">
                    {remittance.jewelryType || "N/A"}
                  </td>
                  <td id="showUserTablebody">
                    {remittance.jewelrySubType || "N/A"}
                  </td>
                  <td id="showUserTablebody">{remittance.refLotId || "N/A"}</td>
                  <td id="showUserTablebody">
                    {remittance.shapeType || "N/A"}
                  </td>
                  <td id="showUserTablebody">
                    {remittance.metalType || "N/A"}
                  </td>
                  <td id="showUserTablebody">
                    {remittance.jewelrySize || "N/A"}
                  </td>
                  <td id="showUserTablebody">
                    {remittance.diamondApoSize
                      ? `${remittance.diamondApoSize} ct`
                      : "N/A"}
                  </td>
                  <td id="showUserTablebody">{remittance.qty || "N/A"}</td>
                  <td id="showUserTablebody">{remittance.comment || "N/A"}</td>
                  <td id="showUserTablebody">
                    <Link
                      target="_blank"
                      to={remittance.refImageUploadLink}
                      style={{
                        color: remittance.refImageUploadLink
                          ? "#3fc9ff"
                          : "red",
                      }}
                    >
                      <MDBIcon fas icon="eye" />
                    </Link>
                  </td>
                  <td id="showUserTablebody">
                    <MDBBtn
                      id="chatBox"
                      data-bs-toggle="modal"
                      data-bs-target={`#popup-${index}`}
                    >
                      <MDBIcon far icon="comments" />
                    </MDBBtn>
                    <div
                      className="modal fade"
                      id={`popup-${index}`}
                      tabIndex="-1"
                      aria-labelledby={`popupLabel-${index}`}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id={`popupLabel-${index}`}
                            >
                              ChatBot - {remittance.refLotId}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div>
                              <ChatApp />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td id="showUserTablebody">
                    <MDBBtn
                      id="chatBox"
                      data-bs-toggle="modal"
                      data-bs-target="#viewImage"
                    >
                      <MDBIcon fas icon="photo-video" />
                    </MDBBtn>
                    <div
                      className="modal fade"
                      id="viewImage"
                      tabIndex="-1"
                      aria-labelledby={`popupLabel-${index}`}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id={`popupLabel-${index}`}
                            >
                              Final Image
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div>
                              <MDBCarousel showControls fade>
                                <MDBCarouselItem itemId={1}>
                                  <img
                                    src="cadimg.png"
                                    className="d-block w-100"
                                    alt="..."
                                  />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={2}>
                                  <img
                                    src="cadimg1.png"
                                    className="d-block w-100"
                                    alt="..."
                                    loading="lazy"
                                  />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={3}>
                                  <img
                                    src="cadimg2.png"
                                    className="d-block w-100"
                                    alt="..."
                                    loading="lazy"
                                  />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={4}>
                                  <img
                                    src="cadimg3.png"
                                    className="d-block w-100"
                                    alt="..."
                                    loading="lazy"
                                  />
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId={5}>
                                  <img
                                    src="cadimg4.png"
                                    className="d-block w-100"
                                    alt="..."
                                    loading="lazy"
                                  />
                                </MDBCarouselItem>
                              </MDBCarousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
