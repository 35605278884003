import React, { useState } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl, Logo } from "../../utils/Url";

export default function UserNavbar() {
  const [centredModal, setCentredModal] = useState(false);
  const [passwordData, setPasswordData] = useState({
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const user_name = localStorage.getItem("userId");
  const toggleOpen = () => setCentredModal(!centredModal);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/clogout`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Logout request failed");
      }

      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userCode");
      localStorage.removeItem("userRoleId");
      sessionStorage.removeItem('hasModalOpened');
      localStorage.removeItem('allowOutlet');

      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSubmitNewPassword = async () => {
    try {
      const { current_password, new_password, new_password_confirmation } =
        passwordData;

      if (new_password !== new_password_confirmation) {
        alert("New password and confirmation password do not match.");
        return;
      }

      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json)");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          current_password,
          new_password,
          new_password_confirmation,
        }),

        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/change-passwd`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Change password request failed");
      }
      const responseData = await response.json();
      alert(responseData.message);
    } catch (error) {
      console.error("There was an error!", error);
      alert("Password change failed. Please try again.");
    }
  };
  return (
    <div style={{ position: "sticky", top: 0, zIndex: 9999 }}>
      <nav className="navbar">
        <MDBCol className="text-center" style={{ width: "10%" }}>
          <img src={Logo[0]} alt="logo_skylab" className="w-50" loading="lazy" />
        </MDBCol>
        <MDBCol md="9"></MDBCol>
        <MDBCol style={{ width: "10%" }}>
          <MDBDropdown className="text-end">
            <MDBDropdownToggle tag="a" className="btn">
              <span className="d-block" style={{ fontSize: "10px" }}>
                Admin Name
              </span>
              <span style={{ fontSize: "12px" }}>{user_name}</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu className="d-flex flex-column">
              <MDBDropdownItem
                onClick={handleLogout}
                className="p-2 cursor-pointer"
              >
                Log Out
              </MDBDropdownItem>
              <MDBDropdownItem
                onClick={toggleOpen}
                className="p-2 cursor-pointer"
              >
                Change Password
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>

        <MDBModal tabIndex="-1" open={centredModal} setOpen={setCentredModal}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Change Password</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Old Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="current_password"
                    value={passwordData.current_password}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="new_password"
                    value={passwordData.new_password}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="new_password_confirmation"
                    value={passwordData.new_password_confirmation}
                    onChange={handleChange}
                  />
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn onClick={handleSubmitNewPassword}>Save changes</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

      </nav>
    </div>
  );
}
