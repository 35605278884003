import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  MDBBtn,
  MDBCarousel,
  MDBCarouselItem,
  MDBIcon,
} from "mdb-react-ui-kit";
import ChatApp from "../chatbot/ChatApp";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TrackProduct from "./TrackProduct";
import CommentIcon from "@mui/icons-material/Comment";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FilterIcon from "@mui/icons-material/Filter";
import Papa from "papaparse";
import { baseUrl, Permissionclient } from "../../utils/Url";
import UserUploadOrderData from "./UserUploadOrderData";

export default function UserReportProduct() {
  const [remittanceData, setRemittanceData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedJewelryType, setSelectedJewelryType] = useState("all");
  const [selectedJewelrySubType, setSelectedJewelrySubType] = useState("all");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedJewelrySubTypeFilter, setSelectedJewelrySubTypeFilter] =
    useState("all");
  const [selectedShapeTypeFilter, setSelectedShapeTypeFilter] = useState("all");
  const [selectedMetalTypeFilter, setSelectedMetalTypeFilter] = useState("all");
  const [refLotIdFilter, setRefLotIdFilter] = useState("");
  const [jewelrySizeFilter, setJewelrySizeFilter] = useState("");
  const [diamondSizeFilter, setDiamondSizeFilter] = useState("");
  const [filterQty, setFilterQty] = useState("");
  const [orderNo, setOrderNoFilter] = useState("");
  const [outletName, setOutletName] = useState("");
  const [showOrderNoFilter, setShowOrderNoFilter] = useState(false);
  const [showOutletNameFilter, setShowOutletNameFilter] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showSubTypeFilter, setShowSubTypeFilter] = useState(false);
  const [showLotIDFilter, setShowLotIDFilter] = useState(false);
  const [showShape_TypeFilter, setshowShape_TypeFilter] = useState(false);
  const [showMetal_TypeFilter, setshowMetal_TypeFilter] = useState(false);
  const [showJewelry_SizeFilter, setshowJewelry_SizeFilter] = useState(false);
  const [showDiamondSizeFilter, setshowDiamondSizeFilter] = useState(false);
  const [showQtyFilter, setshowQtyFilter] = useState(false);
  const [dueDateFilter, setDueDateFilter] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [selectedRemittance, setSelectedRemittance] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const [trackingData, setTrackingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [chatData, setChatData] = useState(null);
  // const [showE_DateFilter, setShowE_DateFilter] = useState(false);
  // const [startE_Date, setStartE_Date] = useState(null);
  // const [endE_Date, setEndE_Date] = useState(null);
  // const [showDe_DateFilter, setShowDe_DateFilter] = useState(false);
  // const [startDe_Date, setStartDe_Date] = useState(null);
  // const [endDe_Date, setEndDe_Date] = useState(null);
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({});
  const chatBoxRef = useRef(null);
  const [showPoNo_Filter, setShowPoNo_Filter] = useState(false);
  const [poNoFilter, setPoNoFilter] = useState("");
  const [showDesignNeed_Filter, setShowDesignNeed_Filter] = useState(false);
  const [designNeedFilter, setDesignNeedFilter] = useState("");
  const [selectedSetting, setSelectedSetting] = useState("all");
  const [showSettingFilter, setShowSettingFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showFullValue, setShowFullValue] = useState(false);
  const allowOutletlocal = localStorage.getItem("allowOutlet");
  const [showPriorityFilter, setShowPriorityFilter] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [openPriorityDropdownIndex, setOpenPriorityDropdownIndex] =
    useState(null);
  const [showDue_DateFilter, setShowDue_DateFilter] = useState(false);
  const [startDue_Date, setStartDue_Date] = useState(null);
  const [endDue_Date, setEndDue_Date] = useState(null);

  const handleStartDue_DateChange = (e) => {
    setStartDue_Date(e.target.value);
    setCurrentPage(1);
  };

  const handleEndDue_DateChange = (e) => {
    setEndDue_Date(e.target.value);
    setCurrentPage(1);
  };

  const clearDue_DateFilters = () => {
    setStartDue_Date("");
    setEndDue_Date("");
    setShowDue_DateFilter(false);
  };

  const handleStartDue_DateClose = () => {
    clearDue_DateFilters();
  };

  // const [showBag_Filter, setShowBag_Filter] = useState(false);
  // const [bagFilter, setBagFilter] = useState("");
  // const dropdownRef = useRef(null);
  // const [showInput, setShowInput] = useState(false);
  // const [id, setId] = useState("");
  // const [isEditing, setIsEditing] = useState(null);
  // const [dates, setDates] = useState({});
  // const [dateChanged, setDateChanged] = useState({});

  // const handleStartDe_DateChange = (e) => {
  //   setStartDe_Date(e.target.value);
  // };
  // const handleEndDe_DateChange = (e) => {
  //   setEndDe_Date(e.target.value);
  // };

  // const clearDe_DateFilters = () => {
  //   setStartDe_Date("");
  //   setEndDe_Date("");
  //   setShowDe_DateFilter(false);
  // };

  // const handleStartDe_DateClose = () => {
  //   clearDe_DateFilters();
  // };

  // const handleBlur = (remittanceId) => {
  //   // setIsEditing(null);
  // };

  // const handleChange = (event, remittanceId) => {
  //   const { value } = event.target;
  //   const reformattedDate = value.split("-").reverse().join("-");
  //   setDates((prevDates) => ({
  //     ...prevDates,
  //     [remittanceId]: reformattedDate,
  //   }));
  //   // Assuming handleSubmit is a function defined somewhere in your component
  //   handleSubmit(remittanceId, reformattedDate);
  //   setDateChanged((prevDateChanged) => ({
  //     ...prevDateChanged,
  //     [remittanceId]: true,
  //   }));
  // };

  // const handleDateChange = (event) => {
  //   const newDate = event.target.value;
  //   setShowInput(false);
  //   sendDateToBackend(newDate, id);
  // };

  // const handleTdClick = (remittanceId) => {
  //   if (isEditing !== remittanceId && !dateChanged[remittanceId]) {
  //     setIsEditing(remittanceId);
  //   }
  // };

  // const clearE_DateFilters = () => {
  //   setStartE_Date("");
  //   setEndE_Date("");
  //   setShowE_DateFilter(false);
  // };
  // const handleStartE_DateChange = (e) => {
  //   setStartE_Date(e.target.value);
  // };
  // const handleStartE_DateClose = () => {
  //   clearE_DateFilters();
  // };
  // const handleEndE_DateChange = (e) => {
  //   setEndE_Date(e.target.value);
  // };
  const [editedValues, setEditedValues] = useState({});
  const [editedRowIndex, setEditedRowIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [buttonText, setButtonText] = useState("View unread messages");
  const [showUnreadMessages, setShowUnreadMessages] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const statusOptions = [
    { label: "Req_Pending", value: "Req_Pending" },
    { label: "Req_Approved", value: "Req_Approved" },
    { label: "Designing", value: "Designing" },
    { label: "Design Approval Pending", value: "Design Approval Pending" },
    { label: "Design Approved", value: "Design Approved" },
    { label: "Wax & Cast", value: "Wax & Cast" },
    { label: "Filing", value: "Filing" },
    { label: "Pre Polishing", value: "Pre Polishing" },
    { label: "Diamond", value: "Diamond" },
    { label: "Metal Setting", value: "Metal Setting" },
    { label: "Final Polishing", value: "Final Polishing" },
    { label: "Finished Good", value: "Finished Good" },
    { label: "Hold", value: "Hold" },
    { label: "Assembly Filling", value: "Assembly Filling" },
  ];

  const priorityOptions = [
    { label: "Urgent", value: "Urgent" },
    // { label: "High", value: "High" },
    { label: "Normal", value: "Normal" },
    { label: "Low", value: "Low" },
  ];

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleValueClick = (index) => {
    setShowFullValue((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handlePriorityFilterChange = (value) => {
    const newSelectedPriority = selectedPriority.includes(value)
      ? selectedPriority.filter((priority) => priority !== value)
      : [...selectedPriority, value];
    setSelectedPriority(newSelectedPriority);
    setCurrentPage(1);
  };

  const clearPriorityFilter = () => {
    setSelectedPriority("all");
    setShowPriorityFilter(false);
  };

  const handlePriorityClose = () => {
    clearPriorityFilter();
  };

  const handleSave = async (remittance) => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        refLotId: editedValues.refLotId,
        jewelryType: editedValues.jewelryType,
        jewelrySubType: editedValues.jewelrySubType,
        shapeType: editedValues.shapeType,
        metalType: editedValues.metalType,
        jewelrySize: editedValues.jewelrySize,
        diamondApoSize: editedValues.diamondApoSize,
        qty: editedValues.qty,
        setting: editedValues.setting,
        PoNo: editedValues.PoNo,
        DesignNeed: editedValues.DesignNeed,
        refLink: editedValues.refLink,
        vendor_style: editedValues.vendor_style,
        milgrain: editedValues.milgrain,
        quote: editedValues.quote,
        labor_gdc: editedValues.labor_gdc,
        head: editedValues.head,
        stones: editedValues.stones,
        due_date: editedValues.due_date,
      };

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        `${baseUrl}/api/update_custom_order/${remittance.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Error updating data. Please try again.");
      }

      const responseData = await response.json();
      setEditedValues(responseData);
      setEditedValues({});
      setEditMode(false);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const toggleEditMode = (rowIndex) => {
    setEditedValues({});
    setEditedRowIndex(rowIndex);
    setEditMode(!editMode);
  };

  const handleInputChange = (event, fieldName) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: event.target.value,
    }));
  };

  const handleChat = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      const userCode = localStorage.getItem("userCode");

      const [chatResponse, markReadResponse] = await Promise.all([
        axios.get(
          `${baseUrl}/api/order-chat/show/${chatId}`,
          {
            params: { user_id: userCode },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        ),
        // axios.get(
        //   `${baseUrl}/api/order-chat/message-read/${chatId}`,
        //   {
        //     params: { user_id: userCode },
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //       "Content-Type": "application/json",
        //     },
        //   }
        // ),
      ]);

      if (chatResponse.status === 200) {
        setChatData(chatResponse.data);
      } else {
        throw new Error(
          `Failed to fetch status: ${chatResponse.status} ${chatResponse.statusText}`
        );
      }

      if (markReadResponse.status !== 200) {
        throw new Error(
          `Failed to mark messages as read: ${markReadResponse.status} ${markReadResponse.statusText}`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataChat = useCallback(async () => {
    if (chatId) {
      await handleChat(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    fetchDataChat();

    // const interval = setInterval(() => {
    //   fetchDataChat();
    // }, 3000);

    // return () => clearInterval(interval);
  }, [fetchDataChat]);

  const handleScroll = () => {
    setUserScrolledUp(true);
  };

  useEffect(() => {
    if (!userScrolledUp && chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [userScrolledUp]);

  const fetchChatData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const userRoleId = localStorage.getItem("userRoleId");
      const role_type = localStorage.getItem("userCode");

      const queryParams = new URLSearchParams({
        user_id: role_type,
        role_type: userRoleId,
      }).toString();

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const url = `${baseUrl}/api/order-chat/message-unread/count?${queryParams}`;

      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const responseData = await response.json();
        const unreadMessagesData = {};
        responseData.data.forEach((item) => {
          unreadMessagesData[item.order_id] = item.unread_message;
        });
        setUnreadMessages(unreadMessagesData);
      } else {
        console.error("Error fetching remittance data:", response.error);
        // alert("An error occurred while fetching data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };

  useEffect(() => {
    fetchChatData();
    const intervalId = setInterval(fetchChatData, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // const renderRedDot = (orderId) => {
  //   const unreadCount = unreadMessages[orderId] || 0;
  //   if (unreadCount > 0) {
  //     return <span className="red-dot position-absolute"></span>;
  //   }
  //   return null;
  // };

  const handleTrackOrderClick = async (remittanceId) => {
    setLoading1(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/api/track-corder/${remittanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setTrackingData(response.data);
      } else {
        throw new Error(
          `Failed to fetch status: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching status:", error);
    } finally {
      setLoading1(false);
    }
  };

  // const fetchData = async () => {
  //   // setLoading(true); // Set loading to true before fetching data
  //   await Promise.all([fetchRemittanceData(), handleTrackOrderClick()]);
  // };

  const handleOpenImageModal = (src) => {
    setImageSource(src);
    setShowImageModal(true);
  };

  const handleOpenModal = (remittanceData) => {
    setSelectedRemittance(remittanceData);
    toggleOpen();
  };

  const toggleOpen = () => {
    setBasicModal(!basicModal);
  };

  const formatDate = (dateString, dateType) => {
    if (!dateString) return "N/A";

    if (dateType === "estDate") {
      const [day, month, year] = dateString.split("-");
      return `${month}-${day}-${year}`;
    } else {
      const [year, month, day] = dateString.split("-");
      return `${month}-${day}-${year}`;
    }
  };

  const fetchRemittanceData = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/get_customize_order_list`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setRemittanceData(responseData);
      } else {
        console.error("Error fetching remittance data:", response.error);
        // alert("An error occurred while fetching data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching remittance data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchRemittanceData().then(() => setLoading(false));

    const intervalId = setInterval(fetchRemittanceData, 10000);

    return () => clearInterval(intervalId);
  }, []);

  // const handleStatusChange = (e) => {
  //   setSelectedStatus(e.target.value);
  //   setCurrentPage(1);
  // };

  const handleStatusChange = (value) => {
    const newSelectedStatus = selectedStatus.includes(value)
      ? selectedStatus.filter((status) => status !== value)
      : [...selectedStatus, value];
    setSelectedStatus(newSelectedStatus);
    setCurrentPage(1);
  };

  const handleOrderNoFilterChange = (e) => {
    const value = e.target.value;
    setOrderNoFilter(value.startsWith("J") ? value.slice(1) : value);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setCurrentPage(1);
  };

  const handleJewelryTypeChange = (e) => {
    setSelectedJewelryType(e.target.value);
    setCurrentPage(1);
  };

  const handleJewelrySubTypeFilterChange = (e) => {
    setSelectedJewelrySubTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleShapeTypeFilterChange = (e) => {
    setSelectedShapeTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleMetalTypeFilterChange = (e) => {
    setSelectedMetalTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleRefLotIdFilterChange = (e) => {
    setRefLotIdFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleOutletNameFilterChange = (e) => {
    setOutletName(e.target.value);
    setCurrentPage(1);
  };

  const handleJewelry_SizeFilterChange = (e) => {
    setJewelrySizeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleDiamondSizeChange = (e) => {
    setDiamondSizeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleQtyChange = (e) => {
    setFilterQty(e.target.value);
    setCurrentPage(1);
  };

  const handleDueDate = (e) => {
    setDueDateFilter(e.target.checked);
  };

  // const handleBagFilterChange = (e) => {
  //   setBagFilter(e.target.value);
  // };
  const handlePoNoFilterChange = (e) => {
    setPoNoFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleSettingFilterChange = (e) => {
    setSelectedSetting(e.target.value);
    setCurrentPage(1);
  };

  const handleDesignNeedFilterChange = (event) => {
    const values = event.target.value;
    setDesignNeedFilter(values);
    setCurrentPage(1);
  };

  const filteredRemittanceData = remittanceData.filter((item) => {
    const toLowerCase = (str) =>
      typeof str === "string" ? str.toLowerCase() : str;
    // if (startE_Date && endE_Date) {
    //   const requestE_Date = item?.estDate;
    //   if (!requestE_Date) return false;

    //   const [day, month, year] = requestE_Date.split("-");
    //   const requestE_DateObj = new Date(`${year}-${month}-${day}`);
    //   const startE_DateObj = new Date(startE_Date);
    //   const endE_DateObj = new Date(endE_Date);

    //   return (
    //     requestE_DateObj >= startE_DateObj && requestE_DateObj <= endE_DateObj
    //   );
    // }

    // const requestDe_Date = new Date(item?.deliveryDate);
    // if (!requestDe_Date || isNaN(requestDe_Date.getTime())) return false;

    // const startDeDateObj = new Date(startDe_Date);
    // const endDeDateObj = new Date(endDe_Date);
    if (orderNo && item.id != orderNo) {
      return false;
    }

    // Check if outletName is provided and filter accordingly
    if (
      outletName &&
      (!item.user || toLowerCase(item.user.name) !== toLowerCase(outletName))
    ) {
      return false;
    }

    const requestDue_Date = new Date(item?.due_date);
    if (!requestDue_Date || isNaN(requestDue_Date.getTime())) return false;
    const startDue_DateObj = new Date(startDue_Date);
    const endDue_DateObj = new Date(endDue_Date);

    if (startDue_Date && endDue_Date) {
      if (
        !(
          requestDue_Date >= startDue_DateObj &&
          requestDue_Date <= endDue_DateObj
        )
      ) {
        return false;
      }
    } else if (!startDue_Date && endDue_Date) {
      if (!(requestDue_Date <= endDue_DateObj)) {
        return false;
      }
    } else if (startDue_Date && !endDue_Date) {
      if (!(requestDue_Date >= startDue_DateObj)) {
        return false;
      }
    }

    // if (startDe_Date && endDe_Date) {
    //   return requestDe_Date >= startDeDateObj && requestDe_Date <= endDeDateObj;
    // } else if (!startDe_Date && endDe_Date) {
    //   return requestDe_Date <= endDeDateObj;
    // } else if (startDe_Date && !endDe_Date) {
    //   return requestDe_Date >= startDeDateObj;
    // }

    if (
      designNeedFilter !== undefined &&
      designNeedFilter !== null &&
      typeof designNeedFilter === "string" &&
      item.DesignNeed !== undefined &&
      item.DesignNeed !== null &&
      typeof item.DesignNeed === "string" &&
      !item.DesignNeed.toLowerCase().includes(designNeedFilter.toLowerCase()) &&
      item.DesignNeed.toLowerCase() !== "n/a" // Exclude 'N/A' values
    ) {
      return false;
    }

    // if (showBag_Filter && bagFilter && item.bagNo !== bagFilter) {
    //   return false;
    // }
    if (
      showPoNo_Filter &&
      poNoFilter &&
      toLowerCase(item.PoNo) !== toLowerCase(poNoFilter)
    ) {
      return false;
    }

    if (
      selectedJewelryType !== "all" &&
      toLowerCase(item.jewelryType) !== toLowerCase(selectedJewelryType)
    ) {
      return false;
    }

    if (
      selectedJewelrySubType !== "all" &&
      toLowerCase(item.jewelrySubType) !== toLowerCase(selectedJewelrySubType)
    ) {
      return false;
    }

    if (
      selectedSetting !== "all" &&
      toLowerCase(item.setting) !== toLowerCase(selectedSetting)
    ) {
      return false;
    }

    if (
      selectedJewelrySubTypeFilter !== "all" &&
      toLowerCase(item.jewelrySubType) !==
      toLowerCase(selectedJewelrySubTypeFilter)
    ) {
      return false;
    }
    if (
      selectedShapeTypeFilter !== "all" &&
      toLowerCase(item.shapeType) !== toLowerCase(selectedShapeTypeFilter)
    ) {
      return false;
    }
    if (
      selectedMetalTypeFilter !== "all" &&
      toLowerCase(item.metalType) !== toLowerCase(selectedMetalTypeFilter)
    ) {
      return false;
    }
    if (
      refLotIdFilter !== "" &&
      toLowerCase(item.refLotId) !== toLowerCase(refLotIdFilter)
    ) {
      return false;
    }

    const itemJewelrySize = item.jewelrySize
      ? toLowerCase(item.jewelrySize)
      : null;

    if (jewelrySizeFilter) {
      if (!isNaN(jewelrySizeFilter)) {
        if (!itemJewelrySize) return false;
        const numericFilter = parseFloat(jewelrySizeFilter);
        const numericJewelrySize = parseFloat(itemJewelrySize.split(" ")[0]);
        if (numericJewelrySize !== numericFilter) {
          return false;
        }
      } else {
        if (itemJewelrySize !== toLowerCase(jewelrySizeFilter)) {
          return false;
        }
      }
    }

    if (showPriorityFilter && selectedPriority.length > 0) {
      return (
        selectedPriority.includes(item.priority) ||
        selectedPriority.includes("all")
      );
    }

    // if (jewelrySizeFilter && item.jewelrySize) {
    //   if (!compareJewelrySize(item.jewelrySize, jewelrySizeFilter)) {
    //     return false;
    //   }
    // }

    if (
      diamondSizeFilter &&
      toLowerCase(item.diamondApoSize) !== toLowerCase(diamondSizeFilter)
    ) {
      return false;
    }
    if (filterQty !== "" && parseFloat(item.qty) !== parseFloat(filterQty)) {
      return false;
    }

    // Status filter
    if (selectedStatus.length > 0 && !selectedStatus.includes("all")) {
      const lowerCaseStatus = toLowerCase(item.requestStatus || "");

      const isPending =
        selectedStatus.includes("Req_Pending") &&
        (item.requestStatus === null ||
          item.requestStatus === undefined ||
          lowerCaseStatus === "req_pending");

      const matchesOtherStatuses = selectedStatus.some(
        (status) => toLowerCase(status) === lowerCaseStatus
      );

      if (!isPending && !matchesOtherStatuses) {
        return false;
      }
    }

    // if (selectedStatus !== "all") {
    //   if (selectedStatus === "Req_Pending" || selectedStatus === null) {
    //     if (!item.id || !item.requestStatus) {
    //       return true;
    //     } else if (toLowerCase(item.requestStatus) !== "req_pending") {
    //       return false;
    //     }
    //   } else if (
    //     toLowerCase(item.requestStatus) !== toLowerCase(selectedStatus)
    //   ) {
    //     return false;
    //   }
    // }

    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const requestDate = new Date(item.requestDate);

      if (requestDate >= startDateObj && requestDate <= endDateObj) {
        return true;
      } else {
        return false;
      }
    } else if (!startDate && !endDate) {
      return true;
    } else if (!startDate && endDate) {
      const endDateObj = new Date(endDate);
      const requestDate = new Date(item.requestDate);
      return requestDate <= endDateObj;
    } else if (startDate && !endDate) {
      const startDateObj = new Date(startDate);
      const requestDate = new Date(item.requestDate);
      return requestDate >= startDateObj;
    }

    return true;
  });

  const dataToRender = showUnreadMessages
    ? filteredRemittanceData.filter(
      (remittance) => unreadMessages[remittance.id] > 0
    )
    : filteredRemittanceData;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = dataToRender.filter(
    (remittance) =>
      remittance.requestStatus !== "Export" &&
      remittance.requestStatus !== "Canceled Order"
  );

  const currentRemittanceData = filteredData
    .reverse()
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentRemittanceData = filteredRemittanceData
  //   .filter(
  //     (remittance) =>
  //       remittance.requestStatus !== "Export" &&
  //       remittance.requestStatus !== "Canceled Order"
  //   )
  //   .reverse()
  //   .slice(indexOfFirstItem, indexOfLastItem);

  // const totalPages = Math.ceil(
  //   filteredRemittanceData.filter(
  //     (remittance) =>
  //       remittance.requestStatus !== "Export" &&
  //       remittance.requestStatus !== "Canceled Order"
  //   ).length / itemsPerPage
  // );

  // const paginate = (pageNumber) => {
  //   if (pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  const jewelryTypes = [
    ...new Set(remittanceData.map((item) => item.jewelryType)),
  ];
  const jewelrySubTypes = [
    ...new Set(remittanceData.map((item) => item.jewelrySubType)),
  ];

  const setting = [...new Set(remittanceData.map((item) => item.setting))];
  const shapeTypes = [...new Set(remittanceData.map((item) => item.shapeType))];
  const metalTypes = [...new Set(remittanceData.map((item) => item.metalType))];

  const handleCheckboxChange = (e, id) => {
    const checked = e.target.checked;

    if (selectAllChecked && !checked) {
      setSelectAllChecked(false);
    }

    setSelectedRows((prevSelectedRows) => {
      if (checked && !prevSelectedRows.includes(id)) {
        return [...prevSelectedRows, id];
      } else {
        return prevSelectedRows.filter((item) => item !== id);
      }
    });
  };

  const handleSelectAll = () => {
    if (!selectAllChecked || selectedRows.length !== remittanceData.length) {
      const allOrderNumbers = remittanceData.map((item) => item.id);
      setSelectedRows(allOrderNumbers);
      setSelectAllChecked(true);
    } else {
      setSelectedRows([]);
      setSelectAllChecked(false);
    }
  };

  const downloadSelectedCSV = () => {
    // Helper function to convert a string to lowercase
    const toLowerCase = (str) =>
      typeof str === "string" ? str.toLowerCase() : str;

    // Ensure selectedStatus is an array
    const statusArray = Array.isArray(selectedStatus) ? selectedStatus : [];

    // Apply the initial filter based on selected rows and requestStatus
    const initialFilter = remittanceData
      .filter((item) => selectedRows.includes(item.id))
      .filter(
        (item) =>
          item.requestStatus?.toLowerCase() !== "export" &&
          item.requestStatus?.toLowerCase() !== "canceled order"
      );

    // Apply additional filters, including status and other criteria
    const selectedData = initialFilter.filter((item) => {
      const lowerCaseStatus = toLowerCase(item.requestStatus || "");
      const isPending =
        statusArray.includes("Req_Pending") &&
        (item.requestStatus === null ||
          item.requestStatus === undefined ||
          lowerCaseStatus === "req_pending");
      const matchesOtherStatuses = statusArray.some(
        (status) => toLowerCase(status) === lowerCaseStatus
      );

      return (
        (!orderNo || item.id == orderNo) &&
        (!outletName ||
          toLowerCase(item.user.name) === toLowerCase(outletName)) &&
        (!startDate ||
          !endDate ||
          (new Date(item.requestDate) >= new Date(startDate) &&
            new Date(item.requestDate) <= new Date(endDate))) &&
        (!startDue_Date ||
          !endDue_Date ||
          (new Date(item.due_date) >= new Date(startDue_Date) &&
            new Date(item.due_date) < new Date(endDue_Date))) &&
        (statusArray.length === 0 ||
          statusArray.includes("all") ||
          isPending ||
          matchesOtherStatuses) &&
        (selectedJewelryType === "all" ||
          toLowerCase(item?.jewelryType) ===
          toLowerCase(selectedJewelryType)) &&
        (selectedSetting === "all" ||
          toLowerCase(item?.setting) === toLowerCase(selectedSetting)) &&
        (selectedJewelrySubTypeFilter === "all" ||
          toLowerCase(item.jewelrySubType) ===
          toLowerCase(selectedJewelrySubTypeFilter)) &&
        (!refLotIdFilter ||
          toLowerCase(item.refLotId) === toLowerCase(refLotIdFilter)) &&
        (selectedShapeTypeFilter === "all" ||
          toLowerCase(item.shapeType) ===
          toLowerCase(selectedShapeTypeFilter)) &&
        (selectedMetalTypeFilter === "all" ||
          toLowerCase(item.metalType) ===
          toLowerCase(selectedMetalTypeFilter)) &&
        (!jewelrySizeFilter ||
          toLowerCase(item.jewelrySize) === toLowerCase(jewelrySizeFilter)) &&
        (!diamondSizeFilter ||
          toLowerCase(item.diamondApoSize) ===
          toLowerCase(diamondSizeFilter)) &&
        (!filterQty || item.qty === parseInt(filterQty)) &&
        (!poNoFilter || toLowerCase(item.PoNo) === toLowerCase(poNoFilter)) &&
        (!designNeedFilter ||
          toLowerCase(item.DesignNeed) === toLowerCase(designNeedFilter)) &&
        (!showPriorityFilter ||
          selectedPriority.includes("all") ||
          selectedPriority.includes(item.priority))
      );
    });

    // Ensure user.name is included in the data
    const flattenedData = selectedData.map((item) => {
      const { user, ...rest } = item;
      return {
        ...rest,
        outletName: user?.name || "",
      };
    });

    // Convert selectedData to CSV format
    const csv = Papa.unparse(flattenedData);

    // Create a Blob object containing the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "selected_data.csv");

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body after download
    document.body.removeChild(link);
  };

  const clearDateFilters = () => {
    setStartDate("");
    setEndDate("");
    setShowDateFilter(false);
  };

  const clearStatusFilter = () => {
    setSelectedStatus("all");
    setShowStatusFilter(false);
  };

  const clearJewelryTypeFilter = () => {
    setSelectedJewelryType("all");
    setShowTypeFilter(false);
  };

  const clearJewelrySubTypeFilter = () => {
    setSelectedJewelrySubType("all");
    setShowSubTypeFilter(false);
    setSelectedJewelrySubTypeFilter("all");
  };

  const clearSettingFilter = () => {
    setSelectedSetting("all");
    setShowSettingFilter(false);
  };

  // Function to clear ref lot ID filter
  const clearRefLotIdFilter = () => {
    setRefLotIdFilter("");
    setShowLotIDFilter(false);
  };

  const clearShapeTypeFilter = () => {
    setSelectedShapeTypeFilter("all");
    setshowShape_TypeFilter(false);
  };

  const clearMetalTypeFilter = () => {
    setSelectedMetalTypeFilter("all");
    setshowMetal_TypeFilter(false);
  };

  const clearJewelrySizeFilter = () => {
    setJewelrySizeFilter("");
    setshowJewelry_SizeFilter(false);
  };

  const clearOrderNoFilter = () => {
    setOrderNoFilter("");
    setShowOrderNoFilter(false);
  };

  const clearOutletNameFilter = () => {
    setOutletName("");
    setShowOutletNameFilter(false);
  };

  const clearDiamondSizeFilter = () => {
    setDiamondSizeFilter("");
    setshowDiamondSizeFilter(false);
  };

  const clearQtyFilter = () => {
    setFilterQty("");
    setshowQtyFilter(false);
  };

  const handleStartDateClose = () => {
    clearDateFilters();
  };

  const handleStatusClose = () => {
    clearStatusFilter();
  };
  const handleTypeClose = () => {
    clearJewelryTypeFilter();
  };
  const handleJewelrySubTypeClose = () => {
    clearJewelrySubTypeFilter();
  };

  const handleSettingClose = () => {
    clearSettingFilter();
  };
  const handleRefLotIdClose = () => {
    clearRefLotIdFilter();
  };

  const handleOutletNameClose = () => {
    clearOutletNameFilter();
  };

  const handleShapeTypeClose = () => {
    clearShapeTypeFilter();
  };
  const handleMetalTypeClose = () => {
    clearMetalTypeFilter();
  };
  const handleJewelrySizeClose = () => {
    clearJewelrySizeFilter();
  };
  const handleDiamondSizeClose = () => {
    clearDiamondSizeFilter();
  };
  const handleQtyClose = () => {
    clearQtyFilter();
  };

  const handleOrderNoClose = () => {
    clearOrderNoFilter();
  };

  // const handleBagFilterClose = () => {
  //   setBagFilter("");
  //   setShowBag_Filter(false);
  // };
  const handlePoNoFilterClose = () => {
    setPoNoFilter("");
    setShowPoNo_Filter(false);
  };

  const handleDesignNeedFilterClose = () => {
    setDesignNeedFilter("");
    setShowDesignNeed_Filter(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasModalOpened = sessionStorage.getItem("hasModalOpened");

    // Check if the modal has not been opened before
    if (!hasModalOpened) {
      setIsOpen(true);
      // Set flag in sessionStorage to in dicate that the modal has been opened
      sessionStorage.setItem("hasModalOpened", "true");
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const getTotalUnreadMessages = () => {
    const unreadCounts = Object.values(unreadMessages);
    return unreadCounts.reduce((total, count) => total + count, 0);
  };

  const toggleButtonText = () => {
    setButtonText((prevText) =>
      prevText === "View unread messages"
        ? "Show all orders"
        : "View unread messages"
    );
  };

  const renderUnreadMessageCount = () => {
    const totalUnread = getTotalUnreadMessages();
    if (totalUnread > 0) {
      return (
        <button
          className="text-white text-uppercase btn btn-primary"
          onClick={toggleButtonText}
        >
          {buttonText}
        </button>
      );
    }
    return null;
  };

  const unreadMessagesCount = Object.keys(unreadMessages).length - 20;

  const renderRedDot = (orderId) => {
    const unreadCount = unreadMessages[orderId] || 0;
    if (unreadCount > 0) {
      return <span className="red-dot position-absolute"></span>;
    }
    return null;
  };

  const sortedRemittanceData = [...remittanceData].sort((a, b) => {
    const unreadCountA = unreadMessages[a.id] || 0;
    const unreadCountB = unreadMessages[b.id] || 0;
    // Sort in descending order of unread message count
    return unreadCountB - unreadCountA;
  });

  const handleRedChatBox = () => {
    setShowUnreadMessages(!showUnreadMessages);
    toggleButtonText();

    // setShowRenderedData(!showRenderedData);

    // // Filter the sortedRemittanceData to include only items with unread messages
    // const remittancesWithUnreadMessages = sortedRemittanceData.filter(
    //   (remittance) => {
    //     return unreadMessages[remittance.id] > 0;
    //   }
    // );

    // // return remittancesWithUnreadMessages
    // setRenderedData(remittancesWithUnreadMessages);
  };

  const allKeys = new Set();

  currentRemittanceData.forEach((item) => {
    Object.keys(item).forEach((key) => allKeys.add(key));
  });

  const jewelryType1 = "jewelryType";
  const jewelrySubType1 = "jewelrySubType";
  const refLotId1 = "refLotId";
  const vendor_style = "vendor_style";
  const milgrain = "milgrain";
  const head = "head";
  const stones = "stones";
  const labor_gdc = "labor_gdc";
  const metalType1 = "metalType";
  const jewelrySize1 = "jewelrySize";
  const diamondApoSize1 = "diamondApoSize";
  const refLink1 = "refLink";
  const quote = "quote";
  const comment1 = "comment";
  const qty1 = "qty";
  const estDate1 = "estDate";
  const requestDate1 = "requestDate";
  const setting1 = "setting";
  const bagNo1 = "bagNo";
  const DesignNeed1 = "DesignNeed";
  const due_date = "due_date";
  const Priority1 = "priority";
  const PoNo1 = "PoNo";
  const finalImg1 = "finalImg";
  const refImge1 = "refImge";
  const deliveryDate1 = "deliveryDate";
  const shapeType1 = "shapeType";
  const ref_by1 = "ref_by";
  const requestStatus1 = "requestStatus";
  const code1 = "code";
  const id1 = "id";
  // const estRate1 = 'estRate';
  // const orderNumber1 = 'orderNumber';
  const due_date1 = "due_date";
  // const created_by1 = 'created_by';
  // const client_id1 = 'client_id';
  const company1 = "company";

  // const dataToRender = showRenderedData ? renderedData : currentRemittanceData;

  const totalOrder = filteredRemittanceData
    .filter(
      (remittance) =>
        remittance.requestStatus !== "Export" &&
        remittance.requestStatus !== "Canceled Order"
    )
    .reverse().length;

  const uniqueJewelryTypes = Array.from(
    new Set(
      jewelryTypes
        .filter((type) => type !== null && type.trim() !== "")
        .map((type) => type.trim().toLowerCase())
    )
  );

  const uniqueJewelrySubTypes = Array.from(
    new Set(
      jewelrySubTypes
        .filter((type) => type !== null && type.trim() !== "")
        .map((type) => type.trim().toLowerCase())
    )
  );

  const uniqueSettings = Array.from(
    new Set(
      setting
        .filter((type) => type !== null && type.trim() !== "")
        .map((type) => type.trim().toLowerCase())
    )
  );

  return (
    <>
      {Permissionclient.includes("warningimg") && (
        <div className={`modalal ${isOpen ? "open" : "closed"}`}>
          <div className="modal-contentes">
            <span className="close-button" onClick={closeModal}>
              <CloseRoundedIcon />
            </span>
            <div>
              <img src="./jewelry poppup_011.jpg" />
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3 w-100">
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <div onClick={handleRedChatBox} className="btn btn-primary">
              {buttonText}
            </div>
            {/* <div onClick={handleRedChatBox}>{renderUnreadMessageCount()}</div> */}
            <div>
              Total Order <span className="fw-bold fs-5">({totalOrder})*</span>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div>
              <UserUploadOrderData setRemittanceData={setRemittanceData} />
            </div>

            <Link to="/userCancelOrder">
              <button className="btn btn-primary">Canceled List</button>
            </Link>

            <Link to="/userExport">
              <button className="btn btn-primary">Delivered List </button>
            </Link>

            <button onClick={handleSelectAll} className="btn btn-primary me-2">
              {selectAllChecked ? "Deselect All" : "Select All"}
            </button>
            <MDBBtn onClick={downloadSelectedCSV}>
              <MDBIcon fas icon="download" className="me-2" />
              Download Data
            </MDBBtn>
          </div>
        </div>
        <div
          className="d-flex  align-items-center gap-2 px-2 rounded-5  bg_report"
          style={{ top: "185px", zIndex: 999, position: "sticky" }}
        >
          {/* <div className=" d-flex gap-3">
            <div className="d-flex flex-column w-100">
              <FilterAltRoundedIcon onClick={toggleFilters} />
            </div>
          </div> */}

          <>
            {showOrderNoFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Order No
                    <CloseRoundedIcon onClick={handleOrderNoClose} />
                  </label>
                  <input
                    type="text"
                    value={`J${orderNo}`}
                    onChange={handleOrderNoFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}

            {showOutletNameFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Outlet Name
                    <CloseRoundedIcon onClick={handleOutletNameClose} />
                  </label>
                  <input
                    type="text"
                    value={outletName}
                    onChange={handleOutletNameFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}

            {showDateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">Start Date</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="form-control "
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End Date</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartDateClose} />
              </div>
            )}

            {showStatusFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="text-center pb-1">
                    Status
                  </label>
                  <div className="dropdown">
                    <button
                      className="form-select w-100"
                      type="button"
                      onClick={handleDropdownToggle}
                    >
                      select
                    </button>
                    {dropdownOpen && (
                      <div className="dropdown-menu show scrollable-menu">
                        {statusOptions.map((option, index) => (
                          <div key={index} className="dropdown-item">
                            <div>
                              <input
                                type="checkbox"
                                id={`checkbox-${option.value}`}
                                checked={selectedStatus.includes(option.value)}
                                onChange={() =>
                                  handleStatusChange(option.value)
                                }
                              />
                              <label
                                className="checkBox"
                                htmlFor={`checkbox-${option.value}`}
                              >
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <CloseRoundedIcon onClick={handleStatusClose} />
              </div>
            )}

            {/* {showStatusFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column  ">
                  <label className=" fs-6 text-center pb-1" htmlFor="">
                    Status
                  </label>
                  <select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    <option value="Req_Pending">Req_Pending</option>
                    <option value="Req_Approved">Req_Approved</option>
                    <option value="Design Approval Pending">
                      {" "}
                      Design Approval Pending
                    </option>
                    <option value="Design Approved">Design Approved</option>
                    <option value="Designing">Designing</option>
                    <option value="Wax & Cast">Wax & Cast</option>
                    <option value="Filing">Filing</option>
                    <option value="Pre Polishing">Pre Polishing</option>
                    <option value="Diamond">Diamond</option>
                    <option value="Metal Setting">Metal Setting</option>
                    <option value="Final Polishing">Final Polishing</option>
                    <option value="Finished Good">Finished Good</option>
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleStatusClose} />
              </div>
            )} */}

            {showPriorityFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="text-center pb-1">
                    Priority
                  </label>
                  <div className="dropdown">
                    <button
                      className="form-select w-100"
                      type="button"
                      onClick={handleDropdownToggle}
                    >
                      select
                    </button>
                    {dropdownOpen && (
                      <div className="dropdown-menu show scrollable-menu">
                        {priorityOptions.map((option, index) => (
                          <div key={index} className="dropdown-item">
                            <div>
                              <input
                                type="checkbox"
                                id={`checkbox-${option.value}`}
                                checked={selectedPriority.includes(
                                  option.value
                                )}
                                onChange={() =>
                                  handlePriorityFilterChange(option.value)
                                }
                              />
                              <label
                                className="checkBox"
                                htmlFor={`checkbox-${option.value}`}
                              >
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <CloseRoundedIcon onClick={handlePriorityClose} />
              </div>
            )}

            {showTypeFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="text-center pb-1">
                    JewelryType
                  </label>
                  <select
                    value={selectedJewelryType}
                    onChange={handleJewelryTypeChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {uniqueJewelryTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}{" "}
                        {/* Capitalize first letter */}
                      </option>
                    ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleTypeClose} />
              </div>
            )}

            {showSubTypeFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    JewelrySubType
                  </label>
                  <select
                    value={selectedJewelrySubTypeFilter}
                    onChange={handleJewelrySubTypeFilterChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {uniqueJewelrySubTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleJewelrySubTypeClose} />
              </div>
            )}

            {showSettingFilter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    Setting
                  </label>
                  <select
                    value={selectedSetting}
                    onChange={handleSettingFilterChange}
                    className="form-select w-100"
                  >
                    <option value="all">All</option>
                    {uniqueSettings.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleSettingClose} />
              </div>
            )}

            {showLotIDFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Ref. Piece
                    <CloseRoundedIcon onClick={handleRefLotIdClose} />
                  </label>
                  <input
                    type="text"
                    value={refLotIdFilter}
                    onChange={handleRefLotIdFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}
            {showShape_TypeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    ShapType
                  </label>
                  <select
                    value={selectedShapeTypeFilter}
                    onChange={handleShapeTypeFilterChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {shapeTypes
                      .filter((type) => type !== null && type.trim() !== "")
                      .map((type, index) => (
                        <option key={index} value={type.trim()}>
                          {type.trim()}
                        </option>
                      ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleShapeTypeClose} />
              </div>
            )}

            {showMetal_TypeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    MetalType
                  </label>
                  <select
                    value={selectedMetalTypeFilter}
                    onChange={handleMetalTypeFilterChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {metalTypes
                      .filter((type) => type !== null && type.trim() !== "")
                      .map((type, index) => (
                        <option key={index} value={type.trim()}>
                          {type.trim()}
                        </option>
                      ))}
                  </select>
                </div>
                <CloseRoundedIcon onClick={handleMetalTypeClose} />
              </div>
            )}
            {showJewelry_SizeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Jewelry_Size
                    <CloseRoundedIcon onClick={handleJewelrySizeClose} />
                  </label>
                  <input
                    type="text"
                    value={jewelrySizeFilter}
                    onChange={handleJewelry_SizeFilterChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}
            {showDiamondSizeFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    T_Dia_Size
                    <CloseRoundedIcon onClick={handleDiamondSizeClose} />
                  </label>
                  <input
                    type="text"
                    value={diamondSizeFilter}
                    onChange={handleDiamondSizeChange}
                    className="form-control  w-50"
                  />
                </div>
              </div>
            )}
            {showQtyFilter && (
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 pb-1">
                    Filter Quantity
                    <CloseRoundedIcon onClick={handleQtyClose} />
                  </label>
                  <input
                    type="text"
                    value={filterQty}
                    onChange={handleQtyChange}
                    className="form-control  w-50"
                    required
                  />
                </div>
              </div>
            )}

            {/* {showBag_Filter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Bag-No
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleBagFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={bagFilter}
                    onChange={handleBagFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )} */}

            {showPoNo_Filter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Po-No
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handlePoNoFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={poNoFilter}
                    onChange={handlePoNoFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}

            {showDesignNeed_Filter && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column  w-100">
                  <label htmlFor="" className="fs-6  pb-1">
                    Design-Need?
                    <CloseRoundedIcon
                      className="ms-2"
                      onClick={handleDesignNeedFilterClose}
                    />
                  </label>
                  <input
                    type="text"
                    value={designNeedFilter}
                    onChange={handleDesignNeedFilterChange}
                    className="form-control w-50"
                  />
                </div>
              </div>
            )}

            {showDue_DateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    Start Due_Date
                  </label>
                  <input
                    type="date"
                    value={startDue_Date}
                    onChange={handleStartDue_DateChange}
                    className="form-control "
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    End Due_Date
                  </label>
                  <input
                    type="date"
                    value={endDue_Date}
                    onChange={handleEndDue_DateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartDue_DateClose} />
              </div>
            )}

            {/* {showE_DateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    Start E_Date
                  </label>
                  <input
                    type="date"
                    value={startE_Date}
                    onChange={handleStartE_DateChange}
                    className="form-control"
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End E_Date</label>
                  <input
                    type="date"
                    value={endE_Date}
                    onChange={handleEndE_DateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartE_DateClose} />
              </div>
            )} */}

            {/* {showDe_DateFilter && (
              <div className=" d-flex gap-1">
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">
                    Start De_Date
                  </label>
                  <input
                    type="date"
                    value={startDe_Date}
                    onChange={handleStartDe_DateChange}
                    className="form-control "
                  />
                </div>
                <div className="d-flex flex-column ">
                  <label className="me-2  text-center pb-1 ">End De_Date</label>
                  <input
                    type="date"
                    value={endDe_Date}
                    onChange={handleEndDe_DateChange}
                    className="form-control"
                  />
                </div>
                <CloseRoundedIcon onClick={handleStartDe_DateClose} />
              </div>
            )} */}

            {/*
         <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    JewelrySubType
                  </label>
                  <select
                    value={selectedJewelrySubTypeFilter}
                    onChange={handleJewelrySubTypeFilterChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {jewelrySubTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className=" d-flex gap-3">
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="fs-6 text-center pb-1">
                    Category
                  </label>
                  <select
                    value={selectedJewelryType}
                    onChange={handleJewelryTypeChange}
                    className="form-select  w-100"
                  >
                    <option value="all">All</option>
                    {jewelryTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div> 
              </div> */}
          </>
          {/* )} */}
        </div>

        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <table
              className="table table-bordered"
              style={{ width: "100%", maxWidth: "5000px" }}
            >
              <thead id="addProductTableHead" style={{ top: "136px" }}>
                <tr>
                  <th className="p-2 fw-bold">Select</th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(id1) ? "table-cell" : "none",
                    }}
                  >
                    OrderID
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowOrderNoFilter(!showOrderNoFilter)}
                    />
                  </th>

                  {allowOutletlocal == 1 && (
                    <th
                      className="p-2 fw-bold"
                      style={{
                        display: allKeys?.has(id1) ? "table-cell" : "none",
                      }}
                    >
                      Outlate_Name
                      <ArrowDropDownRoundedIcon
                        onClick={() =>
                          setShowOutletNameFilter(!showOutletNameFilter)
                        }
                      />
                    </th>
                  )}

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(requestDate1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Date
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowDateFilter(!showDateFilter)}
                    />
                  </th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(requestStatus1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Status
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowStatusFilter(!showStatusFilter)}
                    />
                  </th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(jewelryType1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Type
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowTypeFilter(!showTypeFilter)}
                    />
                  </th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(jewelrySubType1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Sub_Type
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowSubTypeFilter(!showSubTypeFilter)}
                    />
                  </th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(setting1) ? "table-cell" : "none",
                    }}
                  >
                    Setting
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowSettingFilter(!showSettingFilter)}
                    />
                  </th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(refLotId1) ? "table-cell" : "none",
                    }}
                  >
                    Gdc Style
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowLotIDFilter(!showLotIDFilter)}
                    />
                  </th>
                  <th className="p-2 fw-bold">Vendor Style</th>
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(shapeType1) ? "table-cell" : "none",
                    }}
                  >
                    Shape
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowShape_TypeFilter(!showShape_TypeFilter)
                      }
                    />
                  </th> */}
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(metalType1) ? "table-cell" : "none",
                    }}
                  >
                    Metal
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowMetal_TypeFilter(!showMetal_TypeFilter)
                      }
                    />
                  </th>

                  <th className="p-2 fw-bold">Milgrain</th>
                  <th className="p-2 fw-bold">Head</th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(jewelrySize1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Size
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowJewelry_SizeFilter(!showJewelry_SizeFilter)
                      }
                    />
                  </th>
                  <th className="p-2 fw-bold">Stones</th>
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(diamondApoSize1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Diamond Size
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setshowDiamondSizeFilter(!showDiamondSizeFilter)
                      }
                    />
                  </th> */}
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(qty1) ? "table-cell" : "none",
                    }}
                  >
                    Qty
                    <ArrowDropDownRoundedIcon
                      onClick={() => setshowQtyFilter(!showQtyFilter)}
                    />
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(labor_gdc) ? "table-cell" : "none",
                    }}
                  >LABOR GDC</th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(comment1) ? "table-cell" : "none",
                    }}
                  >
                    Instructions
                  </th>
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(refLink1) ? "table-cell" : "none",
                    }}
                  >
                    Ref.link
                  </th> */}
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(refImge1) ? "table-cell" : "none",
                    }}
                  >
                    ref_Img
                  </th> */}
                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(PoNo1) ? "table-cell" : "none",
                    }}
                  >
                    Po.No{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowPoNo_Filter(!showPoNo_Filter)}
                    />
                  </th> */}
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(DesignNeed1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Cad Cam{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() =>
                        setShowDesignNeed_Filter(!showDesignNeed_Filter)
                      }
                    />
                  </th>


                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(quote)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Quote{" "}
                  </th>

                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(due_date)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Due Date{" "}
                  </th>

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(DesignNeed1)
                        ? "table-cell"
                        : "none",
                    }}
                  >
                    Due_Date{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowDue_DateFilter(!showDue_DateFilter)}
                    />
                  </th> */}

                  {/* <th className="p-2 fw-bold">
                  Bag-No{" "}
                  <ArrowDropDownRoundedIcon
                    onClick={() => setShowBag_Filter(!showBag_Filter)}
                  />
                </th> */}
                  {/* <th className="p-2 fw-bold">
                  E_Date{" "}
                  <ArrowDropDownRoundedIcon
                    onClick={() => setShowE_DateFilter(!showE_DateFilter)}
                  />
                </th>
                <th className="p-2 fw-bold">
                  Delivery{" "}
                  <ArrowDropDownRoundedIcon
                    onClick={() => setShowDe_DateFilter(!showDe_DateFilter)}
                  />
                </th> */}

                  {/* <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(Priority1) ? "table-cell" : "none",
                    }}
                  >
                    Priority{" "}
                    <ArrowDropDownRoundedIcon
                      onClick={() => setShowPriorityFilter(!showPriorityFilter)}
                    />
                  </th> */}

                  <th className="p-2 fw-bold">Chatbox</th>
                  <th
                    className="p-2 fw-bold"
                    style={{
                      display: allKeys?.has(finalImg1) ? "table-cell" : "none",
                    }}
                  >
                    F_Img
                  </th>
                  <th className="p-2 fw-bold">Tracking</th>
                  <th className="p-2 fw-bold">Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentRemittanceData.slice().map((remittance, index) => (
                  <tr
                    key={index}
                    className={`hover-row ${selectedRows.includes(remittance.id) ? "bg_select" : ""
                      }`}
                  >
                    <td
                      // id="showUserTablebody"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, remittance.id)}
                        checked={
                          selectAllChecked ||
                          selectedRows.includes(remittance.id)
                        }
                      />
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(id1) ? "table-cell" : "none",
                      }}
                    >
                      J{remittance.id}
                    </td>

                    {allowOutletlocal == 1 && (
                      <td id="showUserTablebody">
                        {remittance.user
                          ? remittance.user.name || "N/A"
                          : "N/A"}
                      </td>
                    )}

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(requestDate1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {formatDate(remittance.requestDate)}
                    </td>

                    <span
                      style={{
                        display: allKeys?.has(requestStatus1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <td
                        id="showUserTablebody"
                        className="fw-bold"
                        style={{
                          color:
                            !remittance.id || !remittance.requestStatus
                              ? "orange"
                              : remittance.requestStatus === "Req_Approved"
                                ? "green"
                                : remittance.requestStatus === "Designing"
                                  ? "blue"
                                  : remittance.requestStatus ===
                                    "Design Approval Pending"
                                    ? "#9A208C"
                                    : remittance.requestStatus === "Design Approved"
                                      ? "#4FA095"
                                      : remittance.requestStatus === "Wax & Cast"
                                        ? "#b7410e"
                                        : remittance.requestStatus === "Filing"
                                          ? "#ff7f50"
                                          : remittance.requestStatus === "Pre Polishing"
                                            ? "#50c878"
                                            : remittance.requestStatus === "Diamond"
                                              ? "olive"
                                              : remittance.requestStatus === "Metal Setting"
                                                ? "#b7410e"
                                                : remittance.requestStatus === "Final Polishing"
                                                  ? "#e0b0ff"
                                                  : remittance.requestStatus === "Finished Good"
                                                    ? "#496989"
                                                    : remittance.requestStatus === "Export"
                                                      ? "red"
                                                      : remittance.requestStatus === "Hold"
                                                        ? "#096C87"
                                                        : remittance.requestStatus === "Assembly Filling"
                                                          ? "#000187"
                                                          : "inherit",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {!remittance.id || !remittance.requestStatus
                          ? "Req_pending"
                          : remittance.requestStatus}
                      </td>
                    </span>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(jewelryType1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          value={
                            editedValues.jewelryType !== undefined
                              ? editedValues.jewelryType
                              : remittance.jewelryType
                          }
                          onChange={(e) => handleInputChange(e, "jewelryType")}
                        />
                      ) : (
                        remittance.jewelryType
                      )}
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(jewelrySubType1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "60px" }}
                          value={
                            editedValues.jewelrySubType !== undefined
                              ? editedValues.jewelrySubType
                              : remittance.jewelrySubType
                          }
                          onChange={(e) =>
                            handleInputChange(e, "jewelrySubType")
                          }
                        />
                      ) : (
                        remittance.jewelrySubType
                      )}
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(setting1) ? "table-cell" : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "80px" }}
                          value={
                            editedValues.setting !== undefined
                              ? editedValues.setting
                              : remittance.setting
                          }
                          onChange={(e) => handleInputChange(e, "setting")}
                        />
                      ) : (
                        <span
                          onClick={() => handleValueClick(index)}
                          style={{ cursor: "pointer" }}
                          title={remittance.setting}
                        >
                          {showFullValue[index] ||
                            !remittance.setting ||
                            remittance.setting.length <= 10
                            ? remittance.setting || "N/A"
                            : remittance.setting.substring(0, 10) + "..."}
                        </span>
                      )}
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(refLotId1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "50px" }}
                          value={
                            editedValues.refLotId !== undefined
                              ? editedValues.refLotId
                              : remittance.refLotId
                          }
                          onChange={(e) => handleInputChange(e, "refLotId")}
                        />
                      ) : (
                        remittance.refLotId
                      )}
                    </td>

                    <td id="showUserTablebody"
                      style={{
                        display: allKeys?.has(vendor_style)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          placeholder="Vendor Style"
                          type="text"
                          className="input-field"
                          style={{ width: "60px" }}
                          value={
                            editedValues.vendor_style !== undefined
                              ? editedValues.vendor_style
                              : remittance.vendor_style
                          }
                          onChange={(e) => handleInputChange(e, "vendor_style")}
                        />
                      ) : (
                        remittance.vendor_style || "N/A"
                      )}
                    </td>

                    {/* <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(shapeType1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "60px" }}
                          value={
                            editedValues.shapeType !== undefined
                              ? editedValues.shapeType
                              : remittance.shapeType
                          }
                          onChange={(e) => handleInputChange(e, "shapeType")}
                        />
                      ) : (
                        remittance.shapeType
                      )}
                    </td> */}

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(metalType1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "50px" }}
                          value={
                            editedValues.metalType !== undefined
                              ? editedValues.metalType
                              : remittance.metalType
                          }
                          onChange={(e) => handleInputChange(e, "metalType")}
                        />
                      ) : (
                        remittance.metalType
                      )}
                    </td>

                    <td id="showUserTablebody"
                      style={{
                        display: allKeys?.has(milgrain)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          placeholder="Milgrain"
                          type="text"
                          className="input-field"
                          style={{ width: "60px" }}
                          value={
                            editedValues.milgrain !== undefined
                              ? editedValues.milgrain
                              : remittance.milgrain
                          }
                          onChange={(e) => handleInputChange(e, "milgrain")}
                        />
                      ) : (
                        remittance.milgrain || "N/A"
                      )}
                    </td>

                    <td id="showUserTablebody"
                      style={{
                        display: allKeys?.has(head)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "50px" }}
                          value={
                            editedValues.head !== undefined
                              ? editedValues.head
                              : remittance.head
                          }
                          onChange={(e) => handleInputChange(e, "head")}
                        />
                      ) : (
                        remittance.head || "N/A"
                      )}
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(jewelrySize1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          value={
                            editedValues.jewelrySize !== undefined
                              ? editedValues.jewelrySize
                              : remittance.jewelrySize
                          }
                          onChange={(e) => handleInputChange(e, "jewelrySize")}
                        />
                      ) : (
                        remittance.jewelrySize
                      )}
                    </td>

                    <td id="showUserTablebody">
                      <div>
                        <CommentIcon
                          data-bs-toggle="modal"
                          data-bs-target={`#modalStone-${index}`}
                          onClick={() => handleOpenModal(remittance)}
                          style={{ color: remittance.stones ? "#008000" : "#EBEBEB" }}
                        />
                        <div
                          className="modal fade"
                          id={`modalStone-${index}`}
                          tabIndex="-1"
                          aria-labelledby={`popupLabelStone-${index}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <div>
                                  <h5 className="fw-bold text-start" id={`popupLabelStone-${index}`}>
                                    Stones
                                  </h5>
                                  <span>
                                    Order No- J{selectedRemittance && selectedRemittance.id}
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body text-center">
                                <span>{remittance.stones || "N/A"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(diamondApoSize1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "30px" }}
                          value={
                            editedValues.diamondApoSize !== undefined
                              ? editedValues.diamondApoSize
                              : remittance.diamondApoSize
                          }
                          onChange={(e) =>
                            handleInputChange(e, "diamondApoSize")
                          }
                        />
                      ) : (
                        <span
                          onClick={() => handleValueClick(index)}
                          style={{ cursor: "pointer" }}
                          title={remittance.diamondApoSize}
                        >
                          {showFullValue[index] ||
                          !remittance.diamondApoSize ||
                          remittance.diamondApoSize.length <= 10
                            ? remittance.diamondApoSize || "N/A"
                            : remittance.diamondApoSize.substring(0, 10) +
                              "..."}
                        </span>
                      )}
                    </td> */}

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(qty1) ? "table-cell" : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "30px" }}
                          value={
                            editedValues.qty !== undefined
                              ? editedValues.qty
                              : remittance.qty
                          }
                          onChange={(e) => handleInputChange(e, "qty")}
                        />
                      ) : (
                        remittance.qty
                      )}
                    </td>


                    <td id="showUserTablebody"
                      style={{
                        display: allKeys?.has(labor_gdc) ? "table-cell" : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          placeholder="Labor GDC"
                          type="text"
                          className="input-field"
                          style={{ width: "60px" }}
                          value={
                            editedValues.labor_gdc !== undefined
                              ? editedValues.labor_gdc
                              : remittance.labor_gdc
                          }
                          onChange={(e) => handleInputChange(e, "labor_gdc")}
                        />
                      ) : (
                        remittance.labor_gdc || "N/A"
                      )}
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(comment1) ? "table-cell" : "none",
                      }}
                    >
                      {remittance.comment ? (
                        <CommentIcon
                          data-bs-toggle="modal"
                          data-bs-target={`#modalfade-${index}`}
                          onClick={() => handleOpenModal(remittance)}
                          style={{ color: "#008000" }} // Green color if comment exists
                        />
                      ) : (
                        <CommentIcon
                          data-bs-toggle="modal"
                          data-bs-target={`#modalfade-${index}`}
                          onClick={() => handleOpenModal(remittance)}
                          style={{ color: "#EBEBEB" }} // Light gray color if no comment
                        />
                      )}
                      <div
                        className="modal fade"
                        id={`modalfade-${index}`}
                        tabIndex="-1"
                        aria-labelledby={`popupLabel-${index}`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <div>
                                <h5
                                  className="fw-bold text-start"
                                  id={`popupLabel-${index}`}
                                >
                                  Comment
                                </h5>
                                <span>
                                  Order No- J
                                  {selectedRemittance && selectedRemittance.id}
                                </span>
                              </div>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body text-center">
                              <span>{remittance.comment || "N/A"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(refLink1) ? "table-cell" : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "30px" }}
                          value={
                            editedValues.refLink !== undefined
                              ? editedValues.refLink
                              : remittance.refLink
                          }
                          onChange={(e) => handleInputChange(e, "refLink")}
                        />
                      ) : remittance.refLink ? (
                        <Link
                          target="_blank"
                          to={remittance.refLink}
                          style={{ color: "#008000" }}
                        >
                          <MDBIcon fas icon="eye" />
                        </Link>
                      ) : (
                        <MDBIcon fas icon="eye" style={{ color: "#EBEBEB" }} />
                      )}
                    </td> */}

                    {/* <td id="showUserTablebody">
                      <FilterIcon
                        data-bs-toggle="modal"
                        data-bs-target={`#modal-${index}`}
                        onClick={() => handleOpenModal(remittance)}
                        style={{
                          color: remittance.refImge ? "#008000" : "#EBEBEB",
                        }} // Green color if comment exists, else light gray
                      />
                      <div
                        className="modal fade"
                        id={`modal-${index}`}
                        tabIndex="-1"
                        aria-labelledby={`popupLabel-${index}`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="fw-bold text-start"
                                id={`popupLabel-${index}`}
                              >
                                Ref Images - {remittance.id}
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              {remittance.refImge ? (
                                (() => {
                                  try {
                                    const sanitizedRefImge =
                                      remittance.refImge.replace(/\\\//g, "/");
                                    let images = [];

                                    // Check if it's a JSON array or a single image path
                                    if (
                                      sanitizedRefImge.startsWith('["') &&
                                      sanitizedRefImge.endsWith('"]')
                                    ) {
                                      images = JSON.parse(sanitizedRefImge);
                                    } else {
                                      images = [sanitizedRefImge];
                                    }

                                    return (
                                      <MDBCarousel
                                        showControls
                                        style={{ color: "red" }}
                                      >
                                        {images.map((media, mediaIndex) => (
                                          <MDBCarouselItem
                                            key={mediaIndex}
                                            itemId={mediaIndex + 1}
                                          >
                                            <img
                                              src={`${baseUrl}${media}`}
                                              className="d-block w-100"
                                              alt="Final"
                                              height={450}
                                              loading="lazy"
                                              onClick={() =>
                                                window.open(
                                                  `${baseUrl}${media}`
                                                )
                                              }
                                            />
                                          </MDBCarouselItem>
                                        ))}
                                      </MDBCarousel>
                                    );
                                  } catch (error) {
                                    console.error("Error parsing JSON:", error);
                                    return (
                                      <img
                                        src="./No Image Fund.jpg"
                                        className="d-block w-100"
                                        alt="Final"
                                        height={450}
                                        loading="lazy"
                                      />
                                    );
                                  }
                                })()
                              ) : (
                                <img
                                  src="./No Image Fund.jpg"
                                  className="d-block w-100"
                                  alt="Final"
                                  height={450}
                                  loading="lazy"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td> */}

                    {/* <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(PoNo1) ? "table-cell" : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "30px" }}
                          value={
                            editedValues.PoNo !== undefined
                              ? editedValues.PoNo
                              : remittance.PoNo
                          }
                          onChange={(e) => handleInputChange(e, "PoNo")}
                        />
                      ) : (
                        remittance.PoNo
                      )}
                    </td> */}

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(DesignNeed1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          type="text"
                          className="input-field"
                          style={{ width: "50px" }}
                          value={
                            editedValues.DesignNeed !== undefined
                              ? editedValues.DesignNeed
                              : remittance.DesignNeed
                          }
                          onChange={(e) => handleInputChange(e, "DesignNeed")}
                        />
                      ) : (
                        remittance.DesignNeed
                      )}
                    </td>

                    <td id="showUserTablebody"
                      style={{
                        display: allKeys?.has(quote)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      {editMode && editedRowIndex === index ? (
                        <input
                          placeholder="Quote"
                          type="text"
                          className="input-field"
                          style={{ width: "60px" }}
                          value={
                            editedValues.quote !== undefined
                              ? editedValues.quote
                              : remittance.quote
                          }
                          onChange={(e) => handleInputChange(e, "quote")}
                        />
                      ) : (
                        remittance.quote || "N/A"
                      )}
                    </td>

                    <td id="showUserTablebody">
                      {formatDate(remittance.due_date)}
                    </td>

                    {/* <td id="showUserTablebody">{remittance.bagNo}</td> */}

                    {/* <td id="showUserTablebody">
                      {formatDate(remittance.estDate, "estDate")}
                    </td>
                    <td id="showUserTablebody">
                      {formatDate(remittance.deliveryDate)}
                    </td> */}

                    {/* <td id="showUserTablebody">
                      {formatDate(remittance.due_date)}
                    </td> */}

                    {/* <td
                      id="showUserTablebody"
                      className="fw-bold"
                      style={{
                        color:
                          !remittance.id || !remittance.priority
                            ? "orange"
                            : remittance.priority === "Urgent"
                            ? "green"
                            : remittance.priority === "High"
                            ? "blue"
                            : remittance.priority === "Normal"
                            ? "#9A208C"
                            : remittance.priority === "Low"
                            ? "#4FA095"
                            : "inherit",
                        display: allKeys?.has(Priority1)
                          ? "table-cell"
                          : "none",
                        justifyContent: "center",
                      }}
                    >
                      {!remittance.id || !remittance.priority
                        ? "Select"
                        : remittance.priority}
                    </td> */}
                    {/* <td id="showUserTablebody">{remittance.priority}</td> */}

                    <td id="showUserTablebody">
                      <MDBBtn
                        className="userChat"
                        id="chatBox"
                        data-bs-toggle="modal"
                        data-bs-target={`#popup-${index}`}
                        onClick={() => setChatId(remittance.id)}
                      >
                        <MDBIcon far icon="comments" />
                        {renderRedDot(remittance.id)}
                      </MDBBtn>
                      <div
                        className="modal fade"
                        id={`popup-${index}`}
                        tabIndex="-1"
                        aria-labelledby={`popupLabel-${index}`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <div className="text-start">
                                <span>Order No-J{remittance.id}</span>
                                <br />
                                <span className="text-danger">
                                  *Content can be deleted within 3 minutes
                                </span>
                              </div>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div
                              className="modal-body"
                              ref={chatBoxRef}
                              onScroll={handleScroll}
                            >
                              <div>
                                <ChatApp
                                  chatPostId={remittance.id}
                                  chatData={chatData}
                                  handleChat={handleChat}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      id="showUserTablebody"
                      style={{
                        display: allKeys?.has(finalImg1)
                          ? "table-cell"
                          : "none",
                      }}
                    >
                      <div key={remittance.id}>
                        <MDBBtn
                          id={`chatBox-${remittance.id}`}
                          data-bs-toggle="modal"
                          data-bs-target={`#viewImage-${remittance.id}`}
                          className="userChat"
                          style={{
                            backgroundColor: remittance.finalImg
                              ? ""
                              : "#EBEBEB",
                          }}
                        >
                          <MDBIcon fas icon="photo-video" />
                        </MDBBtn>
                        <div
                          className="modal fade"
                          id={`viewImage-${remittance.id}`}
                          tabIndex="-1"
                          aria-labelledby={`popupLabel-${remittance.id}`}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id={`popupLabel-${remittance.id}`}
                                >
                                  Final Image - J{remittance.id}
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                {remittance.finalImg ? (
                                  <MDBCarousel
                                    showControls
                                    style={{ color: "red" }}
                                  >
                                    <div>
                                      {JSON.parse(remittance.finalImg).map(
                                        (media, mediaIndex) => (
                                          <MDBCarouselItem
                                            key={mediaIndex}
                                            itemId={mediaIndex + 1}
                                          >
                                            <div key={mediaIndex}>
                                              {media.endsWith(".mp4") ? (
                                                <video
                                                  controls
                                                  className="w-100"
                                                >
                                                  <source
                                                    src={`${baseUrl}/${media}`}
                                                    type="video/mp4"
                                                  />
                                                  Your browser does not support
                                                  the video
                                                </video>
                                              ) : (
                                                <img
                                                  src={`${baseUrl}/${media}`}
                                                  alt={`Media ${mediaIndex}`}
                                                  className="w-100"
                                                  loading="lazy"
                                                />
                                              )}
                                            </div>
                                          </MDBCarouselItem>
                                        )
                                      )}
                                    </div>
                                  </MDBCarousel>
                                ) : (
                                  <img
                                    src="No Image Fund.jpg"
                                    alt="No Images"
                                    className=" w-100 h-100 mw-50 mh-50"
                                    loading="lazy"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td id="showUserTablebody">
                      <MDBBtn
                        id="chatBox"
                        className="userChat"
                        data-bs-toggle="modal"
                        data-bs-target={`#trackProduct-${index}`}
                        onClick={() => handleTrackOrderClick(remittance.id)}
                      >
                        <MDBIcon fas icon="search-location" />
                      </MDBBtn>
                      <div
                        className="modal fade"
                        id={`trackProduct-${index}`}
                        tabIndex="-1"
                        aria-labelledby={`popupLabel-${index}`}
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id={`popupLabel-${index}`}
                              >
                                Order - J{remittance.id}
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              {loading1 ? (
                                <div className="d-flex justify-content-center">
                                  <div className="loader"></div>
                                </div>
                              ) : (
                                <TrackProduct trackingData={trackingData} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td id="showUserTablebody">
                      {editMode && editedRowIndex === index ? (
                        <>
                          <SaveIcon
                            onClick={() => handleSave(remittance, editedValues)}
                          />
                        </>
                      ) : remittance.requestStatus === "Req_Pending" ||
                        remittance.requestStatus === "Req_Approved" ||
                        remittance.requestStatus === "Designing" ||
                        remittance.requestStatus ===
                        "Design Approval Pending" ? (
                        // Enable EditIcon if the condition is met
                        <EditIcon onClick={() => toggleEditMode(index)} />
                      ) : remittance.requestStatus === "Design Approved" ||
                        remittance.requestStatus === "Wax & Cast" ||
                        remittance.requestStatus === "Filing" ||
                        remittance.requestStatus === "Pre Polishing" ||
                        remittance.requestStatus === "Diamond" ||
                        remittance.requestStatus === "Metal Setting" ||
                        remittance.requestStatus === "Final Polishing" ||
                        remittance.requestStatus === "Finished Good" ||
                        remittance.requestStatus === "Export" ? (
                        <EditIcon style={{ opacity: 0.5 }} />
                      ) : (
                        // Enable EditIcon by default if none of the above conditions are met
                        <EditIcon onClick={() => toggleEditMode(index)} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <MDBIcon
                    fas
                    icon="angle-double-left"
                    className={`p-2 rounded-9 text-white ${currentPage === 1 ? "disabled" : ""
                      }`}
                    style={{ backgroundColor: "#386bc0" }}
                  />
                </button>
              </li>
              <li className="page-item">
                <span className="page-link px-2">
                  Page {currentPage} of {totalPages}
                </span>
              </li>
              <li
                className={`page-item ${currentPage === totalPages ? "disabled" : ""
                  }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <MDBIcon
                    fas
                    icon="angle-double-right"
                    className={`p-2 rounded-9 text-white ${currentPage === totalPages ? "disabled" : ""
                      }`}
                    style={{ backgroundColor: "#386bc0" }}
                  />
                </button>
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
}
