import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Slice/AuthSlice";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import * as yup from 'yup';
import Swal from 'sweetalert2'
import { Contact, Logo } from "../../utils/Url";

export default function CustomerLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);


  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required")
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(""); // Clear previous error message when input changes
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(""); // Clear previous error message when input changes
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await schema.validate({ email, password }, { abortEarly: false });

      // If validation passes, proceed with login
      dispatch(loginUser({ email, password }))
        .unwrap()
        .then((response) => {
          const { token, user } = response;
          localStorage.setItem("token", token);
          localStorage.setItem("userId", user.email);
          localStorage.setItem("userRoleId", user.role_id);
          localStorage.setItem("userCode", user.client_code);
          localStorage.setItem("client_name", user.client_name);
          localStorage.setItem("allowOutlet", user.allow_outlet);

          switch (user.role_id) {
            case 1:
              navigate("/userOrder");
              break;
            case 2:
              navigate("/adminOrder");
              break;
            case 3:
              navigate("/superAdminOrder");
              break;
            default:
              navigate("/userOrder");
          }
        })
        .catch((error) => {
          console.error("Login failed!", error);
          Swal.fire({
            icon: "error",
            title: "Login failed!",
            text: "Something went wrong! Please Try Again",
          });
          if (error.error === 'invalid_credentials') {
            setLoginError("Invalid email or password");
          }
        });
    } catch (err) {
      err.inner.forEach((error) => {
        if (error.path === "email") {
          setEmailError(error.message);
        } else if (error.path === "password") {
          setPasswordError(error.message);
        }
      });
    }
  };

  return (
    <div id="LoginMainBox">
      <div id="LoginBoxCol">
        <img
          src='./loginSideImg.jpg'
          alt="loginImg"
          className="w-100 h-100"
          loading="lazy"
        />
      </div>
      <div id="LoginBoxCol">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="text-center">
            <img
              src={Logo[0]}
              alt="logo_skylab.png"
              width={Logo[1]}
              height={Logo[2]}
              loading="lazy"
            />
            <p className="fs-5 my-4 signupText">Login User to access</p>
            <input
              placeholder="Enter your email"
              type="email"
              className="loginInputField"
              value={email}
              onChange={handleEmailChange}
            />
            {/* {emailError && <p className="text-danger" style={{ color: "red" }}>{emailError}</p>} */}
            <input
              placeholder="Password"
              type="password"
              className="loginInputField"
              value={password}
              onChange={handlePasswordChange}
            />
            {passwordError && <p className="text-danger" style={{ color: "red" }}>{passwordError}</p>}
            {loginError && <p className="text-danger" style={{ color: "red" }}>{loginError}</p>}
            <MDBBtn
              type="submit"
              className="rounded-pill py-2 my-2 px-5"
              id="loginButton"
              disabled={loginStatus === "loading"}
            >
              {loginStatus === "loading" ? <MDBSpinner /> : "Login"}
            </MDBBtn>
            {error && <p className="text-danger">{error}</p>}

            <p
              className="cursor-pointer text-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Forgot Password
            </p>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Forgot Password
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body d-flex justify-content-center align-items-center h-100">
                    <div>
                      <p><span className="fw-bold">Please Contact :</span><a href="tel:+4733378901">{Contact[0]}</a></p>
                      <p>Or</p>
                      <p><span className="fw-bold">Please Email :</span><a href="mailto:someone@example.com">{Contact[1]}</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <p>
              Don't have an account?{" "}
              <Link
                to="/CustomerSignUp"
                className="text-decoration-underline signupText"
              >
                Sign up
              </Link>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
}
