import React, { useState, lazy, Suspense } from 'react';
import SuperAdminUploadPopup from './Components/SuperAdminUploadPopup';
import SuperAdminSidebar from '../SuperAdminSidebar/SuperAdminSidebar';
import SuperAdminNavbar from '../SuperAdminSidebar/SuperAdminNavbar';

// Lazy load the SuperAdminCardFilter component
const SuperAdminCardFilter = lazy(() => import('./Components/SuperAdminCardFilter'));

export default function Style() {
  const [csvData, setCsvData] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDownload = () => {
    // Filter the csvData based on the selected card IDs
    const dataToDownload = csvData.filter(item => selectedCards.includes(item.id));

    if (dataToDownload && dataToDownload.length > 0) {
      // Extract headers from the first item
      const headers = Object.keys(dataToDownload[0]);

      // Construct CSV content with headers
      let csvContent = headers.join(",") + "\n";
      csvContent += dataToDownload.map(row => headers.map(header => row[header]).join(",")).join("\n");

      // Create a Blob object with the CSV content
      const blob = new Blob([csvContent], { type: "text/csv" });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element for download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "selected_data.csv");

      // Append the link to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link element and revoke the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.error("No data selected for download.");
    }
  };


  // Filter CSV data based on search query
  const filteredDataSearchSuperAdmin = csvData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div>
      <SuperAdminNavbar />
      <div style={{ display: 'flex' }}>
        <SuperAdminSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div style={{ flex: 1, padding: '20px', marginTop: '30px', marginLeft: isSidebarOpen ? '13%' : '4.5%' }}>
          <p className="fs-1">Style</p>
          <div className='pb-3'>
            <SuperAdminUploadPopup setCsvData={setCsvData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setSelectedCards={setSelectedCards} selectedCards={selectedCards} handleDownload={handleDownload} />
          </div>
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <SuperAdminCardFilter csvData={csvData} setCsvData={setCsvData} filteredDataSearchSuperAdmin={filteredDataSearchSuperAdmin} setSelectedCards={setSelectedCards} selectedCards={selectedCards} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}
