export  const baseUrl = process.env.REACT_APP_URL === "SKYLAB" 
  ? process.env.REACT_APP_API 
  : process.env.REACT_APP_API_GDC;


  export  const Permissionclient = process.env.REACT_APP_URL === "SKYLAB" ? process.env.REACT_APP_SKYLAB.split(',') : process.env.REACT_APP_GDC?.split(',')



  export  const Logo = process.env.REACT_APP_URL === "SKYLAB" ? process.env.REACT_APP_SKYLAB_LOGO?.split(',') : process.env.REACT_APP_GDC_LOGO?.split(',')


  export  const Name = process.env.REACT_APP_URL === "SKYLAB" ? process.env.REACT_APP_SKYLAB_NAME : process.env.REACT_APP_GDC_NAME


  export const Favicon = process.env.REACT_APP_URL === "SKYLAB" 
  ? process.env.REACT_APP_SKYLAB_FAVICON 
  : process.env.REACT_APP_GDC_FAVICON;


  export const Contact = process.env.REACT_APP_URL === "SKYLAB" 
  ? process.env.REACT_APP_SKYLAB_CONTACT?.split(',') 
  : process.env.REACT_APP_GDC_CONTACT?.split(',');