import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBBtn, MDBCarousel, MDBCarouselItem, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import ChatApp from '../../../Users/chatbot/ChatApp';
import { baseUrl } from '../../../utils/Url';

const PendingOrders = () => {
  const [remittanceData, setRemittanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to display per page

  useEffect(() => {
    const fetchRemittanceData = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/get_customize_order_list`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBwLm5pZGhpcXJjb2RlLnNob3AvYXBpL2Nsb2dpbiIsImlhdCI6MTcxMTk3NTYyOCwiZXhwIjoxNzExOTc5MjI4LCJuYmYiOjE3MTE5NzU2MjgsImp0aSI6IlFiT1FDczB5MWxDOEpFTHkiLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Nng0DNhpM6apsp7z65aGLoO4dH7QtfYK4jxDqvk2Z40',
            'Content-Type': 'application/json' 
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRemittanceData(data); 

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchRemittanceData();
  }, []);




  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRemittanceData = remittanceData?.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <table className='table table-bordered'>
        <thead id='addProductTableHead'>
          <tr>
            <th className="p-2 fw-bold">Select</th>
            <th className="p-2 fw-bold">Req._No.</th>
            <th className="p-2 fw-bold">Req._Date</th>
            <th className="p-2 fw-bold">Req._Status</th>
            <th className="p-2 fw-bold">Jewelry_Type</th>
            <th className="p-2 fw-bold">Jewelry_Sub_Type</th>
            <th className="p-2 fw-bold">Ref_Lot_ID</th>
            <th className="p-2 fw-bold">Shape_Type</th>
            <th className="p-2 fw-bold">Metal_Type</th>
            <th className="p-2 fw-bold">Jewelry_Size</th>
            <th className="p-2 fw-bold">Diamond_a/p_Size</th>
            <th className="p-2 fw-bold">Qty</th>
            <th className="p-2 fw-bold">Comment</th>
            <th className="p-2 fw-bold">Ref.link</th>
            <th className="p-2 fw-bold">Chatbox</th>
            <th className="p-2 fw-bold">Final Image</th>

          </tr>
        </thead>
        <tbody style={{ overflow: "auto", width: '100%', height: '200px' }}>
          {currentRemittanceData?.map((remittance, index) => (
            <tr key={index}>
              <td id='showUserTablebody'>{remittance.id * 54}</td>
              <td id='showUserTablebody'>{remittance.requestDate}</td>
              <td id='showUserTablebody'>{remittance.jewelryType}</td>
              <td id='showUserTablebody'>{remittance.jewelrySubType}</td>
              <td id='showUserTablebody'>{remittance.refLotId}</td>
              <td id='showUserTablebody'>{remittance.shapeType}</td>
              <td id='showUserTablebody'>{remittance.metalType}</td>
              <td id='showUserTablebody'>{remittance.jewelrySize}</td>
              <td id='showUserTablebody'>{remittance.diamondApoSize} ct</td>
              <td id='showUserTablebody'>{remittance.Qty}</td>
              <td id='showUserTablebody' className='text-danger'>Pending</td>
            </tr>
          ))}
        </tbody>

      </table>

      {/* Pagination */}
      <ul className="pagination">
        {Array.from({ length: Math.ceil(remittanceData.length / itemsPerPage) }).map((_, index) => (
          <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PendingOrders;
