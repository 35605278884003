import { MDBBtn, MDBBtnGroup } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import SuperAdminCsvUpload from './SuperAdminCsvUpload';

export default function SuperAdminUploadPopup({ setCsvData, handleDownload, setSearchQuery, searchQuery }) {

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <MDBBtnGroup className="mt-4 justify-content-between d-flex">
          <MDBBtn
            data-bs-toggle="modal"
            data-bs-target="#UploadCSV"
            className="btn-bg-color me-2"
          >
            Upload CSV
          </MDBBtn>
          <MDBBtn className="bg-danger" onClick={handleDownload}>
            Download CSV
          </MDBBtn>
        </MDBBtnGroup>
        <span>
          <div className="search-box">
            <button className="btn-search">
              <i className="fas fa-search"></i>
            </button>
            <input
              type="text"
              className="input-search"
              placeholder="Type to Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </span>
      </div>
      <div className="modal fade" id="UploadCSV" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Upload CSV File</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <SuperAdminCsvUpload setCsvData={setCsvData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
