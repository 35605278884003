// import React, { useState, useEffect } from "react";

// export default function SuperAdminTrackOrder({ trackingData }) {
//   const [completedTasks, setCompletedTasks] = useState(tasks.map(() => false));

//   useEffect(() => {
//     if (trackingData.length > 0) {
//       const lastStatus = trackingData[trackingData.length - 1].requestStatus;

//       const lastIndex = tasks.findIndex(task => task.label === lastStatus);

//       if (lastIndex === -1) {
//         setCompletedTasks(tasks.map(() => false));
//       } else {
//         const newCompletedTasks = tasks.map((task, index) => index <= lastIndex);
//         setCompletedTasks(newCompletedTasks);
//       }
//     } else {
//       setCompletedTasks(tasks.map(() => false));
//     }
//   }, [trackingData]);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-12 col-md-12 hh-grayBox px-0 pt45 pb20">
//           <div className="row" style={{ display: "flex" }}>
//             {tasks.map((task, index) => (
//               <div
//                 className={`order-tracking ${completedTasks[index] ? "completed" : ""} ${index !== 0 && !completedTasks[index - 1] ? "disabled" : ""}`}
//                 key={index}
//                 style={{ display: "inline-block", width: "10%" }}
//               >
//                 <span className={`is-complete ${completedTasks[index] ? "completed" : ""}  d-flex flex-row`}></span>
//                 <p style={{ marginBottom: "0" }}>
//                   {task.label}
//                   <br />
//                   {completedTasks[index] && (
//                     <span>
//                       {trackingData.find(data => data.requestStatus === task.label)?.modified_date && new Date(trackingData.find(data => data.requestStatus === task.label)?.modified_date).toLocaleDateString('en-US')}
//                     </span>
//                   )}
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// const tasks = [
//   { label: "Designing", modified_date: "" },
//   { label: "Wax & Cast", modified_date: "" },
//   { label: "Filing", modified_date: "" },
//   { label: "Pre Polishing", modified_date: "" },
//   { label: "Assembly Filling", modified_date: "" },
//   { label: "Diamond", modified_date: "" },
//   { label: "Metal Setting", modified_date: "" },
//   { label: "Final Polishing", modified_date: "" },
//   { label: "Finished Good", modified_date: "" },
//   { label: "Export", modified_date: "" },
// ];


import React, { useState, useEffect } from "react";

export default function SuperAdminTrackOrder({ trackingData }) {
  const [completedTasks, setCompletedTasks] = useState(tasks.map(() => false));
  const [holdIndex, setHoldIndex] = useState(null);

  useEffect(() => {
    if (trackingData.length > 0) {
      const lastStatus = trackingData[trackingData.length - 1].requestStatus;

      if (lastStatus === "Hold") {
        const previousStatus = trackingData[trackingData.length - 2]?.requestStatus;
        const previousIndex = tasks.findIndex((t) => t.label === previousStatus);
        setHoldIndex(previousIndex);
        const newCompletedTasks = tasks.map((task, index) => index <= previousIndex);
        setCompletedTasks(newCompletedTasks);
      } else {
        setHoldIndex(null);
        const lastIndex = tasks.findIndex((task) => task.label === lastStatus);

        if (lastIndex === -1) {
          setCompletedTasks(tasks.map(() => false));
        } else {
          const newCompletedTasks = tasks.map((task, index) => index <= lastIndex);
          setCompletedTasks(newCompletedTasks);
        }
      }
    } else {
      setCompletedTasks(tasks.map(() => false));
    }
  }, [trackingData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 hh-grayBox px-0 pt45 pb20">
          <div className="row" style={{ display: "flex" }}>
            {tasks.map((task, index) => (
              <div
                className={`order-tracking ${completedTasks[index] ? "completed" : ""} ${index !== 0 && !completedTasks[index - 1] ? "disabled" : ""}`}
                key={index}
                style={{ display: "inline-block", width: "10%" }}
              >
                <span className={`is-complete ${completedTasks[index] ? "completed" : ""}  d-flex flex-row`}></span>
                <p style={{ marginBottom: "0" }}>
                  {task.label}
                  <br />
                  {completedTasks[index] && (
                    <span>
                      {trackingData.find((data) => data.requestStatus === task.label)?.modified_date && new Date(trackingData.find((data) => data.requestStatus === task.label)?.modified_date).toLocaleDateString('en-US')}
                    </span>
                  )}
                  <br />
                  {index === holdIndex && (
                    <span style={{ color: "red" }}>Hold</span>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const tasks = [
  { label: "Designing", modified_date: "" },
  { label: "Wax & Cast", modified_date: "" },
  { label: "Filing", modified_date: "" },
  { label: "Pre Polishing", modified_date: "" },
  { label: "Assembly Filling", modified_date: "" },
  { label: "Diamond", modified_date: "" },
  { label: "Metal Setting", modified_date: "" },
  { label: "Final Polishing", modified_date: "" },
  { label: "Finished Good", modified_date: "" },
  { label: "Export", modified_date: "" },
];







// export default function Scroll() {
//   const containerRef = useRef(null);
//   const dataArray = ['Data 1', 'Data 2', 'Data 3', 'Data 4', 'Data 5', 'Data 6', 'Data 7', 'Data 8', 'Data 9', 'Data 10','Data 11', 'Data 12', 'Data 13', 'Data 14', 'Data 15', 'Data 16', 'Data 17', 'Data 18', 'Data 19', 'Data 20'];
//   const [showAll, setShowAll] = useState(false);

//   const toggleShowAll = () => {
//       setShowAll(prevState => !prevState);
//   };

//   return (
//       <div className='container'>
//           {/* Input field */}
//           <input type="text" onClick={toggleShowAll} placeholder="Click here to see all data" />

//           {/* Scrollable container */}
//           <div ref={containerRef} style={{ maxWidth: "200px", maxHeight: '300px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}>
//               {dataArray.map((data, index) => (
//                   <p key={index}>{data}</p>
//               ))}
//           </div>
//       </div>
//   );
// }