// App.js
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Admin/Dashboard/Dashboard";
import Orders from "./Admin/Order/Orders";
import Report from "./Admin/Report/Report";
import AdminLogin from "./Admin/AdminLogin/AdminLogin";
import CustomerLogin from "./Users/Login/CustomerLogin";
import CustomerSignUp from "./Users/Login/CustomerSignUp";
import Style from "./Admin/Design/Style";
import UserStyle from "./Users/userStyle/components/UserStyle";
import UserReport from "./Users/userReport/components/UserReport";
import UserOrder from "./Users/userOrder/components/UserOrder";
// import UserDashboard from "./Users/Dashbord/components/UserDashbord";
import PrivateRoute from "./PrivateRoute";
import NotFound from "./NotFound";
import SuperAdminStyle from "./Super_Admin/JewelliryStyle/SuperAdminStyle";
import SuperAdminStatus from "./Super_Admin/StatusSummary/SuperAdminStatus";
import SuperAdminOrder from "./Super_Admin/CreateOrder/SuperAdminOrder";
import SuperAdminAddNewUser from "./Super_Admin/SuperAdminAddNewUser/SuperAdminAddNewUser";
import SuperAdminPermission from "./Super_Admin/SuperAdminPermission.js/SuperAdminPermission";
import SuperAdminAddNewDummyUser from "./Super_Admin/SuperAdminAddNewUser/SuperAdminAddNewDummyUser";
import AdminExport from "./Admin/AdminExpot/AdminExport";
import UserExport from "./Users/UserExport/UserExport";
import SuperAdminExport from "./Super_Admin/ExpotUsers/SuperAdminExport";
import AdminCalculator from "./Admin/Calculator/AdminCalculator";
import SuperAdminCancleOrder from "./Super_Admin/CanceleOrder/SuperAdminCancleOrder";
import AdminCancleOrder from "./Admin/CanceleOrder/AdminCancleOrder";
import UserCancleOrder from "./Users/CancleOrder/UserCancleOrder";
import SubUser from "./Users/subUser/components/SubUser";
import ExportAgGridOrder from "./Super_Admin/ExportAgGrid/ExportAgGridOrder";
import CancleAgGridOrder from "./Super_Admin/CancleAgGrid/CancleAgGridOrder";
import AgGridOrder from "./Super_Admin/AgGrid/AgGridOrder";
import CadMasterOrder from "./Super_Admin/CadMaster/CadMasterOrder";
import AdminCadMasterOrder from "./Admin/AdminCadMaster/AdminCadMasterOrder";
import BulkOrder from "./Super_Admin/BluckOrder/BulkOrder";
import AdminBulkOrder from "./Admin/AdminBulk/AdminBulkOrder";
import { Favicon, Name } from "./utils/Url";


export default function App() {
  useEffect(() => {
    // Set the document title
    document.title = Name || "Default Title";

    // Dynamically set the favicon
    const link = document.querySelector("link[rel~='icon']") || document.createElement("link");
    link.rel = "icon";
    link.href = Favicon || "/default-favicon.ico"; // Set the favicon URL, fallback to default
    document.head.appendChild(link);

    return () => {
      // Cleanup if needed
      document.head.removeChild(link);
    };
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CustomerLogin />} />
        {/* <Route path="/CustomerSignUp" element={<PrivateRoute Component={CustomerSignUp} />} /> */}

        {/* User Router  */}

        {/* <Route path="/userDashboard" element={<PrivateRoute Component={UserDashboard} allowedRoles={[1]} />} /> */}
        <Route path="/userOrder" element={<PrivateRoute Component={UserOrder} allowedRoles={[1]} />} />
        <Route path="/userStyle" element={<PrivateRoute Component={UserStyle} allowedRoles={[1]} />} />
        <Route path="/userReport" element={<PrivateRoute Component={UserReport} allowedRoles={[1]} />} />
        <Route path="/adminLogin" element={<PrivateRoute Component={AdminLogin} />} />
        <Route path="/userExport" element={<PrivateRoute Component={UserExport} allowedRoles={[1]} />} />
        <Route path="/userExport" element={<PrivateRoute Component={UserExport} allowedRoles={[1]} />} />
        <Route path="/userCancelOrder" element={<PrivateRoute Component={UserCancleOrder} allowedRoles={[1]} />} />
        <Route path="/SubUser" element={<PrivateRoute Component={SubUser} allowedRoles={[1]} />} />

        {/* admin Router  */}

        {/* <Route path="/adminDashboard" element={<PrivateRoute Component={Dashboard} allowedRoles={[2 , 4]} />} /> */}
        <Route path="/adminOrder" element={<PrivateRoute Component={Orders} allowedRoles={[2, 4, 5]} />} />
        <Route path="/adminReport" element={<PrivateRoute Component={Report} allowedRoles={[2, 4, 5]} />} />
        <Route path="/Style" element={<PrivateRoute Component={Style} allowedRoles={[2, 4, 5]} />} />
        <Route path="/adminExport" element={<PrivateRoute Component={AdminExport} allowedRoles={[2, 4, 5]} />} />
        <Route path="/adminCancelOrder" element={<PrivateRoute Component={AdminCancleOrder} allowedRoles={[2, 4, 5]} />} />
        {/* <Route path="/Calculator" element={<PrivateRoute Component={AdminCalculator} allowedRoles={[2, 4, 5]} />} /> */}
        <Route path="/AdminCadMasterOrder" element={<PrivateRoute Component={AdminCadMasterOrder} allowedRoles={[2, 4, 5]} />} />
        <Route path="/AdminBulkOrder" element={<PrivateRoute Component={AdminBulkOrder} allowedRoles={[2, 4, 5]} />} />

        {/* SuperAdmin Router  */}

        <Route path="/superAdminOrder" element={<PrivateRoute Component={SuperAdminOrder} allowedRoles={[3]} />} />
        <Route path="/superAdminBulk" element={<PrivateRoute Component={BulkOrder} allowedRoles={[3]} />} />
        <Route path="/superAdminaddNewUser" element={<PrivateRoute Component={SuperAdminAddNewUser} allowedRoles={[3]} />} />
        <Route path="/superAdminStatus" element={<PrivateRoute Component={SuperAdminStatus} allowedRoles={[3]} />} />
        <Route path="/superAdminJewelleryStyle" element={<PrivateRoute Component={SuperAdminStyle} allowedRoles={[3]} />} />
        <Route path="/SuperAdminPermission" element={<PrivateRoute Component={SuperAdminPermission} allowedRoles={[3]} />} />
        <Route path="/SuperAdminAddNewDummyUser" element={<PrivateRoute Component={SuperAdminAddNewDummyUser} allowedRoles={[3]} />} />
        <Route path="/superAdminExport" element={<PrivateRoute Component={SuperAdminExport} allowedRoles={[3]} />} />
        <Route path="/superAdminCancelOrder" element={<PrivateRoute Component={SuperAdminCancleOrder} allowedRoles={[3]} />} />
        <Route path="/CadMasterOrder" element={<PrivateRoute Component={CadMasterOrder} />} allowedRoles={[3]} />

        {/* AgGrid */}
        {/* <Route path="/AgGrid" element={<PrivateRoute Component={AgGridOrder} allowedRoles={[3]} />} />
        <Route path="/superAdminCancel" element={<PrivateRoute Component={CancleAgGridOrder} allowedRoles={[3]} />} />
        <Route path="/superAdminExports" element={<PrivateRoute Component={ExportAgGridOrder} allowedRoles={[3]} />} /> */}

        {/* 404 Route */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

