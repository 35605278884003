import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/Url";

export default function TotalClient({ fetchUserData, loading, userData, setFilterCompany, filterCompany }) {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [centredModal, setCentredModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [roles, setRoles] = useState([]); // Add this line to define roles state

  const toggleResetModal = () => setResetModalOpen(!resetModalOpen);

  const toggleOpen = () => setCentredModal(!centredModal);



  useEffect(() => {
    fetchUserData()
    fetchUserRoles(); // Fetch roles when the component mounts
  }, [filterCompany]);

  const handleEdit = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/clients/${userId}`,
        requestOptions
      );
      if (!response.ok) {
        Swal.fire({
          title: "The Internet?",
          text: "Network response was not ok.",
          icon: "question",
        });
      }
      const result = await response.json();

      setSelectedUserId(userId);
      setName(result.name);
      setEmail(result.email);
      setRoleId(result.role_id);
      toggleOpen();
      fetchUserData()
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify({
          name,
          email,
          role_id: roleId,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/clients/${selectedUserId}`,
        requestOptions
      );
      const result = await response.json();
      Swal.fire({
        title: "Good job!",
        text: "Changes Successful!",
        icon: "success",
      });
      toggleOpen();
      fetchUserData();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/clients/${userId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      fetchUserData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const fetchUserRoles = async () => {
    try {
      const token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        `${baseUrl}/api/roles`,
        requestOptions
      );
      if (response.ok) {
        const responseData = await response.json();
        setRoles(responseData);
      } else {
        console.error("Error fetching user roles:", response.error);
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const handleSaveResetPassword = async () => {
    try {
      const token = localStorage.getItem("token");

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("password", newPassword);
      urlencoded.append("approved", "1");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${baseUrl}/api/clients/${selectedUserId}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Password Successfully Changed",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: "my-swal",
            },
            didOpen: () => {
              const swalContainer = document.querySelector(".my-swal");
              swalContainer.style.zIndex = 9999; // Set the z-index to a high value
            },
          });
          // Call toggleResetModal() function
          toggleResetModal();
        });
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  const handleResetPassword = (userId) => {
    setSelectedUserId(userId); // Set the selected user ID
    setNewPassword(""); // Reset the new password field
    toggleResetModal(); // Open the reset password modal
  };

  return (
    <div>
      <h3 className="text-center fw-semibold">Outlet Table</h3>
      <div className="d-flex align-items-center gap-2">
        <label>Company Name:</label>
        <input
          type="text"
          id="inputPassword6"
          className="form-control w-25"
          value={filterCompany}
          onChange={(e) => setFilterCompany(e.target.value)}
          placeholder="Company Name"
        />
      </div>

      <table className="table table-bordered my-4">
        <thead id="addProductTableHead">
          <tr>
            <th className="p-2 fw-bold col-md-1">Code</th>
            <th className="p-2 fw-bold">Company</th>
            {/* <th className="p-2 fw-bold">Edit</th> */}
            <th className="p-2 fw-bold">Delete</th>
            <th className="p-2 fw-bold">Reset_Password</th>
          </tr>
        </thead>

        {loading ? (
          <div className="d-flex justify-content-center w-100">
            <div className="loader"></div>
          </div>
        ) : (
          <tbody>
            {userData.map((user, index) => (
              <tr key={index} className="table-hover">
                <td id="showUserTablebody">{user.code}</td>
                <td id="showUserTablebody">{user.company} </td>
                {/* <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => handleEdit(user.id)}
                  >
                    Edit
                  </MDBBtn>
                </td> */}
                <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDelete(user.id);
                          Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                          });
                        }
                      });
                    }}
                  >
                    Delete
                  </MDBBtn>
                </td>
                <td id="showUserTablebody" style={{ width: "100px" }}>
                  <MDBBtn
                    type="button"
                    className="userChat btn-darkblue"
                    onClick={() => handleResetPassword(user.id)}
                  >
                    Reset
                  </MDBBtn>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <MDBModal tabIndex="-1" open={centredModal} setOpen={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Edit User</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nameInput"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailInput"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="roleInput" className="form-label">
                  Role
                </label>
                <select
                  className="form-control"
                  id="roleInput"
                  value={roleId}
                  onChange={(e) => setRoleId(e.target.value)}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleSaveChanges}>Save changes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal tabIndex="-1" open={resetModalOpen} setOpen={setResetModalOpen}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Reset Password</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleResetModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="mb-3">
                <label htmlFor="newPasswordInput" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="newPasswordInput"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleResetModal}>
                Cancel
              </MDBBtn>
              <MDBBtn onClick={handleSaveResetPassword}>Reset Password</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}
