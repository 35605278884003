import React, { useState } from "react";
import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import { baseUrl } from "../../utils/Url";

export default function UserUploadOrderData({ setRemittanceData, remittance }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadData, setUploadData] = useState([]);
    console.log("uploadData:", uploadData);

    const toggleModal = () => setShowModal(!showModal);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        try {
            const token = localStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const formdata = new FormData();
            formdata.append("file", selectedFile, "gdcorder.csv");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            }

            const response = await fetch(`${baseUrl}/api/add_custom_order/upload-csv`, requestOptions);
            const responseBody = await response.json();

            console.log("responseBody:", responseBody);
            setUploadData(responseBody);
            // setRemittanceData(responseBody);

            if (response.status === 200) {
                await Swal.fire({
                    title: "Upload successful!!",
                    text: "Upload Successfully Submitted!",
                    icon: "success",
                });
                setShowModal(false);
            } else {
                console.error("Error response from server:", response.status, responseBody);
                alert("An error occurred while storing data. Please try again.");
            }
        } catch (error) {
            console.log(error);
        }

        if (selectedFile) {
            console.log('Uploading:', selectedFile);
        } else {
            console.log('No file selected');
        }
        toggleModal();
    };

    return (
        <div>
            <MDBBtn className="btn-bg-color" onClick={toggleModal}>
                Upload CSV
            </MDBBtn>

            <MDBModal open={showModal} setOpen={setShowModal} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <h5 className="modal-title">Upload Csv File</h5>
                            <MDBBtn className="btn-close" color="none" onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <input type="file" accept=".csv" onChange={handleFileChange} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn className="btn-bg-color" onClick={handleUpload}>
                                Upload
                            </MDBBtn>
                            <MDBBtn color="secondary" onClick={toggleModal}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}