import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OutlateTable from "./components/OutlateTable";
import Swal from "sweetalert2";
import { baseUrl } from "../../utils/Url";

export default function SubUserCreate() {
  const loginStatus = useSelector((state) => state.auth.user);
  const c_nameget = localStorage.getItem("client_name");
  const userCode = localStorage.getItem("userCode");

  const [formData, setFormData] = useState({
    outlet_name: "",
    email: "",
    password: "",
    subuser_name: "",
    client_name: c_nameget,
    client_code: userCode,
  });

  const [refreshTableKey, setRefreshTableKey] = useState(0); // Key to force refresh of OutlateTable
  const [showSwal, setShowSwal] = useState(false); // State to control Swal visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const payload = { ...formData };

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(
        `${baseUrl}/api/outlet`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      setFormData({
        outlet_name: "",
        email: "",
        password: "",
        subuser_name: "",
        client_name: c_nameget,
        client_code: userCode,
      });

      setShowSwal(true); // Show Swal after successful submission
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  // Effect to handle refreshing OutlateTable
  useEffect(() => {
    const refreshTable = async () => {
      // Incrementing refreshTableKey to force OutlateTable to re-render
      setRefreshTableKey((prevKey) => prevKey + 1);
    };

    if (showSwal) {
      // Trigger refreshTable after Swal is dismissed
      Swal.fire({
        title: "Good job!",
        text: "Sub User Successfully Created!",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          refreshTable(); // Refresh the OutlateTable
          setShowSwal(false); // Hide Swal
        }
      });
    }
  }, [showSwal]); // Dependency on showSwal to trigger the effect

  return (
    <div className="d-flex gap-5">
      <div className="form-container">
        <h1 className="form-title">Create Sub Users</h1>

        <form onSubmit={handleSubmit}>
          <label>Sub User Name</label>
          <MDBInput
            wrapperClass="mb-4"
            className="input"
            name="subuser_name"
            placeholder="SubUser Name"
            value={formData.subuser_name}
            onChange={handleChange}
            required />

          <label>Outlet Name</label>
          <MDBInput
            wrapperClass="mb-4"
            className="input"
            name="outlet_name"
            placeholder="Outlet Name"
            value={formData.outlet_name}
            onChange={handleChange}
            required />

          <label>Email</label>
          <MDBInput
            wrapperClass="mb-4"
            type="email"
            className="input"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required />

          <label>Password</label>
          <MDBInput
            wrapperClass="mb-4"
            type="password"
            className="input"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required />

          <MDBBtn type="submit">Create Sub User</MDBBtn>
        </form>

      </div>
      <OutlateTable key={refreshTableKey} />
    </div>
  );
}
