import React, { useState, useEffect } from "react";

export default function TrackProduct({ trackingData }) {
  const [completedTasks, setCompletedTasks] = useState(tasks.map(() => false));
  const [holdIndex, setHoldIndex] = useState(null);

  useEffect(() => {
    if (trackingData.length > 0) {
      const lastStatus = trackingData[trackingData.length - 1].requestStatus;

      if (lastStatus === "Hold") {
        const previousStatus = trackingData[trackingData.length - 2]?.requestStatus;
        const previousIndex = tasks.findIndex((t) => t.label === previousStatus);
        setHoldIndex(previousIndex);
        const newCompletedTasks = tasks.map((task, index) => index <= previousIndex);
        setCompletedTasks(newCompletedTasks);
      } else {
        setHoldIndex(null);
        const lastIndex = tasks.findIndex((task) => task.label === lastStatus);

        if (lastIndex === -1) {
          setCompletedTasks(tasks.map(() => false));
        } else {
          const newCompletedTasks = tasks.map((task, index) => index <= lastIndex);
          setCompletedTasks(newCompletedTasks);
        }
      }
    } else {
      setCompletedTasks(tasks.map(() => false));
    }
  }, [trackingData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 hh-grayBox px-0 pt45 pb20">
          <div className="row" style={{ display: "flex" }}>
            {tasks.map((task, index) => (
              <div
                className={`order-tracking ${completedTasks[index] ? "completed" : ""} ${index !== 0 && !completedTasks[index - 1] ? "disabled" : ""}`}
                key={index}
                style={{ display: "inline-block", width: "10%" }}
              >
                <span className={`is-complete ${completedTasks[index] ? "completed" : ""}  d-flex flex-row`}></span>
                <p style={{ marginBottom: "0" }}>
                  {task.label}
                  <br />
                  {completedTasks[index] && (
                    <span>
                      {trackingData.find((data) => data.requestStatus === task.label)?.modified_date && new Date(trackingData.find((data) => data.requestStatus === task.label)?.modified_date).toLocaleDateString('en-US')}
                    </span>
                  )}
                  <br />
                  {index === holdIndex && (
                    <span style={{ color: "red" }}>Hold</span>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const tasks = [
  { label: "Designing", modified_date: "" },
  { label: "Wax & Cast", modified_date: "" },
  { label: "Filing", modified_date: "" },
  { label: "Pre Polishing", modified_date: "" },
  { label: "Assembly Filling", modified_date: "" },
  { label: "Diamond", modified_date: "" },
  { label: "Metal Setting", modified_date: "" },
  { label: "Final Polishing", modified_date: "" },
  { label: "Finished Good", modified_date: "" },
  { label: "Export", modified_date: "" },
];



// import React, { useState, useEffect } from "react";

// export default function TrackProduct({ trackingData }) {
//   const [completedTasks, setCompletedTasks] = useState(tasks.map(() => false));

//   useEffect(() => {
//     if (trackingData.length > 0) {
//       const lastStatus = trackingData[trackingData.length - 1].requestStatus;

//       const lastIndex = tasks.findIndex(task => task.label === lastStatus);

//       if (lastIndex === -1) {
//         // No matching status found. Update completedTasks and optionally display a message.
//         setCompletedTasks(tasks.map(() => false));
//         // You can add code here to display a message, e.g., using a separate state variable.
//       } else {
//         const newCompletedTasks = tasks.map((task, index) => index <= lastIndex);
//         setCompletedTasks(newCompletedTasks);
//       }
//     } else {
//       setCompletedTasks(tasks.map(() => false));
//     }
//   }, [trackingData]);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-12 col-md-12 hh-grayBox px-0 pt45 pb20">
//           <div className="row" style={{ display: "flex" }}>
//             {tasks.map((task, index) => (
//               <div
//                 className={`order-tracking ${completedTasks[index] ? "completed" : ""} ${index !== 0 && !completedTasks[index - 1] ? "disabled" : ""}`}
//                 key={index}
//                 style={{ display: "inline-block", width: "10%" }}
//               >
//                 <span className={`is-complete ${completedTasks[index] ? "completed" : ""}  d-flex flex-row`}></span>
//                 <p style={{ marginBottom: "0" }}>
//                   {task.label}
//                   <br />
//                   {completedTasks[index] && (
//                     <span>
//                       {trackingData.find(data => data.requestStatus === task.label)?.modified_date && new Date(trackingData.find(data => data.requestStatus === task.label)?.modified_date).toLocaleDateString('en-US')}
//                     </span>
//                   )}
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// const tasks = [
//   { label: "Designing", modified_date: "" },
//   { label: "Wax & Cast", modified_date: "" },
//   { label: "Filing", modified_date: "" },
//   { label: "Pre Polishing", modified_date: "" },
//   { label: "Assembly Filling", modified_date: "" },
//   { label: "Diamond", modified_date: "" },
//   { label: "Metal Setting", modified_date: "" },
//   { label: "Final Polishing", modified_date: "" },
//   { label: "Finished Good", modified_date: "" },
//   { label: "Export", modified_date: "" },
// ];

